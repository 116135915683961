import { CRUD_UPDATE } from './CrudType'
import { UPDATE } from 'react-admin';
import { startUndoable } from 'ra-core';

const crudUpdate = function (resource, id, data,  basePath, redirectTo) {
    if (redirectTo === void 0) { redirectTo = 'edit'; }
    return ({
        type: CRUD_UPDATE,
        payload: { id: id, data: data },
        meta: {
            resource: resource,
            fetch: UPDATE,
            onSuccess: {
                notification: {
                    body: 'flexi.edit.raw.success',
                    level: 'info',
                    messageArgs: {
                        smart_count: 1,
                    },
                },
                redirectTo: redirectTo,
                basePath: basePath,
                refresh:true,
            },
            onFailure: {
                notification: {
                    body: 'flexi.edit.raw.failed',
                    level: 'warning',
                },
            },
        },
    });
};

const handleUpdate = (props) => {
    const { resource, record, basePath, redirect, undoable } = props;
    if (undoable) {
        startUndoable(crudUpdate(resource, record.id, record, basePath, redirect))
    } else {
        crudUpdate(resource, record.id, record, basePath, redirect)
    }
};

export { handleUpdate }