import React from 'react'
import { NullableBooleanInput, SelectArrayInput, FormDataConsumer, BooleanInput, REDUX_FORM_NAME, Labeled } from 'react-admin';
import CardExpanded from '../CardExpanded'
import Card from "@material-ui/core/Card";
import { change } from 'redux-form'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    card: {
        padding:"0 10px"
    },
});

const FeaturesConfig = ({ source, ...props }) => {
    const classes = useStyles(props);

    return (
        <CardExpanded columns={4} {...props}>
            <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => (
                    <>
                        <Card className={classes.card}>
                            <Labeled label="Category">
                                <>
                                    <BooleanInput label="Category Tag Url" defaultValue={false} source={`cms_config.category_tag_url`} onChange={(e, value) => dispatch(change(REDUX_FORM_NAME, `cms_config.category_tag_url_multiple`, value === false ? false : formData["cms_config"].category_tag_url_multiple))} />
                                    {
                                        formData["cms_config"] && formData["cms_config"].category_tag_url && <BooleanInput label="Category Tag Url Multiple" defaultValue={false} source={`cms_config.category_tag_url_multiple`} />
                                    }
                                </>
                            </Labeled>
                        </Card>
                        <SelectArrayInput label="Most Used Blocks" source={`${source}.most_used_blocks`} choices={window.blocksCMS.sort((a, b) => (a.name > b.name) ? 1 : -1)} />
                        <NullableBooleanInput label="Copy images across websites" source={`${source}.copy_images_across_websites`} defaultValue={formData && formData["cms_config"] && formData["cms_config"].datastore === 'arango' ? false : true} />
                        <NullableBooleanInput label="Region sets" source={`${source}.region_sets`} defaultValue={true} />
                        <NullableBooleanInput label="Amp Review" source={`${source}.amp_review`} defaultValue={true} />
                        <NullableBooleanInput label="Amp Gallery" source={`${source}.amp_gallery`} defaultValue={true} />
                        <NullableBooleanInput label="Hide preview" source={`${source}.hide_preview`} defaultValue={false} />
                        <NullableBooleanInput label="Show Listing Title" source={`${source}.show_listing`} defaultValue={false} />
                        <NullableBooleanInput label="Imported Flag" source={`${source}.imported_flag`} defaultValue={false} />
                        <NullableBooleanInput label="Live Reports" source={`${source}.live_reports`} defaultValue={true} />
                        <NullableBooleanInput label="Author Roles" source={`${source}.author_roles`} defaultValue={false} />
                        <NullableBooleanInput label="Companies" source={`${source}.companies`} defaultValue={false} />
                        <NullableBooleanInput label="Persons" source={`${source}.persons`} defaultValue={false} />
                        <NullableBooleanInput label="EventGroups" source={`${source}.eventGroups`} defaultValue={false} />
                        <NullableBooleanInput label="Organisations" source={`${source}.organisations`} defaultValue={false} />
                        <NullableBooleanInput label="Stage Label (Multiple Events)" source={`${source}.stage_label_required_for_multiple_events`} defaultValue={false} />
                        <NullableBooleanInput label="Recipe" source={`${source}.recipe`} defaultValue={false} />
                        <NullableBooleanInput label="Shop" source={`${source}.shop`} defaultValue={false} />
                        <NullableBooleanInput label="Ball Sports" source={`${source}.football`} defaultValue={false} />
                        <BooleanInput label="Image Crop" source={`${source}.image_crop`} defaultValue={false} />
                        <BooleanInput label="Image Partial" source={`${source}.image_partial`} defaultValue={false} />
                    </>
                )}
            </FormDataConsumer>
        </CardExpanded>
    )
}

export default FeaturesConfig
