import React from 'react';
import { Field } from 'redux-form';
import { TextInput, NumberInput } from 'react-admin';
import InputFile from './InputFile';
import LinkListInput from './LinkListInput';
import RichTextInput from './RichTextInput';

const negativeNumber = value => (value && value < 0 ? 'This number must be >= 0' : undefined);
const number = value => (value && !Number.isInteger(value) ? 'Must be a number' : undefined);
const minValue = min => value => (value && value < min ? `Must be at least ${min}` : undefined);
const minValue0 = minValue(0);

export default (props) => {
    const elProps = {
        ...props,
        inputFields: [
            <TextInput source="title" label="Title" />,
            <TextInput source="subtitle" label="Subtitle" />,
            <TextInput source="url" label="URL" />,
            <NumberInput
                source="position"
                label="Positions"
                validate={[number, minValue0]}
                warn={negativeNumber}
            />,
            <Field
                name="description"
                toolbar={[
                    ['bold', 'italic', 'underline'], // toggled buttons
                    ['link'],
                    ['code-block'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ align: [] }],
                ]}
                label="Description"
                placeholder="Description"
                component={RichTextInput}
            />,
            <Field multiple={false} name="icon" source="icon" label="Icon" component={InputFile} />,
        ],
    };

    return <LinkListInput {...elProps} />;
};
