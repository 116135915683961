import React from 'react';
import { SelectInput, Filter } from 'react-admin';

const ListFilter = props => (
    <Filter {...props}>
        <SelectInput
            source="lang"
            choices={window.langs}
            alwaysOn
        />
    </Filter>
);

export default ListFilter;
