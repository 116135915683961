import React from 'react';
import { SelectField } from 'react-admin';

const ReferenceWidgetsField = ({ label, source, record }) => (
    <SelectField
        record={record}
        source={source}
        addLabel={false}
        choices={
            (record.widgets
            && record.widgets.map(widget => ({
                id: widget.slug,
                name: widget.name,
            }))) || []
        }
    />
);

export default ReferenceWidgetsField;
