import React from 'react';
import { TextField, SelectField } from 'react-admin';
import LinkListField from './LinkListField';

const typesById = window.toc_types.reduce((acc, type) => ({ ...acc, [type.id]: type }), {});

const getTitle = (item, data) => {
    const type = item.type;
    if (type) {
        const title = typesById[item.type] && typesById[item.type].name;
        const widgets = data && data.widgets ? data.widgets : [];
        const widget = widgets.find(w => w.slug === item.slug);
        const linked = type === 'widget' && widget ? `- (widget linked: ${widget.name})` : '';
        return `${title} ${linked}`;
    }

    return '';
};

const TocLinkListField = ({ data, record }) => (
    <LinkListField
        getTitle={getTitle}
        source="linklist"
        data={data}
        record={record}
        inputFields={[
            <SelectField
                source="type"
                name="type"
                label="Type"
                choices={window.toc_types}
            />,
            <SelectField
                source="slug"
                name="slug"
                label="widget.widget.lightbox_target_slug"
                choices={
                    (data.widgets
                        && data.widgets.map(widget => ({
                            id: widget.slug,
                            name: widget.name,
                        })))
                    || []
                }
            />,
            <TextField source="title" label="Title" />,
            <TextField source="link" label="Link" />,
        ]}
    />
);

export default TocLinkListField;
