import React, { Fragment } from 'react';
import Card from './Card';
// import {orderAscOnAreaPosition} from "../../tools";

const WidgetListField = ({ record, source, permissions }) => {
    return (
        <Fragment>
            {record[source]
            && record[source].length > 0
            && record[source].map((item, index) => (
                <Card
                    key={`widget-card-${(item.slug || index)}`}
                    record={item}
                    data={record}
                    WidgetListField
                    permissions={permissions}
                    source={`${source}[${index}]`}
                />
            ))}
        </Fragment>
    );
};

export default WidgetListField;
