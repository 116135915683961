import React from "react";
import { Labeled, TextField } from "react-admin";
import Grid from "./Grid";

const SliceWidgetField = ({ record }) => {
  return (
    <Grid columns="1">
      <Labeled label="Template">
        <TextField record={record} source="slice_component"></TextField>
      </Labeled>
      <Labeled label="Layout">
        <TextField record={record} source="slice_layout"></TextField>
      </Labeled>
      <Labeled label="Data source">
        <TextField record={record} source="slice_data_source"></TextField>
      </Labeled>

      <Labeled label="CONTENT" />
      <Labeled label="Success Message">
        <TextField record={record} source="slice_success_message"></TextField>
      </Labeled>
      <Labeled label="Already voted message">
        <TextField record={record} source="slice_already_voted_message" />
      </Labeled>
      <Labeled label="Already voted message">
        <TextField record={record} source="slice_error_message" />
      </Labeled>
    </Grid>
  );
};

export default SliceWidgetField;
