import React from "react";
import { ObjectField } from "./Fields";

export const AutoGenFields = ({ jsonData, source, template, disableAllFields }) => {
    const { properties = null, description = null, required = [], $defs = {}, pattern = null } = jsonData;

    if (!properties) {
        return <div></div>;
    }

    return (
        <ObjectField
            key={`${template}-${description}`}
            defs={$defs}
            groupingFlag={1}
            {...{ properties, description, required, source, pattern, disableAllFields }}
        />
    );
};
