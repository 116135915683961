import React from 'react';
import Typography from '@material-ui/core/Typography';

const TabContainer = props => (
    <Typography component="div">
        {props.children}
    </Typography>
);

export default TabContainer;
