import React from 'react';
import Create from '../Create';
import EditForm from './EditForm';
import {validateUnderscore} from '../../tools';

const CustomWidgetCreate = (props) => {

    return (<Create {...props}>
        <EditForm permissions={props.permissions} validateUnderscore={validateUnderscore} />
    </Create>)
};

export default CustomWidgetCreate;
