import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { getSource } from '../tools';
import {
    Labeled, TextField, ArrayField, Datagrid
} from 'react-admin';
import { TextFilterInput } from './TextFilterInput';

import useFilterConfigHanlder from "../Entities/Sites/siteConfigHandler";

const styles = () => ({
    root: {
        width: '100%',
    },
    label: {
        display: 'block',
    },
    detailsRoot: {
        display: 'block',
    }
});

class ApiListField extends Component {
    constructor(props){
        super(props);
        this.state = {isExpanded: false, filterStr: ""};
    }

    shouldComponentUpdate() {
        return true;
    }

    render() {
        const {
            classes, addRootLabel, label, getTitle, Fields, source, record
        } = this.props;

        return (
            <div className={classes.root}>
               
                {addRootLabel && <Labeled label={label} classes={{ label: classes.label }} />}
                <TextFilterInput  onFilterChanged={(str) => this.setState({filterStr: str})} />
                {
                    getSource(record, source)
                    && getSource(record, source).length > 0
                    && getSource(record, source).filter((listItem) =>{  return useFilterConfigHanlder(listItem, this.state.filterStr).isFiltered} )
                    .map((item, index) => {
                        return (
                            <ExpansionPanel key={`api-list-field-${source}-${index}`}  expanded={useFilterConfigHanlder(item, this.state.filterStr).isExpanded || null}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                        {getTitle(item) ? getTitle(item) : item}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
                                    {Fields.map((Field, i) => {
                                        return (
                                            <div key={`api-list-field-${source}-${index}-${i}`}>
                                                <Labeled
                                                    source={source}
                                                    label={Field.props.label}
                                                    classes={{ label: classes.label }}
                                                >
                                                </Labeled>
                                                {React.cloneElement(Field, {
                                                    record: item,
                                                })}
                                            </div>
                                        )
                                    })}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    }
                    )
                }
            </div>
        );
    }
}
ApiListField.defaultProps = {
    addRootLabel: true,
    label: 'Field list',
    getTitle: item => item.id ? item.id : "",
    Fields: [
        <TextField source="id" label={"Id"} style={{ display: "block" }} />,
        <TextField source="name" label={"Value"} style={{ display: "block" }} />,
        <ArrayField source="items" label={"Parameters"}>
            <Datagrid>
                <TextField source="key" label={"Key"} style={{ display: "inline-flex" }} />
                <TextField source="value" label={"Value"} style={{ display: "inline-flex" }} />
            </Datagrid>
        </ArrayField>
    ],
};

export default withStyles(styles)(ApiListField);
