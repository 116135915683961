import { fetch } from "../restClient";
import { isValidJson } from "../tools";
import { showNotification } from "react-admin";


const useFetch = async (url, initialOptions = {}) => {
  const options = initialOptions;
  if (options && options.headers && !(options.headers instanceof Headers)) {
    const headers = new Headers();
    Object.entries(options.headers).forEach((entry) => {
      headers.set(entry[0], entry[1]);
    });

    options.headers = headers;
  }

  try {
    let responseFetch = await fetch(url, options);
    if (!isValidJson(responseFetch)) {
      responseFetch = await responseFetch.json();
    }

    if (responseFetch.status === 200 && options) {
      showNotification(`flexi.${options.resourceApi}.success`,);
    } else {
      showNotification(`flexi.${options.resourceApi}.failed`, "warning");
    }

    return responseFetch;
  } catch (error) {
    showNotification && showNotification(`${error}`, "warning");
    return error;
  }
};

export default useFetch;
