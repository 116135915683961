import React from 'react';
import { TextInput, SelectInput, Filter } from 'react-admin';

const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="name" label="Search in name" alwaysOn resettable/>
        <TextInput source="guid" label="Search in id (guid)" resettable/>

        <SelectInput source="lang" label={'Language'} choices={window.sitesLangChoices} resettable/>
        <SelectInput source="territory" label={'Territory'} choices={window.sitesTerritoryChoices} resettable/>
    </Filter>
);

export default ListFilter;
