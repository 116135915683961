import React, {useState} from 'react'
import { change } from "redux-form";
import {FormDataConsumer, Labeled, REDUX_FORM_NAME, TextInput} from "react-admin"
import Preview from "./Preview"
import DynamicWidgetInputComponent from "../DynamicWidgetInputComponent";
import CardExpanded from '../CardExpanded'
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {makeBranchedUrl} from "../../restClient";
import Select from 'react-select';
import * as _ from "lodash";
import Grid from "../Grid";
import {SingleSelect} from "react-select-material-ui";
import {required} from "../CommonFields/CommonFieldsComponents";
import { removeTwigCode } from "../../Helpers/strings";

export const styles = {
    card: {
        margin: '10px 0'
    },
    widgetSelect: {
        maxWidth: '800px'
    }
}

const CustomWidgetFieldComponent = ({classes, ...props}) => {
    const [payload, setPayload] = useState([])
    const [suggestListNames, setSuggestListNames] = useState([])
    const [renderTypes, setRenderTypes] = useState([{'value': 'in_body_content', 'label': 'In Body Content'}, {'value': 'iframe', 'label': 'Friendly Iframe'}])
    let cancel = null

    const handleRenderChange = (dispatch, selectedOption) => {
        dispatch(change(REDUX_FORM_NAME, `${props.source}custom_widget_render_type`, selectedOption))
        return selectedOption
    }

    const handleWidgetChange = (dispatch, selectedOption) => {
        setPayload(selectedOption.payload[1])
        dispatch(change(REDUX_FORM_NAME, `${props.source}custom_widget_name`, selectedOption.label))
        dispatch(change(REDUX_FORM_NAME, `${props.source}custom_widget_guid`, selectedOption.value))
    }

    const getSuggestList = (event = null, selectedGuid = '') => {
        let query = event ? event.target.value : ''
        if (cancel) {
            cancel('aborting for a new request')
        }
        if(event && query.length <= 0) {
            return;
        }
        axios.get(makeBranchedUrl(`/api/customwidgets_list/${event ? query : selectedGuid}`), {
            cancelToken: new axios.CancelToken((c) => {
                cancel = c;
            }),
        })
        .then((response) => {
            let suggested = []

            Object.entries(response.data.content).forEach(entry => {
                suggested.push({value: entry[1]['guid'], label: entry[1]['name'] + ' (tags: '+ entry[1]['customwidget_tags'] +')', payload: entry})

                if (selectedGuid === entry[1].guid) {
                    setPayload(entry[1])
                }
            })
            setSuggestListNames(suggested)
        })
    }

    if (payload.length <= 0) getSuggestList(null, props.record.custom_widget_guid || '')

    return (
        <FormDataConsumer>
            {({ formData, dispatch }) => {
                const currentWidgetData = _.get(formData, props.source)
                return (
                    <div>
                        <div onKeyUp={(e) => getSuggestList(e)}>
                            <Grid columns={3} width={'100%'}>
                                <div>
                                    <Labeled label="Search for a custom widget"/>
                                    <Select
                                        name={`${props.source}custom_widget_guid`}
                                        onChange={(val) => handleWidgetChange(dispatch, val)}
                                        className={classes.widgetSelect}
                                        options={suggestListNames}
                                        defaultInputValue={props.record.custom_widget_name}
                                        required
                                        validate={[required]}
                                    />
                                </div>
                                <div>
                                    <Labeled label="Render Type"/>
                                    <SingleSelect
                                        value={currentWidgetData.custom_widget_render_type || handleRenderChange(dispatch, renderTypes[0].value)}
                                        options={renderTypes}
                                        onChange={(val) => handleRenderChange(dispatch, val)}
                                    />
                                </div>
                                {undefined !== currentWidgetData.custom_widget_guid && (
                                    <div>
                                        <Labeled label="Change or update the widget"/>
                                        <div>
                                            <a href={`/#/customwidgets/${currentWidgetData.custom_widget_guid}`} rel="noopener noreferrer" target="_blank">Customize widget</a>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                            <div hidden>
                                <TextInput source={`${props.source}custom_widget_name`} label=""/>
                                <TextInput source={`${props.source}custom_widget_render_type`} label=""/>
                            </div>

                        </div>
                        <CardExpanded columns={1} fullWidth={true} label={'Source Query'} {...props} className={classes.card}>
                            <DynamicWidgetInputComponent
                                choices={[
                                    {id: 'other', name: 'Feeds'},
                                    {id: 'fields_query', name: 'Fields Query'},
                                ]}
                                record={currentWidgetData.source_query || {}}
                                source={`${props.source}source_query`}
                                style={{display: 'none'}}
                            />
                        </CardExpanded>
                        <Preview html={removeTwigCode(payload.custom_twig_markup || '')} css={payload.custom_css_markup} fullWidth={true}/>
                    </div>
                )
            }}
        </FormDataConsumer>)
}

export default withStyles(styles)(CustomWidgetFieldComponent);
