import React from "react";
import {
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    BooleanField,
    FunctionField,
    CardActions,
    CreateButton,
    ExportButton,
    ListButton,
} from "react-admin";
import DeleteButtonWithConfirmation from "../../components/DeleteButtonWithConfirmation";
import ListFilter from "./ListFilter";
import List from "../List";
import CopyToProd from "../../components/CopyToProd";
import { connect } from "react-redux";
import { isCodeFreeze } from "../Settings/settingsUI";

const rowStyle = (record, index) => {
    let activated = true === (new Date()).getTime() >= (new Date(record.start)).getTime() && (new Date()).getTime() <= (new Date(record.end)).getTime();

    return {
        borderLeftColor: activated ? "#66bb6a" : "#dcdcdc",
        background: !activated && "#f5f5f5",
        borderLeftStyle: "solid",
        borderLeftWidth: "5px",
    };
};

const AbtestingActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
}) => (
    <CardActions>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
            })}

        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <ListButton basePath="/abtesting_url" label="Display experiments URLs" />
    </CardActions>
);

const AbtestingList = (props) => {
    const {
        resource,
        permissions,
        codeFreeze: { status },
        ...rest
    } = props;
    return (
        <List
            perPage={100}
            sort={{ field: "last_modified", order: "DESC" }}
            resource={resource}
            permissions={permissions}
            filters={<ListFilter />}
            actions={<AbtestingActions permissions={permissions} />}
            {...rest}
        >
            <Datagrid rowStyle={rowStyle}>
                <TextField source="guid" label="ID" />
                <TextField source="name" />
                <TextField source="site" />
                <FunctionField
                    source="type"
                    label="Global"
                    render={(record, source) => (
                        <BooleanField record={{ ...record, type: record.type === "global" }} source={source} />
                    )}
                />

                {permissions && permissions.checkUserCanCRUD(resource, "update") ? (
                    <EditButton disabled={status && !permissions.checkUserIsSuperAdmin(resource)} />
                ) : null}
                {permissions && permissions.checkUserCanCRUD(resource, "read") ? <ShowButton /> : null}
                {permissions && permissions.checkUserCanCopyToProd(resource) ? <CopyToProd /> : null}
                {permissions && permissions.checkUserCanCRUD(resource, "delete") ? (
                    <DeleteButtonWithConfirmation
                        disabled={status && !permissions.checkUserIsSuperAdmin(resource)} /* undoable={false} */
                    />
                ) : null}
            </Datagrid>
        </List>
    );
};

const mapStateToProps = (state) => ({
    codeFreeze: isCodeFreeze(state.settingsui),
});

export default connect(mapStateToProps)(AbtestingList);
