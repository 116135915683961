import React, { Fragment } from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { Badge, Tooltip, withStyles } from '@material-ui/core';
import { handleUpdate } from '../Actions/CrudActions';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import PublishButton from '../components/PublishButton';
import UnPublishButton from '../components/UnPublishButton';
import { connect } from 'react-redux';
import { isCodeFreeze } from './Settings/settingsUI';
import { getFormSyncErrors } from 'redux-form';
import { flattenObject } from '../tools';
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from '@material-ui/icons/Save';


const renderErrors = errors => (errors && JSON.stringify(errors)
    .replace(/,/gi, "\r\n")
    .replace(/api/gi, "config")
)

const styles = theme => ({
    customWidth: { maxWidth: 400, fontSize: 16 },
    marginRight: { marginRight: 25 },
    errorColor: { backgroundColor: theme.palette.error.main }
})
const ButtonWithError = withStyles(styles)(
    ({ isInvalid, children, errorsCount, syncErrors, classes, ...rest }) => (
        <Fragment>
            {(isInvalid === true) ?
                <Tooltip title={renderErrors(syncErrors)} classes={{ tooltip: classes.customWidth }}>
                    <Badge color="primary" variant="dot" badgeContent={errorsCount} classes={{ colorPrimary: classes.errorColor }}>
                        {children}
                    </Badge>
                </Tooltip> :
                children}
        </Fragment>
    )
)

class EditToolbar extends React.Component {
    constructor(props) {
        super(props);

        const { record } = this.props;
        const displayAnyway = typeof record.is_modified === 'undefined';
        const finalDisplaying = displayAnyway === true || record.is_modified;

        this.state = { isSaving: false, isDialogOpen: false, display: finalDisplaying || false };
        this.handleSave = this.handleSave.bind(this);
        this.handleOnPublishedSuccess = this.handleOnPublishedSuccess.bind(this);
    }
    handleSave = () => {
        this.setState({ isSaving: true });
        handleUpdate(this.props);
        this.setState({ display: true });
    }
    handleOnPublishedSuccess = () => {
        this.setState({ display: false });
    }
    render() {
        const { resource, record, codefreeze: { status }, permissions, dispatch, errorsCount, syncErrors, invalid, handleSubmit, handleSubmitWithRedirect, saving,  ...rest } = this.props;
        const { isSaving, display } = this.state;
        const resourceWithPublishDisplayed = resource !== 'abtesting';

        return <Toolbar resource={resource} permissions={permissions} record={record} {...rest}>
            <ButtonWithError isInvalid={invalid} errorsCount={errorsCount} syncErrors={syncErrors}>
                    <SaveButton
                        label={'save'}
                        record={record}
                        redirect="edit"
                        resource={resource}
                        onClick={this.handleSave}
                        handleSubmit={handleSubmit}
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        disabled={(status && !permissions.checkUserIsSuperAdmin(resource)) || invalid || saving}
                        submitOnEnter
                        icon={ saving ? <CircularProgress size={20} /> : <SaveIcon /> }
                    />
            </ButtonWithError>
            {record && record.id && display && resourceWithPublishDisplayed ? (
                <Fragment>
                    <PublishButton
                        record={record}
                        resource={resource}
                        onPublishedSuccess={this.handleOnPublishedSuccess}
                        disabled={isSaving || (status && !permissions.checkUserIsSuperAdmin(resource))}
                    />
                    {/*{permissions && permissions.checkUserIsSuperAdmin(resource) && resource !== 'abtesting' && (*/}
                    {/*    <PublishButton*/}
                    {/*        record={record}*/}
                    {/*        resource={resource}*/}
                    {/*        disabled={isSaving || (status && !permissions.checkUserIsSuperAdmin(resource))}*/}
                    {/*        labeled={"Publish + Purge cache"}*/}
                    {/*        onPublishedSuccess={this.handleOnPublishedSuccess}*/}
                    {/*        withPurgeCache={true}*/}
                    {/*        withConfirmation={true}*/}
                    {/*    />*/}
                    {/*)*/}
                    {/*}*/}
                </Fragment>
            ) : null}
            <UnPublishButton
                record={record}
                resource={resource}
                disabled={(status && !permissions.checkUserIsSuperAdmin(resource))}
            />
        </Toolbar>
    }
}

const enhance = compose(
    shouldUpdate((props, nextProps) => (nextProps.codefreeze.status !== props.codefreeze.status)
        || (nextProps.invalid !== props.invalid)
        || (nextProps.errorsCount !== props.errorsCount)
        || (nextProps.isSaving !== props.isSaving)
    )
);

const flatReduxForm = state => {
    const flattedForm = flattenObject(state);
    return Object.filter(flattedForm, res => res)
}

const mapStateToProps = (state) => {
    //Issue on global validation that does'nt persist validation on all tabs https://github.com/marmelab/react-admin/issues/1866. Waiting RA migration to fix this
    const flatSyncErrorsForm = flatReduxForm(getFormSyncErrors('record-form')(state));
    return {
        codefreeze: isCodeFreeze(state.settingsui),
        syncErrors: flatSyncErrorsForm,
        errorsCount: Object.keys(flatSyncErrorsForm).length,
        isSaving: state.admin.saving
    }
}

export default connect(mapStateToProps)(enhance(EditToolbar));
