import { AUTH_CHECK, AUTH_LOGIN, AUTH_GET_PERMISSIONS } from 'react-admin';
import Cookies from 'js-cookie';

const checkUserIsSuperAdmin = (roles) => roles.some(role => ['SuperAdmin'].indexOf(role) >= 0);
const checkUserIsAdmin = (roles) => roles.some(role => ['Admin'].indexOf(role) >= 0);
const userIsAdminOrSuperAdmin = (roles = []) => checkUserIsAdmin(roles) || checkUserIsSuperAdmin(roles);

const checkUserCanCRUD = ( roles = [], acl = [], resource = '', action ) => {
    return (
        ["create", "read", "update", "delete"].includes( action )
        && ( userIsAdminOrSuperAdmin(roles) || acl.some(permission => ['.*', `${resource}.*`, `${resource}.${action}`].indexOf(permission) >= 0 ) )
    )
}

const checkUserCanCopyToProd = (roles = [], acl = [], resource = '') =>
    'qa' === window.environment && (userIsAdminOrSuperAdmin(roles) || acl.some( role => [`${resource}.*`, `${resource}.copy_to_prod`].indexOf(role) >= 0 ));

const checkUserCanHaveAccess = (acl = []) => acl.length > 0;

const checkWidgetPermission = (acl = [], widgetType, action) => {
    let arrayExclude = [
        `widget_type.exclude.${widgetType}.*`,
        `widget_type.exclude.${widgetType}.${action}`,
    ];
    let arrayInclude = [
        'widget_type.*',
        `widget_type.${widgetType}.*`,
        `widget_type.${widgetType}.${action}`,
    ];
    return !acl.some(permission => arrayExclude.indexOf(permission) >= 0)
        && acl.some(permission => arrayInclude.indexOf(permission) >= 0);
};

const checkUserCanCreateWidget = (isAdmin, acl = []) => {
    return (
        isAdmin()
        || acl.some(
            permission => [
                'widget_type.*',
                'widget_type.create'
            ].indexOf(permission) >= 0,
        )
    );
};
const checkUserCanEditWidget = (isAdmin, acl = [], params) => {
    if (!params || !params.type || isAdmin()) {
        return true;
    }
    const widgetType = params.type;
    return checkWidgetPermission(acl, widgetType, 'update');
};
const checkUserCanReadWidget = (isAdmin, acl = [], record) => {
    if (!record || isAdmin()) {
        return true;
    }
    const widgetType = record.type;
    return checkWidgetPermission(acl, widgetType, 'read');
};

/**
 * 
 * @description checks if user can delete Widget/Translate/TranslateItem
 * 
 * @param {*} isAdmin 
 * @param {*} acl 
 * @param {*} record 
 * @returns {boolean}
 */
const checkUserCanDeleteItem = (isAdmin, acl = [], record) => {
    if (!record || isAdmin()) {
        return true;
    }
    const widgetType = record.type;
    return checkWidgetPermission(acl, widgetType, 'delete');
};

// https://github.com/marmelab/react-admin/blob/master/docs/Authentication.md#user-content-checking-credentials-during-navigation
export const checkAuth = () =>
    Cookies.get("token")
    ? Promise.resolve()
    : Promise.reject({ redirectTo: "/#/login" });

export default (type, payload) => {
    if (type === AUTH_LOGIN) {
        if (!payload.token) {
            return Promise.reject();
        }

        const role = !payload.role.length ? ['ReadOnly'] : payload.role;
        // store the token inside a cookie and not localStorage
        // so we can setup the expiration date
        const t = new Date();
        t.setSeconds(t.getSeconds() + payload.expiresIn);
        Cookies.set('token', payload.token, { expires: t });
        Cookies.set('user', payload.user);
        localStorage.setItem('user', payload.user);
        localStorage.setItem('role', role.join(','));
        localStorage.setItem('expiresIn', payload.expiresIn);

        // get the set of permissions from the roles
        // without duplicates
        const permissions = [...new Set(role.reduce(function(acc, r) {
            return [...acc, ...window.credentials[r]];
        }, []))];

        localStorage.setItem('permission', permissions.join(','));
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        if (Cookies.get('token')) {
            return Promise.resolve();
        }
        removeSession();
        return Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        if (!Cookies.get('token')) {
            return Promise.reject();
        }
        const role = localStorage.getItem('role');
        const permission = localStorage.getItem('permission');
        const roles = role ? role.split(',') : [];
        const acl = permission ? permission.split(',') : [];
        return (role && permission) ? Promise.resolve({
            role,
            acl,
            userIsAdminOrSuperAdmin: userIsAdminOrSuperAdmin.bind(null, roles),
            checkUserCanCRUD: checkUserCanCRUD.bind(null, roles, acl),
            checkUserIsSuperAdmin: checkUserIsSuperAdmin.bind(null, roles),
            checkUserCanCopyToProd: checkUserCanCopyToProd.bind(null, roles, acl),
            checkUserCanHaveAccess: checkUserCanHaveAccess.bind(null, acl),
            checkUserCanEditWidget: checkUserCanEditWidget.bind(
                null,
                userIsAdminOrSuperAdmin.bind(null, roles),
                acl
            ),
            checkUserCanDeleteItem: checkUserCanDeleteItem.bind(
                null,
                userIsAdminOrSuperAdmin.bind(null, roles),
                acl
            ),
            checkUserCanCreateWidget: checkUserCanCreateWidget.bind(
                null,
                userIsAdminOrSuperAdmin.bind(null, roles),
                acl
            ),
            checkUserCanReadWidget: checkUserCanReadWidget.bind(
                null,
                userIsAdminOrSuperAdmin.bind(null, roles),
                acl
            ),
        }) : Promise.reject();
    }
    return Promise.resolve();
};

function removeSession() {
    Cookies.remove('token');
    Cookies.remove('isConnected', { path: '/api' });
    Cookies.remove('user');
    localStorage.removeItem('role');
    localStorage.removeItem('permission');
    localStorage.removeItem('companies');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('user');
}

export { removeSession };
export {checkUserIsSuperAdmin};