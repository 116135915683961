/* eslint-disable no-eval */
import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextInput, SelectInput, BooleanInput, NumberInput, translate as trans } from "react-admin";
import Grid from "./Grid";

import IconButton from "./IconButton";
import Dashboard from "@material-ui/icons/Dashboard";
import { required } from "../tools";
import { QueryFields } from "./SolrQueryFields/QueryFields";

const styles = {};

const DynamicWidgetInput = ({ origin, source, template = "", type = "" }) => {
    switch (type) {
        case "feeds_api":
            return (
                <Grid>
                    <Grid columns="3">
                        <TextInput source={`${source}.feed_url`} label="widget.feed_url" />
                        <NumberInput source={`${source}.limit`} label="widget.limit" defaultValue={10} />
                        <NumberInput source={`${source}.items_per_page`} label="widget.items_per_page" />
                        {["cross_site", "more_like_this"].includes(template) === true && (
                            <TextInput source={`${source}.items_per_site`} label="widget.items_per_site" />
                        )}
                    </Grid>
                    {origin && origin === "timeline" && (
                        <Grid columns={4}>
                            <NumberInput source={`${source}.custom_height`} label="Custom widget height" />
                        </Grid>
                    )}
                </Grid>
            );

        case "other":
            return (
                <Grid>
                    <Grid columns="3">
                        <TextInput source={`${source}.feed_url`} label="widget.feed_url" />
                        <NumberInput source={`${source}.limit`} label="widget.limit" defaultValue={10} />
                        <NumberInput source={`${source}.items_per_page`} label="widget.items_per_page" />
                        {["cross_site", "more_like_this"].includes(template) === true && (
                            <TextInput source={`${source}.items_per_site`} label="widget.items_per_site" />
                        )}
                    </Grid>
                    {origin && origin === "timeline" && (
                        <Grid columns={4}>
                            <NumberInput source={`${source}.custom_height`} label="Custom widget height" />
                        </Grid>
                    )}
                </Grid>
            );
        case "page_context":
            return (
                <Grid columns="3">
                    <SelectInput
                        source={`${source}.page_context_data`}
                        label="PAGE CONTEXT DATA"
                        resettable
                        choices={window.pageContextChoices}
                    />
                </Grid>
            );
        case "rating_api":
            return (
                <div style={{ marginTop: 20 }}>
                    Requesting data from{" "}
                    <code>https://ratings.master.van-[prod|qa].futureplc.engineering/ratings/[site]/[article-id] </code>
                    <p>
                        By choosing this source of data, the following list of values will be also available as
                        placeholders for you:
                        <ul>
                            <li>
                                <code>rating_api.totalVotes</code>
                            </li>
                            <li>
                                <code>rating_api.average</code>
                            </li>
                            <li>
                                <code>rating_api.count</code>
                            </li>
                            <li>
                                <code>rating_api.aggregateRating</code>
                            </li>
                            <li>
                                <code>rating_api.postEndpoint</code>
                            </li>
                        </ul>
                    </p>
                </div>
            );
        case "solr_api":
        case "fields_query":
            return <QueryFields source={source}></QueryFields>;
        case "timeline_fallback":
            return (
                <Fragment>
                    <Grid columns={2} style={{ textAlign: "center", alignItems: "center" }}>
                        <IconButton
                            icon={(iconClasses) => <Dashboard className={iconClasses.leftIcon} />}
                            onClick={() => {
                                window.open(
                                    "https://docs.google.com/spreadsheets/d/1pHBvXN7nmGkiG8uQSUB82eNlnL8xHu6kydzH_-eguHQ/copy",
                                    "_blank"
                                );
                            }}
                            color="secondary"
                        >
                            {`Copy, fill spreadsheet & publish it on web`}
                        </IconButton>
                        <a target={"_blank"} rel="noopener noreferrer" href="https://timeline.knightlab.com/#make">
                            Informations
                        </a>
                    </Grid>
                    <Grid columns={3}>
                        <TextInput
                            source={`${source}.id_spreadsheet`}
                            label={`Google Id Spreadsheet`}
                            validate={[required]}
                            required
                        />
                        <SelectInput
                            source={`${source}.language`}
                            defaultValue={window.widgetTimelineChoices[0].lang[0].name}
                            label="Language"
                            choices={window.widgetTimelineChoices[0].lang}
                            allowEmpty
                            resettable
                        />
                        <SelectInput
                            source={`${source}.font`}
                            defaultValue={"Bevan-PotanoSans"}
                            label="Font"
                            choices={window.widgetTimelineChoices[1].font}
                            allowEmpty
                            resettable
                        />
                    </Grid>
                    <Grid columns={4} style={{ alignItems: "center", textAlign: "center" }}>
                        <TextInput source={`${source}.dflt_start_slide`} label="Default start slide" />
                        <SelectInput
                            source={`${source}.zoom_lvl`}
                            defaultValue={"2"}
                            label="Zoom level"
                            choices={window.widgetTimelineChoices[2].zm_lvl}
                            allowEmpty
                            resettable
                        />
                        <TextInput source={`${source}.height`} label="Height (px)" />
                        <BooleanInput source={`${source}.fullwidth`} defaultValue={true} label="Full width" />
                    </Grid>
                </Fragment>
            );
        default:
            return <div> Oops, looks like there is no configuration for your option yet.</div>;
    }
};

export default trans(withStyles(styles)(DynamicWidgetInput));
