import React, { useEffect, useState } from "react";
import { FormDataConsumer, REDUX_FORM_NAME } from "react-admin";
import { CircularProgress } from "@material-ui/core";
import { change } from "redux-form";

import { AutoGenFields } from "./AutoGen";
import { SLICE_ROUTE, CURRENT_PROTOCOL } from "../Constants/slice";
import { capName } from "../Helpers/strings";
import { theme } from "../Admin";

const SliceWidgetInput = ({ template, source, currentWidget, record, jsonType = "components", permissions }) => {
    const [sliceData, setData] = useState({});

    useEffect(() => {
        if (template) {
            fetch(`${CURRENT_PROTOCOL}${window.slice.version.replaceAll(".", "-")}.${SLICE_ROUTE}/schema/${jsonType}/${capName(template)}.json`, {
                Accept: "application/json",
            })
                .then((response) => response.json())
                .then((data) => setData(data))
                .catch((e) => console.error("Error trying to fetch component", e));
        }
    }, [template]);

    const paperStyle = {
        marginBottom: 20,
        padding: "50px 0",
        marginTop: 20,
        textAlign: "center",
        fontFamily: theme.typography.fontFamily,
        color: "#444",
        justifyContent: "center",
        backgroundColor: "#e3e3e3",
    };

    if (!template) {
        return <div style={paperStyle}>Please choose a slice</div>;
    }

    if (!sliceData) {
        return <CircularProgress />;
    }

    if (sliceData.statusCode === 404) {
        return (
            <div style={paperStyle}>
                Looks like this slice does not have a json schema available.
                <a
                    href={`${CURRENT_PROTOCOL}${window.slice.version.replaceAll(".", "-")}.${SLICE_ROUTE}/schema/${jsonType}/${capName(
                        template
                    )}.json`}
                >
                    404 error code
                </a>
            </div>
        );
    }

    const alertStyles = {
        marginTop: 16,
        padding: 16,
        borderRadius: 4,
    };

    return (
        <div style={{ marginBottom: 20, padding: "15px 0" }}>
            <FormDataConsumer>
                {({ formData, dispatch }) => {
                    useEffect(() => {
                        // We want to always revert to false.
                        (permissions && permissions.checkUserIsSuperAdmin(permissions.role)) || jsonType === "variables"
                            ? dispatch(change(REDUX_FORM_NAME, `${source}.edit_fields`, true))
                            : dispatch(change(REDUX_FORM_NAME, `${source}.edit_fields`, false));
                    }, [jsonType]);

                    let editComponent = <></>;

                    if (jsonType !== "variables") {
                        editComponent =
                            permissions && permissions.checkUserIsSuperAdmin(permissions.role) ? (
                                <div
                                    style={{
                                        ...alertStyles,
                                        color: "#1e4620",
                                        backgroundColor: "#edf7ed",
                                    }}
                                >
                                    Good news you are a super admin, so feel free to change the default values
                                </div>
                            ) : (
                                <div
                                    style={{
                                        ...alertStyles,
                                        color: "#611a15",
                                        backgroundColor: "#fdecea",
                                    }}
                                >
                                    Only super admins can edit these values
                                </div>
                            );
                    }

                    const currentWidgetData = formData.widgets[source.match(/\[(.*?)\]/)[1]];

                    return (
                        <>
                            {editComponent}
                            <AutoGenFields
                                jsonData={sliceData}
                                key={`${template}-main-autogen`}
                                source={`${source}.slice.${template}.${jsonType}`}
                                disableAllFields={!currentWidgetData.edit_fields}
                                {...{ currentWidget, record, template }}
                            />
                        </>
                    );
                }}
            </FormDataConsumer>
        </div>
    );
};

export default SliceWidgetInput;
