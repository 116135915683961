import * as React from 'react';
import { Fragment } from 'react';
import { BulkDeleteButton } from 'react-admin';
import CopyToProdBulkActionButton from './CopyToProdBulkActionButton';

const BulkActionButtons = props => {
    const { resource, permissions } = props;

    return (
        <Fragment>
            {permissions && permissions.checkUserCanCopyToProd(resource) ? <CopyToProdBulkActionButton label="Copy many to prod" {...props} /> : null}
            <BulkDeleteButton {...props} />
        </Fragment>
    )
};

export default BulkActionButtons;