import React, { Fragment} from 'react';
import {TextInput, Filter} from 'react-admin';
import { FieldArray } from "redux-form"
import { withStyles } from "@material-ui/core/styles";
import MultipleSelectWithCustom from "../../components/MultipleSelectWithCustom";

export const styles = theme => ({
    form: {
        '& p.MuiFormHelperText-root': {
            display: 'none'
        },
        '& .MuiFormControl-root': {
            minWidth: '350px'
        }
    },
});

const ListFilter = props => (
    <Filter className={props.classes.form} {...props}>
        <TextInput key={0} source="name" label="Search in name" alwaysOn resettable/>
        <div alwaysOn resettable={'true'}>
            <Fragment>
                <FieldArray
                    label="Category Tags"
                    name={`customwidget_tags`}
                    component={MultipleSelectWithCustom}
                    choices={window.customwidget_tags ? window.customwidget_tags.map(value => value.id) : []}
                    helper=' '
                />
            </Fragment>
        </div>
    </Filter>
);

export default withStyles(styles)(ListFilter);
