import React, { Component } from 'react';
import { Labeled } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { fetch } from '../restClient';

class PublishersField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            choices: [],
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetch('/api/publishers')
            .then(response => response.json())
            .then((data) => {
                this.setState({
                    isLoading: false,
                    choices: data,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    }

    getPublisherHeadline = (guid) => {
        const { choices } = this.state;
        const publisherItem = choices.find(item => item.guid === guid);
        return publisherItem ? publisherItem.headline : guid;
    };

    render() {
        const { record, label } = this.props;
        const { isLoading } = this.state;
        return (
            <div>
                <Labeled label={label} record={record}>
                    <div>
                        {record.publishers
                            && record.publishers.map((guid) => (
                                <Chip
                                    key={guid}
                                >
                                    {this.getPublisherHeadline(guid)}
                                </Chip>
                            ))}
                    </div>
                </Labeled>
                {isLoading && <CircularProgress size={20} thickness={2} />}
            </div>
        );
    }
}

export default PublishersField;
