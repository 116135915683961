import React from 'react';
import {
    DateField,
    TextField,
    TabbedForm,
    FormTab,
    RichTextField,
    BooleanField
} from 'react-admin';
import { FieldArray } from 'redux-form';
import Show from '../Show';
import ChangelogField from "../../components/ChangelogField";
import LinkListInput from "../../components/LinkListInput";

const AbtestingShow = props => (
    <Show title="Abtesting show" {...props}>
        <TabbedForm>
            <FormTab label="Summary">
                <TextField source="guid" />
                <TextField source="name" />
                <TextField source="site" />
                <TextField source="url" label="Relative Urls" />
                <BooleanField source="is_published" label="Is item published" defaultValue={false} />
                <DateField source="start" label={"Date start"} />
                <DateField source="end" label={"Date end"} />
                <FieldArray name="abtests"
                            label={"Ab Tests"}
                            fullWidth={true}
                            component={LinkListInput}
                            hideRemoveButton={true}
                            hideAddButton={true}
                            getTitle={(item) => (typeof item.pace !== "undefined" ? item.pace+'% - ' : '')+(item.variant ? item.variant : 'control')}
                            initiallyExpanded={(item) => false}
                            gridColumnTitle={1}
                            extendedTitle={(item) => (typeof props.url !== "undefined" ? 'Preview URI: '+ props.url + (item.variant ? '?_flexi=1&_flexi_doc_slug='+item.variant+'&' : '?') + "_flexi_variantId=" + (item.variant ? item.variant : 'control') + '&_flexi_abtest=' + props.record.id : '')}
                            inputFields={[
                                <TextField source="variant" />,
                                <TextField source="no_flexi_variant_name" label="Variant name (Flexi layout disabled if it is filled)" />,
                                <TextField source="articleId" label="A/B testing articleId (replace the original article)" />,
                                <BooleanField source="enhancedElk" label="Add extra tag to ELK blocks (for front end enhanced modifications)" defaultValue={false} />,
                                <RichTextField source="headMarkup" label={"Head Markup"} />,
                                <RichTextField source="bottomMarkup" label={"Bottom Markup"} />
                            ]}
                            showEmptyKeys={[]}
                            style={{display: 'none'}}
                />
                <RichTextField
                    label="Comment"
                    source="comment"
                />
            </FormTab>
            {props.permissions && props.permissions.checkUserCanCRUD('abtesting_log', "read") &&
            <FormTab label="Changelogs">
                <ChangelogField guid={props.id} />
            </FormTab>
            }
        </TabbedForm>
    </Show>
);

export default AbtestingShow;
