import React from 'react';
import {
    DateInput,
    TextInput,
    SelectInput,
    NullableBooleanInput,
} from 'react-admin';
import { Field } from 'redux-form';
import ChipInput from 'material-ui-chip-input';

function ConditionsValueInput({ source, fieldValue }) {
    const renderInput = render.bind(null, source);

    switch (fieldValue) {
        case 'site':
            return (
                <SelectInput
                    label="flexi.condition_value"
                    source={source}
                    allowEmpty
                    choices={window.sitesChoices}
                />
            );

        case 'dateFrom':
        case 'dateTo':
            return renderInput(DateInput);

        case 'id':
            return <Field name={source} component={renderChipInput} label="Value" />;

        case 'publishedState':
            return renderInput(NullableBooleanInput);

        default:
            return renderInput(TextInput);
    }
}

function render(source, Input) {
    return (
        <Input
            source={source}
            label="flexi.condition_value"
        />
    );
}

function renderChipInput({ input, label }) {
    return (
        <ChipInput
            label={label}
            value={input.value || []}
            fullWidth={true}
            onAdd={(chip) => { input.onChange([...input.value, chip]); }}
            onDelete={(chip) => { input.onChange(input.value.filter(value => value !== chip)); }}
        />
    );
}

export default ConditionsValueInput;
