import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  showNotification as showNotificationAction,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { FieldArray, Field, reduxForm } from "redux-form";
import EditToolbar from "../EditToolbar";
import CommonDynamicFieldsComponents from "../../components/CommonDynamicFieldsComponents";
import { required } from "../../components/CommonFields/CommonFieldsComponents";
import ChangelogField from "../../components/ChangelogField";
import Grid from "../../components/Grid";
import SingleSelectWithCustom from "../../components/SingleSelectWithCustom";
import { isInvalidCodeHttp, RAValidateUrlFormat } from "../../tools";
import CustomRouteOverrideInput from "../../components/CustomRoute/CustomRouteOverrideInput";
import CatchAllDynamicFieldsComponents from "../../components/CatchAllDynamicFieldsComponents";
import AutocompleteSiteConfigOverride from "../../components/LinkListInput/AutocompleteSiteConfigOverride";
import IconButton from "../../components/IconButton";
import CallSplit from "@material-ui/icons/CallSplit";
import fetchRA from "../../hooks/fetchRA";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';


const styles = {
  g1501fr1fr: {
    gridTemplateColumns: "150px 1fr 1fr !important",
  },
  g30p15p1fr: {
    gridTemplateColumns: "30% 15% 1fr !important",
  },
  thirdx2: { width: "66%", marginRight: "1%" },
  half: { width: "50%", marginRight: "1%" },
  third: { width: "33%", marginRight: "1%" },
  midHalf: { width: "25%", marginRight: "1%" },
  ten: { width: "10%", marginRight: "1%" },
  columnsField: { width: "10%", marginRight: "1%" },
  align: {
    marginTop: 0,
  },
  placeHoldersLabel: {
    marginTop: "20px",
  },
  alignVertical: {
    margin: "auto 0",
  },
  grid: {
    display: "grid",
    width: 150,
  },
};

const RenderPlaceholdersLabel = ({
  isLoading,
  placeholdersOptions = [],
  placeholdersFromPageLayout = [],
  classes,
  formData,
}) => {
  const otherRoutingParams = formData["other_routing_params"] ? formData["other_routing_params"] : [];
  const usedPlaceholdersCount = placeholdersOptions.filter(option =>
    otherRoutingParams.map(param => param["key"]).includes(option)
  ).length;

  return (
    <>
      <Typography className={classes.placeHoldersLabel}>
        Getting placeholders from pagelayouts and settings:{" "}
      </Typography>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
          <Typography className={classes.placeHoldersLabel}>
            {`${usedPlaceholdersCount} used / ${placeholdersOptions.length} found`}
          </Typography>
          <Tooltip
            title={`${placeholdersFromPageLayout.length} placeholders retrieved from page layouts : ${placeholdersFromPageLayout.join(', ')}`}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      )}
    </>
  );
};
const optionRenderer = (choice) => `${choice.name} (${choice.guid})`;
const UriHelperText = () => (
  <>
    <Typography style={{ fontSize: 15, marginTop: 20 }}>
      You can add either a simple URI or a URI with patterns. Examples:
    </Typography>
    <ul>
      <li style={{ marginBottom: 8 }}>
        <code>/best-mirrorless-cameras,review-2221.html</code>
      </li>
      <li style={{ marginBottom: 8 }}>
        <code>/us/&#123;headline:slugify&#125;,review-2221.html</code>
      </li>
      <li style={{ marginBottom: 8 }}>
        <code>/us/&#123;canonical:urlencode&#125;,review-2221.html</code>
      </li>
    </ul>
    <p>
      <span>
        Where <b>slugify</b> and <b>urlencode</b> are functions that will be
        applied on <b>headline</b> and <b>canonical</b>
      </span>
    </p>
  </>
);
const limitRenderSuggestions = (val) => {
  return val.trim().length > 0;
};

const patternPagination = window.custom_route.pattern_seo_pagination;
const patternRegPagination = new RegExp(RegExp.escape(patternPagination, "i"));

const hasSeoPagination = (str) => {
  let hasPagination = str.match(patternRegPagination);

  return (hasPagination && hasPagination.length > 0) || false;
};

export const SummaryRender = (props) => {
  let { classes, formData, parentFormData = {} } = props;
  let source = props.source ? `${props.source}.` : "";
  var currentUri = (formData && formData.uri) || "";
  const regExpPattern = /^(.*)_\w.*_\w.*$/;
  const site = (formData && formData.site && `${formData.site}||`) || "";
  const parentSite =
    (parentFormData && parentFormData.site && `${parentFormData.site}||`) || "";
  const siteSubbrandMatch =
    formData && formData.subbrand && formData.subbrand.match(regExpPattern);
  const siteSubbrand = (siteSubbrandMatch && `${siteSubbrandMatch[1]}||`) || ``;
  const siteSubbrandFromParentMatch =
    parentFormData &&
    parentFormData.subbrand &&
    parentFormData.subbrand.match(regExpPattern);
  const siteSubbrandFromParent =
    (siteSubbrandFromParentMatch && `${siteSubbrandFromParentMatch[1]}||`) ||
    ``;
  const layoutSiteFilter = `${site}${siteSubbrand}${parentSite}${siteSubbrandFromParent}default`;

  const handleSeoPaginationChange = useCallback(
    (event, value) => {
      currentUri =
        props.formData && props.formData.uri ? props.formData.uri : "";

      if (
        props.change &&
        value &&
        currentUri &&
        currentUri.length > 0 &&
        !currentUri.match(patternRegPagination)
      ) {
        props.change(`${source}uri`, `${currentUri}${patternPagination}`);
      } else {
        props.change(
          `${source}uri`,
          `${currentUri.replace(patternRegPagination, "")}`
        );
      }
    },
    [currentUri.length]
  );

  const handleTargetChange = useCallback(
    (event, value) => {
      currentUri = props.formData ? props.formData.uri : "";

      if (
        props.change &&
        [...window.custom_route.target_default_pagination].includes(value) &&
        currentUri &&
        currentUri.length > 0 &&
        !currentUri.match(patternRegPagination)
      ) {
        props.change(`${source}uri`, `${currentUri}${patternPagination}`);
      }
    },
    [currentUri.length]
  );

  return (
    <Grid columns="1">
      {!props.fromIndex && (
        <Grid columns="5">
          <SelectInput
            className={classes.ten}
            label={"Subscriber Status"}
            name={`${source}subscriber_status`}
            source={`${source}subscriber_status`}
            resettable
            choices={window.subscriberStatusChoices}
          />
          <SelectInput
            className={classes.ten}
            label={"Language"}
            name={`${source}lang`}
            source={`${source}lang`}
            choices={window.sitesLangChoices}
            required
            validate={[required]}
          />
          <SelectInput
            className={classes.ten}
            label={"Territory"}
            name={`${source}territory`}
            source={`${source}territory`}
            choices={window.sitesTerritoryChoices}
            required
            validate={[required]}
          />
        </Grid>
      )}

      {props.fromIndex && (
        <TextInput
          className={classes.third}
          label="name"
          name={`${source}name`}
          source={`${source}name`}
          required
          validate={[required]}
          fullWidth={true}
        />
      )}

      {props.fromIndex && (
        <SelectInput
          className={classes.ten}
          label="Site"
          name={`${source}site`}
          source={`${source}site`}
          choices={window.sitesChoices}
          required={props.fromIndex}
          validate={[required]}
          fullWidth={true}
        />
      )}
      <Grid
        columns="2"
        style={{ width: "inherit" }}
        classes={classes.g30p15p1fr}
      >
        <Field
          component={TextInput}
          /* className={classes.third} */ label="URI"
          name={`${source}uri`}
          source={`${source}uri`}
          fullWidth={true}
          required
          validate={props.fromIndex ? [required] : null}
        />
        <BooleanInput
          source={`${source}has_seo_pagination`}
          className={classes.alignVertical}
          label="has SEO pagination"
          defaultValue={hasSeoPagination(currentUri)}
          onChange={handleSeoPaginationChange}
        />
      </Grid>
      <UriHelperText></UriHelperText>
      <Field
        component={SelectInput}
        className={classes.third}
        label="Target"
        name={`${source}target`}
        source={`${source}target`}
        choices={window.customRouteOrigin}
        validate={props.fromIndex ? [required] : null}
        fullWidth={true}
        onChange={handleTargetChange}
      />
      <Grid columns="2">
        <ReferenceInput
          {...props.rest}
          resource={""}
          allowEmpty
          label="Subbrand"
          name={`${source}subbrand`}
          source={`${source}subbrand`}
          perPage={100}
          reference="sites"
          sort={{ field: "guid", order: "ASC" }}
          filterToQuery={(text) => ({ name: text })}
        >
          <AutocompleteInput
            helperText="Search for a subbrand"
            options={{
              fullWidth: true,
            }}
            shouldRenderSuggestions={limitRenderSuggestions}
            optionText={optionRenderer}
            optionValue="guid"
          ></AutocompleteInput>
        </ReferenceInput>
      </Grid>
      <div style={{ padding: "20px 0" }}>
        <Field
          label={"Add Flexi config overrides"}
          name={`${source}flexi_overrides`}
          source={`${source}flexi_overrides`}
          component={AutocompleteSiteConfigOverride}
          allowEmpty
          data={formData}
          {...props.rest}
        />
      </div>
      <Grid columns="2">
        <ReferenceInput
          {...props.rest}
          resource={""}
          filter={{ site: layoutSiteFilter }}
          label="Flexi Layout Id"
          name={`${source}flexi_layout_id`}
          source={`${source}flexi_layout_id`}
          perPage={100}
          reference="pagelayouts"
          // fullWidth={true}
          validate={props.fromIndex ? [required] : null}
          sort={{ field: "guid", order: "ASC" }}
          filterToQuery={(text) => ({ name: text })}
        >
          <AutocompleteInput
            // style={{ width: "100%" }}
            options={{
              fullWidth: true,
            }}
            helperText="Search for a pagelayout"
            shouldRenderSuggestions={limitRenderSuggestions}
            optionText={optionRenderer}
            optionValue="guid"
          ></AutocompleteInput>
        </ReferenceInput>
        {formData.flexi_layout_id && (
          <a
            href={`/#/pagelayouts/${formData.flexi_layout_id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <IconButton
              color="primary"
              className={classes.third}
              icon={(iconClasses) => (
                <CallSplit className={iconClasses.leftIcon} />
              )}
            >
              {`Link to ${formData.flexi_layout_id}`}
            </IconButton>
          </a>
        )}
      </Grid>
      <BooleanInput
        label="Transmit Original Canonical"
        name={`${source}transmit_original_canonical`}
        source={`${source}transmit_original_canonical`}
        defaultValue={false}
      />
      <BooleanInput
        label="Catch all"
        name={`${source}catch_all`}
        source={`${source}catch_all`}
        defaultValue={false}
      />
      <BooleanInput
        label="Redirect"
        name={`${source}redirect`}
        source={`${source}redirect`}
        defaultValue={false}
      />
      <Grid
        columns="2"
        style={{ width: "inherit" }}
        classes={classes.g1501fr1fr}
      >
        <TextInput
          label="Target Url"
          name={`${source}redirect_target_url`}
          source={`${source}redirect_target_url`}
          className={classes.align}
          required
          resettable
          disabled={!formData || !formData.redirect}
          validate={[RAValidateUrlFormat]}
        />

        <Field
          label={"Http Code"}
          name={`${source}redirect_http_code`}
          source={`${source}redirect_http_code`}
          component={SingleSelectWithCustom}
          options={[
            "301",
            "302",
            "303",
            "304",
            "305",
            "306",
            "307",
            "308",
            "403",
            "404",
            "410",
          ]}
          disabled={!formData || !formData.redirect}
          validate={(value) => isInvalidCodeHttp(value)}
          data={formData}
          currentValue={
            formData && formData.redirect_http_code
              ? formData.redirect_http_code
              : undefined
          }
        />
      </Grid>
      <BooleanInput
        label="Reverse Proxy"
        name={`${source}reverse_proxy`}
        source={`${source}reverse_proxy`}
        defaultValue={false}
      />
      <Grid
        columns="2"
        style={{ width: "inherit" }}
        classes={classes.g1501fr1fr}
      >
        <TextInput
          style={{ width: 300 }}
          label="Reverse Proxy Target Url"
          name={`${source}proxy_target_url`}
          source={`${source}proxy_target_url`}
          className={classes.align}
          required
          resettable
          disabled={!formData || !formData.reverse_proxy}
          validate={[RAValidateUrlFormat]}
        />
      </Grid>
    </Grid>
  );
};

export const CatchAllParams = (props) => {
  if (!props.formData || true !== props.formData.catch_all) {
    return <div />;
  }

  const isEnabled =
    (props.formData &&
      props.formData.catch_all_routing_params &&
      props.formData.catch_all_routing_params.is_enabled) ||
    false;

  return (
    <div style={{ marginTop: 30 }}>
      <label>
        <b>{"Catch All Params"}</b>
      </label>
      <BooleanInput
        label="Activate"
        source={`${props.fieldname}.is_enabled`}
        defaultValue={false}
      />
      {isEnabled ? (
        <Fragment>
          <TextInput
            source={`${props.fieldname}.url_search_value`}
            label={`Url Search Value`}
            fullWidth={true}
            required
            validate={[required]}
          />
          <FieldArray
            name={`${props.fieldname}.rules`}
            component={CatchAllDynamicFieldsComponents}
            formData={props.formData}
          />
        </Fragment>
      ) : null}
    </div>
  );
};

const styleCommonDynamicFieldsComponents = {
  textAreaAutoComplete: { reactTextAreaStyle: { minHeight: "75px" } },
};

export const EditForm = ({ classes, showNotification, ...props }) => {
  const [placeholdersOptions, setPlaceholdersOptions] = useState([]);
  const [placeholdersFetched, setPlaceholdersFetched] = useState([]);
  const [placeholdersFromPageLayout, setPlaceholdersFromPageLayout] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;

    const fetchApi = async () => {
      const placeholdersLists = await fetchRA(
        `api/customroutes_placeholders/${props.record.guid}`,
        { method: "get", resourceApi: "fetch_placeholders" }
      );
      const completePlaceholdersList = placeholdersLists.json.placeholdersFromSettings.concat(placeholdersLists.json.placeholdersFromPageLayout);
      if (
        placeholdersLists &&
        placeholdersLists.status === 200 &&
        completePlaceholdersList.length > 0
      ) {
        if (isMounted) {
          //list options not used in the current routing params, also removes duplicates
          let otherRoutingParams = props.record["other_routing_params"] ? props.record["other_routing_params"] : [];
          const placeholdersFiltered = filterPlaceholders(completePlaceholdersList, otherRoutingParams);
          setPlaceholdersFromPageLayout(placeholdersLists.json.placeholdersFromPageLayout)
          setPlaceholdersFetched(completePlaceholdersList);
          setPlaceholdersOptions(placeholdersFiltered);
        }
      } else {
        console.error(placeholdersLists);
      }
      setIsLoading(false);
    };
    fetchApi();
    return () => (isMounted = false);
  }, []);

  return (
    <TabbedForm
      submitOnEnter={false}
      redirect="edit"
      toolbar={
        <EditToolbar record={props.record} permissions={props.permissions} />
      }
      {...props}
    >
      <FormTab guid="summary" label="summary">
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <FieldArray
              component={SummaryRender}
              name={""}
              change={props.change}
              formData={formData}
              classes={classes}
              record={props.record}
              fromIndex={true}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab guid="routing_params" label="Routing Params">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <RenderPlaceholdersLabel
                placeholdersOptions={placeholdersFetched}
                placeholdersFromPageLayout={placeholdersFromPageLayout}
                isLoading={isLoading}
                classes={classes}
                formData={formData}
              />
              <FieldArray
                label={`Route Params and Placeholders`}
                name="other_routing_params"
                component={CommonDynamicFieldsComponents}
                singleSelectCustom={true}
                fcsis_fields={false}
                optionsSingleSelectCustom={placeholdersOptions}
                //   onChangeSelect={filterOptionsByDiff}
                formData={formData}
                // classes={classes.commonDynamicField}
                canCopy={true}
              />
              <FieldArray
                label={"Fcsis Fields"}
                name="fcsis_routing_params"
                fscis_fields={true}
                component={CommonDynamicFieldsComponents}
                canCopy={true}
              />

              <CatchAllParams
                formData={formData}
                classes={classes}
                record={props.record}
                rest={props.rest}
                fieldname={"catch_all_routing_params"}
              />
            </>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab guid="ssi_vars" label="SSI Vars">
        <FieldArray
          label={"SSI Vars"}
          name="ssi_vars"
          suggest={true}
          component={CommonDynamicFieldsComponents}
          style={styleCommonDynamicFieldsComponents}
        />
      </FormTab>

      <FormTab guid="overrides" label="Overrides">
        <FieldArray
          name="overrides"
          change={props.change}
          component={CustomRouteOverrideInput}
          rest={props.rest}
        />
      </FormTab>

      {props.permissions &&
        props.permissions.checkUserCanCRUD("customroutes_log", "read") && (
          <FormTab guid="changelogs" label="Changelogs">
            <ChangelogField guid={props.record.guid} />
          </FormTab>
        )}
    </TabbedForm>
  );
};

/* checks if that placeholders have no duplicate and that placeholders aren't already set */
const filterPlaceholders = (completePlaceholderList, alreadySetRoutingParams) => {
  const filteredPlaceholders = [];
    const alreadySetRoutingParamsStringArray = alreadySetRoutingParams.map(placeholder => placeholder.key);
    completePlaceholderList.forEach(placeholder => {
      if (!alreadySetRoutingParamsStringArray.includes(placeholder) && !filteredPlaceholders.includes(placeholder)) {
        filteredPlaceholders.push(placeholder);
      }
    });
    return filteredPlaceholders;
}

const form = "record-form";
export default connect(null, { showNotification: showNotificationAction })(
  reduxForm({ form, asyncBlurFields: [] })(withStyles(styles)(EditForm))
);
