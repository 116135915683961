export const METRICS_DEFAULT_VALUES = {
    metric_region_field : "all",
    metric_type_field : "popularity",
    metric_rank_start_field : "1",
    metric_rank_end_field : "100",
    metric_date_value_field : "30",
    metric_time_unit_field : "DAYS"
}

export const METRICS_FIELDS = [
    "metric_region_field",
    "metric_type_field",
    "metric_rank_start_field",
    "metric_rank_end_field",
    "metric_date_value_field",
    "metric_time_unit_field"   
]