import React, { Component, Fragment } from 'react';
import {TextInput,SelectInput,FormDataConsumer, SimpleForm,DateInput,NumberInput, LongTextInput}  from "react-admin";
import { SaveButton, Toolbar } from 'react-admin';
import { handleUpdate } from '../../Actions/CrudActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core';
import CommonDynamicFieldsComponents from '../../components/CommonDynamicFieldsComponents';
import { FieldArray } from 'redux-form';
import { getSource } from '../../tools';

const styles = () => ({
    inputStyle:{
        marginRight:"15px"
    },
})

//https://github.com/marmelab/react-admin/pull/4544/commits/c7c263ac42e159ee35b815a62a0f4c8a6ef41f66
//Sanitize props button to avoid js errors
const SanitizedToolBarButtons = ({invalid,pristine,submitOnEnter,...rest}) => (
    <Fragment>
        <SaveButton
            record={rest.record}
            redirect="list"
            resource={rest.resource}
            onClick={ handleUpdate(rest) }
            {...rest}
        />
        <Button onClick={rest.onCancel}>Cancel</Button>
    </Fragment>
)



const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SanitizedToolBarButtons {...props} />
    </Toolbar>
)

const filteredParams = (settings,filter) => settings.reduce((acc,setting) => (setting.id === filter ? [...acc,...setting.parameters] : acc),[])

const defaultValuesBy = key => getSource(window,key)

export const renderInput = ({type,defaultValues,...props},key,classes,formData) => {
    switch(type){
        case "textinput":
            return <TextInput key={key} className={classes.inputStyle} resettable {...props}></TextInput>
        case "longtextinput":
            return <LongTextInput key={key} className={classes.inputStyle} resettable {...props}></LongTextInput>
        case "dateinput":
            return <DateInput key={key} className={classes.inputStyle} {...props}></DateInput>
        case "selectinput":
            return <SelectInput key={key} className={classes.inputStyle} resettable {...props} ></SelectInput>
        case "numberinput":
            return <NumberInput key={key} className={classes.inputStyle} {...props}></NumberInput>
        case "commondynamicfield":
            return <FieldArray key={key} component={CommonDynamicFieldsComponents} formData={formData} defaultValues={defaultValuesBy(defaultValues)} {...props} />
        default:
            return <TextInput key={key} className={classes.inputStyle} resettable {...props} ></TextInput>
        }
}

const renderParamsSetting = (settings,setting,classes,formData) => {
    return filteredParams(settings,setting).map((param,key)=>renderInput(param,key,classes,formData));
}


class EditForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            listOptionsState: ( window.setting && window.setting.list_settings ) || [],
            isLoading: false,
        };
    }


    render(){
        const { record, onCancel,classes, permissions,...rest } = this.props;
        const { listOptionsState, isLoading } = this.state;
        return(
            <SimpleForm
                submitOnEnter={false}
                redirect="list"
                record={record}
                permissions={permissions}
                toolbar={<EditToolbar record={record} onCancel={onCancel} permissions={permissions} />}
                {...rest}
                >
                    {isLoading ? <CircularProgress size={40} thickness={2} /> :
                    //TODO let's filter selectinput choices minus existing setting ? --> need to create a custom component because SelectInput RA need current value in choices
                    <SelectInput
                        source="setting"
                        choices={listOptionsState}
                        resettable
                    />
                    }
                    <FormDataConsumer>
                        {({formData}) => formData.setting && renderParamsSetting(window.setting.list_settings,formData.setting,classes,formData)}
                    </FormDataConsumer>
            </SimpleForm>
        )
    }
}
const form = 'setting';
export default reduxForm({form,asyncBlurFields: []})(withStyles(styles)(EditForm));
