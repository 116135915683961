import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import CallSplit from "@material-ui/icons/CallSplit";

import IconButton from "./IconButton";
import { fillArrayFieldIfMissingRefs } from '../tools';
import { SelectInput, ReferenceInput, AutocompleteInput, NumberInput } from 'react-admin';
import { FieldArray } from "redux-form";
import Grid from "./Grid";
import { required } from "./CommonFields/CommonFieldsComponents";
import CommonDynamicFieldsComponents from "./CommonDynamicFieldsComponents";

const gridStyle = {
    marginTop: '15px',
    gridTemplateColumns: '75% 20%',
    alignItems: 'end',
    borderBottom: 'groove #00000061 0.5px',
    paddingBottom: 30
};

export const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    half: { width: "50%" },
    full: { width: "100%" },
    buttons: { alignSelf: 'center' },
    grid: gridStyle,
});

const FullLabelField = ({ record }) => <span>{record.name} ({record.id})</span>;

class CatchAllDynamicFieldsComponents extends Component {
    componentWillMount() {
        const { fields, defaultValues, formData } = this.props;
        fillArrayFieldIfMissingRefs(fields, defaultValues, formData);
    }
    render() {
        const { fields, classes, formData } = this.props;

        return (
            <div style={{ paddingTop: 10 }}>
                <Paper className={classes.paper}>
                    {fields.length > 0 &&
                        fields.map((itemSource, index) => (
                            <div key={`catch-all-routing-params-${index}`}>
                                <Typography component="div">
                                    <Grid style={{ ...gridStyle }} columns="2" columnGap="20px" rowGap="20px">
                                        <div>
                                            <NumberInput source={`${itemSource}.position`} label={`#${index + 1} position`} required validate={[required]} />

                                            <FieldArray label={"Rules to match"} source={`${itemSource}.fcsis_rules_to_match`} name={`${itemSource}.fcsis_rules_to_match`} fscis_fields={true} component={CommonDynamicFieldsComponents} />
                                            <FieldArray label={"Fcsis Fields routing params"} source={`${itemSource}.fcsis_routing_params`} name={`${itemSource}.fcsis_routing_params`} fscis_fields={true} component={CommonDynamicFieldsComponents} />

                                            <Grid style={{ marginTop: '15px' }} columns="3" columnGap="20px" rowGap="20px">
                                                <SelectInput label={`#${index + 1} Target Name`}
                                                    source={`${itemSource}.target`} choices={window.customRouteOrigin}
                                                    optionText={<FullLabelField />} required validate={[required]}
                                                />

                                                <ReferenceInput
                                                    {...this.props.rest} fullWidth={true} className={classes.full}
                                                    label={`#${index + 1} Flexi Layout Id`} name={`${itemSource}.flexi_layout_id`}
                                                    source={`${itemSource}.flexi_layout_id`} perPage={100} reference="pagelayouts"
                                                    resource={""}
                                                    sort={{ field: 'guid', order: 'ASC' }} filterToQuery={text => ({ name: text })}
                                                    required validate={[required]}
                                                >
                                                    <AutocompleteInput
                                                        helperText='Search for a pagelayout' 
                                                        shouldRenderSuggestions={(val) => { return val.trim().length > 0 }}
                                                        inputRoot={{ width: 300 }}
                                                        optionText={choice => `${choice.name} (${choice.guid})`} optionValue="guid">
                                                    </AutocompleteInput>
                                                </ReferenceInput>
                                                <IconButton
                                                    color="primary"
                                                    style={{width:"fit-content"}}
                                                    className={classes.third}
                                                    icon={(iconClasses) => (
                                                        <CallSplit className={iconClasses.leftIcon} />
                                                    )}
                                                >
                                                    {
                                                        formData.catch_all_routing_params.rules[index].flexi_layout_id &&
                                                    <a href={`/#/pagelayouts/${formData.catch_all_routing_params.rules[index].flexi_layout_id}`} rel="noopener noreferrer" target="_blank">
                                                        {`Link to ${formData.catch_all_routing_params.rules[index].flexi_layout_id}`}
                                                    </a>
                                                    }
                                                </IconButton>
                                            </Grid>
                                        </div>

                                        <div style={{ textAlign: 'center' }}>
                                            <Tooltip title="Remove this item">
                                                <IconButton className={classes.buttons} onClick={() => fields.remove(index)}
                                                icon={(iconClasses) => (
                                                    <MinusIcon className={iconClasses.leftIcon} />
                                                )}>
                                                </IconButton>
                                            </Tooltip>
                                            {index === (fields.length - 1) &&
                                                <Tooltip title="Add a new condition">
                                                    <IconButton className={classes.buttons} onClick={() => fields.push({})}
                                                     icon={(iconClasses) => (
                                                        <AddIcon className={iconClasses.leftIcon} />
                                                    )}>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                    </Grid>
                                </Typography>
                            </div>
                        ))
                    }

                    {fields.length === 0 &&
                        <Button size="small" onClick={() => fields.push({})}>
                            <AddIcon /> Add new Item
                        </Button>
                    }
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(CatchAllDynamicFieldsComponents);