import React, { Component, Fragment } from 'react';
import { Labeled, SelectField } from 'react-admin';
import DynamicWidgetField from './DynamicWidgetField';
import Grid from './Grid';
const RenderRadioButtonGroup = ({ record }) =>
{
    switch(record.source_query){
        case "timeline_fallback":
        return (<span>Timeline Fallback</span>)
        default:
        return (<span>Other</span>)
    }
}
export default class TimelineWidgetFieldComponent extends Component {
    constructor(props) {
        super(props);
        const { record } = this.props;
        this.state = {
            sourceQuery: (record && record.source_query) || null,
            template: (record && record.template) || null,
            sourceApi: (record && record.source_api) || null,
        };
    }

    render() {
        const { record } = this.props;
        const { sourceQuery, template, sourceApi } = this.state;
        return (
            <Fragment>
                <Labeled label="widget.source_query">
                    <RenderRadioButtonGroup record={record} />
                </Labeled>
                <Grid>
                    <Labeled label="Template">
                        <SelectField
                            record={record}
                            source="template"
                            choices={window.widgetTemplates.timeline}
                        />
                    </Labeled>
                </Grid>
                <DynamicWidgetField
                    type={sourceQuery}
                    template={template}
                    sourceApi={sourceApi}
                    record={record}
                    origin="timeline"
                /> 
            </Fragment>
        );
    }
}