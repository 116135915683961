import React from "react";
import PropTypes from "prop-types";
import { getSource } from "../tools";
import Chip from "@material-ui//core/Chip";

const ChipsField = ({ record, source, arrayValues }) => {
  const sourced = getSource(record, source) || arrayValues;
  if (sourced && sourced.length > 0) {
      if (Array.isArray(sourced)) {
          return (
              <ul style={{padding:0}}>
                {sourced && sourced.length > 0 &&
                sourced.map((item, index) => <Chip style={{marginRight:5}} key={index} label={item} />)}
              </ul>
          );
      }

      return (<Chip style={{marginRight:5}} key={sourced} label={sourced} />)
  }

  return (<div/>);
};
ChipsField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  arrayValues: PropTypes.arrayOf(PropTypes.string)
};

export default ChipsField;
