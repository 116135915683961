import React from "react";
import { ArrayField, BooleanField, ObjectField, StringField, DropdownField } from "./Fields";
import "./Fields/array";

const getElement = (type) => {
    switch (type) {
        case "boolean":
            return BooleanField;
        case "array":
            return ArrayField;
        case "object":
            return ObjectField;
        case "number":
        case "string":
            return StringField;
        case "dropdown":
            return DropdownField;
        default:
            return "div";
    }
};

// TODO: all of, one of...

export const UIElement = ({
    type,
    data,
    required = false,
    source,
    propertyName,
    groupingFlag = 0,
    disableAllFields,
}) => {
    if (!type) {
        return (
            <div style={{ textAlign: "center", color: "gray", padding: 20 }}>
                Looks like the slice schema is incomplete for these set of fields
            </div>
        );
    }
    let newGroupingFlag = groupingFlag;

    if ((type === "object" || type === "array") && groupingFlag < 5) {
        newGroupingFlag = groupingFlag + 1;
    }

    const finalType = data.enum ? "dropdown" : type;
    const Field = getElement(finalType.toLowerCase());

    return <Field groupingFlag={newGroupingFlag} {...data} {...{ disableAllFields, required, source, propertyName }} />;
};
