import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "./Grid";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import IconButton from '../IconButton';
// import Grid from '../Grid';

const styles = {
  card: { margin: "10px", height: "fit-content" }
}

const CardExpanded = ({ label, children, classes, columns = 4, ...rest }) => {
  return (
    <Card className={classes.card} {...rest} >
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.pannelSummary}>
          <Typography variant={"title"}>{label}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid columns={columns}>{children}</Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
};

export default withStyles(styles)(CardExpanded);
