import React, {Component} from 'react';
import {Labeled} from "react-admin";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CardExpanded from "../CardExpanded";

const styles = (theme) => ({
    button: {
        margin: '10px 0',
        width: '150px',
        height: '36px',
        padding: '10px',
        backgroundColor: '#3f51b5',
        color: '#fff',
        border: '0',
        borderRadius: '4px',
        position: 'relative',
        cursor: 'pointer',
        textTransform: 'capitalize',
        display: 'block',
        '&:hover': {
            backgroundColor: '#3f51b5'
        },
        '&[class*="MuiButtonBase-disabled"]': {
            opacity: '0.5',
        }
    },
    history: {
        maxWidth: '470px',
        '& p[class="assistant"]': {
            color: '#10a37f',
            margin: 0,
            textTransform: 'capitalize'
        },
        '& p[class="you"]': {
            color: window.environment !== 'prod' ? '#851be6' : '#f50057',
            margin: 0,
            textTransform: 'capitalize'
        },
        '& div': {
            maxWidth: '100%'
        },
        '& p[class="content"], & p[class="content"] div:first-of-type': {
            margin: '5px 0',
            display: 'inline-block',
            wordWrap: 'break-word',
        },
        '& p[class="content"] label': {
            width: '470px',
            lineHeight: '1em',
            fontSize: '12px'
        },
        '& p[class="content"] div:first-of-type div:first-of-type': {
            display: 'none'
        },
        '& div[style]:not(div[style=""])': {
            gridTemplateColumns: 'repeat(1, 1fr) !important',
            maxHeight: '800px',
            overflowY: 'scroll'
        }
    },
});

class ChatBotImageInput extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    clearHistory = (guid) => {
        const { setChatHistory } = this.props;
        setChatHistory([]);
        localStorage.removeItem(`chat_${guid}`);
        localStorage.removeItem(`chat_na`);
    }

    saveHistory = (guid, chatHistory, setChatHistory) => {
        if (chatHistory.length <= 0 && localStorage.getItem(`chat_${guid}`)) {
            setChatHistory(JSON.parse(localStorage.getItem(`chat_${guid}`)));
        }else if(chatHistory.length > 0) {
            localStorage.setItem(`chat_${guid}`, JSON.stringify(chatHistory));
        }
    }

    render() {
        const { chatHistory, setChatHistory, classes, record } = this.props;
        let guid = record.guid || 'na';
        let chat = [];
        let role = '';

        this.saveHistory(guid, chatHistory, setChatHistory);

        for (const convo in chatHistory) {
            role = chatHistory[convo].role === 'user' ? 'you' : chatHistory[convo].role;
            chat.push(<div key={convo}><p className={`${role}`}>{`${role}`}:</p><p className="content"><Labeled label={`${chatHistory[convo].content}`}></Labeled></p></div>);
        }

        return <CardExpanded className={classes.history} columns={1} fullWidth={true} label={'Chat History'}>
            <div>Chat history will be stored in local storage until you clear it.
                <Button className={classes.button} onClick={() => this.clearHistory(guid)} label={'clear'}>Reset Conversation</Button>
            </div>
            <hr width={'100%'}/>
            {chat.length ? chat : 'You need to start a conversation with Chat-GPT'}
        </CardExpanded>;
    }
}

export default withStyles(styles)(ChatBotImageInput);