import Icon from '@material-ui/icons/CallSplit';
import List from './List';

const name = 'abtesting_url';
const label = '';

export {
    List,
    Icon,
    name,
    label
};
