import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ImageInput, ImageField, showNotification as showNotificationAction,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetch } from '../restClient';

class InputFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    handleChange = (files) => {
        const { showNotification, input } = this.props;
        this.setState({ isLoading: true });
        const fd = new FormData();

        if (!files) {
            this.setState({ isLoading: false });
            input.onChange([]);
            return;
        }

        fd.append('file', files.rawFile);
        fetch('/api/uploads', {
            method: 'post',
            body: fd,
        })
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.error) {
                    return showNotification(`flexi.fileupload.${response.error.exception[0].message}`, 'warning');
                }
                showNotification('flexi.fileupload.success');
                input.onChange([
                    {
                        src: response.json.url,
                        preview: response.json.url,
                        mimeType: response.json.mimeType,
                        fileContent: response.json.fileContent,
                    },
                ]);
            });
    };

    render() {
        const props = {
            ...this.props,
            input: {
                ...this.props.input,
                onBlur: this.handleChange,
            },
        };
        const { disabled } = this.props;
        const { isLoading } = this.state;
        return (
            <div>
                {!!disabled ? (
                    <ImageField source="src" title="title" />
                ) : (
                    <ImageInput {...props}>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                )}
                {isLoading && <CircularProgress size={20} thickness={2} />}
            </div>
        );
    }
}

InputFile.defaultProps = { disabled: false };

export default connect(
    null,
    {
        showNotification: showNotificationAction,
    },
)(InputFile);
