const fields = {
    placement_id: 'Placement ID',
    baselayout: 'Base layout',
    templatelayout: 'Template layout',
    doc_override: 'Document Override Layout',
    infinite: 'Infinite Scroll',
    page_layout_css: 'Page Layout CSS',
    date: 'Last Update Date',
    published_date: 'Last Publish Date',
    modified_by: 'Last Modified By',
};

const messages = {
    resources: {
        pagelayouts: {
            name: 'Page Layout |||| Page Layouts',
            fields
        },
        navigations: {
            name: 'Navigations |||| Navigation',
            fields
        }
    },
    flexi: {
        fileupload: {
            success: 'File uploaded.',
            error_extension: 'Please upload images or svg files only.',
            fileTooBig: 'You can only upload files under 1MB and it must be of type: .jpg, .jpeg or .png'
        },
        sites_clone:{
            success: 'Success site clone, refresh coming',
            failed: 'Failed cloned site'
        },
        pagelayouts_clone:{
            success: 'Success page layout clone, refresh coming',
            failed: 'Failed cloned page layout'
        },
        fetch_related_documents:{
            success:'Successfully fetched related documents',
            failed:'Failed to fetch related documents',
        },
        fetch_placeholders:{
            success:'Successfully fetched placeholders of all pagelayouts',
            failed:'Failed to fetch placeholders of all ',
        },
        duplicated_widget_name: 'Duplicated widget name',
        widget_required_fields: 'Widget "name" and "type" are required',
        site_not_allowed_for_a_widget: 'There is one widget that is not allowed for the current site',
        save: 'Save',
        update: 'Update',
        save_and_publish: 'Save & Publish',
        published: {
            success: 'Entity published successfully',
            error: 'Error on Entity publish',
        },
        unpublished: {
            success: 'Entity unpublished successfully',
            error: 'Error on Entity unpublish',
        },
        edit: {
            raw: {
                success: 'Entity updated successfully',
                failed:'Entity not updated',
            },
        },
        clone: 'Clone',
        invalid_css: 'The Page Layout CSS is not valid',
        condition_operator: 'Operator',
        condition_field: 'Field',
        condition_value: 'Value',
        condition_value_type: 'Value type',
        condition_join: 'Query join',
    },
    widget: {
        more_links_label: 'See all label',
        no_render: 'No Render',
        ga_tracked: 'GA Tracked',
        disable_carousel_exclusion: 'Disable Carousel exclusion',
        ga_tracking_id: 'GA tracking ID',
        see_all_url: 'See all url',
        target_blank: 'Open see all link in a new window',
        more_links: 'Display a dropdown of links',
        target_blank_default: 'Open link sources default behaviour in New Window',
        commercial_label: 'Label commercial',
        commercial_filter_logo:'Shadow logo',
        commercial_class_logo:'Logo\'s class',
        commercial_url_logo:'Logo\'s Url',
        commercial_link_logo:'Logo\'s link',
        show_category_link:'Show Category Link',
        video_mode: 'Video Mode',
        full_width: 'Full width',
        lightbox: 'Display as lightbox',
        lightbox_toggler: 'Open a lightbox',
        widgetFeatureFlag: 'Only render if featureFlag is enabled',
        lightbox_target_slug: 'Lightbox target',
        pinned_item: 'Pinned item',
        contain_hero: 'Contain Hero',
        css_class: 'Css class',
        sponsored_item: 'Sponsored item',
        sponsored_item_position: 'Sponsored position',
        change_media_config: 'Media Config per responsive breakpoints : (eg:1,2,3,4,5 = mob,tab,desk,lg,xl)',
        lazy_data: 'Lazy load data pages',
        allow_aggregated: 'Allow aggregate(grouping by category)',
        no_fallback_feed: 'No fallback feed',
        title_category: 'Category Title, you can also use the %category% pattern',
        max_items: 'Max items',
        items_per_page: 'Items per page',
        feed_url: 'Feed url',
        category: 'Category, you can also use the %category% pattern',
        site_from: 'Site from',
        toggle_comments: 'Toggle comments',
        nb_items: 'Items to display',
        hide_breadcrumbs: 'Hide Breadcrumbs',
        hide_share_bar: 'Hide Share Bar',
        hide_title: 'Hide Title',
        hide_byline: 'Hide Byline',
        hide_widget: 'Hide the whole widget',
        cookie_delay: 'Cookie delay (number of days) with -1 means infinite',
        min_height: 'MinHeight (in px)',
        playlist_id: 'Playlist ID',
        filter_placeholder: 'Filter placeholder',
        divid: 'Div Id',
        product_blocks: 'Blocks',
        show_filter: 'Show filter',
        doc_type: 'Edito Doc Type',
        source_doc_type: 'Source Doc Type',
        editorial_content_type: 'Edito Content Type',
        sort: 'Sort by',
        sortdir: 'Sort dir',
        date_from: 'Date from',
        fl: 'fl (Comma separated items)',
        tags: 'Tags (Comma separated items)',
        tags_and: 'Tags AND (Comma separated items)',
        highlight: 'Highlight (Comma separated items)',
        internaltags: 'Internal tags (Comma separated items)',
        limit: 'Nb results',
        items_per_site: 'Items per site',
        video_id: 'Video ID',
        video_player_id: 'Video Player ID',
        is_playlist: 'is playlist?',
        date_format: 'Date Format (Ex: Y-m-d)',
        time_zone: 'Time zone',
        hide_publisher: 'Hide publisher',
        hide_author: 'Hide author',
        hide_date: 'Hide date',
        hide_hero: 'Hide Hero',
        add_extra_data: 'Add Extra Data',
        source_query: 'Source Query',
        source_api: 'Source Api',
        tooltip_tags_and: 'This is an \'AND\' logic tags, you can also use the %tagsAND% pattern',
        tooltip_tags: 'This is an \'OR\' logic tags, you can also use the %tagsOR% pattern',
        tooltip_category: 'You can also use the %category% pattern',
        articleTypePriority: 'Article Type priority',
        group: 'Group',
        'group_field': 'Group By',
        'group_sort': 'Group Sort',
        'group_limit': 'Group Limit',
        facet: 'Facet',
        'facet_field': 'Facet Fields',
        'facet_sort':  'Facet Sort',
        'facet_limit': 'Facet Limit',
        'custom_query': 'Custom Query (Added to current query params)',
        'custom_filter_query': 'Custom FQ (Added to current fq params)',
        'custom_sort': 'Custom Sort (Added to current sort params)',
        'lazy': 'Lazy',
        'ttl': 'TTL (in second and for lazy only)',
    },
};

export default messages;
