import React from 'react'
import { AppBar } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LogoutButton from '../LogoutButton'
const styles = theme => ({
    grid: {
        display:'grid',
        gridTemplateColumns:'1fr 80px',
        width:"100%",
        alignItems:"center",
        justifyItems:"center",
        marginTop:0,
        background:theme.palette.secondary.main
    },
    typo:{
        ...theme.typography,
        fontSize:20,
    },
    header:{
        fontSize:30,
    },
})
const AppBarCustom = ({title,classes}) =>  (
    <AppBar
    title={title}
    >
        <div className={classes.grid}>
            <Typography className={[classes.typo,classes.header].join(' ')}>
                {title}
            </Typography>
            <LogoutButton parentClass={classes.typo}></LogoutButton> 
        </div>
    </AppBar>
    )

export default withStyles(styles)(AppBarCustom)
