import React from 'react'
import {
    Labeled, TextField, SelectField, BooleanField
} from 'react-admin';
import Grid from './Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    group:{
        padding:"10px 40px",
        backgroundColor:theme.color.grayScale.superLight,
    },
    textLabel:{
        '& label[class*="Labeled-label"] span':{color:theme.color.grayScale.light},
    }
})
const WidgetVideoSettingsField = ({record,classes}) => {
    return (
        <Labeled label="Video Settings" className={[classes.group,classes.textLabel].join(" ")}>
            <Grid columns={3}>
                <Labeled label="Provider" className={classes.textLabel}>
                    <SelectField record={record} source={`provider`} choices={window.videoProviders}  />
                </Labeled>
                <Labeled label="ID" className={classes.textLabel}>
                    <TextField record={record} source={`playlist_id`} />
                </Labeled>
                <Labeled label="Is Playlist?" className={classes.textLabel}>
                    <BooleanField record={record} source={`playlist`}  />
                </Labeled>
                <Labeled label="Player ID" className={classes.textLabel}>
                    <TextField record={record} source={`player_id`} />
                </Labeled>
                <Labeled label="Jwplayer provider" className={classes.textLabel}>
                    <SelectField record={record} source={`jwplayer_provider`} choices={[{id:"future",name:"Future"}]}  />
                </Labeled>
            </Grid>
        </Labeled>
    )
}
export default withStyles(styles)(WidgetVideoSettingsField)