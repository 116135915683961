import React, { useState, useEffect } from 'react';
import { Labeled } from "react-admin";
import { MultipleSelect } from 'react-select-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { theme } from '../Admin';

/* use example in parent
<FieldArray
label={"Http Code"}
name="redirect_http_code"
component={MultipleSelectWithCustom}
options={["404","410"]}
disabled={!formData.redirect} //FormDataConsumer
validate={value=>(isInvalidCodeHttp(value))} //validation coming from tools.js
/>

*/
const styles = currenTheme => ({
    root: {
        fontFamily: currenTheme.typography.fontFamily,
        color: currenTheme.palette.text.primary,
    },
    error: {
        fontFamily: currenTheme.typography.fontFamily,
        color: currenTheme.palette.error.main,
    }
})
const disabledStyle = (state) => {
    const opacity = state ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { opacity, transition }
}
//https://react-select.com/styles#style-object
const customStyles = {
    multiValueLabel: (provided, state) => ({ ...provided, fontFamily: theme.typography.fontFamily, color: theme.palette.text.primary, fontSize: 16, ...disabledStyle(state.isDisabled) }),
    multiValueRemove: (provided, state) => ({ ...provided, color: theme.palette.secondary.main, ...disabledStyle(state.isDisabled) }),
    menu: (provided) => ({ ...provided }),/* TODO: optimize diplay list when component in the bottom of window */
    menuList: (provided) => ({ ...provided, fontFamily: theme.typography.fontFamily, color: theme.palette.text.primary, fontSize: 16 }),
    clearIndicator: (provided) => ({ ...provided, color: theme.palette.secondary.main }),
}

export const renderHelper = (label, classes, invalid, disabled) => (<span style={disabledStyle(disabled)} className={!invalid ? classes.root : classes.error}>
    {!invalid
        ? `You can add a new ${label} by writing its name and pressing enter`
        : `There is an invalid ${label} in the list`}
</span>
)

const MultipleSelectWithCustom = ({ fields= {}, label = "", disabled, classes, meta: { invalid = false }, helper, choices = [] }) => {

    const [options, setOptions] = useState(choices);

    const handleChange = (selectedValue) => {
        fields.removeAll();

        //remove all values if null value is present (purpose example: inheritance rules for subbrands)
        if(!selectedValue) selectedValue = []
        if (selectedValue.includes("null")) {
            fields.push("null");
        } else {
            selectedValue.forEach(value => {
                fields.push(value);
            })
        }
    }

    //set options if choices changing
    useEffect(() => {
        if(fields.getAll()){
            setOptions([...fields.getAll(), ...choices])
        }
    }, [choices,fields])

    return (
        <Labeled label={label}>
            <MultipleSelect
                values={fields.getAll()}
                options={options}
                helperText={helper || renderHelper(label.toLowerCase(), classes, invalid, disabled)}
                onChange={handleChange}
                SelectProps={{
                    styles: customStyles,
                    isCreatable: true,
                    isClearable: true,
                    msgNoOptionsAvailable: `All ${label.toLowerCase()} are selected`,
                    msgNoOptionsMatchFilter: `${label.toLowerCase()} already existing`,//Apply if isCreatable false
                    msgNoValidValue: `Not a valid ${label.toLowerCase()}`
                }}
                disabled={disabled}
            />
        </Labeled>
    )
}

export default withStyles(styles)(MultipleSelectWithCustom)