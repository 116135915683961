import React, { useEffect, useState } from "react";
import { FieldArray } from "redux-form";
import * as _ from "lodash";

import { UIElement } from "../UIElement";
import { FormDataConsumer } from "react-admin";
import { CURRENT_PROTOCOL, SLICE_ROUTE } from "../../../Constants/slice";

import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const styles = () => ({
    tooltip: {
        fontSize: 14,
    },
});

export const ArrayField = ({ description = "", items, required = false, source, propertyName = "", groupingFlag }) => {
    return (
        <FormDataConsumer>
            {({ formData }) => {
                const existentInputsLength = _.get(formData, `${source}.${propertyName}`);

                return (
                    <div
                        style={{
                            background: groupingFlag === 2 ? "#cecece26" : "",
                            padding: "6px 20px",
                            borderRadius: 5,
                            display: "flex",
                            flexFlow: "column",
                            marginTop: 20,
                            width: "100%",
                        }}
                    >
                        <FieldArray
                            addLabel="Add another"
                            removeLabel="Remove item"
                            name={description}
                            {...{
                                items,
                                required,
                                source,
                                propertyName,
                                description,
                                groupingFlag,
                                existentInputsLength,
                            }}
                            component={ItemSlice}
                        />
                    </div>
                );
            }}
        </FormDataConsumer>
    );
};

const CustomAddButton = ({ dataInput, onClick }) => {
    return dataInput === "manual" ? (
        <Button color="primary" onClick={onClick}>
            <AddIcon />
            Add another
        </Button>
    ) : (
        <span />
    );
};

const DataTypeToggle = ({ dataInput, classes, setDataInput, fields, source }) => {
    return (
        <div aria-label="button group">
            <FormDataConsumer>
                {({ formData }) => {
                    const currentWidgetData = formData.widgets[source.match(/\[(.*?)\]/)[1]];

                    return (
                        <Tooltip
                            title={
                                !currentWidgetData.slice_data_source
                                    ? "Select a source of data to enable dynamic"
                                    : "Allow you to map values coming from an available array.\neg %solr-1.author%"
                            }
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <span>
                                <Button
                                    color="primary"
                                    variant={dataInput === "dynamic" ? "contained" : "outlined"}
                                    onClick={() => {
                                        setDataInput("dynamic");
                                        if (fields.length !== 1) fields.shift();
                                    }}
                                    style={{ borderRadius: "4px  0 0 4px" }}
                                    disabled={!currentWidgetData.slice_data_source}
                                >
                                    Dynamic
                                </Button>
                            </span>
                        </Tooltip>
                    );
                }}
            </FormDataConsumer>

            <Tooltip
                title={
                    "Allow you to add static values or placeholders per iteration.\neg. %solr-1.author[3]%. Note the use of the index."
                }
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    color="primary"
                    variant={dataInput === "manual" ? "contained" : "outlined"}
                    onClick={() => {
                        setDataInput("manual");
                        if (fields.length !== 1) fields.shift();
                    }}
                    style={{ borderRadius: " 0 4px 4px 0" }}
                >
                    Manual
                </Button>
            </Tooltip>
        </div>
    );
};

const ItemSliceComponent = ({
    fields = {},
    classes,
    description,
    items,
    required,
    source,
    propertyName,
    groupingFlag,
    existentInputsLength = 0,
}) => {
    // dataInput can be one of dynamic or manual
    const [dataInput, setDataInput] = useState("manual");
    const [fullItems, setFullItems] = useState(items);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!fields.length) {
            fields.push({});
        } else if (fields.length <= 2 && existentInputsLength !== 0) {
            [...Array(existentInputsLength.length)].forEach(() => fields.push({}));
        }

        /**
         * We need to fetch external schemas that are referenced in the main schema file nested in arrays.
         * This case if for a direct reference, other internal references are handled by the object.
         */

        if (loading && typeof fullItems === "object" && "$ref" in fullItems) {
            let schemaToFetch = null;

            if (fullItems["$ref"].includes(".json")) {
                schemaToFetch = fullItems["$ref"];
            }

            if (schemaToFetch) {
                fetch(`${CURRENT_PROTOCOL}${window.slice.version.replaceAll(".", "-")}.${SLICE_ROUTE}/schema/components/${schemaToFetch}`, {
                    Accept: "application/json",
                })
                    .then((response) => response.json())
                    .then((extraData) => {
                        setFullItems(extraData);
                        setLoading(false);
                    });
            }
        } else if (loading) {
            setLoading(false);
        }
    }, []);

    const HtmlTagForTitle = `h${groupingFlag}`;

    if (!fullItems || fields === {}) {
        return <></>;
    }

    if (loading) {
        return <CircularProgress color="secondary" />;
    }

    return (
        <>
            <div
                style={{
                    flexFlow: "row nowrap",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <HtmlTagForTitle style={{ marginBottom: 0 }}>{description}</HtmlTagForTitle>
                <DataTypeToggle {...{ dataInput, classes, setDataInput, fields, source }} />
            </div>

            {fields.length > 0 &&
                fields.map((field, index) => (
                    <React.Fragment key={`${propertyName}-${groupingFlag}-${fullItems.type}-${field}`}>
                        <UIElement
                            type={fullItems.type}
                            data={fullItems}
                            required={required}
                            source={`${source}.${propertyName}[${index}]`}
                            propertyName={propertyName}
                            groupingFlag={groupingFlag}
                        />
                        <Button
                            color="primary"
                            style={{ marginBottom: 20, padding: 5 }}
                            onClick={() => fields.remove(index)}
                        >
                            <DeleteIcon /> Delete this item
                        </Button>
                        <hr style={{ width: "100%", borderStyle: "dashed", color: "lightgray" }} />
                    </React.Fragment>
                ))}
            <CustomAddButton dataInput={dataInput} key={propertyName} onClick={() => fields.push({})} />
        </>
    );
};

const ItemSlice = withStyles(styles)(ItemSliceComponent);
