import React, { Fragment, useCallback, useState } from "react";
import {
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
  TextField,
  BooleanInput,
  LongTextInput,
  FormDataConsumer,
  DateInput,
  Labeled,
} from "react-admin";
import { FieldArray } from "redux-form";
import WidgetListInput from "../../components/WidgetListInput";
import ConditionsInput from "../../components/ConditionsInput";
import EditToolbar from "../EditToolbar";
import ChangelogField from "../../components/ChangelogField";
import AreasField from "../../components/AreasField";
import { required } from "../../tools";
import AceEditorInput from "../../components/AceEditorInput";
import RelatedDocuments from "../../components/RelatedDocuments";
import ModalButton from "../../components/ModalButton";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LayoutPreviewButton from "../../components/LayoutPreviewButton";


export const EditForm = (props) => {

  const getModalTitle = useCallback(
    (record) => `Customroutes using this pagelayout : ${record.guid}`,
    []
  );
  const templateLayout = props && props.record && props.record.templatelayout ? props.record.templatelayout : null;
  const slug = props && props.record && props.record.slug ? props.record.slug : null;
  const site = props && props.record && props.record.site ? props.record.site : null;

  const [selectedBaseLayout, setSelectedBaseLayout] = useState(templateLayout);

  return (
    <TabbedForm
      submitOnEnter={false}
      redirect="edit"
      toolbar={
        <EditToolbar record={props.record} permissions={props.permissions} />
      }
      {...props}
    >
      <FormTab guid="summary" label="summary" >
        <TextField source="slug" />
        <LayoutPreviewButton layoutSlug={slug} templateLayout={selectedBaseLayout} site={site}/>
        <ModalButton
          IconElement={<ViewColumn />}
          getModalTitle={getModalTitle}
          labelButton={`Customroutes related`}
          maxWidth={false}
        >
          <RelatedDocuments route={`api/pagelayouts_related`} />
        </ModalButton>
        <TextInput source="name" />
        <TextInput source="placement_id" />
        <TextField source="status" />
        <SelectInput
          source="site"
          label="Site"
          choices={window.sitesChoices}
          required
          validate={[required]}
        />
        <SelectInput
          source="baselayout"
          allowEmpty
          resettable
          choices={window.baseLayoutChoices}
        />
        <SelectInput
          source="templatelayout"
          label="Template layout"
          required
          validate={[required]}
          choices={window.templateLayoutChoices}
          onChange={(target, value) => setSelectedBaseLayout(value) }
        />
        <TextInput source="doc_override" validate={props.validateUnderscore ? [props.validateUnderscore] : false} disabled={props.hasOwnProperty('disabledOverrideField')} />
        <BooleanInput source="infinite" defaultValue={false} />
        {props.permissions && props.permissions.userIsAdminOrSuperAdmin() && (
          <Fragment>
            <LongTextInput source="layout_css_class" />
            <Labeled label={"Page Layout CSS"} />
            <AceEditorInput
              source={`page_layout_css`}
              label={"Page Layout CSS"}
              displayAceLabel={false}
              mode={"css"}
              placeholder="<!-- Put your HTML code here -->"
            />
            <Labeled label={"Head markup"} />
            <AceEditorInput
              source={`page_layout_markup`}
              label={"Head markup"}
              displayAceLabel={false}
              placeholder="<!-- Put your HTML code here -->"
            />
            <Labeled label={"Bottom markup"} />
            <AceEditorInput
              source={`bottom_layout_markup`}
              label={"Bottom markup"}
              displayAceLabel={false}
              placeholder="<!-- Put your HTML code here -->"
            />
          </Fragment>
        )}
      </FormTab>
      <FormTab guid="widgets" label="widgets">
        <FieldArray
          name="widgets"
          permissions={props.permissions}
          component={WidgetListInput}
        />
      </FormTab>
      {props.permissions && props.permissions.userIsAdminOrSuperAdmin() && (
        <FormTab guid="conditions" label="conditions">
          <SelectInput
            source="conditions_status"
            label={"Status"}
            choices={[
              { id: "active", name: "Active" },
              { id: "not_active", name: "Not active" },
            ]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <FieldArray
                site={formData.site}
                conditionsQuery={formData.conditions_query}
                name="conditions"
                component={ConditionsInput}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <DateInput source="conditions_start_date" label={"Start date"} />
          <DateInput source="conditions_end_date" label={"End date"} />
        </FormTab>
      )}
      <FormTab guid="areas" label="Areas">
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <FieldArray
              component={AreasField}
              guid={props.record.guid}
              formData={formData}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </FormTab>
      {props.permissions &&
        props.permissions.checkUserCanCRUD("pagelayouts_log", "read") && (
          <FormTab label="Changelogs">
            <ChangelogField guid={props.record.guid} />
          </FormTab>
        )}
    </TabbedForm>
  );
};

export default EditForm;
