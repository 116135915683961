import React, { useCallback } from "react";
import {
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  SelectField,
} from "react-admin";
import DeleteButtonWithConfirmation from "../../components/DeleteButtonWithConfirmation";
import CloneButton from "../../components/Clone";
import CloneCustomRoute from "../../components/Clone/CloneCustomRoute";
import ListFilter from "./ListFilter";
import List from "../List";
import CopyToProd from "../../components/CopyToProd";
import { connect } from "react-redux";
import { isCodeFreeze } from "../Settings/settingsUI";
import RelatedDocuments from "../../components/RelatedDocuments";
import ModalButton from "../../components/ModalButton";
import ViewColumn from "@material-ui/icons/ViewColumn";

const rowStyle = (record) => {
  return {
    background: !record.is_published && "#f5f5f5",
  };
};

const CustomRouteList = (props) => {
  const {
    resource,
    permissions,
    codeFreeze: { status },
    ...rest
  } = props;

  const getModalTitle = useCallback(
    (record) => `Pagelayouts inside this customroute : ${record.guid}`,
    [],
  )
  
  return (
    <List
      perPage={100}
      sort={{ field: "last_modified", order: "DESC" }}
      resource={resource}
      permissions={permissions}
      filters={<ListFilter />}
      {...rest}
    >
      <Datagrid rowStyle={rowStyle}>
        <TextField source="guid" label="ID" />
        <TextField source="name" />
        <TextField source="uri" label="URI" />
        <TextField source="site" label="Site" />
        <SelectField
          source="subscriber_status"
          label={"Subscriber Status"}
          choices={window.subscriberStatusChoices}
        />
        <SelectField
          source="lang"
          label={"Language"}
          choices={window.sitesLangChoices}
        />
        <SelectField
          source="territory"
          label={"Territory"}
          choices={window.sitesTerritoryChoices}
        />
        {permissions && permissions.checkUserIsSuperAdmin() ? (
          <EditButton
            disabled={status && !permissions.checkUserIsSuperAdmin(resource)}
          />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "read") ? (
          <ShowButton />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "read") ? (
          <ModalButton
            IconElement={<ViewColumn />}
            getModalTitle={getModalTitle}
            labelButton={`Pagelayouts related`}
            maxWidth={false}
          >
            <RelatedDocuments route={`api/customroutes_related`} />
          </ModalButton>
        ) : null}
        {permissions && permissions.checkUserIsSuperAdmin() ? (
          <CloneButton>
            <CloneCustomRoute />
          </CloneButton>
        ) : null}
        {permissions && permissions.checkUserCanCopyToProd(resource) ? (
          <CopyToProd />
        ) : null}
        {permissions && permissions.checkUserIsSuperAdmin() ? (
          <DeleteButtonWithConfirmation
            disabled={
              status && !permissions.checkUserIsSuperAdmin(resource)
            } /* undoable={false} */
          />
        ) : null}
      </Datagrid>
    </List>
  );
};

const mapStateToProps = (state) => ({
  codeFreeze: isCodeFreeze(state.settingsui),
});

export default connect(mapStateToProps)(CustomRouteList);
