import React, { Component, Fragment } from 'react';
import {
    Labeled, TextField, SelectField, BooleanField, ChipField
} from 'react-admin';
const RenderRadioButtonGroup = ({ record }) => (record.source_query === 'curated' ? <span>Curated</span> : <span>CMS Document</span>);

export default class ContentParsedWidgetFieldComponent extends Component {
    constructor(props) {
        super(props);
        const { record } = this.props;
        this.state = {
            sourceQuery: (record && record.source_query) || null,
        };
    }

    render() {
        const { record } = this.props;
        return (
            <Fragment>
                <Labeled label="Template">
                    <SelectField
                        record={record}
                        source="template"
                        allowEmpty
                        choices={window.widgetTemplates.contentparsed}
                    />
                </Labeled>
                <Labeled label="Iterable">
                    <BooleanField record={record} source="iterable" />
                </Labeled>
                <Labeled label="Multipage">
                    <BooleanField record={record} source="multipage" />
                </Labeled>
                <Labeled label="Active SSE">
                    <BooleanField record={record} source="active_sse" />
                </Labeled>
                <Labeled label="Shortcode">
                    <Fragment>
                        <ChipField record={record} source="shortcode" />
                    </Fragment>
                </Labeled>
                <Labeled label="Source Query">
                    <RenderRadioButtonGroup record={record} />
                </Labeled>
                {this.state.sourceQuery === 'curated' && (
                    <Labeled label="Curated Content">
                        <TextField
                            record={record}
                            source="curated_content"
                        />
                    </Labeled>
                )}
            </Fragment>
        );
    }
}
