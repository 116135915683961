import React, { Component } from 'react';
import {TextInput, SimpleFormIterator, ArrayInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles/';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RACheckIfJsonIsValid } from '../../tools';

const styles = theme => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: `160px 1fr 160px 1fr ${theme.spacing.unit * 6}px ${theme.spacing.unit * 6}px`,
        columnGap: theme.spacing.unit,
        width: '100%',
    },
    autoTextInput: {
        fontSize: '20px'
    }
});

class ABTestParamsOverride extends Component {
    render() {
        const { label = "", source } = this.props;

        return (
            <div>
                <Typography>Flexi Article Parameters Override</Typography>
                <ExpansionPanel defaultExpanded={false}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>FTE Article Parameters Override Tips</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        - Allow overriding or adding values to article parameters from a custom route or through AB Testing parameters, the parameter needs to be in the following format:<br/>
                        - article.FIELDNAME = value to override it<br/>
                        - +article.FIELDNAME = value to add it to existing value(s)<br/>
                        - if the field to override is an array value, then it will be set as an array. e.g. 'value1,value2' will be equal to [value1,value2]<br/>
                        - example: article.controlTags = tag,tag
                        <br/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ArrayInput label={label} source={`${source}.abtest_params`}>
                    <SimpleFormIterator>
                        <TextInput label="Key" source="key" warn={[RACheckIfJsonIsValid]} validate={[RACheckIfJsonIsValid]} />
                        <TextInput label="Value" source="value" warn={[RACheckIfJsonIsValid]} validate={[RACheckIfJsonIsValid]} style={{ width: "100%" }} />
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        );
    }
}

export default withStyles(styles)(ABTestParamsOverride);
