import React, { Fragment } from "react";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import IconButton from "../IconButton";
import Grid from "../Grid";
import Tooltip from "@material-ui/core/Tooltip";
// import { spawn } from 'child_process';
const types = window.widgetTypes.reduce((acc, type) => {
  acc[type.id] = type.name;
  return acc;
}, {});
const templates = (type) => {
  const newType = type === "forumtopics" ? "dynamic" : type.toLowerCase();
  const templatesOfType = window.widgetTemplates[newType];
  if (templatesOfType) {
    return templatesOfType.reduce((acc, elt) => {
      acc = [...acc, elt["id"]];
      return acc;
    }, []);
  }
  return [];
};

const styles = (theme) => ({
  area: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    alignSelf: "center",
    fontStyle: "italic",
  },
  position: {
    fontSize: theme.typography.pxToRem(12),
    flexShrink: 0,
    fontStyle: "italic",
    alignSelf: "center",
    lineHeight: 2,
  },
  onerror: {
    verticalAlign: "middle",
    marginTop: "-3px",
    color: "#f39c12",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    alignSelf: "center",
    marginLeft: theme.spacing.unit * 4,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    alignSelf: "center",
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit,
  },
});

const showValueMissing = (source, classes) => {
  return typeof source !== "undefined" && source !== null ? (
    source
  ) : (
    <ErrorIcon className={classes.onerror} />
  ); //need to display item 0
};

const WidgetCardSummary = ({ sourceData, classes, site, handleDragToggle }) => {
  if (!sourceData) {
    sourceData = {};
  }

  return (
    <Fragment>
      <Grid
        width={"fit-content"}
        style={{ marginRight: 25, alignContent: "center" }}
      >
        <Tooltip title="Hold & Drag to reposition widget">
          <DragHandleIcon
            style={{ cursor: "grab" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onMouseLeave={(e) => (e.target.style.cursor = "grab")}
            onMouseDown={(e) => {
              handleDragToggle(true);
              e.target.style.cursor = "grabbing";
            }}
            onMouseUp={(e) => {
              e.target.style.cursor = "grab";
              handleDragToggle(false);
            }}
          />
        </Tooltip>
      </Grid>
      <Grid width={"30%"} columns="1" direction="column">
        <Typography className={classes.area}>
          Area: {showValueMissing(sourceData.area, classes)}
        </Typography>
        <Typography className={classes.position}>
          Position: {showValueMissing(sourceData.position, classes)}
        </Typography>
      </Grid>
      <Grid width={"50%"} columns="1">
        <Typography className={classes.heading}>
          Name: {sourceData.name}
        </Typography>
      </Grid>
      <Grid columns="2">
        {sourceData && sourceData.type && (
          <Fragment>
            <Typography className={classes.secondaryHeading}>
              {types[sourceData.type]}{" "}
              {templates(sourceData.type).includes(sourceData.template)
                ? `(${sourceData.template})`
                : null}
            </Typography>
            <IconButton
              size="small"
              icon={(iconClasses) => (
                <OpenInNew className={iconClasses.leftIcon} />
              )}
              href={`https://${
                site !== "default" ? site : "nexttv"
              }.com.master.van-qa.futureplc.engineering/flexi/documentation/widget/${
                sourceData.type
              }${sourceData.template ? "/" + sourceData.template : ""}`}
              target="_blank"
            >
              Documentation
            </IconButton>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles)(WidgetCardSummary);
