import React from "react";
import { SelectInput, FormDataConsumer } from "react-admin";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ExternalLink from "@material-ui/icons/OpenInNew";
import DynamicWidgetInput from "../DynamicWidgetInput";
import { theme } from "../../Admin";
import Grid from "../Grid";

const DataScreen = ({ source }) => {
    return (
        <>
            <h2>Data sources</h2>
            <div>
                This panel will allow you to add data from API calls and inject it into the current slice. At the
                moment, only one data source is allowed to be selected. You will be able to map the data from this API
                call through placeholders, which you can use in the following way:
                <p>
                    eg. <code>{`%solr_api.author.authorName%`}</code>
                </p>
            </div>

            <DataSources source={source} />

            <h3>Other data sources</h3>
            <div>
                Automatically, you can access all data from:
                <Typography component="span">
                    <ul>
                        <li>
                            The article data eg.<code>{`%article.id%`}</code> or <code>{`%article.authors%`}</code>
                        </li>
                        <li>
                            List of{" "}
                            <a href="https://docs.google.com/spreadsheets/d/13-R-UGns1Ox-MDycdcrfsRVbKtI6V_HLd4HsorsxvFc/edit#gid=1087601074">
                                translation terms
                            </a>
                            , which will be translated depending on the displayed edition eg.{" "}
                            <code>PHPTranslatorTerms::CONSENT_OFFERS</code> or{" "}
                            <code>PHPTranslatorTerms::CONSENT_SPONSORS</code>
                        </li>
                    </ul>
                </Typography>
            </div>
        </>
    );
};

const DataSources = ({ source }) => {
    const paperStyle = {
        marginBottom: 20,
        padding: "50px 0",
        marginTop: 20,
        textAlign: "center",
        fontFamily: theme.typography.fontFamily,
        color: "#444",
        justifyContent: "center",
        backgroundColor: "#e3e3e3",
    };

    const currentWidget = source.match(/\[(.*?)\]/)[1];

    const getLink = (option) => {
        switch (option) {
            case "rating_api":
                return "https://ratings.master.van-prod.futureplc.engineering/ratings/realhomes/fDbPwfH4wBJQevWAqAPkdg";
            case "solr_api":
                return "http://index.quantum.fcsis.future.net.uk/solr/proof_coachmag/select?q=documentType%3Aarticle";
            case "feeds_api":
            default:
                return "#";
        }
    };

    return (
        <>
            <Grid columns="2">
                <SelectInput
                    source={`${source}.slice_data_source`}
                    label="Select a data component"
                    choices={[
                        { id: "feeds_api", name: "Feeds API" },
                        { id: "rating_api", name: "Rating API" },
                        { id: "solr_api", name: "Solr API" },
                    ]}
                    resettable
                />
            </Grid>

            <FormDataConsumer>
                {({ formData, record }) => {
                    const template =
                        formData && formData.widgets[currentWidget].slice_data_source
                            ? formData.widgets[currentWidget].slice_data_source
                            : null;
                    return (
                        <>
                            {template ? (
                                <>
                                    <div
                                        style={{
                                            marginTop: 20,
                                            backgroundColor: "#e8e8e8e0",
                                            color: theme.palette.secondary.main,
                                            padding: "8px 20px",
                                            borderRadius: 100,
                                            fontSize: "0.9em",
                                            display: "flex",
                                            alignItems: "center",
                                            width: "max-content",
                                        }}
                                    >
                                        {`Request ID: ${template}`}{" "}
                                        <Tooltip title="Example of data from request">
                                            <a href={getLink(template)} target="_blank" rel="noopener noreferrer">
                                                <ExternalLink
                                                    style={{
                                                        display: "flex",
                                                        alignSelf: "center",
                                                        justifySelf: "center",
                                                        marginLeft: "5px",
                                                        color: "#851be6",
                                                    }}
                                                />
                                            </a>
                                        </Tooltip>
                                    </div>
                                    <DataWidgetInput
                                        template={template}
                                        currentWidget={currentWidget}
                                        source={source}
                                        record={record}
                                    />
                                </>
                            ) : (
                                <div style={paperStyle}>Please choose a data source</div>
                            )}
                        </>
                    );
                }}
            </FormDataConsumer>
        </>
    );
};

const DataWidgetInput = ({ template, source }) => {
    return <DynamicWidgetInput type={template} source={source} />;
};

export default DataScreen;
