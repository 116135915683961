import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';

const SiteEdit = props => (
    <Edit undoable={false} title="Site config" {...props}>
        <EditForm permissions={props.permissions} />
    </Edit>
);

export default SiteEdit;
