import React from 'react';
import { TextInput, Filter } from 'react-admin';
import { withStyles } from "@material-ui/core/styles";

export const styles = theme => ({
    filter: {
    //   width: 150,
      "& > div.filter-field[data-source=site]":{width:"300px"},
    },
  });

const ListFilter = ({classes,...props}) => (
    <Filter className={classes.filter} {...props}>
        <TextInput source="guid" /* alwaysOn */ resettable label="Search by id (guid)"/>
        <TextInput source="name" /* alwaysOn */ resettable label="Search by name"/>
    </Filter>
);

export default withStyles(styles)(ListFilter);
