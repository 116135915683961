/* eslint-disable no-eval */
import React from 'react';

import { TextInput, SelectInput, FormDataConsumer, NumberInput } from "react-admin";
import {Button, Typography, Divider } from "@material-ui/core";
import {FieldArray } from "redux-form";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import Tooltip from '@material-ui/core/Tooltip';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '@material-ui/core';
import SolrFields from "./SolrFields";
import Grid from "../Grid";

export const LoopQuery = {
    label: 'Loop',
    dropdownLabel: 'Use Loop Solr Query',
    getSourceName: (source) => `${source}.loop_query_params`,
    getExpandableHelper: () => {
        return (
            <ExpansionPanel defaultExpanded={false} style={{ margin: '20px 0px'}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subheading">Usage</Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                    - This query is used to return a list of results.<br />
                    - In the "General Solr Filters" part below, the results of this request are used as %loop_query_item.exampleProperty%.<br />
                    - You will have one dynamic widget generated for each result of the Loop Request, and each of them will use the request in "General Solr Filters"
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    },
    shouldBeAddable: (fields) => fields.length === 0,
    tooltipText: 'Loop query are used to generate multiple widgets using one UI widget. Click and See "Usage" to know more',
    hasDescription: false,
}

export const MoreQuery = {
    label: 'More',
    dropdownLabel: null,
    getSourceName: (source) => `${source}.more_query_params`,
    getExpandableHelper: (classes) => null,
    shouldBeAddable: (fields) => true,
    tooltipText: '',
    hasDescription: true,
}

export const getSolrQueryComponent = (type, source) => {
    return (
        <FieldArray
            name={type.getSourceName(source)}
            component={CustomSolrQueryFields}
            options={[type.label, type.dropdownLabel, type.shouldBeAddable, type.getExpandableHelper, type.tooltipText, type.hasDescription]}
        />
    );
}

const CustomSolrQueryFields = (props) => {

    const { fields, options } = props;
    const [label, dropdownLabel, shouldBeAddable, getExpandableHelper, tooltipText, hasDescription ] = options;

    return (
        <div style={{width: '90%', margin: 'auto'}}>
            {fields.length > 0 &&
                fields.map((itemSource, index) => (
                    <FormDataConsumer>
                        {({ formData }) => {
                            return (
                                <div style={{
                                    backgroundColor: "#8080801f",
                                    padding:"20px",
                                    margin:"20px 0px",
                                    borderColor:"#8080801f",
                                    borderStyle:'solid',
                                    borderWidth:1,
                                    borderRadius:"3px"
                                }}>
                                    <Typography variant="subheading">General Solr Filters For {label} Query</Typography>
                                    { getExpandableHelper() !== null && 
                                        getExpandableHelper()
                                    }
                                    <TextInput source={`${itemSource}._title`} label="Title" style={{width: 'calc(50% - 5px)', marginRight: '5px'}}/>
                                    { hasDescription &&
                                        <TextInput source={`${itemSource}._description`} label="Description" style={{width: 'calc(50% - 5px)'}}/>
                                    }
                                    <FieldArray
                                        name={`${itemSource}.solr`}
                                        component={SolrFields}
                                        options={window.fcsisSearchFields}
                                    />
                                    <Divider style={{ margin: "40px 15px" }} />
                                    <Typography variant="subheading">Sorting and Document Count</Typography>
                                    <Grid columns={2}>
                                        <SelectInput
                                            source={`${itemSource}.sortBy`}
                                            label="Sort By"
                                            choices={window.sortByChoices}
                                        />

                                        <SelectInput
                                            source={`${itemSource}.sortDir`}
                                            label="Sort Dir"
                                            choices={window.sortDirChoices}
                                        />
                                        <NumberInput source={`${itemSource}.items_per_page`} label="Items per page" />
                                        <TextInput source={`${itemSource}.page`} label="Page" />
                                        <TextInput source={`${itemSource}.rows`} label="Rows" />
                                        <TextInput source={`${itemSource}.start`} label="Start" />
                                    </Grid>
                                    <Divider style={{ margin: "40px 15px" }} />
                                    <Button size="small" onClick={() => fields.remove(index)} style={{ margin: "20px auto", display: "flex" }}>
                                        <MinusIcon /> Remove Solr Query
                                    </Button>
                                </div>
                            );
                        }}
                    </FormDataConsumer>
                ))}

            { shouldBeAddable(fields) &&
                <Tooltip title={`${tooltipText}`}>
                    <Button size="small" onClick={() => fields.push({})} style={{ margin: "20px auto", display: "flex" }}>
                        <AddIcon /> {dropdownLabel ? dropdownLabel : `Add ${label} Solr Query`}
                    </Button>
                </Tooltip>
            }

        </div>
    );
};

export default CustomSolrQueryFields;

