import React from 'react';
import {
    TextField, UrlField, BooleanField,
} from 'react-admin';
import LinkListField from './LinkListField';

export default props => (
    <LinkListField
        {...props}
        inputFields={[
            <TextField source="title" label="Title" />,
            <UrlField source="url" label="URL" />,
            <TextField source="position" label="Position" />,
            <BooleanField
                source="target_blank"
                label="Open Link in New Window"
            />,
        ]}
    />
);
