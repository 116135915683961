import React from "react";
import {
  FormDataConsumer,
  BooleanInput,
  TextInput,
} from "react-admin";
import CardExpanded from "../CardExpanded";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import MultipleSelectWithCustom from "../../components/MultipleSelectWithCustom";
import { FieldArray } from "redux-form";
// import clsx from "clsx";

export const styles = (theme) => ({
  formLabel: {
    color: theme.color.grayScale.dark,
  },
  inputCluster: {
    "& input": {
      // width: 320,
    },
  },
  formGroup: {
    flexDirection: "row",
    justifyContent: "space-around",
    "& > div": {
      // flexDirection: "inherit",
    },
  },
  formControlFcsis:{
    width: "49%",
  },
  formControl: {
    margin: "30px 0",
  },
});

const domainsChoices = (data) =>
  data
    ? window.domainsCMS.map((domain) => domain.replace("{site}", data.site))
    : [];

export class Outbox extends React.Component {
  render() {
    const { source, label, classes } =
      this.props;
    const { env, fcsisEndpointProd, fcsisEndpointStage } = window;
    /* let formGroupFcsis = clsx({
      [classes.formControlFcsis]: true,
      [classes.formGroup]: true,
    }); */
    return (
      <CardExpanded columns={1} label={label}>
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <FormControl className={classes.formControl} component="fieldset">
                <FormLabel
                  classes={{ root: classes.formLabel }}
                  component="legend"
                >
                  Redirect
                </FormLabel>
                <FormGroup classes={{ root: classes.formGroup }}>
                  <FieldArray
                    label="Allowed redirect source domains"
                    name={`${source}.allowed_redirect_source_domains`}
                    component={MultipleSelectWithCustom}
                    choices={domainsChoices(formData)}
                    helper={`You can enter input null to disable inheritance from parent`}
                  />
                  <FieldArray
                    label="Allowed redirect destination domains"
                    name={`${source}.allowed_redirect_destination_domains`}
                    component={MultipleSelectWithCustom}
                    choices={domainsChoices(formData)}
                    helper={`You can enter input null to disable inheritance from parent`}
                  />
                </FormGroup>
              </FormControl>
              <Divider />
              <>
              <FormControl className={classes.formControl} component="fieldset">
                <FormLabel
                  classes={{ root: classes.formLabel }}
                  component="legend"
                >
                  Varnish
                </FormLabel>
                <FormGroup className={classes.formGroup}>
                  <BooleanInput
                    label={"Origin enabled"}
                    source={`${source}.varnish.origin_enabled`}
                    defaultValue={true}
                  />
                  <BooleanInput
                    label={"Edge enabled"}
                    source={`${source}.varnish.edge_enabled`}
                    defaultValue={true}
                  />
                  <TextInput
                    label={"Cluster version"}
                    source={`${source}.varnish.cluster_version`}
                    defaultValue={"default"}
                  />
                </FormGroup>
              </FormControl>
              <Divider />
              <FormControl className={classes.formControl} component="fieldset">
                <FormLabel
                  classes={{ root: classes.formLabel }}
                  component="legend"
                >
                  FCSIS
                </FormLabel>
                <FormGroup className={classes.formGroup}>
                  <TextInput
                    classes={{ root: classes.formControlFcsis }}
                    label={"Cluster"}
                    source={`${source}.fcsis.cluster`}
                    helperText={`ex: http://master.fcsis.van-qa.futureplc.engineering`}
                    defaultValue={
                      env === "prod" ? fcsisEndpointProd : fcsisEndpointStage
                    }
                    />
                  <TextInput
                    label={"Collection"}
                    classes={{ root: classes.formControlFcsis }}
                    source={`${source}.fcsis.collection`}
                    defaultValue={`proof_${formData && formData.site_id}`}
                    helperText={`ex: proof_default`}
                  />
                </FormGroup>
              </FormControl>
              </>
            </>
          )}
        </FormDataConsumer>
      </CardExpanded>
    );
  }
}

export default withStyles(styles)(Outbox);
