import React, { useState, cloneElement, useCallback } from "react";
import IconButton from "./IconButton";
import { Modal } from "./Modal";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  dialogContentClasses: {
    textAlign: "center",
  }
});

const ModalButton = ({
  children,
  labelButton = ``,
  IconElement,
  getModalTitle = ``,
  record,
  maxWidth = "md",
  styles = '',
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles(props);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleIconClasses = useCallback(
    (iconClasses) =>
      cloneElement(IconElement, { className: iconClasses.leftIcon }),
    []
  );

  return (
    <>
        <div style={styles === 'related' ? {textAlign: 'left', minHeight: 'auto', display: 'block', backgroundColor: 'unset' } : {}}>
            <IconButton
                color="primary"
                onClick={handleOpenModal}
                icon={handleIconClasses}
            >
                {`${labelButton}`}
            </IconButton>
        </div>
      <Modal
        title={
          <span
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {getModalTitle(record)}
          </span>
        }
        showDialog={openModal}
        handleCloseClickCancel={handleCloseModal}
        record={record}
        dialogContentClasses={classes.dialogContentClasses}
        maxWidth={maxWidth}
        hideApplyButton={true}
      >
        {React.cloneElement(children, { record, ...children.props })}
      </Modal>
    </>
  );
};

export default ModalButton;
