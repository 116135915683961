import React, { Component, Fragment } from "react";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "../IconButton";
import Card from "./Card";
import { orderByKey, getSource } from "../../tools";
import { FormDataConsumer } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as _ from "lodash";
const styles = {
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    background: "rgb(216 212 212 / 50%)",
    border: "0.5px #80808061 solid",
    display: "flex",
    paddingTop: "50vh",
    justifyContent: "center",
  },
  dragging: {
    marginTop: 10,
    border: "5px #4caf50 solid",
    borderRadius: 5,
    height: "100px",
  },
  areaBoundary: {
    width: "100%",
    border: "1px solid #851be6",
    borderRadius: "5px",
    marginTop: "15px",
  },
  areaHeader: {
    fontWeight: "600",
    color: "white",
    background: "#851be6",
    textAlign: "center",
    padding: "5px",
  },
  areaBoundaryWrapper: {
    margin: "0.3px 0 0.5px",
  },
  emptyArea: {
    fontSize: "0.9375rem",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    color: "black",
  },
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draggedItemIndex: null,
      replaceIndex: null,
      fieldsList: props.fields.getAll(),
      isDragging: false,
      isUpdating: false,
      newArea: null,
    };
    this.handleWidgetDrag = this.handleWidgetDrag.bind(this);
    this.handleWidgetDrop = this.handleWidgetDrop.bind(this);
    this.handleWidgetDragOver = this.handleWidgetDragOver.bind(this);
    this.getAreasGroups = this.getAreasGroups.bind(this);
    this.getSortedFields = this.getSortedFields.bind(this);
  }


  handleWidgetDrag(index) {
    if (this.state.draggedItemIndex !== index) {
      this.setState({ draggedItemIndex: index });
    }

    if (!this.state.isDragging) {
      setTimeout(() => {
        this.setState({ isDragging: true });
      });
    }
  }

  handleWidgetDragOver(index) {
    if (this.state.replaceIndex !== index) {
      this.setState({ replaceIndex: index });
    }
  }

  handleWidgetDrop() {
    this.setState({ isDragging: false });
    const { draggedItemIndex, replaceIndex } = this.state;
    const affectedAreas = []; //areas that have been dragged from or dropped over that need re-distribution
    let updatedList = this.getSortedFields(this.state.fieldsList.slice());
    if (draggedItemIndex !== replaceIndex) {
      const draggedField = updatedList.splice(draggedItemIndex, 1)[0];
      affectedAreas.push(draggedField.area);
      const replaceFieldArea = this.state.newArea
        ? this.state.newArea
        : this.props.fields.get(replaceIndex).area;
      affectedAreas.push(replaceFieldArea);

      draggedField.area = replaceFieldArea;
      //if the widget is dragged down, subtract 1 from the replace index
      //to accomodate for the dragged field removed from the array
      updatedList.splice(
        replaceIndex - (draggedItemIndex < replaceIndex ? 1 : 0),
        0,
        draggedField
      );
      this.props.fields.removeAll();
      let area = null;
      let fieldPositionInArea = 1;
      updatedList = updatedList.map((field, index) => {
        if (!area || field.area !== area) {
          area = field.area;
          fieldPositionInArea = 1;
        }
        let updatedField = { ...field };
        if (affectedAreas.indexOf(area) > -1) {
          //only update the index of fields within the affected areas
          updatedField = {
            ...updatedField,
            ...{ position: fieldPositionInArea },
          };
          fieldPositionInArea++;
        }

        this.props.fields.push(updatedField);
        return updatedField;
      });
    }

    this.setState({
      fieldsList: updatedList.slice(),
      draggedItemIndex: null,
      replaceIndex: null,
      isDragging: false,
      isUpdating: false,
      newArea: null,
    });
  }

  getAreasGroups(formDataWidgets = []) {
    const currentFields = this.getSortedFields(formDataWidgets);
    const highestAreaIndex = Math.max(
      ...currentFields
        .filter((item) => typeof item.area === "number")
        .map((item) => item.area)
    );
    const isZeroIndexField = currentFields.find(field => field.area === 0)
    const smallestAreaIndex = isZeroIndexField ? 0 : 1;
    const groupedAreas = {};
    for (let i = smallestAreaIndex; i <= highestAreaIndex; i++) {
      groupedAreas[i] = [];
    }
    const sources = this.props.fields.map((field) => field);
    currentFields.forEach((field, index) => {
      const updatedField = {
        ...field,
        source: sources[index],
        fieldIndex: index,
      };
      if (typeof field.area === "number") {
        groupedAreas[field.area].push(updatedField);
      } else {
        groupedAreas["no-area"]
          ? groupedAreas["no-area"].push(updatedField)
          : (groupedAreas["no-area"] = [updatedField]);
      }
    });
    return groupedAreas;
  }

  getSortedFields(fieldsArray = []) {
    return fieldsArray
      .sort((a, b) => {
        //sorting to ensure that the order is the same as shown in the ui
        // sort asc by area
        return orderByKey(a, b, "area");
      })
      .sort((a, b) => {
        // sort asc by position depending area
        return orderByKey(a, b, "position", "area");
      });
  }

  render() {
    const { fields, permissions, record, classes } = this.props;
    const { isDragging, isUpdating } = this.state;
    return (
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Fragment>
              <div style={{ position: "relative" }}>
                {isUpdating && <Loader />}
                {fields.length > 0 &&
                  Object.entries(this.getAreasGroups(formData? formData.widgets : [])).map(
                    ([key, value]) => {
                      return (
                        <AreaBoundaryWrapper
                          id={`widget-area-${key}`}
                          isDragging={isDragging}
                        >
                          {isDragging && <AreaHeader areaIndex={key} />}
                          {!value.length && isDragging && (
                            <EmptyArea
                              handleDropEvent={() =>
                                this.setState(
                                  {
                                    newArea: parseInt(key),
                                    replaceIndex: null,
                                  },
                                  () => this.handleWidgetDrop()
                                )
                              }
                            />
                          )}
                          {!!value.length &&
                            value.map((field) => {
                              return (
                                <Card
                                  key={`widget-card-${field.fieldIndex}`}
                                  index={field.fieldIndex}
                                  fields={fields}
                                  source={field.source}
                                  record={field}
                                  permissions={permissions}
                                  site={record.site}
                                  baselayout={record.baselayout}
                                  handleWidgetDrag={() =>
                                    this.handleWidgetDrag(field.fieldIndex)
                                  }
                                  handleWidgetDrop={this.handleWidgetDrop}
                                  handleWidgetDragOver={(e) => {
                                    setTimeout(
                                      () =>
                                        (document.getElementById(
                                          `list-item-${field.fieldIndex}`
                                        ).parentNode.style.transform =
                                          "scale(1.02)"),
                                      50
                                    );
                                    this.handleWidgetDragOver(field.fieldIndex);
                                  }}
                                  handleWidgetDragLeave={() => {
                                    setTimeout(
                                      () =>
                                        (document.getElementById(
                                          `list-item-${field.fieldIndex}`
                                        ).parentNode.style.transform = "none"),
                                      50
                                    );
                                  }}
                                  handleDropCapture={(e) => {
                                    setTimeout(
                                      () =>
                                        (document.getElementById(
                                          `list-item-${this.state.replaceIndex}`
                                        ).parentNode.style.transform = "none"),
                                      50
                                    );
                                    this.setState({ isUpdating: true });
                                  }}
                                />
                              );
                            })}
                        </AreaBoundaryWrapper>
                      );
                    }
                  )}

                {permissions && permissions.checkUserCanCreateWidget() ? (
                  <div style={{ marginTop: 16 }}>
                    <IconButton
                      color="primary"
                      icon={(iconClasses) => (
                        <AddIcon className={iconClasses.leftIcon} />
                      )}
                      onClick={() => fields.push({})}
                    >
                      Add a widget
                    </IconButton>
                  </div>
                ) : null}
              </div>
            </Fragment>
          );
        }}
      </FormDataConsumer>
    );
  }
}

const Loader = () => {
  return (
    <div style={styles.overlay}>
      <CircularProgress size={100} thickness={2} />
    </div>
  );
};

const EmptyArea = ({ handleDropEvent }) => {
  return (
    <div
      draggable={true}
      style={styles.emptyArea}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      onDrop={handleDropEvent}
    >
      Empty
    </div>
  );
};

const AreaHeader = ({ areaIndex }) => {
  return <div style={styles.areaHeader}>Area {areaIndex}</div>;
};

const AreaBoundary = ({ children, id, isDragging, classes }) => {
  const setAreaBoxShadow = (action) => {
    document.getElementById(id).style.boxShadow =
      action === "set" ? "5px 5px 30px 1px grey" : "none";
  };

  return (
    <div
      id={id}
      className={
        isDragging ? classes.areaBoundary : classes.areaBoundaryWrapper
      }
      onDragLeave={() => {
        setAreaBoxShadow("remove");
      }}
      onDrop={() => setAreaBoxShadow("remove")}
      onDragOver={() => {
        setAreaBoxShadow("set");
      }}
    >
      {children}
    </div>
  );
};

const AreaBoundaryWrapper = withStyles(styles)(AreaBoundary);
export default withStyles(styles)(List);
