import React, { Component } from 'react';
import { init } from 'pell';
import { Labeled } from 'react-admin';

export default class RichTextInput extends Component {
    constructor(props) {
        super(props);
        this.editor = null;
        this.textInput = null;
    }

    componentDidMount() {
        const { input, pell } = this.props;
        this.editor = init({
            element: this.textInput,
            onChange: (html) => {
                input.onChange(html);
            },
            defaultParagraphSeparator: 'p',
            styleWithCSS: true,
            actions: ['bold', 'italic', 'underline', 'olist', 'ulist', 'link'],
            ...pell,
        });
        this.editor.content.innerHTML = input.value;
    }

    componentDidUpdate(prevProps) {
        const { input } = this.props;
        const isFocused = document.activeElement === this.editor.content;
        if (!isFocused && prevProps.input.value !== input.value) {
            this.editor.content.innerHTML = input.value;
        }
    }

    render() {
        const { label, input } = this.props;
        return (
            <div>
                {label && <Labeled label={label} />}
                <div
                    name={input.name}
                    id={input.name}
                    ref={(area) => {
                        this.textInput = area;
                    }}
                />
            </div>
        );
    }
}
