import React from 'react';
import List from './List';

const types = window.widgetTypes.reduce((acc, type) => {
    acc[type.id] = type.name;
    return acc;
}, {});

const getSubtitle = record => (record.type ? `Type: ${types[record.type] || record.type} - Area: ${record.area}` : '');

const getTitle = record => record.name;

class WidgetListInput extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { fields, permissions } = this.props;
        return (
            fields.length !== nextProps.fields.length
            || permissions !== nextProps.permissions
        );
    }

    render() {
        const { fields, record, permissions } = this.props;
        return (
            <List
                permissions={permissions}
                fields={fields}
                record={record}
            />
        );
    }
}

export default WidgetListInput;

export {
    getSubtitle,
    getTitle,
    types,
};
