import React, { Fragment, Component } from "react";
import { Edit } from "react-admin";
import EditActions from "./EditActions";
import Snackbar from "../components/SnackBar";
import { connect } from "react-redux";
import { isCodeFreeze } from "./Settings/settingsUI";

class Editing extends Component {
    componentDidMount() {
        const { settingsui = [], requestSettingUI } = this.props;
        if (!settingsui.length > 0) {
            requestSettingUI();
        }
    }

    render() {
        const { children, permissions, codefreeze = {}, requestSettingUI, ...rest } = this.props;
        const { status, variant, message } = codefreeze;
        return (
            <Fragment>
                <Edit undoable={false} actions={<EditActions permissions={permissions} />} {...rest}>
                    {children}
                </Edit>
                {status && <Snackbar open={true} variant={variant} message={message} />}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    settingsui: state.settingsui,
    codefreeze: isCodeFreeze(state.settingsui),
});

const mapPropsToDispatch = (dispatch) => ({
    requestSettingUI: () => dispatch({ type: "SETTINGS_FETCH_REQUESTED" }),
});

export default connect(mapStateToProps, mapPropsToDispatch)(Editing);
