import React, { Component } from "react";
import { TextInput, Labeled, FormDataConsumer } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from "../IconButton";
import LinkListCard from "./Card";
import Grid from "../Grid";
import { FieldArray, isDirty as dirty } from "redux-form";
import {
  RACheckIfJsonIsValid,
  diffArraySetting,
  diffArraySettingDefaultVal,
} from "../../tools";
import LinkListInput from "../LinkListInput";
import { extractFromSettings } from "../../Entities/Settings/settingsUI";
import { connect } from "react-redux";
import { TextFilterInput } from "../TextFilterInput";
import {useFilterConfigHanlder, useFilterfteConfig} from "../../Entities/Sites/siteConfigHandler";
import TreeView from "../TreeView";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getFTEConfig } from "../../hooks/dataFetcher";


const injectSiteNameInApisList = (ApisList, site = "") => {
  return ApisList.map((el) => {
    if (el.id === "solr" && el.items && el.items.length) {
      el.items.forEach((solrItem) => {
        if (solrItem.key && solrItem.key === "search_index" && solrItem.value) {
          const solrItemSplit = solrItem.value.split("_");
          if (solrItemSplit.length) {
            const replacValue = solrItemSplit.pop() || null;
            if (replacValue) {
              solrItem.value = solrItem.value.replace(replacValue, site);
            }
          }
        }
      });
    } else if (
      el.name &&
      (el.id === "test_ad_unit" || el.id === "live_ad_unit")
    ) {
      const adName = el.name.split("/");
      if (adName.length > 1 && adName[1]) {
        el.name = el.name.replace(adName[1], site);
      }
    } else if (el.id === "dfp" && el.items && el.items.length) {
      el.items.forEach((dfpItem) => {
        if (dfpItem.key && dfpItem.key === "base_ad_unit" && dfpItem.value) {
          const dfpItemSplit = dfpItem.value.split("/");
          if (dfpItemSplit.length > 1 && dfpItemSplit[1]) {
            dfpItem.value = dfpItem.value.replace(dfpItemSplit[1], site);
          }
        }
      });
    }
    return el;
  });
};
class ApiInputs extends Component {
  constructor(props) {
    super(props);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.togglefteConfig = this.togglefteConfig.bind(this);
    this.state = { 
      isToggleExpanded: false, 
      filterStr: "", 
      showfteConfig: true, 
      fteConfigPlaceholder: {},
      isLoadingFteConfig: false,
      fteConfigError: false
    };
  }

  componentDidMount() {
    this.setFTEConfig();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { fields, placeholderConfig, isDirty } = this.props;
    const { isToggleExpanded, filterStr, showfteConfig, isLoadingFteConfig, fteConfigError } = this.state;

    return (
      fields.length !== nextProps.fields.length
      || isToggleExpanded !== nextState.isToggleExpanded
      || ((nextProps.placeholderConfig && nextProps.placeholderConfig.content) !== (placeholderConfig && placeholderConfig.content))
      || isDirty
      || nextState.filterStr !== filterStr 
      || nextState.showfteConfig !== showfteConfig
      || nextState.isLoadingFteConfig !== isLoadingFteConfig
      || nextState.fteConfigError !== fteConfigError
    );
  }
  async setFTEConfig(){

    this.setState({isLoadingFteConfig: true});
    this.setState({fteConfigError: false});
    this.setState({fteConfigPlaceholder: undefined});

    const fteConfig = await getFTEConfig(this.props.record);
    this.setState({ fteConfigPlaceholder: fteConfig.json});
    if(fteConfig.isError){
      this.setState({fteConfigError: true})
    }
    this.setState({isLoadingFteConfig: false});
    
  }

  toggleExpanded() {
    this.setState((prevState) => ({
      isToggleExpanded: !prevState.isToggleExpanded,
    }));
  }

  togglefteConfig() {
    this.setState((prevState) => ({
      showfteConfig: !prevState.showfteConfig,
    }));
  }


  render() {
    const { record, source, label, placeholderConfig, ...rest } = this.props;
    const { fields, addLabel } = rest;
    const { isToggleExpanded, showfteConfig } = this.state;
    return (
      <FormDataConsumer>
        {({ formData }) => {
          const thirdPartyApisKeysToFilter = injectSiteNameInApisList(
            window.thirdPartyApisList,
            formData && formData.site_id
          );

          // store preloaded default id's in thirdPartyApisList if not exist in fields
          diffArraySetting(
            thirdPartyApisKeysToFilter,
            !fields.getAll() ? [] : fields.getAll()
          ).forEach((defaultSetting) => {
            fields.unshift(defaultSetting);
          });

          // setting default values for fields
          fields.getAll() &&
            diffArraySettingDefaultVal(
              thirdPartyApisKeysToFilter,
              fields.getAll()
            ).forEach((setting) => {
              const existingField = fields
                .getAll()
                .find((el) => el.id && setting.id && el.id === setting.id);
              if (
                existingField &&
                setting &&
                !existingField["items"] &&
                setting["items"]
              ) {
                fields.unshift(setting);
              }
            });

          /**
           * setting array that will record fields id's such as "botify", "selligent" etc.;
           * we'll only put fields that have items in there for further processing of duplicates
           */
          let fieldsWithItems = [];

          const thirdPartyApisCardsToDisplay = fields.map((itemSource, index) => {
            const id = fields.get( index ).id;
            const items = fields.get( index ).items;
            const filterConfig = useFilterConfigHanlder(fields.get(index), this.state.filterStr);
            if ( items ) {
              fieldsWithItems.push( id );
            }
            return (
              <div key={`api-list-${itemSource}`}>
                <LinkListCard
                  key={`api-list-${itemSource}`}
                  index={index}
                  expanded={isToggleExpanded || filterConfig.isExpanded}
                  source={itemSource}
                  displayIndex={true}
                  filterStr={this.state.filterStr}
                  {...rest}
                />
              </div>
            );
          })
          // sort third party API's cards alphabetically
          .sort( (a, b) => {
            return fields.get( a.props.children.props.index ).id > fields.get( b.props.children.props.index ).id ?
              1 : -1;
          })
          .filter((listItem) => {
            const configItem = fields.get(
              listItem.props.children.props.index
            );
            return (
              useFilterConfigHanlder(configItem, this.state.filterStr).isFiltered
            );
          });

          // if field id exists twice and one has no items, remove card and field that have no items
          let duplicatesToRemove = [];
          thirdPartyApisCardsToDisplay.forEach((card, index) => {
            const id = fields.get(card.props.children.props.index).id;
            const items = fields.get(card.props.children.props.index).items;
            if (!items && fieldsWithItems.includes(id)) {
              // filling the duplicate cards array
              duplicatesToRemove.push(index);
              // removing the relevant index in the stored fields
              fields.remove(card.props.children.props.index);
            }
          });
          // removing the relevant card for display
          duplicatesToRemove.forEach((dup) =>
            thirdPartyApisCardsToDisplay.splice(dup, 1)
          );

          return (
            <Grid columns={2} style={{ display: "flex", gap: "20px" }}>
              <div>
                <Labeled label={label} />
                <TextFilterInput
                  value={this.state.filterStr}
                  onFilterChanged={(str) => this.setState({ filterStr: str })}
                />
                <IconButton
                  color="primary"
                  icon={(classes) =>
                    !isToggleExpanded ? (
                      <AddIcon className={classes.leftIcon} />
                    ) : (
                      <RemoveIcon className={classes.leftIcon} />
                    )
                  }
                  onClick={this.toggleExpanded}
                >
                  {isToggleExpanded ? "Hide" : "Show"} All
                </IconButton>
                <IconButton
                  color="primary"
                  icon={(classes) => (
                    <VisibilityIcon className={classes.leftIcon} />
                  )}
                  onClick={this.togglefteConfig}
                >
                  {showfteConfig ? "Hide" : "Show"} FTE Config
                </IconButton>
                {fields.length > 0 && thirdPartyApisCardsToDisplay}
                <IconButton
                  color="primary"
                  icon={(classes) => <AddIcon className={classes.leftIcon} />}
                  onClick={() => {
                    return fields.push({});
                  }}
                >
                  {addLabel}
                </IconButton>
              </div>
              {showfteConfig && (
                <div
                  style={{
                    marginTop: "60px",
                    marginBottom: "40px",
                    overflow: "hidden",
                    boxShadow:
                      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
                    padding: "15px",
                    minWidth: "500px",
                  }}
                >
                 <h2 style={{textAlign: 'center'}}>FTE Config result of the current published version</h2> 
                  {this.state.isLoadingFteConfig && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "500px",
                        flexDirection: "column",
                        gap: "15px",
                        fontSize: "20px ",
                      }}
                    >
                      Loading FTE Config <CircularProgress color="secondary" />
                    </div>
                  )}
                  {!this.state.isLoadingFteConfig &&
                    this.state.fteConfigPlaceholder && (
                      <TreeView
                        data={useFilterfteConfig(
                          this.state.fteConfigPlaceholder,
                          this.state.filterStr
                        )}
                        filterStr={this.state.filterStr}
                      />
                    )}
                  {!this.state.isLoadingFteConfig &&
                    !this.state.fteConfigPlaceholder && 
                    !this.state.fteConfigError && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "500px",
                          fontSize: "20px ",
                        }}
                      >
                        No FTE Config Found
                      </div>
                    )}

                  {!this.state.isLoadingFteConfig &&
                    this.state.fteConfigError && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "10px",
                          height: "500px",
                          fontSize: "20px ",
                        }}
                      >
                        An Error Occurred While Fetching FTE Config
                        <div style={{color: "#3f51b5", cursor: "pointer"}} onClick={() => this.setFTEConfig()}> Fetch Config Again</div>
                      </div>
                    )}    
                </div>
              )}
            </Grid>
          );
        }}
      </FormDataConsumer>
    );
  }
}

ApiInputs.defaultProps = {
  label: "Config list",
  getTitle: (item) => item.id,
  extendedTitle: (item) =>
    `${
      item.name && item.items && item.items.length
        ? `${item.name} - ${
            (item.items && item.items.length) || 0
          } sub-param(s)`
        : `${
            item.name ||
            ((item.items && item.items.length) || 0) + " sub-param(s)"
          }`
    }`,
  gridColumnTitle: 2,
  gridTemplateColumns: "1fr 1fr 100px",
  displayIndex: true,
  addLabel: "Add Config",
  removeLabel: "Remove Config",
  showEmptyKeys: [],
  initiallyExpanded: (item) => false,
  inputFields: [
    <TextInput
      source="id"
      label={"Id"}
      style={{ display: "block" }}
      fullWidth={true}
    />,
    <TextInput
      source="name"
      placeholder={`use [] or {} to deal JSON structures`}
      label={"name"}
      fullWidth={true}
      style={{ display: "block" }}
      warn={[RACheckIfJsonIsValid]}
      validate={[RACheckIfJsonIsValid]}
      multiline
    />,
    <FieldArray
      name="items"
      addLabel="Parameters"
      label="Parameters"
      removeLabel="Remove Sub key"
      getTitle={(item) => item.key}
      extendedTitle={(item) => (item.value ? item.value : "")}
      gridTemplateColumns={"100px 1fr 1fr"}
      initiallyExpanded={(record) => !record.key || !record.value}
      showEmptyKeys={[]}
      component={LinkListInput}
      inputFields={[
        <TextInput
          source="key"
          label={"key"}
          style={{ display: "inline-flex" }}
          fullWidth={true}
        />,
        <TextInput
          placeholder={`use [] or {} to deal JSON structures`}
          source="value"
          label={"value"}
          warn={[RACheckIfJsonIsValid]}
          validate={[RACheckIfJsonIsValid]}
          style={{ display: "inline-flex" }}
          fullWidth={true}
          multiline
        />,
      ]}
    ></FieldArray>,
  ],
};

const mapStateToProps = (state, props) => ({
  placeholderConfig: extractFromSettings(
    state.settingsui,
    "documentation_site_config"
  ),
  isDirty: dirty("record-form")(state),
  touchOnChange: true,
});

//avoid useless call to store
const areStatesEqual = (prev, next) => {
  const prevDocumentation = extractFromSettings(
    prev.settingsui,
    "documentation_site_config"
  );
  const nextDocumentation = extractFromSettings(
    next.settingsui,
    "documentation_site_config"
  );
  return (
    (prevDocumentation && prevDocumentation.content) ===
    (nextDocumentation && nextDocumentation.content)
  );
};

export default connect(mapStateToProps, null, null, {
  areStatesEqual: areStatesEqual,
})(ApiInputs);
