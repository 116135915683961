import React, { useState, useEffect } from "react";
import { REDUX_FORM_NAME, FormDataConsumer } from "react-admin";
import { change } from "redux-form";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    CircularProgress,
    Button,
    Tooltip,
} from "@material-ui/core";
import { ExpandMore, VisibilityOff, Visibility } from "@material-ui/icons";

import { UIElement } from "../UIElement";
import Grid from "../../Grid";
import { CURRENT_PROTOCOL, SLICE_ROUTE } from "../../../Constants/slice";
import { theme } from "../../../Admin";

const SliceAccordion = ({ HtmlTagForTitle, properties, isRequired, UIElementComponent, source }) => {
    const [deactivateAccordion, setDeactivateAccordion] = useState(false);
    const [expanded, setExpanded] = useState(false);

    return (
        <div style={{ display: "flex", padding: 0, flexFlow: "row nowrap", alignItems: "center", width: "100%" }}>
            <FormDataConsumer>
                {({ formData, dispatch }) => (
                    <>
                        <ExpansionPanel
                            style={{ padding: "4px 20px 8px", margin: "20px 5px", width: "100%" }}
                            disabled={deactivateAccordion}
                            expanded={expanded}
                        >
                            <ExpansionPanelSummary
                                onClick={() => setExpanded((value) => !value)}
                                expandIcon={<ExpandMore />}
                                style={{ padding: 0 }}
                            >
                                <HtmlTagForTitle
                                    style={{
                                        fontFamily: theme.typography.fontFamily,
                                        marginBottom: 0,
                                        marginTop: 0,
                                        fontSize: "1.3em",
                                        lineHeight: "1.7em",
                                    }}
                                >
                                    {properties && properties.description ? properties.description : "-"}
                                </HtmlTagForTitle>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ flexFlow: "column" }}>
                                {UIElementComponent}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {!isRequired && (
                            <Tooltip
                                title={
                                    deactivateAccordion
                                        ? "Click to activate fields"
                                        : "Click to deactivate\nThese fields are not required and can be removed from payload"
                                }
                            >
                                <Button
                                    style={{
                                        color: deactivateAccordion ? "#4caf50" : "#777",
                                    }}
                                    onClick={() => {
                                        setDeactivateAccordion((value) => !value);
                                        setExpanded(false);
                                        dispatch(change(REDUX_FORM_NAME, source, null));
                                    }}
                                >
                                    {deactivateAccordion ? <Visibility /> : <VisibilityOff />}
                                </Button>
                            </Tooltip>
                        )}
                    </>
                )}
            </FormDataConsumer>
        </div>
    );
};
export const ObjectField = ({
    properties: initialProps,
    description = "",
    required = [],
    defs = {},
    source,
    groupingFlag,
    pattern,
    disableAllFields,
}) => {
    const fields = {
        boolean: [],
        string: [],
        number: [],
        other: [],
    };

    const [properties, setProperties] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        /**
         * We need to fetch external schemas that are referenced in the main schema file.
         * So I think fetching when mounting is better.
         */

        if (initialProps && loading) {
            const schemasToFetch = [];

            Object.keys(initialProps).forEach((key) => {
                if (initialProps[key] && "$ref" in initialProps[key]) {
                    if (initialProps[key]["$ref"].includes(".json")) {
                        schemasToFetch.push({ key: key, fileName: initialProps[key]["$ref"] });
                    }
                }
            });

            if (!schemasToFetch.length) {
                setProperties(initialProps);
                setLoading(false);
            } else {
                const urlsToFetch = schemasToFetch.map((schema) =>
                    fetch(
                        `${CURRENT_PROTOCOL}${window.slice.version.replaceAll(".", "-")}.${SLICE_ROUTE}/schema/components/${schema.fileName}`
                    )
                );

                Promise.all(urlsToFetch)
                    .then((responses) => {
                        return Promise.all(responses.map((r) => r.json()));
                    })
                    .then((extraData) => {
                        let propsFullData = initialProps;

                        schemasToFetch.forEach((schema) => {
                            propsFullData[schema.key] = extraData.find((element) =>
                                element["$id"].includes(`/${schema.fileName}`)
                            );
                        });

                        setProperties(propsFullData);
                        setLoading(false);
                    });
            }
        }
    }, [initialProps]);

    if (loading) {
        return <CircularProgress color="secondary" />;
    }

    if (!properties) {
        return <div>Your json schema does not contain properties</div>;
    }

    const HtmlTagForTitle = `h${groupingFlag}`;

    Object.keys(properties).forEach((key) => {
        if (properties[key] && typeof properties[key] === "object" && "$ref" in properties[key]) {
            /**
             * Get definitions mentioned in the same schema file.
             */

            const defKey = properties[key]["$ref"].substr(properties[key]["$ref"].lastIndexOf("/") + 1);
            properties[key] = defKey in defs ? setProperties((value) => (value[key] = { ...defs[defKey] })) : null;
        }

        if (properties[key]) {
            const isRequired = typeof required === "object" ? required.includes(key) : false;
            const fieldsKey = properties[key].type in fields ? properties[key].type : "other";
            const updatedSource = properties[key].type === "object" ? `${source}.${key}` : source;

            const UIElementComponent = (
                <UIElement
                    key={`${properties[key].description}-${properties[key].type}-${groupingFlag}`}
                    type={properties[key].type}
                    data={properties[key]}
                    required={isRequired}
                    propertyName={key}
                    source={updatedSource}
                    {...{ groupingFlag, pattern, disableAllFields }}
                />
            );

            if (fieldsKey === "other" && groupingFlag !== 1) {
                fields[fieldsKey].push(
                    <SliceAccordion
                        key={`slice-accordion-${properties[key].description}-${properties[key].type}-${groupingFlag}`}
                        properties={properties[key]}
                        source={updatedSource}
                        {...{ HtmlTagForTitle, isRequired, UIElementComponent }}
                    />
                );
            } else {
                fields[fieldsKey].push(UIElementComponent);
            }
        }
    });

    return (
        <>
            {groupingFlag === 1 && (
                <HtmlTagForTitle
                    style={{
                        fontFamily: theme.typography.fontFamily,
                        marginBottom: 0,
                        marginTop: 30,
                        fontSize: groupingFlag === 1 ? "1.3em" : null,
                    }}
                >
                    {groupingFlag === 1 ? `${description.toUpperCase()} SLICE` : description}
                </HtmlTagForTitle>
            )}
            {fields.boolean.length ? <Grid columns="2">{fields.boolean}</Grid> : <></>}
            {fields.number.length ? <Grid columns="2">{fields.number}</Grid> : <></>}
            {fields.string.length ? <Grid columns="2">{fields.string}</Grid> : <></>}
            {fields.other.length ? fields.other : <></>}
        </>
    );
};
