import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import axios from 'axios/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOff from '@material-ui/icons/HighlightOff';
import IconButton from './IconButton';
import { makeBranchedUrl } from '../restClient';
import Cookies from "js-cookie";

class UnPublishButton extends Component {
    state = {
        isLoading: false,
        display: true
    };

    handleUnPublish = () => {
        this.setState({ isLoading: true });
        const { record, resource, showNotification } = this.props;
        const fd = new FormData();

        fd.append('guid', record.id);
        fd.append('doc_type', record.doc_type);

        let requestConfig = {
            headers: {
                Authorization: Cookies.get('token'),
                Accept: 'application/json',
                'X-Role': localStorage.getItem('role'),
                'X-ExpiresIn': localStorage.getItem('expiresIn'),
                'X-User-Name': localStorage.getItem('user')
            }
        };


        function forceToReload() {
            return resource === 'abtesting';
        }

        axios
            .post(makeBranchedUrl(`/api/unpublishes/${record.id}/${resource}`), fd, requestConfig)
            .then(response => response.data)
            .then((response) => {
                if (forceToReload()) {
                    window.location.reload();
                }
                this.setState({
                    isLoading: false,
                    display: false
                });
                if (response.error) {
                    showNotification(
                        `${'flexi.unpublished.error :'}${response.error.exception[0].message}`,
                        'warning',
                    );
                }
                showNotification('flexi.unpublished.success');
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    console.log(thrown);
                    this.setState({ isLoading: false });
                    showNotification('flexi.unpublished.error');
                }
            });
    };

    render() {
        const { isLoading } = this.state;
        const { record, disabled } = this.props;
        const displayAnyway = typeof this.props.record.is_published === 'undefined';
        const display = displayAnyway === false && this.props.record.is_published && this.state.display;
        return record && record.id && (displayAnyway ? true : display) ? (
            <IconButton
                color="secondary"
                icon={classes =>
                    isLoading ?
                        <CircularProgress className={classes.leftIcon} size={20} thickness={2} />
                        : <HighlightOff className={classes.leftIcon} />
                }
                onClick={this.handleUnPublish}
                disabled={disabled}
            >
                {this.getUnpublishLabel()}
            </IconButton>
        ) : null;
    }

    getUnpublishLabel() {
        const labels = {
            abtesting: 'Stop Abtest'
        };

        return labels[this.props.record.doc_type] ? labels[this.props.record.doc_type] :'Unpublish';
    }
}

export default connect(
    null,
    { showNotification: showNotificationAction },
)(UnPublishButton);
