import React, {Component} from 'react'
import { connect } from 'react-redux';
import {ImageField, ImageInput, showNotification as showNotificationAction} from "react-admin"
import { withStyles } from '@material-ui/core/styles'
import {fetch} from "../../restClient";

const styles = (theme) => ({
    chatGptImage: {
        '& > div span div:first-of-type': {
            maxWidth: '355px',
            float: 'left',
            marginRight: '10px'
        },
        '& > div span div:first-of-type ~ div': {
            width: '160px',
            display: 'flex',
        },
        '& > div img': {
            width: '100%',
            height: '70px',
            margin: 0
        },
        '& > div button': {
            marginRight: '-40px'
        },
        '& > [uploaderr="true"] img': {
            display: 'none'
        }
    }
});

class ChatBotImageInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadErr: true
        };
    }

    handleChange = (files) => {
        const { showNotification, input, setImage } = this.props;

        if (null !== files && files.hasOwnProperty('rawFile')) {
            const fd = new FormData();

            fd.append('file', files.rawFile);
            fetch('/api/uploads', {
                method: 'post',
                body: fd,
            })
            .then((response) => {
                this.setState({ isLoading: false, uploadErr: false });
                if (response.error) {
                    return showNotification(`flexi.fileupload.${response.error.exception[0].message}`, 'warning');
                }
                showNotification('flexi.fileupload.success');
                setImage(response.json);
                input.onChange([
                    {
                        src: response.json.url,
                        preview: response.json.url,
                        mimeType: response.json.mimeType,
                        fileContent: response.json.fileContent,
                    },
                ]);
            })
            .catch(e => {
                this.setState({uploadErr: true})
                showNotification(`flexi.fileupload.fileTooBig`, 'warning');
            });
        } else if(null === files) {
            setImage([]);
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.chatGptImage}>
                <div uploaderr={`${this.state.uploadErr}`}>
                    <ImageInput label="Send a reference image to Chat-GPT" {...{input: {...this.props.input, onBlur: this.handleChange}}}>
                        <ImageField source="src" title="referenceImage"/>
                    </ImageInput>
                </div>
            </div>
        );
    }
}

export default connect(null, {showNotification: showNotificationAction})(withStyles(styles)(ChatBotImageInput));