import React, { useState, useEffect } from "react";
import MinusIcon from "@material-ui/icons/Remove";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import { FieldArray, Field } from "redux-form";
import {
  SelectInput,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  TextInput,
} from "react-admin";
import Grid from "../Grid";
import IconButton from "../IconButton";
import {
  getSource,
  required,
  negativeNumber,
  minValue0,
  number,
} from "../../tools";
import WidgetTypeInput from "../WidgetTypeInput";
import WidgetAdvancedSettings from "./WidgetAdvancedSettings";
import WidgetCommercialSettings from "./WidgetCommercialSettings";
import WidgetMoreLinksInput from "../WidgetMoreLinksInput";
import TabContainer from "./TabContainer";
import WidgetCardSummary from "./CardSummary";
import TextareaAutoComplete from "../TextareaAutoComplete";
import { useDraggableRef } from "../DragDropWrapper";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.secondary.main,
  },
  inputRoot: {
    "& > div": { minWidth: 80 },
  },
});

function WidgetCard({
  record,
  fields,
  index,
  permissions,
  site,
  baselayout,
  classes,
  source,
  handleWidgetDrag = () =>{},
  handleWidgetDragOver= () =>{},
  handleWidgetDrop= () =>{},
  handleDropCapture= () =>{},
  handleWidgetDragLeave=() =>{}
}) {
  const [order, setOrder] = useState(0);
  const [recordType, setRecordType] = useState((record && record.type) || "");
  const [isDraggable, setIsDraggable] = useState(false)
  const dragRef = useDraggableRef(
    "list-item-" + index,
    handleWidgetDrag,
    handleWidgetDragOver,
    undefined,
    handleWidgetDrop,
    undefined,
    true, 
    true,
    true
  );
  
  useEffect(()=>{
    if(dragRef.current){
    dragRef.current.draggable = isDraggable
  }
  }, [isDraggable])


  const handleTabChange = (e, value) => {
    setOrder(value);
  };

  const handleTypeChange = (e, type) => {
    setRecordType(type);
  };

  const handleDelete = () => {
    fields.remove(index);
  };

  return (
    permissions &&
    permissions.checkUserCanEditWidget(record) && (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const sourceData = formData ? getSource(formData, source) : record;
          return (
            <ExpansionPanel defaultExpanded={initiallyExpanded(sourceData)}>
              <ExpansionPanelSummary
                id={"list-item-" + index}
                buttonRef={dragRef}
                expandIcon={<ExpandMoreIcon />}
                onDropCapture={handleDropCapture}
                onDragLeave={handleWidgetDragLeave}
              >
                <WidgetCardSummary
                  sourceData={sourceData}
                  handleDragToggle={(draggable)=> setIsDraggable(draggable)}
                  site={site}
                  {...rest}
                />
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <div className={classes.root}>
                  <AppBar position="static" className={classes.appBar}>
                    <Tabs value={order} onChange={handleTabChange}>
                      <Tab label="General settings" />
                      <Tab label="See all link" />
                      <Tab label="Advanced settings" />
                      <Tab label="Commercial settings" />
                    </Tabs>
                  </AppBar>
                  {order === 0 && (
                    <TabContainer>
                      <Grid style={{ gridTemplateColumns: "100px 100px 1fr" }}>
                        <NumberInput
                          source={`${source}.area`}
                          label="Area"
                          validate={[number, minValue0]}
                          warn={negativeNumber}
                          allowEmpty
                          classes={{ root: classes.inputRoot }}
                        />
                        <NumberInput
                          source={`${source}.position`}
                          label="Position"
                          validate={[number, minValue0]}
                          warn={negativeNumber}
                          allowEmpty
                          classes={{ root: classes.inputRoot }}
                        />
                        <TextInput
                          label="Widget Name (ID)"
                          source={`${source}.name`}
                        />
                      </Grid>
                      <Grid style={{ gridTemplateColumns: "220px 1fr" }}>
                        <SelectInput
                          source={`${source}.title_markup`}
                          allowEmpty
                          defaultValue="default"
                          label="Title HTML tag"
                          resettable
                          choices={window.widgetTitlesMarkups}
                        />
                        <TextInput label="Title" source={`${source}.title`} />
                      </Grid>
                      <Grid columns="1">
                        <Field
                          name={`${source}.description`}
                          component={TextareaAutoComplete}
                          label="Description"
                          source={`${source}.description`}
                        />
                      </Grid>
                      <Grid columns="2">
                        <SelectInput
                          source={`${source}.type`}
                          label="Type"
                          choices={getWidgetChoices(
                            permissions.userIsAdminOrSuperAdmin,
                            permissions.acl,
                            record,
                            site,
                            baselayout
                          )}
                          required
                          resettable
                          validate={[required]}
                          onChange={handleTypeChange}
                          {...rest}
                        />
                      </Grid>
                      <Divider />
                      <WidgetTypeInput
                        formData={formData}
                        sourceData={sourceData}
                        source={source}
                        permissions={permissions}
                        type={recordType}
                        {...rest}
                      />
                    </TabContainer>
                  )}
                  {order === 1 && (
                    <TabContainer>
                      <Grid columns="2">
                        <Field
                          name={`${source}.more_links_label`}
                          component={TextareaAutoComplete}
                          label="widget.more_links_label"
                          source={`${source}.more_links_label`}
                        />
                        <Field
                          name={`${source}.url`}
                          component={TextareaAutoComplete}
                          label="widget.see_all_url"
                          source={`${source}.url`}
                        />
                      </Grid>
                      <BooleanInput
                        source={`${source}.target_blank`}
                        label="widget.target_blank"
                        defaultValue="false"
                        allowEmpty
                      />
                      <FieldArray
                        name={`${source}.more_links`}
                        component={WidgetMoreLinksInput}
                        label="widget.more_links"
                      />
                    </TabContainer>
                  )}
                  {order === 2 && (
                    <TabContainer>
                      <WidgetAdvancedSettings
                        type={recordType}
                        record={formData}
                        source={source}
                        sourceData={sourceData}
                        index={index}
                        {...rest}
                      />
                    </TabContainer>
                  )}
                  {order === 3 && (
                    <TabContainer>
                      <WidgetCommercialSettings
                        type={recordType}
                        record={formData}
                        source={source}
                        sourceData={sourceData}
                        {...rest}
                      />
                    </TabContainer>
                  )}
                </div>
              </ExpansionPanelDetails>
              <ExpansionPanelActions>
                {permissions.checkUserCanDeleteItem(record) ? (
                  <IconButton
                    variant="contained"
                    color="secondary"
                    icon={(iconClasses) => (
                      <MinusIcon className={iconClasses.leftIcon} />
                    )}
                    onClick={handleDelete}
                  >
                    Delete this widget
                  </IconButton>
                ) : null}
              </ExpansionPanelActions>
            </ExpansionPanel>
          );
        }}
      </FormDataConsumer>
    )
  );
}

function getWidgetChoices(isAdmin, acl = [], source, siteId, baselayout) {
  const widgetTypeList = window.widgetTypes;

  // if this is an create, we filter all the widgets type that the user has access to
  let widgetList = widgetTypeList.filter((x) => {
    if (isAdmin && isAdmin()) {
      return true;
    }

    let permissions = acl ? acl : [];

    let arrayExclude = [
      `widget_type.exclude.${x.id}.*`,
      `widget_type.exclude.${x.id}.create`,
      `widget_type.exclude.${x.id}.update`,
    ];

    if (
      permissions.some((permission) => arrayExclude.indexOf(permission) >= 0)
    ) {
      return false;
    }

    let arrayInclude = [
      "widget_type.*",
      `widget_type.${x.id}.*`,
      `widget_type.${x.id}.create`,
    ];

    return permissions.some(
      (permission) => arrayInclude.indexOf(permission) >= 0
    );
  });

  if (!siteId) {
    return widgetList;
  }

  // filters widget that are allowed for the current site (here we are using the site code, tguus, ttr etc.
  // See the sites_parameters.yml file)
  widgetList = widgetList.filter(
    (x) =>
      typeof x.allowed_sites === "undefined" ||
      x.allowed_sites.some((siteCode) => ["*", siteId].indexOf(siteCode) >= 0)
  );

  if (baselayout === "amp") {
    widgetList = widgetList.filter((x) => x.amp === true);
  }

  return widgetList;
}

const initiallyExpanded = (record) => {
  return !record.type;
};

export default withStyles(styles)(WidgetCard);
