import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {showNotification as showNotificationAction,} from 'react-admin';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '../components/IconButton';
import IconSend from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import "./CloneLocale.css";
import Cookies from "js-cookie";

const styles = theme => ({
    block: {
        display: "inline-block",
        verticalAlign: "middle",
        width: "100%",
        height:"100%",
        position: "absolute",
    },
    progress:{
        verticalAlign:"middle",
    }
});

const pStyle = {fontSize: 17, textAlign: "left"};

const SimpleTable = (props) => {
    return (
        <Table style={{minWidth: 750}} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>GUID</TableCell>
                    <TableCell align="right">Result</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.rows.map((row) => (
                    <TableRow key={row.guid}>
                        <TableCell>{row.guid}</TableCell>
                        <TableCell align="left">
                            <div>
                                {(200 !== row.status && null !== row.message) && <p style={{...pStyle, color: 'red'}}>{row.message}</p>}
                                {(200 === row.status && null !== row.message) && <p style={{...pStyle, color: 'green'}}>{row.message}</p>}

                                {(200 === row.status && null !== row.dataLink) &&
                                    <div style={{...pStyle, color: 'blue'}}>Data Link: <a href={row.dataLink} target="_blank" rel="noopener noreferrer">{row.dataLink}</a></div>
                                }

                                {(200 === row.status && null !== row.editLink) &&
                                    <div style={{...pStyle, color: 'blue', marginTop: 12}}>Edit Link: <a href={row.editLink} target="_blank" rel="noopener noreferrer">{row.editLink}</a></div>
                                }
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

class CopyToProdBulkActionButton extends Component {

    state = {
        message: null,
        showDialog: false,
        isLoading: false,
        showCopyButton: true,
        resultDataRows: [],
    };

    copyDocumentsToProd() {
        const { resource, showNotification } = this.props;
        this.setState({ isLoading: true, message: null, resultDataRows: []});

        let requestConfig = {
            headers: {
                Authorization: Cookies.get('token'),
                Accept: 'application/json',
                'X-Role': localStorage.getItem('role'),
                'X-ExpiresIn': localStorage.getItem('expiresIn'),
                'X-User-Name': localStorage.getItem('user'),
            },
            method: 'POST',
            body: JSON.stringify({list_of_ids: this.props.selectedIds}),
        };

        let url = `/api/copy-many-to-prod/${resource}`;

        fetch(url, requestConfig)
            .then((response) => {
                return response.json().then((data) => {
                    return  data;
                });
            }).then((data) => {
                if (409 === data.status) {
                    this.setState({ isLoading: false, message: null, resultDataRows: []});
                    return true;
                }
                if (200 === data.status) {
                    this.setState({isLoading: false, message: data.message, resultDataRows: data.resultDataRows, showCopyButton: false});
                    return true;
                }

                this.setState({ isLoading: false, message: null, resultDataRows: []});
                showNotification(`${data.message}`, "warning");
            }).catch((error) => {
                console.log('ERROR', error);
                showNotification(`${error}`, "warning");
            });
    }

    handleClick = () => {
        this.setState({ showDialog: true, showCopyButton: true, resultDataRows: []});
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false, resultDataRows: []});
    };

    showIdsList = () => {
        return (
            <div>
                <span>List of guids</span>:
                {this.props.selectedIds.map((item) => {
                    return (<span key={item}><b> {item}</b>; </span>)
                })}
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        const { isLoading, showDialog } = this.state;
        const title = "COPY DOCUMENTS LIST TO PROD";

        return (
            <Fragment>
                <IconButton
                    color="primary" icon={iconClasses => <IconSend className={iconClasses.leftIcon} />} onClick={() => this.handleClick()}>
                    Copy
                </IconButton>
                <Dialog fullWidth maxWidth={'md'} open={showDialog} onClose={this.handleCloseClick} aria-label={title}>
                    <DialogTitle style={{textAlign: 'center'}}>{title}</DialogTitle>
                    <DialogContent>
                        {isLoading && <div style={{marginTop: 10, textAlign: "center"}}><CircularProgress className={classes.progress} size={20} thickness={2} /></div>}

                        <div style={{textAlign: 'center'}}>{this.showIdsList()}</div>

                        <div style={{marginTop: 10, textAlign: "center"}}>
                            <IconButton color="primary" icon={iconClasses => <IconSend className={iconClasses.leftIcon}/>} onClick={() => this.copyDocumentsToProd()}>
                                Copy To Prod
                            </IconButton>

                            <IconButton color="secondary" icon={iconClasses => <IconCancel className={iconClasses.leftIcon} />} onClick={() => this.handleCloseClick()}>
                                Close
                            </IconButton>
                        </div>

                        {this.state.resultDataRows.length > 0 && <SimpleTable rows={this.state.resultDataRows}/>}
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    }
}

export default connect(null, { showNotification: showNotificationAction })(withStyles(styles)(CopyToProdBulkActionButton));
