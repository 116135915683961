import React, { Fragment } from 'react';
import {
    Labeled, TextField, SelectField, BooleanField, DateField, ArrayField, Datagrid
} from 'react-admin';
import PublishersField from './PublishersField';
import Grid from './Grid';
import IconButton from './IconButton';
import Dashboard from '@material-ui/icons/Dashboard';
// import ChipsField from './ChipsField';

const fcsisDynamicField = (field, record,index) => {
    switch (field.type) {
        case 'date':
            return (
                <DateField
                    key={index}
                    record={record}
                    source={field.id}
                    label={field.name}
                />
            );
        case 'boolean':
            return (
                <BooleanField
                    key={index}
                    record={record}
                    source={field.id}
                    label={field.name}
                />
            );
        case 'choice':
            return (
                <SelectField
                    key={index}
                    record={record}
                    source={field.id}
                    label={field.name}
                    choices={window[field.choice_values]}
                />
            );
        default:
            return (
                <Labeled key={index} label={field.name}>
                    <TextField record={record} source={field.id}/>
                </Labeled>
            );
    }
};

export default ({ type = '', template = '', sourceApi = '', record,origin }) => {
    switch (type) {
        case 'other':
            return (
                <Fragment>
                    <Grid columns={4}>
                        <Labeled label="widget.feed_url">
                            <TextField record={record} source="feed_url" />
                        </Labeled>
                        <Labeled label="widget.limit">
                            <TextField record={record} source="limit" />
                        </Labeled>
                        <Labeled label="widget.items_per_page">
                            <TextField record={record} source="items_per_page" />
                        </Labeled>
                        {['cross_site', 'more_like_this'].includes(template) === true && (
                        <Labeled label="widget.items_per_site">
                            <TextField record={record} source="items_per_site" />
                        </Labeled>
                    )}
                    </Grid>
                    { 
                        origin && origin === "timeline" &&
                        (<Grid columns={4}>
                            <Labeled label="Custom widget height">
                                <TextField
                                    record={record}
                                    source={`custom_height`}
                                    />
                            </Labeled>
                        </Grid>)
                        }
                </Fragment>
            );
        case 'fields_query':
            return (
                <Fragment>
                    {['cross_site', 'more_like_this'].includes(template) === false
                        && [
                            'custom_query',
                        ].includes(sourceApi)
                        && (
                            <Labeled label="Site">
                                <SelectField
                                    record={record}
                                    source="site"
                                    choices={window.sitesChoices}
                                />
                            </Labeled>
                        )
                    }
                    {(['cross_site', 'more_like_this'].includes(template) === true
                        || sourceApi === 'syndication_api_endpoints')
                        && (
                            <PublishersField
                                label="Publishers"
                                name="publishers"
                                source="publishers"
                                record={record}
                            />
                        )
                    }
                    {window.fcsisSearchFields.length > 0 && window.fcsisSearchFields.map((field, index) => (
                        fcsisDynamicField(field, record,index)
                    ))}
                    <Grid columns={4} style={{alignItems:"center",textAlign:"center"}}>
                        <Labeled label="widget.items_per_page">
                            <TextField record={record} source="items_per_page"></TextField>
                        </Labeled>
                        <Labeled label="Relative date">
                            <TextField record={record} source="relative_date" />
                        </Labeled>
                        <Labeled label="Standalone">
                            <BooleanField record={record} source="standalone" />
                        </Labeled>
                        <Labeled label="Pagination">
                            <BooleanField record={record} source="pagination" />
                        </Labeled>
                    </Grid>
                    <Labeled label="widget.show_publisher">
                        <BooleanField record={record} source="show_publisher" />
                    </Labeled>

                    <Labeled label="widget.show_author">
                        <BooleanField record={record} source="show_author" />
                    </Labeled>

                    <Labeled label="widget.show_date">
                        <BooleanField record={record} source="show_date" />
                    </Labeled>

                    <Labeled label="widget.add_extra_data">
                        <BooleanField record={record} source="add_extra_data" />
                    </Labeled>
                    <Labeled label="widget.show_filter">
                        <BooleanField record={record} source="show_filter" />
                    </Labeled>
                    <Labeled label="widget.date_format">
                        <TextField record={record} source="date_format" />
                    </Labeled>
                    <Labeled label="widget.time_zone">
                        <TextField record={record} source="time_zone" />
                    </Labeled>
                    { 
                        origin && origin === "timeline" &&
                        (<Grid columns={4}>
                            <Labeled label="Custom widget height">
                                <TextField
                                    record={record}
                                    source={`custom_height`}
                                    />
                            </Labeled>
                        </Grid>)
                    }
                    {['table_list'].includes(template) === true && (
                        <Grid columns={4}>
                            <ArrayField record={record} source="header_table">
                                <Datagrid>
                                    <TextField source="header"/>
                                </Datagrid>
                            </ArrayField>
                        </Grid>)
                    }
                    <Grid columns={4} style={{alignItems:"center",textAlign:"center"}}>
                        <Labeled label="widget.lazy">
                            <BooleanField record={record} source="lazy" />
                        </Labeled>
                        <Labeled label="widget.ttl">
                            <TextField record={record} source="ttl" />
                        </Labeled>
                    </Grid>
                    <Grid columns={3} style={{alignItems:"center",textAlign:"center"}}>
                        <Labeled label="widget.custom_query">
                            <TextField record={record} source="custom_query"></TextField>
                        </Labeled>
                        <Labeled label="widget.custom_filter_query">
                            <TextField record={record} source="custom_filter_query"></TextField>
                        </Labeled>
                        <Labeled label="widget.custom_sort">
                            <TextField record={record} source="custom_sort"></TextField>
                        </Labeled>
                    </Grid>
                </Fragment>
            );
        case 'page_context':
            return (
                <Fragment>
                    <Labeled label="PAGE CONTEXT DATA">
                        <SelectField
                            record={record}
                            source="page_context_data"
                            choices={window.pageContextChoices}
                        />
                    </Labeled>
                </Fragment>
            );
            case 'timeline_fallback':
        return (
            <Fragment>
                    <Grid columns={2} style={{textAlign:"center",alignItems:"center"}}>
                            <IconButton
                            icon={iconClasses => <Dashboard className={iconClasses.leftIcon} />}
                            disabled
                            color="secondary">{`Copy, fill spreadsheet & publish it on web`}
                            </IconButton>
                            <a target={"_blank"} rel="noopener noreferrer" href="https://timeline.knightlab.com/#make">Informations</a>
                        </Grid>
                        <Grid columns={3}>
                            <Labeled label="Google Id Spreadsheet">
                                <TextField record={record} source="id_spreadsheet"/>
                            </Labeled>
                        </Grid>
                        <Grid columns={3} style={{textAlign:"center",alignItems:"center"}}>
                            <Labeled label="Language">
                                <TextField record={record} source="language" />
                            </Labeled>
                            <Labeled label="Font">
                                <TextField record={record} source="font" />
                            </Labeled>
                        </Grid>
                        <Grid columns={4} style={{alignItems:"center",textAlign:"center"}}>
                            <Labeled label="Default start slide">
                                <TextField record={record} source="dflt_start_slide" />
                            </Labeled>
                            <Labeled label="Zoom level">
                                <TextField record={record} source="zoom_lvl"/>
                            </Labeled>
                            <Labeled label="Height (px)">
                                <TextField record={record} source="height"  />
                            </Labeled>
                            <Labeled label="fullwidth">
                                <BooleanField record={record} source="fullwidth" />
                            </Labeled>
                        </Grid>
            </Fragment>
        )
        default:
            return <div/>;
    }
};
