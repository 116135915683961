import React, { Component, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import Tooltip from "@material-ui/core/Tooltip";
import { fetch } from '../restClient';
import { ReactMUIDatatable } from "react-material-ui-datatable";
import Modale from './Modale';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    circularProgress: {
        margin: "40px auto",
        display: "block"
    },
    customWidth: {
        maxWidth: "1200px",
    }
})

const useStyles = makeStyles({
    color: {
        color: props => props.isFailedStatus ? '#F28B88' : "white",
    },
});

const RenderExtraLogs = ({ extra, val, key, classes }) => {

    return (
        <>
            {
                (Array.isArray(extra[val]) || typeof extra[val] === "string") &&
                <Typography
                    className={classes.color}
                >
                    {`${val} : ${extra[val]}`}
                </Typography>
            }
            {
                typeof extra[val] === 'object' &&
                !Array.isArray(extra[val]) &&
                extra[val] !== null &&
                Object.keys(extra[val]).map((keyName, k) =>
                    <Typography
                        key={`${key}-${k}`}
                        style={{ color: extra[val][keyName].indexOf("0") === -1 ? "yellow" : "white" }}
                    >{`${keyName} : ${extra[val][keyName]}`}</Typography>

                )
            }
        </>
    )
}

const RenderPurgeTitle = props => {
    const classes = useStyles(props);
    const { values } = props;
    return (
        <>
            {
                values.length > 0 && values.map((value, j) => {
                    const { id, type, status, message, keys, uris, extra, error } = value;
                    return (
                        <>
                            {id && <Typography style={{ color: "white" }}>id: <a target="_blank" rel="noopener noreferrer" href={`${window.cachePurgeServiceStatus}${id}`}>{id}</a></Typography>}
                            {type && <Typography style={{ color: "white" }}>type: {type}</Typography>}
                            {status && <Typography className={classes.color}>status: {status}</Typography>}
                            {message && <Typography className={classes.color}>message:{message}</Typography>}
                            {keys && keys.length > 0 &&
                                <Typography style={{ color: "white" }}>
                                    keys: {Array.isArray(keys) && keys.map((key, i) => <Typography style={{ color: "white" }} key={`purge-tooltip-key-${i}`}>{key}</Typography>)}
                                </Typography>}
                            {uris && uris.length > 0 &&
                                <Typography style={{ color: "white" }}>uris: {uris[0]['uri']}</Typography>}
                            {extra && Object.keys(extra).length > 0 &&
                                <Typography className={classes.color}>
                                    extra: {Object.keys(extra).map((val, i) => <RenderExtraLogs extra={extra} val={val} key={`purge-tooltip-key-extra-${j}-${i}`} classes={classes} />)}
                                </Typography>}
                            {error && <Typography className={classes.color} >error: {error['message']}</Typography>}
                            <br />
                        </>
                    )
                })
            }
        </>
    )
}
class ChangelogField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetch('/api/' + this.props.resource + '_log/' + this.props.guid)
            .then(response => response.json)
            .then((data) => {
                this.setState({
                    isLoading: false,
                    data: data,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { data, isLoading } = this.state;
        const { classes } = this.props;

        if (isLoading) {
            return <CircularProgress className={classes.circularProgress} size={40} thickness={2} />;
        }

        const customCell = ({ value, column, row }) => {
            let isFailedStatus = value && (value["error"] || value["status"] === "Failed");
            if (column.name === "modified_at") {
                return new Date(value * 1000).toUTCString();
            }
            if (column.name === "old") {
                return <a target={'_blank'} rel="noopener noreferrer" href={'/api/' + this.props.resource + '_archived/' + value}>{value}</a>;
            }
            if (column.name === "new") {
                return <a target={'_blank'} rel="noopener noreferrer" href={'/api/' + this.props.resource + '_archived/' + value}>{value}</a>;
            }
            if (column.name === "action") {
                return <Modale display_restore_button={row.version !== data.last_version} item={row} record={this.props.record} resource={this.props.resource} ></Modale>;
            }
            if (column.name === "purge_log") {
                return value ?
                    <Fragment>
                        <Tooltip
                            leaveDelay={5000}
                            classes={{ popper: classes.customWidth, tooltip: classes.customWidth }}
                            placement="top-start"
                            title={<RenderPurgeTitle values={value} isFailedStatus={isFailedStatus} />}
                        >
                            {isFailedStatus ? <CloseIcon style={{ "color": "red" }} /> : <Check />}
                        </Tooltip>
                    </Fragment>
                    :
                    null
            }

            return value;
        };

        const columns = [
            { label: "Version", name: "version" },
            { label: "Modified by", name: "modified_by" },
            { label: "Modified at", name: "modified_at" },
            { label: "Purge", name: "purge_log" },
            { label: "From", name: "old" },
            { label: "To", name: "new" },
            { label: "Action", name: "action" },
        ];

        return (
            <div>
                {data && data.changes && <ReactMUIDatatable
                    title={"CHANGELOG"}
                    data={data.changes}
                    columns={columns}
                    customCell={customCell}
                    perPage={10}
                    perPageOption={[10, 20, 50]}
                    selectable={false} />}
            </div>
        );
    }
}

export default withStyles(styles)(ChangelogField);

