import React from "react";
import { FormDataConsumer } from "react-admin";

import SliceWidgetInput from "../SliceWidgetInput";

const FieldsScreen = ({ source, permissions }) => {
    // Getting value from squared brackets since it is a string,
    // and cant get the value otherwise
    // Todo: check this if it is right;
    const currentWidget = source.match(/\[(.*?)\]/)[1];

    return (
        <>
            <h2>Slice fields setup</h2>
            <div style={{ marginBottom: 20 }}>
                This panel will allow you to set up your slices according to the json stated in the Slice project. If
                you need to create advanced settings for your slice, you might need to do it manually in the repository.
                Data can be requested on the <i>data tab</i> and mapped in here with the provided ID.
                <strong>Default values are there for a reason, if you change them do it under your own risk!</strong>
            </div>
            <FormDataConsumer>
                {({ formData, record }) => {
                    const template = formData ? formData.widgets[currentWidget].slice_component : null;
                    return (
                        <SliceWidgetInput
                            key={template}
                            template={template}
                            currentWidget={currentWidget}
                            source={source}
                            record={record}
                            permissions={permissions}
                        />
                    );
                }}
            </FormDataConsumer>
        </>
    );
};

export default FieldsScreen;
