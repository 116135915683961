import React, {Fragment} from "react";
import {
    TextInput, BooleanInput,SelectInput
} from 'react-admin';
import Grid from "../components/Grid";
import { withStyles } from '@material-ui/core/styles';
const styles = theme=> ({
    grid:{
        gridTemplateColumns: "repeat(3,200px)"
    },
    mAuto0:{
        margin:"10px 0"
    },
    lineSettings:{
        margin:"auto 0",
    },
    groupSettings: {
        width: '100%',
        backgroundColor:"#8080801f",
        borderColor:"#8080801f",
        borderStyle:'solid',
        borderWidth:1,
        padding:20,
        borderRadius:"3px",
        marginTop:10,

    },
    textLabel:{
        '& label[class*="MuiInputLabel"] span':{color:theme.color.grayScale.light},
    }
})
 const WidgetVideoSettings = ({classes,source,sourceData}) => {
    return (
    <Fragment>
        <SelectInput
            className={[classes.mAuto0,classes.textLabel].join(' ')}
            source={`${source}.provider`}
            label="Provider"
            resettable
            choices={window.videoProviders}
        />
        <TextInput
            className={[classes.mAuto0,classes.textLabel].join(' ')}
            source={`${source}.video_id`}
            label="widget.video_id"
        />
        <BooleanInput
            className={[classes.mAuto0,classes.textLabel].join(' ')}
            source={`${source}.is_playlist`}
            label="widget.is_playlist"
            defaultValue={true}
        />
        <Grid columns={2} style={{gridTemplateColumns:"30% 1fr"}} classes={classes.groupSettings}>
            <TextInput
                label="Player ID"
                source={`${source}.player_id`}
                defaultValue={'34Eru7MZ'}
                resettable
            />
            {
                sourceData && sourceData.player_id
                &&
                <SelectInput
                    className={classes.lineSettings}
                    source={`${source}.jwplayer_provider`}
                    choices={[{id:"future",name:"Future"}]}
                    label={"Jwplayer Provider"}
                    defaultValue={'future'}
                    resettable
                />
            }
        </Grid>
    </Fragment>
    )
};
export default withStyles(styles)(WidgetVideoSettings);