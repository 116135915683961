import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import {
  showNotification as showNotificationAction,
  SelectInput,
} from "react-admin";
import fetchRA from "../hooks/fetchRA";
import CircularProgress from "@material-ui/core/CircularProgress";
import Publish from "@material-ui/icons/Publish";
import IconButton from "./IconButton";
import { Typography } from "@material-ui/core";
import DialogConfirmationWrapper from "./DialogConfirmationWrapper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  rootFormStyle: {
    width: '35%',
  },
  rootHelper: {
    width:"max-content"
  },
  rootHelperNoMargin: {
    margin: 0,
  }
});

const PurgeConfirmationContent = ({
  record,
  methodPurge,
  onSetMethodPurge,
  isHardPurge,
  onSetIsHardPurge,
  cluster,
  setCluster
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography>
        {record.doc_type === "site"
          ? `This action will purge all ${record.site} site.`
          : record.doc_type === "custom_route"
            ? `This action will purge all pages using this custom route.`
            : record.doc_type === "page_layout"
              ? `This action will purge this specific template on each sites using it.`
              : ""}
      </Typography>
      <FormControl component="fieldset" classes={{ root: classes.rootFormStyle }}>
        <SelectInput
          label="Method purge"
          value={methodPurge}
          defaultValue={"xkeys-surrogate"}
          onChange={(e, val) => {
            onSetMethodPurge(val);
            if (val === "xkey") onSetIsHardPurge(false);
          }}
          source="method_purge"
          choices={[
            { id: "xkeys-surrogate", name: "By XKeys + Surrogate" },
            { id: "surrogate", name: "By Surrogate" },
            { id: "xkey", name: "By XKeys" },
            { id: "uri", name: "By URI (for site entity)" },
          ]}
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              disabled={!["xkeys-surrogate", "surrogate"].includes(methodPurge)}
              checked={isHardPurge}
              onChange={(e, val) => onSetIsHardPurge(val)}
              color="primary"
            />
          }
          label="Hard purge seriously?"
        >
        </FormControlLabel>
        <FormHelperText className={classNames(classes.rootHelper,classes.rootHelperNoMargin)}>Soft purge mark content as outdated while hard purge delete it from cache</FormHelperText>
      </FormControl>

      {["qa", "dev"].includes(window.environment) && <>
        <br />
        <FormControl variant="filled" classes={{ root: classes.rootFormStyle }}>
          <InputLabel id="purge-cache-cluster-label">Cluster (van-qa default)</InputLabel>
          <Select
            labelId="purge-cache-cluster-label"
            id="purge-cache-cluster"
            value={cluster}
            onChange={(e) => { setCluster(e.target.value) }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"qa"}>QA</MenuItem>
            <MenuItem value={"prod"}>PROD</MenuItem>
          </Select>
          <FormHelperText classes={{ root: classes.rootHelper }}>only available on dev / qa environment</FormHelperText>
        </FormControl>
      </>}
    </>
  )
};

const PublishButton = (props) => {
  const {
    record,
    resource,
    disabled,
    labeled = "Publish",
    withConfirmation = false,
    withPurgeCache,
    onPublishedSuccess,
    showNotification,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isHardPurge, setIsHardPurge] = useState(false);
  const [cluster, setCluster] = useState("");
  const [methodPurge, setMethodPurge] = useState(record.method_purge || "xkeys-surrogate");
  const fd = new FormData();

  fd.append("guid", record.id);
  fd.append("doc_type", record.doc_type);
  var requestConfig = {
    method: "post",
    resourceApi: "published",
    ...(withPurgeCache && {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "X-Should-Purge": true,
        "X-Purge-Uri": record.url || "",
        "X-Purge-Method": methodPurge || "",
        "X-Purge-Site": record.site || "",
        "X-Purge-Hard": isHardPurge,
        "X-Purge-Cluster": cluster,
      },
    }),
    body: fd,
  };

  const handlePublish = () => {
    setIsLoading(true);
    fetchRA(
      `/api/publishes/${record.id}/${resource}`,
      requestConfig
    ).then(() => {
      setIsLoading(false);
      onPublishedSuccess();
    });
  };

  return (
    <Fragment>
      {withConfirmation ? (
        <DialogConfirmationWrapper
          onConfirm={handlePublish}
          title={`Are you sure you want to publish with cache purge for ${record.id}`}
          content={
            <PurgeConfirmationContent
              record={record}
              isHardPurge={isHardPurge}
              methodPurge={methodPurge}
              onSetIsHardPurge={setIsHardPurge}
              onSetMethodPurge={setMethodPurge}
              cluster={cluster}
              setCluster={setCluster}
            />
          }
        >
          <IconButton
            color="primary"
            disabled={disabled}
            icon={(classes) =>
              isLoading ? (
                <CircularProgress
                  className={classes.leftIcon}
                  size={20}
                  thickness={2}
                />
              ) : (
                <Publish className={classes.leftIcon} />
              )
            }
          >
            {labeled}
          </IconButton>
        </DialogConfirmationWrapper>
      ) : (
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={() => handlePublish()}
          icon={(classes) =>
            isLoading ? (
              <CircularProgress
                className={classes.leftIcon}
                size={20}
                thickness={2}
              />
            ) : (
              <Publish className={classes.leftIcon} />
            )
          }
        >
          {labeled}
        </IconButton>
      )}
    </Fragment>
  );
};

export default connect(null, { showNotification: showNotificationAction })(
  PublishButton
);
