import Icon from '@material-ui/icons/Dashboard';
import List from './List';
import Edit from './Edit';
import Show from './Show';
import Create from './Create';

const name = 'pagelayouts';
const label = 'Page Layouts';

export {
    List,
    Edit,
    Show,
    Create,
    Icon,
    name,
    label
};
