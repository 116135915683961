import React from 'react'
import { SelectArrayInput } from 'react-admin';
import CardExpanded from '../CardExpanded'

const sortAscListId = (list) => list.sort((a,b)=> a.id > b.id ? 1 : -1)
const RegionsConfig = ({ source, ...props }) => {
    return (
        <CardExpanded columns={1} {...props}>
            <SelectArrayInput label={`Languages`} source={`${source}.languages`} choices={sortAscListId(window.languagesCMS)} />
            <SelectArrayInput label={`Selected Regions`} source={`${source}.selected_regions`} choices={sortAscListId(window.regionsCMS)} />
            <SelectArrayInput label={`Regions`} source={`${source}.regions`} choices={sortAscListId(window.regionsCMS)} />
        </CardExpanded>
    )
}

export default RegionsConfig
