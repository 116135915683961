import React from 'react';
import {
    UrlField, TextField, ImageField, RichTextField,
} from 'react-admin';
import LinkListField from './LinkListField';

export default (props) => {
    const elProps = {
        ...props,
        inputFields: [
            <TextField source="subtitle" label="subtitle" />,
            <UrlField source="url" label="URL" />,
            <TextField source="position" label="Position" />,
            <RichTextField source="description" label="Description" />,
            <ImageField source="icon[0].src" label="Icon" />,
        ],
    };

    return <LinkListField {...elProps} />;
};
