import React, { Component, Fragment } from 'react';
import {
    TextInput, Labeled,ArrayInput, SimpleFormIterator,NumberInput
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '../IconButton';
import LinkListCard from './Card';
import { negativeNumber, number, minValue0 } from '../../tools';

class SearchInputs extends Component {

    shouldComponentUpdate(nextProps,nextState) {
        const { fields } = this.props;
        return fields.length !== nextProps.fields.length 
    }

    render() {
        const { record, source, label, ...rest } = this.props;
        const { fields, addLabel } = rest;
        return (
            <Fragment>
            <Labeled label={label} />
                <div style={{ marginBottom: '8px' }}>
                    {fields.length > 0
                    && fields.map((itemSource, index) =>{
                        return (
                        <div key={`search-list-${itemSource}`}>
                            <LinkListCard
                                key={`search-list-${itemSource}`}
                                index={index}
                                source={itemSource}
                                {...rest}
                            />
                        </div>
                        )
                    })}
                </div>
                <IconButton
                    color="primary"
                    icon={classes => <AddIcon className={classes.leftIcon} />}
                    onClick={() => fields.push({})}
                >
                    {addLabel}
                </IconButton>
            </Fragment>
        );
    }
}

SearchInputs.defaultProps = {
    label: 'Search list',
    getTitle: item => item.id,
    getUrl: item => (item.url ? `Position: ${item.url}` : ''),
    addLabel: 'Add search',
    removeLabel: 'Remove search',
    initiallyExpanded:(item)=> !item.id,
    inputFields: [
                <TextInput source="id" label={"Label Id"} style={{display:"block"}} />,
                <ArrayInput source="items" label={"Parameters"}>
                    <SimpleFormIterator>
                        <TextInput source="label" label={"label"} style={{display:"inline-flex"}} />
                        <TextInput source="value" label={"value"} style={{display:"inline-flex"}} />
                        <NumberInput
                        source="position"
                        label={"Position"}
                        validate={[number, minValue0]}
                        warn={negativeNumber} 
                        />
                    </SimpleFormIterator>
                </ArrayInput>
    ],
};

export default SearchInputs;
