import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';

const CustomRouteEdit = props => (
    <Edit undoable={false} title="Custom Route" {...props}>
        <EditForm permissions={props.permissions} />
    </Edit>
);

export default CustomRouteEdit;
