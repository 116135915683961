import React, { Fragment, PureComponent } from 'react'
import {
 TextInput, BooleanInput, Labeled

} from 'react-admin';
import Grid from '../Grid';
import { negativeNumber,required } from "../../tools";
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    lineSettings:{
        margin:"auto 0",
    },
    groupSettings: {
        width: '100%',
        backgroundColor:"#8080801f",
        borderColor:"#8080801f",
        borderStyle:'solid',
        borderWidth:1,
        padding:20,
        borderRadius:"3px",
        marginTop:10,

    },
    relatedTemplateLabeled:{
        fontWeight:"bold"
    }
});

class WidgetAdvancedSettings extends PureComponent {

    constructor(props) {
        super(props);
        const { record } = this.props;
        this.state = {
            value: 0,
            type: (record && record.type) || '',
            template: (record && record.template) || '',
        };
    }

    render() {
        const { classes, source, sourceData } = this.props;

        return (
        <Fragment>
            <Grid classes={classes.groupSettings}>
                <Labeled style={{fontSize:20}} className={classes.relatedTemplateLabeled} label={`Commercial Header`} />
                <Grid columns={4}>
                    <TextInput
                    className={classes.lineSettings}
                    source={`${source}.commercial_label`}
                    //choices={[{id:"sponsored",name:"Sponsored By"},{id:"powered",name:"Powered By"}]}
                    label={"Commercial Label"}
                    resettable
                    />
                    {sourceData && sourceData.commercial_label &&
                    <Fragment>
                        <TextInput
                        className={classes.lineSettings}
                        source={`${source}.commercial_class_logo`}
                        label="widget.commercial_class_logo"
                        resettable
                        />
                        <TextInput
                        className={classes.lineSettings}
                        source={`${source}.commercial_url_logo`}
                        label="widget.commercial_url_logo"
                        resettable
                        />
                        <TextInput
                        className={classes.lineSettings}
                        source={`${source}.commercial_link_logo`}
                        label="widget.commercial_link_logo"
                        resettable
                        />
                    </Fragment>
                    }
                </Grid>
            </Grid>
            <Grid columns={3} style={{alignItems:"left",textAlign:"left"}}>
                <Grid columns={2} classes={classes.groupSettings}>
                    <BooleanInput
                        className={classes.lineSettings}
                        source={`${source}.sponsored_item`}
                        label="widget.sponsored_item"
                    />
                    {sourceData && sourceData.sponsored_item &&
                        <TextInput
                            className={classes.lineSettings}
                            source={`${source}.sponsored_item_position`}
                            label="widget.sponsored_item_position"
                            validate={[required]}
                            warn={[required,negativeNumber]}
                        />
                    }
                </Grid>
            </Grid>
        </Fragment>
        )
    }
}

export default withStyles(styles)(WidgetAdvancedSettings);
