import React from 'react';
import { SelectInput, FormDataConsumer } from 'react-admin';

const ReferenceWidgets = ({ label, ...rest }) => (
    <FormDataConsumer>
        {({ formData }) =>
            <SelectInput
                label={label}
                choices={getWidgets(formData.widgets)}
                allowEmpty
                {...rest}
            />
        }
    </FormDataConsumer>
);

function getWidgets(widgets) {
    return (
        widgets
        && widgets
            .filter(widget => widget.slug)
            .map(widget => ({ id: widget.slug, name: widget.name }))
    ) || [];
}

export default ReferenceWidgets;
