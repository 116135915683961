import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';

const CustomWidgetsEdit = props => (
    <Edit undoable={false} title="Custom Widget" {...props}>
        <EditForm permissions={props.permissions} disabledOverrideField />
    </Edit>
);

export default CustomWidgetsEdit;
