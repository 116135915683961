import React from "react";
import TextField from '@material-ui/core/TextField';
export function TextFilterInput({onFilterChanged}) {
  return (
    <TextField
      style={{ marginRight: "10px" }}
      label={"Search Config"}
      onChange={(e) => {
        onFilterChanged( e.target.value ? e.target.value.toLowerCase() : "");
      }}
    />
  );
}
