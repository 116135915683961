import React, { Component, Fragment } from "react";
import { CardActions, Datagrid, ListButton, TextField } from "react-admin";
import { Select, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ListFilter from "./ListFilter";
import List from "../List";
import { connect } from "react-redux";
import { isCodeFreeze } from "../Settings/settingsUI";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "../../components/IconButton";
import SyncIcon from "@material-ui/icons/Sync";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import fetchRA from "../../hooks/fetchRA";

const styles = (theme) => ({
    progress: {
        verticalAlign: "middle",
    },
});

const pStyle = { fontSize: 17, textAlign: "center" };

const AbtestingUrlActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
}) => (
    <CardActions>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
            })}
        <ListButton basePath="/abtesting" label="Back to experiments" />
    </CardActions>
);

class AbtestingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            error: null,
            showDialog: false,
            isLoading: false,
            currentRecord: {},
            selectedAbTest: "",
            disableConfirmButton: false,
            abTests: [],
        };
    }

    ChangeButton = ({ record, handleClick, disabled }) => {
        return (
            <IconButton
                disabled={disabled}
                color="primary"
                icon={(iconClasses) => <SyncIcon className={iconClasses.leftIcon} />}
                onClick={() => handleClick(record)}
            >
                Change
            </IconButton>
        );
    };

    openChangeDialog = (record) => {
        const { resource } = this.props;

        this.setState({
            isLoading: true,
            showDialog: true,
            error: null,
            message: null,
            currentRecord: record,
            selectedAbTest: record.guid,
            disableConfirmButton: true,
        });
        fetchRA(
            "/api/abtesting?site=" + record.site + "&status=Published&activated=true",
            {
                method: "get",
                resourceApi: resource,
            }
        ).then((res) => {
            this.setState({ isLoading: false });
            if (res && res.status === 200) {
                const abTests = res.json !== undefined ? res.json.map((v) => ({ guid: v.guid, name: v.name })) : [];
                this.setState({ abTests });
            }
        });
    };

    handleCloseClick = () => {
        this.setState({
            showDialog: false,
            error: null,
            message: null,
            currentRecord: {},
            selectedAbTest: "",
            abTests: [],
        });
    };

    handleChangeAbTest = (event) => {
        const { currentRecord } = this.state;

        if (currentRecord.guid === event.target.value) {
            this.setState({ disableConfirmButton: true });
        } else {
            this.setState({ disableConfirmButton: false });
            this.setState({ selectedAbTest: event.target.value });
        }
    };

    saveChange() {
        const { resource } = this.props;
        const { currentRecord, selectedAbTest } = this.state;

        this.setState({ isLoading: true });
        fetchRA(
            `/api/${resource}/${encodeURIComponent(currentRecord.url)}`,
            {
                method: "put",
                body: JSON.stringify({
                    guidFrom: currentRecord.guid,
                    guidTo: selectedAbTest,
                }),
                resource,
            }
        ).then((res) => {
            this.setState({ isLoading: false });
            if (res && res.status === 200) {
                if (res.json.error && res.json.error === "unable_to_move_url") {
                    this.setState({
                        error: "Unable to move the URL because the target conditional experiment query does not match it",
                        message: "",
                    });
                } else {
                    this.setState({ message: "URL has been moved successfully", error: "" });
                    return setTimeout(window.location.reload(), 500);
                }
            } else {
                this.setState({ error: "An error occured while trying to move the URL", message: "" });
            }
        });
    }

    render() {
        const {
            resource,
            permissions,
            codeFreeze: { status },
            classes,
            ...rest
        } = this.props;
        const { showDialog, isLoading, selectedAbTest, abTests, error, disableConfirmButton, currentRecord } =
            this.state;
        const title = "Change experiment of URL '" + currentRecord.url + "'";
        const ChangeButton = this.ChangeButton;

        return (
            <Fragment>
                <List
                    perPage={100}
                    sort={{ field: "last_modified", order: "DESC" }}
                    resource={resource}
                    permissions={permissions}
                    filters={<ListFilter />}
                    actions={<AbtestingUrlActions permissions={permissions} />}
                    noBulkActionButtons={true}
                    {...rest}
                >
                    <Datagrid>
                        <TextField source="url" />
                        <TextField source="site" />
                        <TextField source="name" label="AB test" />
                        <TextField source="type" label="AB test type" />
                        {permissions && permissions.checkUserCanCRUD(resource, "update") ? (
                            <ChangeButton
                                disabled={status && !permissions.checkUserIsSuperAdmin(resource)}
                                handleClick={this.openChangeDialog}
                            />
                        ) : null}
                    </Datagrid>
                </List>
                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label={title}>
                    <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
                    <DialogContent>
                        {isLoading && (
                            <div style={{ marginTop: 10, textAlign: "center" }}>
                                <CircularProgress className={classes.progress} size={20} thickness={2} />
                            </div>
                        )}

                        {null !== error && <p style={{ ...pStyle, color: "red" }}>{error}</p>}
                        {null !== this.state.message && (
                            <p style={{ ...pStyle, color: "green" }}>{this.state.message}</p>
                        )}

                        <div style={{ marginTop: 10, textAlign: "center" }}>
                            <div>
                                <Select id="selected-abtest" value={selectedAbTest} onChange={this.handleChangeAbTest}>
                                    {abTests.map(({ guid, name }) => (
                                        <MenuItem key={guid} value={guid}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div style={{ marginTop: 15 }}>
                                <IconButton
                                    disabled={disableConfirmButton}
                                    color="primary"
                                    icon={(iconClasses) => <SyncIcon className={iconClasses.leftIcon} />}
                                    onClick={() => this.saveChange()}
                                >
                                    Confirm
                                </IconButton>

                                <IconButton
                                    color="secondary"
                                    icon={(iconClasses) => <IconCancel className={iconClasses.leftIcon} />}
                                    onClick={() => this.handleCloseClick()}
                                >
                                    Close
                                </IconButton>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    codeFreeze: isCodeFreeze(state.settingsui),
});

export default connect(mapStateToProps)(withStyles(styles)(AbtestingList));
