import React from 'react';
import {TextInput, Filter, SelectArrayInput} from 'react-admin';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../CustomRoutes/ListFilter";

const ListFilter = props => (
    <Filter className={props.classes.filter} {...props}>
        <TextInput alwaysOn source="url" resettable label="Search in URL"/>
        <TextInput alwaysOn source="name" resettable label="Search in AB test"/>
        <SelectArrayInput
            fullWidth={true}
            alwaysOn
            source="site"
            label="Search in site"
            choices={window.sitesChoices}
            parse={(val)=> val ? val.join("||") : ""}
            format={(val)=> val ? val.split("||") : []}
        />
    </Filter>
);

export default withStyles(styles)(ListFilter);
