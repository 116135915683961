import React, { Component, Fragment } from 'react';
import {
    TextInput, Labeled
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '../IconButton';
import LinkListCard from './Card';

class VanillaNavigationInputs extends Component {
    shouldComponentUpdate(nextProps,nextState) {
        const { fields } = this.props;
        return fields.length !== nextProps.fields.length 
    }

    render() {
        const { record, source, label, ...rest } = this.props;
        const { fields, addLabel } = rest;
        return (
            <Fragment>
            <Labeled label={label} />
                <div style={{ marginBottom: '8px' }}>
                    {fields.length > 0
                    && fields.map((itemSource, index) =>{
                        return (
                        <div key={`vanilla-list-${itemSource}`}>
                            <LinkListCard
                                key={`vanilla-list-${itemSource}`}
                                index={index}
                                source={itemSource}
                                {...rest}
                            />
                        </div>
                        )
                    })}
                </div>
                <IconButton
                    color="primary"
                    icon={classes => <AddIcon className={classes.leftIcon} />}
                    onClick={() => fields.push({})}
                >
                    {addLabel}
                </IconButton>
            </Fragment>
        );
    }
}

VanillaNavigationInputs.defaultProps = {
    getTitle: item => item.key,
    addLabel: 'Add Item',
    removeLabel: 'Remove Item',
    initiallyExpanded:(item)=> !item.value,
    inputFields: [
                <TextInput source="key" label={"Key"} style={{display:"block"}} />,
                <TextInput source="value" label={"Value"} style={{display:"block"}} />,
    ],
};

export default VanillaNavigationInputs;
