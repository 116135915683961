import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import { fillArrayFieldIfMissingRefs } from '../tools';
import { TextInput, SelectInput, LongTextInput } from 'react-admin';
import { Field } from 'redux-form';
import TextareaAutoComplete from "./TextareaAutoComplete";
import SingleSelectWithCustom from './SingleSelectWithCustom';
import CopyButton from './CopyButton';

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    buttons: {
        alignSelf: 'center',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: `450px 1fr 70px ${theme.spacing.unit * 8}px 30px`,
        columnGap: theme.spacing.unit,
        width: '100%',
        alignItems:'center'
    }
});

const FullLabelField = ({ record }) => <span>{record.name} ({record.id})</span>;

class CommonDynamicFieldsComponents extends Component {
    componentWillMount() {
        const { fields, defaultValues, formData } = this.props;
        fillArrayFieldIfMissingRefs(fields, defaultValues, formData); //flex-585 apply default values
    }
    render() {
        const {
            fields,
            classes,
            additionnalFields,
            record,
            warnValidator = [],
            errorValidator = [],
            style = {},
            fscis_fields,
            label,
            formData,
            singleSelectCustom = false,
            suggest,
            longTextValue,
            optionsSingleSelectCustom = [],
            onChangeSelect,
            canCopy=false
         } = this.props;
        return (
            <div style={{ paddingTop: 15 }}>
                <label><b>{label}</b></label>
                <Paper className={classes.paper}>
                    {fields.length > 0 &&
                        fields.map((itemSource, index) =>(
                            <div key={`routing-params-${index}`}>
                                <Typography component="div">
                                    <div className={classes.grid}>
                                        {fscis_fields &&
                                           <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                                            {canCopy && <CopyButton copyValue={fields.get(index).key} customStyles={{marginTop: "30px"}}/>}
                                             <SelectInput
                                                label={`Item #${index + 1} key name`}
                                                source={`${itemSource}.key`}
                                                choices={window.fcsisSearchFields}
                                                optionText={<FullLabelField />}
                                            />
                                           </div>
                                        }
                                        {singleSelectCustom && !fscis_fields &&
                                            <Field
                                                label={`placeholder #${index + 1} key name`}
                                                name={`${itemSource}.key`}
                                                component={SingleSelectWithCustom}
                                                isClearable={true}
                                                options={optionsSingleSelectCustom}
                                                onChangeSelect={onChangeSelect}
                                                data={formData}
                                                currentValue={formData && formData[itemSource] && formData[itemSource]['key'] ? formData[itemSource]['key'] : undefined}
                                                canCopy={canCopy}
                                            />
                                        }
                                        {!fscis_fields && !singleSelectCustom &&
                                            <TextInput source={`${itemSource}.key`} label={`Item #${index + 1} key name`} warn={warnValidator} validate={errorValidator} />
                                        }
                                        {longTextValue && !suggest &&
                                            <LongTextInput source={`${itemSource}.value`} label={`Item #${index + 1} value`} warn={warnValidator} validate={errorValidator} />// issue on placeholder let check with warning props
                                        }
                                        {!longTextValue && !suggest &&
                                            <TextInput classes={classes.textInputValue} multiline source={`${itemSource}.value`} label={`Item #${index + 1} value`} warn={warnValidator} validate={errorValidator}/>// issue on placeholder let check with warning props
                                        }
                                        {suggest &&
                                            <Field
                                                name={`${itemSource}.value`}
                                                component={TextareaAutoComplete}
                                                label={`Item #${index + 1} value`}
                                                source={`${itemSource}.value`}
                                                style={style.textAreaAutoComplete}
                                            />
                                        }
                                        {additionnalFields &&
                                            additionnalFields.length > 0 &&
                                            additionnalFields.map((component, idx) => {
                                                return React.cloneElement(component, {
                                                    source: component.props.source ? `${itemSource}.${component.props.source}` : `${itemSource}.param${idx}`,
                                                    key: idx,
                                                    label: component.props.label ? `Item #${index + 1} ${component.props.label} ` : `Item#${index + 1} Param${idx}`,
                                                    record: record
                                                }) //cleanContentMiscForFront method in API use "param" id for regexp
                                            })}
                                        <Tooltip title="Remove this item">
                                            <IconButton className={classes.buttons} onClick={() => fields.remove(index)}>
                                                <MinusIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {index === (fields.length - 1) &&
                                            <Tooltip title="Add a new item">
                                                <IconButton className={classes.buttons} onClick={() => fields.push({})}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </Typography>
                            </div>
                        ))
                    }

                    {fields.length === 0 &&
                        <Button size="small" onClick={() => fields.push({})}>
                            <AddIcon /> Add new Item
                        </Button>
                    }
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(CommonDynamicFieldsComponents);