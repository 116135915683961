import React, { useEffect, useState } from "react";

import { Button } from "react-admin";
import { Dialog, DialogContent, Tooltip } from "@material-ui/core";
import { theme } from "../Admin";

export const Modal = ({
    title,
    children,
    showDialog,
    handleCloseClick,
    handleCloseClickCancel,
    classes = null,
    handleDisableApply,
    dialogContentClasses = {},
    record,
    hideApplyButton,
    ...rest
}) => {
    return (
        <Dialog
            fullWidth
            open={showDialog}
            onClose={() => handleCloseClickCancel()}
            aria-label="Create widget"
            maxWidth="lg"
            scroll="paper"
            classes={classes}
            {...rest}
        >
            <h2
                style={{
                    flex: "0 0 auto",
                    margin: 0,
                    padding: "16px 25px",
                    fontWeight: 500,
                    fontSize: "1.5em",
                    color: "white",
                    background: theme.palette.secondary.main,
                    fontFamily: theme.typography.fontFamily,
                }}
            >
                {title}
            </h2>
            <DialogContent
                style={{
                    minHeight: 500,
                    overflowX: "hidden",
                    background: "#fafafa",
                    padding: 0,
                }}
                classes={{root:dialogContentClasses}}
            >
                {children}
            </DialogContent>

            <div
                style={{
                    display: "flex",
                    padding: "15px 30px",
                    justifyContent: "flex-end",
                    border: "1px solid #cfc7d2",
                    background: "#f2f2f2",
                }}
            >
                <Buttons {...{ handleCloseClickCancel, handleCloseClick, handleDisableApply, hideApplyButton }} />
            </div>
        </Dialog>
    );
};

const Buttons = ({ handleCloseClickCancel, handleCloseClick, handleDisableApply, hideApplyButton = false }) => {
    const [disableApply, setDisableApply] = useState("Please fill the required parameters");

    useEffect(() => {
        if (handleDisableApply) handleDisableApply(setDisableApply);
    });

    const WrappingComponent = disableApply ? Tooltip : React.Fragment;
    const propsWrappingComponent = disableApply ? { title: disableApply || "", placement: "top" } : {};
    return (
        <>
            <Button
                style={{ padding: "0.7em 1.3em 0.7em 1em", marginRight: 15 }}
                variant="outlined"
                color="secondary"
                label="Cancel"
                onClick={() => handleCloseClickCancel()}
            />
            {!hideApplyButton && 
                <WrappingComponent {...propsWrappingComponent}>
                    <span>
                        <Button
                            style={{ padding: "0.7em 1.3em 0.7em 1em" }}
                            variant="contained"
                            color="secondary"
                            label="Apply"
                            disabled={Boolean(disableApply)}
                            onClick={() => handleCloseClick()}
                        />
                    </span>
                </WrappingComponent>
            }
        </>
    );
};
