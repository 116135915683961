import React, { Fragment, useState } from 'react'
import { Field } from 'redux-form';
import {
  TextInput,
  NumberInput,
  BooleanInput,
  Labeled,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';
import Grid from '../Grid';
import {negativeNumber, isListNumberWithCommaSeparator, number, minValue0 } from "../../tools";
import InputFile from '../InputFile';
import ReferenceWidgets from '../ReferenceWidgets';
import { withStyles } from '@material-ui/core/styles';
import WidgetFeatureFlag from "../LinkListInput/WidgetFeatureFlag";
import WidgetListingV2Options from './WidgetListingV2Options';
import SingleSelectWithCustom from "../../components/SingleSelectWithCustom";
import ImageExample from '../ImageExample'
import defaultTabStylePreview from '../../../images/widgets_examples/dynamic_multiquery_tab_style_default.png';
import chipsTabStylePreview from '../../../images/widgets_examples/dynamic_multiquery_tab_style_chips.png';

const styles = () => ({
    lineSettings:{
        margin:"auto 0",
    },
    groupSettings: {
        width: '100%',
        backgroundColor:"#8080801f",
        borderColor:"#8080801f",
        borderStyle:'solid',
        borderWidth:1,
        padding:20,
        borderRadius:"3px",
        marginTop:10,

    },
    relatedTemplateLabeled:{
        fontWeight:"bold"
    }
});
const customStyleSingleSelect = {
    control: () => ({ minWidth: 250 }),
    menu: () => ({ height: 500, width: 'inherit', maxWidth: 900, overflow: 'scroll' }),
}

const customStyleGaTrackingID = {
    control: () => ({ minWidth: 156 }),
    menu: () => ({ height: 'auto', width: 'inherit', maxWidth: 900, overflow: 'scroll', marginBottom: '10px' }),
}

const tabStylesOptionsPreviews = {
    "default-tab-style": defaultTabStylePreview,
    "chips-tab-style": chipsTabStylePreview,
};

const WidgetAdvancedSettings = ({ classes, source, sourceData, record, dispatch,index }) => {
    const multiQueryTabDisplayStyle = sourceData.multiquery_tab_display_style ? sourceData.multiquery_tab_display_style : null;
    const [selectedMultiQueryTabStyleOption, setSelectedMultiQueryTabStyleOption] = useState(multiQueryTabDisplayStyle);

    return (
        <Fragment>
            <Grid columns={3}>
            <BooleanInput
                className={classes.lineSettings}
                source={`${source}.target_blank_default`}
                label="widget.target_blank_default"
                allowEmpty
            />
            <BooleanInput
                className={classes.lineSettings}
                source={`${source}.full_width`}
                label="widget.full_width"
                />
            <TextInput
                className={classes.lineSettings}
                source={`${source}.css_class`}
                label="widget.css_class"
            />
        </Grid>
            <Grid columns={3} style={{alignItems:"left",textAlign:"left"}}>
            <BooleanInput
                className={classes.lineSettings}
                source={`${source}.lightbox`}
                label="widget.lightbox"
            />
            <BooleanInput
                className={classes.lineSettings}
                source={`${source}.pinned_item`}
                label="widget.pinned_item"
            />
            <BooleanInput
                className={classes.lineSettings}
                source={`${source}.add_extra_data`}
                label="widget.add_extra_data"
            />
            </Grid>
            <Grid columns={3} style={{alignItems:"left",textAlign:"left"}}>
            <Grid columns={2} classes={classes.groupSettings}>
                <BooleanInput
                    className={classes.lineSettings}
                    source={`${source}.lightbox_toggler`}
                    label="widget.lightbox_toggler"
                />
                {sourceData && sourceData.lightbox_toggler &&
                    <ReferenceWidgets
                        className={classes.lineSettings}
                        source={`${source}.lightbox_target_slug`}
                        label="widget.lightbox_target_slug"
                    />
                }
            </Grid>
        <BooleanInput
            className={classes.lineSettings}
            source={`${source}.active_sse`}
            label="Active SSE"
        />
        <BooleanInput
            className={classes.lineSettings}
            source={`${source}.essential`}
            label="Essential"
        />
        <Grid columns={1} classes={classes.groupSettings}>
            <WidgetFeatureFlag
                label={"Only render if featureFlag is enabled"}
                name={`${source}.widgetFeatureFlag`}
                source={`${source}.widgetFeatureFlag`}
                customStyle={customStyleSingleSelect}
                sourceData={sourceData}
                data={getFeatureFlagChoices()}
            />
        </Grid>
        <NumberInput
            source={`${source}.toggle_more_at`}
            label="Toggle More/Less At"
            helperText={"Minimum value(s) showed"}
            validate={[number, minValue0]}
            warn={negativeNumber}
        />
        <BooleanInput
            className={classes.lineSettings}
            source={`${source}.no_render`}
            label="widget.no_render"
        />
        <Grid classes={classes.groupSettings}>
        <BooleanInput
            className={classes.lineSettings}
            source={`${source}.ga_tracked`}
            label="widget.ga_tracked"
        />
        {sourceData && sourceData.ga_tracked &&
        <>
            <Field
                label={"GA Tracking ID"}
                name={`${source}.ga_tracking_id`}
                component={SingleSelectWithCustom}
                options={window.gaTrackingIds.map(option => option.id)}
                customStyle={customStyleGaTrackingID}
                resettable
                allowEmpty
                isClearable={true}
            /> 
        </>
        }
        </Grid>
        <BooleanInput
            className={classes.lineSettings}
            source={`${source}.disable_carousel_exclusion`}
            label="widget.disable_carousel_exclusion"
            defaultValue={false}
        />
        </Grid>
            <Fragment>
                <Grid classes={classes.groupSettings}>
                    <Labeled style={{fontSize:20}} className={classes.relatedTemplateLabeled} label={`Related to type ${sourceData.type ? sourceData.type : ""}`} />
                        
                        {["slice"].includes(sourceData.type) && (
                            <BooleanInput
                                className={classes.lineSettings}
                                source={`${source}.standalone`}
                                label={"Standalone"}
                                defaultValue={false}
                            />
                        )}
                    
                    {
                        ['dynamic','forumtopics'].includes(sourceData.type)
                        &&
                        (
                            <Fragment>
                                <Labeled label="Internal functionality settings" />
                                <Grid columns="4">
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.standalone`}
                                        label={"Standalone"}
                                        defaultValue={false}
                                    />
                                    {sourceData && sourceData.facet &&
                                        <BooleanInput
                                            className={classes.lineSettings}
                                            source={`${source}.drilldown_anchor`}
                                            label={"Anchor on Drilldown"}
                                            defaultValue={false}
                                        />
                                    }
                                </Grid>

                                <Labeled label="General Display settings" />
                                <Grid columns={4}>
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.hide_title`}
                                        label="Hide Title"
                                    />
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.hide_subtitle`}
                                        label="Hide Strapline"
                                    />
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.hide_image`}
                                        label="Hide Image"
                                    />
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.contain_hero`}
                                        label="Contains Hero"
                                    />
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.show_filter`}
                                        label="Show filter"
                                    />
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.collapse`}
                                        label={"Collapse"}
                                    />
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.sort_options`}
                                        label={"Sort options"}
                                    />
                                    <BooleanInput
                                        source={`${source}.is_content`}
                                        label={"Use as content?"}
                                    />
                                    <BooleanInput
                                        source={`${source}.is_video`}
                                        label={"Is video"}
                                    />
                                    <SelectInput
                                        className={classes.lineSettings}
                                        source={`${source}.article_label_with`}
                                        helperText={"Default Dek/Label if empty"}
                                        choices={[
                                            {id:"source",name:"Article Source"},
                                            {id:"category",name:"Article Category"},
                                            {id:"vertical",name:"Article Vertical"},
                                            {id:"tag",name:"Article Tag"}
                                        ]}
                                        label={"Article Label"}
                                        resettable
                                    />
                                </Grid>
                                {sourceData && (sourceData.more_query_params || sourceData.loop_query_params) &&
                                    <Grid columns={3} classes={classes.groupSettings}>
                                        <BooleanInput
                                            className={classes.lineSettings}
                                            source={`${source}.multiquery_tab_display`}
                                            label={"Multi Query Tab Display"}
                                            defaultValue={false}
                                        />
                                        { sourceData.multiquery_tab_display &&
                                            <>
                                                <SelectInput
                                                    className={classes.lineSettings}
                                                    source={`${source}.multiquery_tab_display_style`}
                                                    helperText={"Default style if empty"}
                                                    choices={[
                                                        { id:"default-tab-style", name:"Default style" },
                                                        { id:"chips-tab-style", name:"Chips style" },
                                                    ]}
                                                    label="Multi query tab style"
                                                    emptyValue="default-tab-style"
                                                    resettable
                                                    onChange={(target, value) => setSelectedMultiQueryTabStyleOption(value)}
                                                />
                                                <ImageExample
                                                    src={selectedMultiQueryTabStyleOption ? 
                                                        tabStylesOptionsPreviews[selectedMultiQueryTabStyleOption] : defaultTabStylePreview}
                                                    alt={selectedMultiQueryTabStyleOption || "default-tab-style"}
                                                    className={classes.lineSettings}
                                                    classes={classes}
                                                    width="580px"
                                                />
                                            </>
                                        } 
                                    </Grid>
                                }
                                    <Grid columns={3} classes={classes.groupSettings}>
                                        <BooleanInput
                                            className={classes.lineSettings}
                                            source={`${source}.pagination`}
                                            label={"Pagination"}
                                            defaultValue={true}
                                        />
                                        {sourceData && sourceData.pagination
                                            && <Grid columns={2}>
                                                <SelectInput
                                                className={classes.lineSettings}
                                                source={`${source}.pagination_type`}
                                                choices={window.widget_pagination_type}
                                                label={"Pagination Type"}
                                                />
                                                <BooleanInput
                                                className={classes.lineSettings}
                                                source={`${source}.pagination_anchor`}
                                                label={"Pagination Anchor"}
                                                defaultValue={false}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                <Labeled label="Solr settings" />
                                <Grid columns="4">
                                    <NumberInput
                                        source={`${source}.limit`}
                                        label="Number of results for curated lists (limit)"
                                        validate={[number, minValue0]}
                                        warn={negativeNumber}
                                        allowEmpty
                                    />
                                </Grid>

                                <Labeled label="Image settings" />
                                
                                <Grid columns="1">
                                    <Grid columns={2} style={{gridTemplateColumns:"30% 1fr"}} classes={classes.groupSettings}>
                                        <BooleanInput
                                            className={classes.lineSettings}
                                            source={`${source}.endorsement_image`}
                                            label={"Display endorsement image"}
                                            defaultValue={false}
                                        />

                                        {sourceData && sourceData.endorsement_image &&
                                            <SelectInput
                                                className={classes.lineSettings}
                                                source={`${source}.endorsement_placement`}
                                                choices={[
                                                    { id: "main_image", name: "Replace main image" },
                                                    { id: "overlay", name: "Overlay over main image" },
                                                ]}
                                                defaultValue="main_image"
                                                label={"Endorsement Placement"}
                                            />
                                        }
                                    </Grid>
                                    <Grid columns={2} style={{gridTemplateColumns:"30% 1fr"}} classes={classes.groupSettings}>
                                        <BooleanInput
                                            className={classes.lineSettings}
                                            source={`${source}.lazy`}
                                            label={"Lazy"}
                                            defaultValue={false}
                                        />
                                        {sourceData && sourceData.lazy &&
                                            <NumberInput
                                                className={classes.lineSettings}
                                                source={`${source}.ttl`}
                                                label="TTL (in second and only for lazy content)"
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
                <Grid classes={classes.groupSettings}>
                    <Labeled style={{fontSize:20}} className={classes.relatedTemplateLabeled} label={`Related to template ${sourceData.template ? sourceData.template : ""}`} />
                        <Fragment>
                        {
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            ['fter_lists_list'].includes(sourceData.template)
                            &&
                            (
                                <Grid columns={4}>
                                    <BooleanInput
                                        source={`${source}.default_grid`}
                                        label={"Grid Default"}
                                    />
                                </Grid>
                            )
                        }
                        {
                            (['dynamic','forumtopics','homeCarousel'].includes(sourceData.type)
                            &&
                            ['fter_lists_list','fter_lists_searchlist','fter_lists','feature','fter_feature_block','listing_v2'].includes(sourceData.template))
                            &&
                            (
                                <Fragment>
                                    <Grid columns={4}>
                                    <BooleanInput
                                        source={`${source}.hide_hero`}
                                        label="widget.hide_hero" />
                                    <BooleanInput
                                        source={`${source}.hide_date`}
                                        label="widget.hide_date" />
                                    <BooleanInput
                                        source={`${source}.hide_author`}
                                        label="widget.hide_author" />
                                    <BooleanInput
                                        source={`${source}.hide_publisher`}
                                        label="widget.hide_publisher" />
                                    <BooleanInput
                                        source={`${source}.show_category_link`}
                                        label="widget.show_category_link" />
                                </Grid>
                                <Grid columns="1">
                                    <Grid columns={3} style={{gridTemplateColumns:"33% 1fr 1fr"}} classes={classes.groupSettings}>
                                            <BooleanInput
                                                source={`${source}.video_mode`}
                                                label="widget.video_mode" />
                                            {sourceData && sourceData.video_mode &&
                                                <><SelectInput
                                                    className={classes.lineSettings}
                                                    source={`${source}.video_provider`}
                                                    label="Video Provider"
                                                    resettable
                                                    choices={window.videoProviders} />
                                                    <TextInput
                                                        className={classes.lineSettings}
                                                        source={`${source}.video_player_id`}
                                                        label="widget.video_player_id" />
                                                </>}
                                        </Grid>
                                    </Grid>
                                </Fragment>  
                            )
                        }
                        {
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            ['group_hub'].includes(sourceData.template)
                            &&
                            (
                                <Grid columns={4}>
                                    <BooleanInput
                                        source={`${source}.hide_more_link`}
                                        label={"Hide \"More Link\""}
                                    />
                                    <BooleanInput
                                        source={`${source}.disable_content_links`}
                                        label={"Disable Content Links"}
                                    />
                                    <BooleanInput
                                        className={classes.lineSettings}
                                        source={`${source}.reverse_sort_priority`}
                                        label={"Reverse Group List Priority"}
                                        defaultValue={false}
                                    />
                                </Grid>
                            )
                        }
                        {
                            ['table_list'].includes(sourceData.template) === true
                            &&
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            (
                                <ArrayInput label="Headers Table" source={`${source}.header_table`}>
                                    <SimpleFormIterator>
                                        <TextInput label="Header Label" source="header" />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            )
                        }
                        {
                            ['list_video'].includes(sourceData.template) === true
                            &&
                            ['dynamic'].includes(sourceData.type)
                            &&
                            (
                                <Grid columns={3}>
                                    <NumberInput
                                        source={`${source}.player_height`}
                                        label="Height Player"
                                        defaultValue="350"
                                        validate={[number, minValue0]}
                                        warn={negativeNumber}
                                    />
                                    <Grid columns={2} style={{gridTemplateColumns:"30% 1fr"}} classes={classes.groupSettings}>
                                        <TextInput
                                            label="Player ID"
                                            source={`${source}.player_id`}
                                            defaultValue={'34Eru7MZ'}
                                            resettable
                                        />
                                        {
                                            sourceData && sourceData.player_id
                                            &&
                                            <SelectInput
                                                className={classes.lineSettings}
                                                source={`${source}.jwplayer_provider`}
                                                choices={[{id:"future",name:"Future"}]}
                                                label={"Jwplayer Provider"}
                                                defaultValue={'future'}
                                                resettable
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            ['carousel','carousel_rounded'].includes(sourceData.template) === true
                            &&
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            (
                            <Grid columns={3}>
                                <TextInput
                                    className={classes.lineSettings}
                                    source={`${source}.change_media_config`}
                                    label="widget.change_media_config"
                                    validate={[isListNumberWithCommaSeparator]}
                                    warn={[isListNumberWithCommaSeparator]}
                                />
                                <Grid columns={3} style={{gridTemplateColumns:"33% 1fr 1fr"}} classes={classes.groupSettings}>
                                    <BooleanInput
                                        defaultValue={true}
                                        source={`${source}.is_scrollable`}
                                        label={"Is scrollable?"}
                                    />
                                    {sourceData && sourceData.is_scrollable &&
                                    <Fragment>
                                        <NumberInput
                                            className={classes.lineSettings}
                                            source={`${source}.scroll_from`}
                                            defaultValue={3}
                                            label="From item (index)"
                                            validate={[negativeNumber]}
                                            warn={[negativeNumber]}
                                        />
                                        <TextInput
                                            className={classes.lineSettings}
                                            source={`${source}.scroll_gap`}
                                            defaultValue={20}
                                            label="With gap (%)"
                                        />
                                    </Fragment>
                                    }
                                </Grid>
                            </Grid>
                            )
                        }
                        {
                            ['listing_v2'].includes(sourceData.template) === true
                            &&
                            ['dynamic'].includes(sourceData.type)
                            &&
                            <WidgetListingV2Options
                                classes={classes}
                                source={source}
                                record={record}
                                sourceData={sourceData}
                                dispatch={dispatch}
                                index={index}
                            />
                        }
                    </Fragment>
                </Grid>
            </Fragment>
            <Field
                multiple={false}
                label="Icon"
                name={`${source}.icon`}
                docType={record.doc_type}
                component={InputFile}
            />
        </Fragment>
        )
}

function getFeatureFlagChoices() {
    return window.featureFlags || [];
}
export default withStyles(styles)(WidgetAdvancedSettings);
