import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Select,
  TextField,
  InputLabel,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

const styles = (theme) => ({
  boxWrapper: {
    position: "absolute",
    padding: 15,
    border: "1px solid #333",
    backgroundColor: "#FFF",
    zIndex: 1,
    marginLeft: -35,
    width: 350,
  },
  input: {
    marginTop: 5,
    marginBottom: 15,
  },
  box: {
    display: "block",
    '& > *': {
      width: "100%",
    },
  },
  button: {
    width: "50%",
    backgroundColor: "#4051B6",
    display: "block",
    margin: "auto",
    "&:hover": {
      backgroundColor: "#4051B6",
      opacity: 0.8,
    },
    "&:disabled": {
      backgroundColor: "grey",
      opacity: 0.8,
    },
    color: "#fff",
  },
});

const guidPageLayoutExp = new RegExp(window.page_layout.guid_pattern,'i');
const isNotValid = (str) => {
  const error = [];
  const valuesMatch = str.match(guidPageLayoutExp);

  if(!valuesMatch){
    return true;
  }

  const isSiteExist = window.sitesChoices.filter((site) => {
    return valuesMatch && Array.isArray(valuesMatch) ? site.id === valuesMatch[1] : ""
  });

  const isTemplateExist = window.templateLayoutChoices.filter((template) => {
    return valuesMatch && Array.isArray(valuesMatch) ? template.id === valuesMatch[3] : "";
  });

  if (Array.isArray(valuesMatch) && isSiteExist.length < 1) {
    error.push("Inexisting Site! ");
  }
  
  if (Array.isArray(valuesMatch) && isTemplateExist.length < 1) {
    error.push("Inexisting Template! ");
  }

  return error;
};

const renderErrors = (errors) => errors.join(" ");

class ClonePageLayout extends Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    this.state = {
      selectedSiteValue: "",
      selectedTemplateValue: "",
      newGuidValue: `${record.guid}_${Date.now()}`,
      withTimestamp:true,
      showInput:this.props.showInput || false,
      error: [],
    };

    this.handleSelectedSiteChange = this.handleSelectedSiteChange.bind(this);
    this.handleSelectedTemplateChange = this.handleSelectedTemplateChange.bind(this);
    this.handleWithTimestamp = this.handleWithTimestamp.bind(this);
    this.handleEnterInput = this.handleEnterInput.bind(this);
  }


  handleEnterInput = (e) => {
    const {record} = this.props
    const {selectedTemplateValue, selectedSiteValue} = this.state;
    if(isNotValid(e.target.value) === true){
      this.setState({newGuidValue: `${selectedSiteValue || record.site }_page_layout_${selectedTemplateValue || record.templatelayout}`, 
      withTimestamp: false,
      })
    }
    else{
      this.setState({ newGuidValue: e.target.value })
    }
  }
  handleWithTimestamp = (e) => {
    const { record } = this.props;
    this.setState((prevState)=>({
      withTimestamp:!prevState.withTimestamp,
      newGuidValue:!prevState.withTimestamp ? `${record.guid}_${Date.now()}`:record.guid,
      selectedSiteValue:"",
      selectedTemplateValue:"",
    }))
  }

  handleSelectedSiteChange = (e) => {
    const { newGuidValue } = this.state;
    const { record } = this.props;

    let newValue = newGuidValue.replace(
      guidPageLayoutExp,
      (match, p1, p2, p3, p4, p5) =>
        [e.target.value || record.site, p2, p3, ...(p5 ? [p5] : [])].join("_")
    );
    this.setState({
      selectedSiteValue: e.target.value,
      newGuidValue: newValue,
      showInput: true,
    });
  };

  handleSelectedTemplateChange = (e) => {
    const { newGuidValue } = this.state;
    const { record } = this.props;

    let newValue = newGuidValue.replace(
      guidPageLayoutExp,
      (match, p1, p2, p3, p4, p5) => {
        return [p1,p2,e.target.value || record.templatelayout,...(p5 ? [p5] : []),].join("_");
      }
    );
    this.setState({
      selectedTemplateValue: e.target.value,
      newGuidValue: newValue,
      showInput: true,
    });
  };

  render() {
    const {
      selectedSiteValue,
      selectedTemplateValue,
      newGuidValue,
      showInput,
    } = this.state;

    const { classes, record, cbHandleClone } = this.props;

    return (
      <Fragment>
      {showInput && <div
          id={`${record.id}-clone-page-layout`}
          className={classes.boxWrapper}
        >
          <div className={classes.input}>
            <InputLabel htmlFor={`${record.id}-clone-page-layout-guid-params`}>
              ID
            </InputLabel>
            <TextField
              id={`${record.id}-clone-page-layout-guid-params`}
              error={
                newGuidValue && isNotValid(newGuidValue).length > 0 ? true : false
              }
              // inputProps={{className:"clone-parameters"}}
              onChange={this.handleEnterInput}
              className={classes.box}
              helperText={
                newGuidValue && isNotValid(newGuidValue).length > 0
                  ? `${renderErrors(isNotValid(newGuidValue))}`
                  : "New Guid"
              }
              value={newGuidValue}
            />
          </div>
          <FormControlLabel
          control={
            <Checkbox
              // inputProps={{className:"clone-parameters"}}
              // className={"clone-parameters"}
              checked={this.state.withTimestamp}
              onChange={this.handleWithTimestamp}
            />
          }
          label="Simple timestamp cloning"
        />
          <div className={classes.input}>
            <InputLabel htmlFor={`${record.id}-clone-page-layout-site-param`}>
              Target Site
            </InputLabel>
            <Select
              disabled={this.state.withTimestamp}
              className={classes.box}
              id={`${record.id}-clone-page-layout-site-param`}
              value={selectedSiteValue}
              onChange={this.handleSelectedSiteChange}
            >
              {[{ id: "", name: "None" }, ...window.sitesChoices].map(
                (site, index) => (
                  <MenuItem
                    key={`${record.id}-clone-page-layout-site-param-${index}`}
                    className={`clone-parameters`}
                    value={site.id}
                  >
                    {site.name}
                  </MenuItem>
                )
              )}
            </Select>
          </div>
          <div className={classes.input}>
            <InputLabel htmlFor={`${record.id}-clone-page-layout-template-param`}>
              Target Template
            </InputLabel>
            <Select
              disabled={this.state.withTimestamp}
              className={classes.box}
              id={`${record.id}-clone-page-layout-template-param`}
              value={selectedTemplateValue}
              onChange={this.handleSelectedTemplateChange}
            >
              {[{ id: "", name: "None" },...window.templateLayoutChoices].map(
                (site, index) => (
                  <MenuItem
                    key={`${record.id}-clone-page-layout-template-param-${index}`}
                    className={`clone-parameters`}
                    value={site.id} 
                    >
                    {site.name}
                  </MenuItem>
                )
              )}
            </Select>
          </div>
          <Button
            onClick={()=>cbHandleClone(record.guid,newGuidValue)}
            className={classes.button}
            disabled={newGuidValue && isNotValid(newGuidValue).length > 0 ? true : false}
          >
            Let's clone
          </Button>
        </div>
      }
      </Fragment>
    );
  }
}

export default withStyles(styles)(ClonePageLayout);
