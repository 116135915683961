import React, { Fragment } from 'react';
import { Labeled } from 'react-admin';
import Chip from '@material-ui/core/Chip';

const { blocks } = window.product_blocks;
const blocksById = blocks.reduce((acc, block) => ({ ...acc, [block.id]: block }), {});

export default ({ record }) => (
    <Labeled label="Blocks">
        <Fragment>
            {record.product_blocks
                && record.product_blocks.map(blockId => (
                    <Chip key={blockId} label={blocksById[blockId].name} />
                ))}
        </Fragment>
    </Labeled>
);
