import React from 'react';
import Create from '../Create';
import EditForm from './EditForm';

const CustomRouteCreate = props => (
    <Create {...props}>
        <EditForm permissions={props.permissions} />
    </Create>
);

export default CustomRouteCreate;
