import React, { Fragment, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SelectInput, NumberInput } from "react-admin";
import Grid from "./../components/Grid";
import metricsTypesFields from "../metricsTypesFields";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { REDUX_FORM_NAME } from "react-admin";
import { change } from "redux-form";
import { METRICS_DEFAULT_VALUES, METRICS_FIELDS } from "../Constants/articles_metrics";

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    }
});

export const getWidgetCustomQuery = (data, idx) => {
    return idx !== null && data.widgets[idx].custom_query ? data.widgets[idx].custom_query : '';
}

export const isMetricsFiltered = (featureSrc, formData) => {
    return formData[`${featureSrc}_metric_region_field`] && formData[`${featureSrc}_metric_type_field`]
        && formData[`${featureSrc}_metric_region_field`] !== ''
        && formData[`${featureSrc}_metric_type_field`] !== '' 
}

const metricsReg = (new RegExp(/(&&\s){0,1}\(metrics\.([a-zA-Z_]{2,5})_region_(popularity|trending|engagement)_rank:\[([0-9]{1,3}) TO ([0-9]{1,3})\] && publishedDate:\[NOW-([0-9]{1,3})(HOURS|DAYS|MONTHS|YEARS) TO NOW\]\)/));

/**
 *
 * @description given metrics values in form data, builds a sub query with sensible defaults
 * 
 * @param {object} formData 
 * @returns 
 */
const ArticlesMetricsInput = (props) => {

    const { dispatch, featureSrc, formData, querySrc } = props;
    const site = formData.site ? formData.site : "";
    const classes = props.classes ? props.classes : null;
    const solrQueryAPICall = props.solrQueryAPICall ? props.solrQueryAPICall : null;
    const widgetCustomQuery = props.widgetCustomQuery ? props.widgetCustomQuery : "";
    const widgetIdx = props.widgetIdx ? props.widgetIdx : null;
    const widgetPayload = widgetIdx !== null ? formData.widgets[widgetIdx] : null;

    const [metricsPanelEnabled, setMetricsPanelEnabled] = useState(false);
    const [regionField, setRegionField] = useState(METRICS_DEFAULT_VALUES.metric_region_field);
    const [typeField, setTypeField] = useState(METRICS_DEFAULT_VALUES.metric_type_field);
    const [rankStartField, setRankStartField] = useState(METRICS_DEFAULT_VALUES.metric_rank_start_field);
    const [rankEndField, setRankEndField] = useState(METRICS_DEFAULT_VALUES.metric_rank_end_field);
    const [dateValueField, setDateValueField] = useState(METRICS_DEFAULT_VALUES.metric_date_value_field);
    const [timeUnitField, setTimeUnitField] = useState(METRICS_DEFAULT_VALUES.metric_time_unit_field);

    const dispatchToQueryField = (src, valueToDispatch) => {
        dispatch(change(REDUX_FORM_NAME, src, valueToDispatch));
    };

    const getPayloadFieldVal = (field) => {
        if (widgetPayload !== null && widgetPayload[field]) {
            return widgetPayload[field]; 
        } else if (formData[`${featureSrc}${field}`]) {
            return formData[`${featureSrc}${field}`];
        } else {
            return  METRICS_DEFAULT_VALUES[field.slice(1)];
        }
    };

    const dispatchMetricsVals = () => {
        METRICS_FIELDS.forEach(field => {
            dispatchToQueryField(featureSrc+'_'+field, getPayloadFieldVal('_'+field));
        });
    };

    const formDataHasMetrics = () => {
        if (widgetPayload !== null) {
            return widgetPayload[`_metric_region_field`] !== undefined && widgetPayload[`_metric_type_field`] !== undefined
                && widgetPayload[`_metric_region_field`] !== '' 
                && widgetPayload[`_metric_type_field`] !== '';
        }
        return formData[`${featureSrc}_metric_region_field`] !== undefined && formData[`${featureSrc}_metric_type_field`] !== undefined
            && formData[`${featureSrc}_metric_region_field`] !== '' 
            && formData[`${featureSrc}_metric_type_field`] !== '';
    };

    const getNumberValThenDispatch = (num, cb) => {
        cb(num);
    };

    /**
     * 
     * @description takes the select change event as a 1st parameter,
     *              constructs the string consisting of the selcted value in a loop,
     *              uses 2nd `setState` function param to update the local state for a given field
     * 
     * @param {Event} e 
     * @param {React.SetStateAction} cb 
     */
    const getSelectValThenDispatch = (e, cb) => {
        let valToDispatch = "";
        for (const key in e) {
            if (Object.hasOwnProperty.call(e, key) && key !== "preventDefault") {
                valToDispatch += e[key];
            }
        }
        cb(valToDispatch);
    };

    const getInitialQuery = () => {
        return widgetCustomQuery !== '' ? widgetCustomQuery : (formData[querySrc] ? formData[querySrc] : '');
    };

    const getMetricsSolrSubQuery = (query) => {
        const metric_region_field = getPayloadFieldVal("_metric_region_field");
        const metric_type_field = getPayloadFieldVal("_metric_type_field");
        const metric_rank_start_field = getPayloadFieldVal("_metric_rank_start_field");
        const metric_rank_end_field = getPayloadFieldVal("_metric_rank_end_field");
        const metric_date_value_field = getPayloadFieldVal("_metric_date_value_field");
        const metric_time_unit_field = getPayloadFieldVal("_metric_time_unit_field");
        let subQuery = `${query && query.trim().length > 0 && !query.startsWith("(metrics.") ? " && " : ""}`;
        subQuery += `(metrics.${metric_region_field}_region_`;
        subQuery += `${metric_type_field}_rank:`;
        subQuery += `[${metric_rank_start_field}`;
        subQuery += ` TO `;
        subQuery += `${metric_rank_end_field}]`;
        subQuery += ` && publishedDate:[`;
        subQuery += `NOW-${metric_date_value_field}`;
        subQuery += `${metric_time_unit_field}`
        subQuery += ` TO NOW])`;
        return subQuery;
    }

   const handleMetricsChange = (enabled) => {
        const initialQuery = getInitialQuery();
        let updatedQuery = '';
        if (enabled) {
            updatedQuery += initialQuery.replace(metricsReg, '').replace(/  +/g, ' ') + getMetricsSolrSubQuery(initialQuery.replace(metricsReg, '').replace(/  +/g, ' '));
            dispatchMetricsVals();
        } 
        if (!enabled) {
            METRICS_FIELDS.forEach(field => {
                dispatchToQueryField(featureSrc+'_'+field, '');
            });
            if (initialQuery !== '') {
                updatedQuery += initialQuery.replace(metricsReg, '');
            } else {
                updatedQuery += '';
            } 
        }
        if (solrQueryAPICall !== null && site !== '') {
            /**
             * re playing the query without metrics to get updated url's;
             * setting updated query to an empty space here case updated query is null allows to reset the number of found URL's
             */
            solrQueryAPICall(updatedQuery === '' ? ' ': updatedQuery, site);
        }
        if (updatedQuery.startsWith(" && ")) {
            updatedQuery = updatedQuery.slice(4);
        }
        dispatchToQueryField(querySrc, updatedQuery);
        setMetricsPanelEnabled(enabled);
    };

    /**
     * @description if form data for the wired widget contains metrics, then panel appears on mount
     */
    useEffect(() => {
        setMetricsPanelEnabled(formDataHasMetrics());
    }, []);

    useEffect(() => {
        handleMetricsChange(formDataHasMetrics());
    }, [regionField, typeField, rankStartField, rankEndField, dateValueField, timeUnitField])

    /**
     *  @description with metrics checkbox enabled,
     *               when dynamic widget custom query input that is not a metrics query is updated,
     *               then the custom query saved in form data is appended with metrics subquery
     */
    useEffect(() => {
        if (formDataHasMetrics() && widgetCustomQuery !== '' && !metricsReg.test(widgetCustomQuery)) {
            dispatchToQueryField(querySrc, widgetCustomQuery.replace(/  +/g, ' ') + getMetricsSolrSubQuery(widgetCustomQuery));
        }
    }, [widgetPayload]);

    const metricsRegions = !window.regionsCMS.some(region => region.id === "all") ?
        window.regionsCMS.concat([{id: "all", name: "all regions"}]) : window.regionsCMS;
    metricsRegions.sort((a,b) => a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1);

    return  <Fragment>
        <FormControlLabel 
            label={"Get the most popular articles"} 
            control={<Checkbox 
                lab 
                checked={metricsPanelEnabled} 
                onClick={() => handleMetricsChange(!metricsPanelEnabled)} 
            />} 
        />
        {metricsPanelEnabled && <Paper className={classes ? classes.paper : ''} style={{marginBottom: "1rem"}}>
            <Typography variant="headline" gutterBottom>
                Articles Metrics Data
                <Typography variant="caption" gutterBottom>
                    Get the top entries in regard of metrics data
                </Typography>
            </Typography>
            <Grid columns="2">
                <SelectInput
                    allowEmpty
                    choices={metricsRegions}
                    defaultValue={METRICS_DEFAULT_VALUES.metric_region_field}
                    label="Select Metrics Region"
                    onChange={(e) => getSelectValThenDispatch(e, setRegionField)}
                    source={`${featureSrc}_metric_region_field`}
                />
                <SelectInput
                    allowEmpty
                    choices={metricsTypesFields}
                    defaultValue={METRICS_DEFAULT_VALUES.metric_type_field}
                    label="Select Metrics Type"
                    onChange={(e) => getSelectValThenDispatch(e, setTypeField)}
                    source={`${featureSrc}_metric_type_field`}
                />
                <NumberInput
                    allowEmpty
                    label="Select Metrics Rank Start"
                    defaultValue={METRICS_DEFAULT_VALUES.metric_rank_start_field}
                    onClick={(e) => getNumberValThenDispatch(e.target.value, setRankStartField)}
                    onChange={() => getNumberValThenDispatch(
                        document.querySelector(`input[name="${featureSrc}_metric_rank_start_field"]`).value, 
                        setRankStartField
                    )}
                    source={`${featureSrc}_metric_rank_start_field`}
                />
                <NumberInput
                    allowEmpty
                    defaultValue={METRICS_DEFAULT_VALUES.metric_rank_end_field}
                    label="Select Metrics Rank End"
                    onClick={(e) => getNumberValThenDispatch(e.target.value, setRankEndField)}
                    onChange={() => getNumberValThenDispatch(
                        document.querySelector(`input[name="${featureSrc}_metric_rank_end_field"]`).value, 
                        setRankEndField
                    )}
                    source={`${featureSrc}_metric_rank_end_field`}
                />  
            </Grid>
            <Typography variant="subheading" style={{marginTop:"1rem"}} gutterBottom>
                Select latest metrics time span
            </Typography>
            <Grid columns="2">
                <NumberInput
                    allowEmpty
                    defaultValue={METRICS_DEFAULT_VALUES.metric_date_value_field}
                    label="Published from now to x hours/days/months..."
                    onClick={(e) => getNumberValThenDispatch(e.target.value, setDateValueField)}
                    onChange={() => getNumberValThenDispatch(
                        document.querySelector(`input[name="${featureSrc}_metric_date_value_field"]`).value, 
                        setDateValueField
                    )}
                    source={`${featureSrc}_metric_date_value_field`}
                />
                <SelectInput
                    allowEmpty
                    choices={[
                        { id: 'HOURS', name: 'hours' },
                        { id: 'DAYS', name: 'days' },
                        { id: 'MONTHS', name: 'months' },
                        { id: 'YEARS', name: 'years' }
                    ]}                    
                    defaultValue={METRICS_DEFAULT_VALUES.metric_time_unit_field}
                    label="Select Time Unit Field"
                    onChange={(e) => getSelectValThenDispatch(e, setTimeUnitField)}
                    source={`${featureSrc}_metric_time_unit_field`}
                />
            </Grid>
    </Paper>}
    </Fragment>
};

export default withStyles(styles)(ArticlesMetricsInput);
