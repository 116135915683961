import React from 'react';
import IconButton from "../../components/IconButton";
// import AddIcon from '@material-ui/icons/Add';
// import DeleteDialog from './DeleteDialog';
import { Field } from 'redux-form';
import { TextInput, SelectInput } from 'react-admin';
import MinusIcon from '@material-ui/icons/Remove';
export const renderInPut = props => <TextInput {...props} />;
export const required = value => (value ? undefined : 'Required');
export const renderSelect = props => <SelectInput {...props} />;

export const renderArrayField = ({ fields, label, source, addKeyNameValue, addValueField }) => (
    <div>
        <div><h3>{label}</h3></div>
    
        {fields.map((item, index) =>
            <div key={index}>
                <div style={{marginTop: 15}}>
                    <div style={{display: 'inline', marginRight: 15, fontWeight: 'bold'}}>Item #{index + 1}</div>
                    {addKeyNameValue && <Field name={addKeyNameValue && addValueField ? `${item}.keyname` : item} component={renderInPut} label="Item key Name" style={{marginRight: 50}}/>}
                    {addValueField && <Field name={addKeyNameValue && addValueField ?  `${item}.value` : item}  component={renderInPut} label="Item Value" style={{marginRight: 50}}/>}
                    <IconButton
                    color="secondary"
                    icon={iconClasses => <MinusIcon className={iconClasses.leftIcon} />}
                    onClick={() => fields.remove(index)}
                >
                    {`Remove #${index + 1}`}
                </IconButton>
                </div>
            </div>
        )}
        <div>
     {/*    <IconButton
        color="primary"
        icon={classes => <AddIcon className={classes.leftIcon} />}
        onClick={() => fields.push({})}
        >
        Add a link
        </IconButton> */}
        </div>
    </div>
);