import React, { Component, Fragment } from 'react';
import { Labeled } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import ContentAdd from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from './IconButton';

const { blocks, groups } = window.product_blocks;

class WidgetProductBlockInput extends Component {
    constructor(props) {
        super(props);
        const { value } = this.props.input;
        this.state = (value || []).reduce(
            (acc, blockId) => ({
                ...acc,
                [blockId]: true,
            }),
            {},
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state !== prevState) {
            const checked = Object.keys(this.state).reduce((acc, blockId) => {
                if (this.state[blockId]) {
                    return [...acc, blockId];
                }
                return acc;
            }, []);
            this.props.input.onChange(checked);
        }
    }

    updateCheck = name => event => {
        event.persist();
        this.setState(state => ({
            ...state,
            [name]: event.target.checked,
        }));
    };

    updateGroup = (grouped) => {
        const checked = grouped.reduce((acc, blockId) => ({ ...acc, [blockId]: true }), {});

        this.setState((prevState) => {
            // disable all previous blocks
            // and mix them with tne new one checked
            const unchecked = Object.keys(prevState)
                .reduce((acc, blockId) => ({ ...acc, [blockId]: false }), {});

            return {
                ...unchecked,
                ...checked,
            };
        });
    };

    render() {
        return (
            <Fragment>
                <Labeled label="Pre-defined packages">
                    <Fragment>
                        {groups.map(group => (
                            <IconButton
                                key={group.id}
                                icon={iconClasses => <ContentAdd className={iconClasses.leftIcon} />}
                                onClick={() => {
                                    this.updateGroup(group.blocks);
                                }}
                            >
                                {group.name}
                            </IconButton>
                        ))}
                    </Fragment>
                </Labeled>
                <FormControl component="fieldset">
                    <Labeled label="Blocks">
                        <Fragment>
                            {blocks.map(block => (
                                <FormControlLabel
                                    key={block.id}
                                    control={
                                        <Checkbox
                                            checked={this.state[block.id] || false}
                                            onChange={this.updateCheck(block.id)}
                                            value={block.id}
                                        />
                                    }
                                    label={block.name}
                                />
                            ))}
                        </Fragment>
                    </Labeled>
                </FormControl>
            </Fragment>
        );
    }
}

export default WidgetProductBlockInput;
