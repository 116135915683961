import React, { Component, Fragment } from 'react';
import { SelectArrayInput } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetch } from '../restClient';

class PublishersInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            choices: [],
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetch('/api/publishers')
            .then(response => response.json())
            .then((data) => {
                this.setState({
                    isLoading: false,
                    choices: data,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { input: { name } } = this.props;
        const { choices, isLoading } = this.state;
        return (
            <Fragment>
                <SelectArrayInput
                    label="Publishers"
                    source={name}
                    choices={choices}
                    optionText="headline"
                    optionValue="guid"
                />
                {isLoading && <CircularProgress size={20} thickness={2} />}
            </Fragment>
        );
    }
}

export default PublishersInput;
