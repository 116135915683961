import React from 'react';

const Grid = ({
  children, columns, rowGap, columnGap,width, height, style, classes, gridTemplateColumns
}) => (
    <div
        style={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns : (columns ? `repeat(${columns}, 1fr)` : '1fr'),
            gridColumnGap: columnGap,
            gridRowGap: rowGap,
            width: width ? width : '100%',
            ...style,
        }}
        className={classes}
    >
        {children}
    </div>
);

Grid.defaultProps = {
    columnGap: '20px'
};

export default Grid;
