import React, {Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { getSource } from "../tools";
import {
    Labeled, TextField,BooleanField
} from 'react-admin';

const styles = () => ({
    root: {
        width: '100%',
    },
    label: {
        display: 'block',
    },
    detailsRoot: {
        display: 'block',
    }
});

class TrendingListField extends Component {

    shouldComponentUpdate() {
        return true;
    }
    render() {
        const {
            classes, addRootLabel, label, getTitle, Fields, source, record
        } = this.props;
        return (
            <div className={classes.root}>
                {addRootLabel && <Labeled label={label} classes={{ label: classes.label }} />}
                {
                getSource(record,source)
                    && getSource(record,source).length > 0
                    && getSource(record,source).map((item, index) => {
                                return (
                                <ExpansionPanel key={`trending-list-field-${source}-${index}`}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {getTitle(item)}
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
                                        {Fields.map((Field, i) => {
                                            return(
                                            <div key={`trending-list-field-${source}-${index}-${i}`}>
                                                <Labeled
                                                    source={source}
                                                    label={Field.props.label}
                                                    classes={{ label: classes.label }}
                                                >
                                                </Labeled>
                                                    {React.cloneElement(Field, {
                                                        record: item,
                                                    })}
                                            </div>
                                        )})}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        }
                    )
                }
            </div>
        );
    }
}
TrendingListField.defaultProps = {
    addRootLabel: true,
    label: 'Field list',
    getTitle: item => item.name ? item.name : "",
    Fields:  [
        <TextField source="name" label={"Title"} style={{display:"block"}} />,
        <TextField source="position" label="Position" />,
        <TextField source="url" label="URL" />,
        <BooleanField source="target_blank" label="Open Link in New Window" />,
    ],
};

export default withStyles(styles)(TrendingListField);
