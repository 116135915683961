import React from 'react'
import { BooleanField, SelectField, Labeled, TextField, FunctionField } from 'react-admin';
import CardExpanded from '../CardExpanded';
import Grid from "../../components/Grid";
import ChipsField from "../../components/ChipsField";
import { withStyles } from '@material-ui/core/styles';
import { styles as EdiFormStyles } from "../../Entities/Sites/EditForm";
import { stores } from "./GlobalConfig"
import { styles  as GlobalConfigStyle} from "./Outbox"
import { formatData } from "./DocumentsConfig";
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import { getSource } from '../../tools';


const styles = { ...EdiFormStyles, ...GlobalConfigStyle };


const CMSConfigField = (props) => {
    const { source, record, classes } = props;

    return (
        <Grid gridTemplateColumns={`1fr 2fr`} classes={props.classes.grid}>
            <CardExpanded label={"Main"} columns={4}>
                <Labeled label="Name (site_id)">
                    <TextField source={`${source}.name`} record={record} />
                </Labeled>
                <Labeled label="Label name">
                    <TextField source={`${source}.label_name`} record={record} />
                </Labeled>
                <Labeled label="HTTPS protocol">
                    <BooleanField label="HTTPS protocol" source={`${source}.https`} record={record} />
                </Labeled>
                <Labeled label="Global Domain">
                    <TextField source={`${source}.global_domain`} record={record} />
                </Labeled>
                <Labeled label="Domain">
                    <TextField source={`${source}.domain`} record={record} />
                </Labeled>
                <Labeled label="Redirect Domain">
                    <TextField source={`${source}.redirect_domain`} record={record} />
                </Labeled>
                <Labeled label="Data Store">
                    <SelectField choices={stores} source={`${source}.datastore`} record={record} />
                </Labeled>
                <Labeled label="Show in CMS">
                    <BooleanField label="Show in CMS" source={`${source}.show_in_cms`} record={record} />
                </Labeled>
                <Labeled label="Amp">
                    <BooleanField label="Amp" source={`${source}.amp`} record={record} />
                </Labeled>
                <Labeled label="Category Tag Url">
                    <BooleanField source={`${source}.category_tag_url`} record={record} />
                </Labeled>
                <Labeled label="Category Tag Url Multiple">
                    <BooleanField source={`${source}.category_tag_url_multiple`} record={record} />
                </Labeled>
            </CardExpanded>
            <CardExpanded label={"FCSIS / Varnish / Redirect"} columns={1}>
                <FormControl className={classes.formControl} component="fieldset">
                    <FormLabel classes={{ root: classes.formLabel }} component="legend">Redirect</FormLabel>
                    <FormGroup classes={{ root: classes.formGroup }}>
                        <Grid columns={2}>
                            <Labeled label={"Allowed redirect source domains"}>
                                <FunctionField record={record} fullwidth="true" render={rec => <ChipsField record={rec} source={`${source}.allowed_redirect_source_domains`} />} />
                            </Labeled>
                            <Labeled label={"Allowed redirect destination domains"}>
                                <FunctionField record={record} fullwidth="true" render={rec => <ChipsField record={rec} source={`${source}.allowed_redirect_destination_domains`} />} />
                            </Labeled>
                        </Grid>
                    </FormGroup>
                </FormControl>
                <Divider />
                <FormControl className={classes.formControl} component="fieldset">
                    <FormLabel classes={{ root: classes.formLabel }} component="legend">Varnish</FormLabel>
                    <FormGroup className={classes.formGroup}>
                        <Grid columns={3}>
                            <Labeled label={"Origin enabled"}>
                                <BooleanField source={`${source}.varnish.origin_enabled`} record={record} />
                            </Labeled>
                            <Labeled label={"Edge enabled"}>
                                <BooleanField source={`${source}.varnish.edge_enabled`} record={record} />
                            </Labeled>
                            <Labeled label={"Cluster version"}>
                                <TextField source={`${source}.varnish.cluster_version`} record={record} />
                            </Labeled>
                        </Grid>
                    </FormGroup>
                </FormControl>
                <Divider />
                <FormControl className={classes.formControl} component="fieldset">
                    <FormLabel classes={{ root: classes.formLabel }} component="legend">FCSIS</FormLabel>
                    <FormGroup className={classes.formGroup}>
                        <Grid columns={2}>
                            <Labeled label={"Cluster"}>
                                <TextField source={`${source}.fcsis.cluster`} record={record} />
                            </Labeled>
                            <Labeled label={"Collection"}>
                                <TextField source={`${source}.fcsis.collection`} record={record} />
                            </Labeled>
                        </Grid>
                    </FormGroup>
                </FormControl>
            </CardExpanded>
            <CardExpanded label={"Regions"} columns={2}>
                <Labeled label={"Languages"}>
                    <FunctionField record={record} render={rec => <ChipsField record={rec} source={`${source}.regions.languages`} />} />
                </Labeled>
                <Labeled label={"Selected Regions"}>
                    <FunctionField record={record} render={rec => <ChipsField record={rec} source={`${source}.regions.selected_regions`} />} />
                </Labeled>
                <Labeled label={"Regions"}>
                    <FunctionField record={record} render={rec => <ChipsField record={rec} source={`${source}.regions.regions`} />} />
                </Labeled>
            </CardExpanded>
            <CardExpanded label={"Features"} columns={4}>
                <Labeled label={"Most Used Blocks"}>
                    <FunctionField record={record} render={rec => <ChipsField record={rec} source={`${source}.features.most_used_blocks`} />} />
                </Labeled>
                <Labeled label="Copy images across websites">
                    <BooleanField source={`${source}.features.copy_images_across_websites`} record={record} />
                </Labeled>
                <Labeled label="Region sets">
                    <BooleanField source={`${source}.features.region_sets`} record={record} />
                </Labeled>
                <Labeled label="Amp Review">
                    <BooleanField source={`${source}.features.amp_review`} record={record} />
                </Labeled>
                <Labeled label="Amp Gallery">
                    <BooleanField source={`${source}.features.amp_gallery`} record={record} />
                </Labeled>
                <Labeled label="Hide preview">
                    <BooleanField source={`${source}.features.hide_preview`} record={record} />
                </Labeled>
                <Labeled label="Show Listing Title">
                    <BooleanField source={`${source}.features.show_listing`} record={record} />
                </Labeled>
                <Labeled label="Imported Flag">
                    <BooleanField source={`${source}.features.imported_flag`} record={record} />
                </Labeled>
                <Labeled label="Live Reports">
                    <BooleanField source={`${source}.features.live_reports`} record={record} />
                </Labeled>
                <Labeled label="Author Roles">
                    <BooleanField source={`${source}.features.author_roles`} record={record} />
                </Labeled>
                <Labeled label="Companies">
                    <BooleanField source={`${source}.features.companies`} record={record} />
                </Labeled>
                <Labeled label="Persons">
                    <BooleanField source={`${source}.features.persons`} record={record} />
                </Labeled>
                <Labeled label="EventGroups">
                    <BooleanField source={`${source}.features.eventGroups`} record={record} />
                </Labeled>
                <Labeled label="Organisations">
                    <BooleanField source={`${source}.features.organisations`} record={record} />
                </Labeled>
                <Labeled label="Stage Label (Multiple Events)">
                    <BooleanField source={`${source}.features.stage_label_required_for_multiple_events`} record={record} />
                </Labeled>
                <Labeled label="Recipe">
                    <BooleanField source={`${source}.features.recipe`} record={record} />
                </Labeled>
                <Labeled label="Shop">
                    <BooleanField source={`${source}.features.shop`} record={record} />
                </Labeled>
                <Labeled label="Ball Sports">
                    <BooleanField source={`${source}.features.football`} record={record} />
                </Labeled>
            </CardExpanded>
            <CardExpanded label={"Documents"} style={{ gridColumn: "1 / 2 span" }} columns={1}>
                {record 
                && record[`${source}`] 
                && record[`${source}`].documents 
                && record[`${source}`].documents.article &&
                    <CardExpanded columns={1} label={`Article`}>
                        <CardExpanded columns={1} label={`Templates`}>
                            <Labeled label={"Standard"}>
                                <FunctionField
                                    record={record}
                                    render={rec => <ChipsField
                                        arrayValues={formatData(getSource(rec, `${source}.documents.article.templates.standard`))}
                                    />
                                    }
                                />
                            </Labeled>
                            <Labeled label={"Review"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.templates.review`))} />} />
                            </Labeled>
                            <Labeled label={"Gallery"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.templates.gallery`))} />} />
                            </Labeled>
                            <Labeled label={"Supported"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.templates.supported`))} />} />
                            </Labeled>
                            <Labeled label={"Advertisement Feature"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.templates.advertisement_feature`))} />} />
                            </Labeled>
                            <Labeled label={"Live Report"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.templates.live_report`))} />} />
                            </Labeled>
                            <Labeled label={"Recipe"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.templates.recipe`))} />} />
                            </Labeled>
                        </CardExpanded>
                        <CardExpanded columns={1} label={`Tags`}>
                            <Labeled label={"Tags"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.tags`))} />} />
                            </Labeled>
                        </CardExpanded>
                        <CardExpanded columns={1} label={`Channels`}>
                            <Labeled label={"Tags"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.article.channels`))} />} />
                            </Labeled>
                        </CardExpanded>
                    </CardExpanded>
                }
                {record 
                && record[`${source}`] 
                && record[`${source}`].documents 
                && record[`${source}`].documents.event_group &&
                    <CardExpanded columns={1} label={`Event Group`}>
                        <CardExpanded columns={1} label={`Tags`}>
                            <Labeled label={"Tags"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.event_group.tags`))} />} />
                            </Labeled>
                        </CardExpanded>
                    </CardExpanded>
                }
                {record 
                && record[`${source}`] 
                && record[`${source}`].documents 
                && record[`${source}`].documents.person_group &&
                    <CardExpanded columns={1} label={`Person Group`}>
                        <CardExpanded columns={1} label={`Tags`}>
                            <Labeled label={"Tags"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.person_group.tags`))} />} />
                            </Labeled>
                        </CardExpanded>
                    </CardExpanded>
                }
                {record 
                && record[`${source}`] 
                && record[`${source}`].documents 
                && record[`${source}`].documents.event &&
                    <CardExpanded columns={1} label={`Event`}>
                        <CardExpanded columns={1} label={`Tags`}>
                            <Labeled label={"Tags"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.event.tags`))} />} />
                            </Labeled>
                        </CardExpanded>
                    </CardExpanded>
                }
                {record 
                && record[`${source}`] 
                && record[`${source}`].documents 
                && record[`${source}`].documents.person &&
                    <CardExpanded columns={1} label={`Person`}>
                        <CardExpanded columns={1} label={`Tags`}>
                            <Labeled label={"Tags"}>
                                <FunctionField record={record} render={rec => <ChipsField arrayValues={formatData(getSource(rec, `${source}.documents.person.tags`))} />} />
                            </Labeled>
                        </CardExpanded>
                    </CardExpanded>
                }
            </CardExpanded>
        </Grid>

    )
}

export default withStyles(styles)(CMSConfigField)
