import React from "react";
import { withStyles } from "@material-ui/core/styles";

export const defaultStyles = {
  badge: {
    fontSize: "16px",
    textAlign: "center",
    marginLeft: "12px",
    marginBottom: "10px",
    padding: "10px 15px",
    width: "fit-content",
    borderRadius: "5px",
    fontWeight: "bold",
  },
  link: {
    fontWeight: "bold",
    fontSize: "16px",
  }
};

function Badge({
  description,
  links,
  icon = undefined,
  styles = {},
}) {
    const Icon = () => icon
  return (
      <div style={{...styles.badgeStyles, ...defaultStyles.badge}}>
        {icon && <Icon />}
        {description} {" "}
        {links && links.map( (link, index) =>
          <a style={styles.linkStyles} href={link.linkUrl} target="_blank">
            {link.linkText} {index < links.length -1 && ", "}
          </a>
        )}
      </div>
  );
}

export default withStyles(defaultStyles)(Badge);
