/* eslint-disable no-eval */
import React from "react";

import * as _ from "lodash";
import { TextInput, SelectInput, BooleanInput, DateInput, FormDataConsumer, NumberInput } from "react-admin";
import { IconButton, Button } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";

const getComponent = ({ type, source, choices }) => {
    switch (type) {
        case "boolean":
            return <BooleanInput source={source} label={"Value"} style={{ flex: "0 1 40%" }} />;
        case "number":
            return <NumberInput source={source} label={"Value"} style={{ flex: "0 1 40%" }} />;
        case "date":
            return <DateInput source={source} label={"Value"} style={{ flex: "0 1 40%" }} />;
        case "choice":
            if (!choices) {
                return;
            }
            return (
                <SelectInput
                    source={source}
                    label={"Value"}
                    resettable
                    choices={window[choices]}
                    style={{ flex: "0 1 40%" }}
                />
            );
        case "text":
        default:
            return <TextInput style={{ flex: "0 1 40%" }} source={source} label={"Value"} multiline />;
    }
};

const FullLabelField = ({ record }) => (
    <span>
        {record.name} ({record.id})
    </span>
);

const SortArray = (x, y) => {
    if (x.name < y.name) {
        return -1;
    }
    if (x.name > y.name) {
        return 1;
    }
    return 0;
};

const getFcsisObject = (options, receivedKey) => {
    return options.find((fcsisOption) => fcsisOption.id === receivedKey);
};

const SolrFields = (props) => {
    const { fields, options } = props;

    return (
        <div>
            {fields.length > 0 &&
                fields.map((itemSource, index) => (
                    <FormDataConsumer>
                        {({ formData }) => {
                            const currentWidgetData = _.get(formData, itemSource);
                            const fcsisObject = getFcsisObject(options, currentWidgetData.key) || {};

                            return (
                                <div
                                    key={`solr-params-${props.fields.name}-${index}`}
                                    style={{ display: "flex", flexFlow: "row nowrap", alignItems: "center" }}
                                >
                                    <SelectInput
                                        style={{ flex: "1 1 350px", marginRight: 10 }}
                                        label={`Field type`}
                                        source={`${itemSource}.key`}
                                        choices={options.sort(SortArray)}
                                        optionText={<FullLabelField />}
                                    />

                                    {getComponent({
                                        type: fcsisObject.type ? fcsisObject.type : "text",
                                        source: `${itemSource}.value`,
                                        choices: fcsisObject.choice_values ? fcsisObject.choice_values : null,
                                    })}

                                    <IconButton
                                        onClick={() => fields.remove(index)}
                                        style={{ flex: "0 0 48px" }}
                                        title="Remove"
                                    >
                                        <MinusIcon />
                                    </IconButton>
                                </div>
                            );
                        }}
                    </FormDataConsumer>
                ))}

            <Button size="small" onClick={() => fields.push({})} style={{ margin: "20px auto", display: "flex" }}>
                <AddIcon /> Add new Item
            </Button>
        </div>
    );
};

export default SolrFields;
