import React, { Component } from 'react';
import { TextInput, SimpleFormIterator, ArrayInput, FormDataConsumer } from 'react-admin';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles/';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeBranchedUrl } from '../../restClient';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import SingleSelectWithCustom from "../../components/SingleSelectWithCustom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field } from "redux-form";
import { flattenObject, isObject, RACheckIfJsonIsValid } from "../../tools";

const styles = theme => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: `160px 1fr 160px 1fr ${theme.spacing.unit * 6}px ${theme.spacing.unit * 6}px`,
        columnGap: theme.spacing.unit,
        width: '100%',
    },
    autoTextInput: {
        fontSize: '20px'
    }
});

const customStyleSingleSelect = {
    control: () => ({ minWidth: 250 }),
    menu: () => ({ height: 500, width: 'inherit', maxWidth: 900, overflow: 'scroll' }),
}

class AutocompleteSiteConfigOverride extends Component {
    CancelToken = axios.CancelToken;
    cancel = null;

    constructor(props) {
        super(props);
        this.state = {
            isCheckingQuery: false,
            suggestList: [],
            suggestListStr: [],
            value: '',
            key: ''
        };
    }

    componentDidMount() {
        const { site, lang = "", territory = "" } = this.props.data;
        let site_guid = '';
        if (site && lang && territory) {
            site_guid = `${site}_${lang}_${territory}`
        } else {
            //Find the site territory and attach it
            site_guid = window.sitesChoices.map(siteData => {
                    return (siteData.id + '_' + (siteData.locale).toLowerCase());
            }).filter(item => item.indexOf(site) > -1 ? item : false);
            if (site_guid.length) {
                site_guid = site_guid.reduce(item => item);
            }
        }

        if (this.state.suggestList.length <= 0) {
            this.getSiteConfig(site, site_guid);
        }
    }

    componentWillUnmount() {
        if (this.cancel) {
            this.cancel('aborting current request');
        }
    }

    changeFirstKey(suggestsList) {
        let edited = {};

        Object.entries(suggestsList).forEach(entry => {
            if (entry[1].id.length > 0) {
                edited[entry[1].id] = entry[1];
            }
        });

        return edited;
    }

    getSiteConfig = (site, site_guid = '') => {
        this.setState({ isCheckingQuery: true });
        let completions = [];

        if (this.cancel) {
            this.cancel('aborting for a new request');
        }

        axios.get(makeBranchedUrl(`/api/override_site_config/${site}/${site_guid}`), {
            cancelToken: new this.CancelToken((c) => {
                this.cancel = c;
            }),
        })
            .then((response) => {
                let suggestListFlatten = flattenObject(this.changeFirstKey(response.data.documents));

                Object.entries(suggestListFlatten).forEach(entry => {
                    completions.push({
                        id: entry[0] || '',
                        name: entry[0].replace('thirdPartyIntegration.', '') || '',
                        value: entry[1] || ''
                    });
                });

                this.setState({
                    isCheckingQuery: false,
                    suggestList: completions,
                    suggestListStr: Object.keys(suggestListFlatten)
                });
            });
    };

    render() {
        const { classes, label = "", source } = this.props;
        const { isCheckingQuery } = this.state;

        if (this.state.suggestList.length <= 0) {
            return (<div>
                <Typography>Flexi Config Overrides</Typography>
                {isCheckingQuery && <CircularProgress size={16} />}
                Could not load Flexi config overrides...
            </div>);
        }

        return (
            <div>
                <Typography>Flexi Config Overrides</Typography>
                <ExpansionPanel defaultExpanded={false}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Config Override Tips</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.db}>
                        - Searching for any site config will bring up a list of configs for this site<br />
                        - Click on the config and then enter a new value and that will override the config for this test variation
                        <br />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ArrayInput label={label} source={`${source}.override`}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({ getSource,formData, scopedFormData }) => {
                                return (
                                    <Field
                                        label={"Key"}
                                        name={getSource('key')}
                                        component={SingleSelectWithCustom}
                                        options={this.state.suggestListStr}
                                        customStyle={customStyleSingleSelect}
                                        currentValue={isObject(scopedFormData) ? scopedFormData.key : ''}
                                        data={formData}
                                        canCopy={true}

                                    />
                                );
                            }}
                        </FormDataConsumer>
                        <div>{isCheckingQuery && <CircularProgress size={16} />}</div>
                        <TextInput label="Value" source="value" warn={[RACheckIfJsonIsValid]} validate={[RACheckIfJsonIsValid]} style={{ width: "100%" }} />
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        );
    }
}

export default withStyles(styles)(AutocompleteSiteConfigOverride);
