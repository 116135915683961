import React, {Component} from 'react';
import AceEditor from 'react-ace';
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/mode-twig";
import "ace-builds/src-min-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-spellcheck";
import "ace-builds/src-min-noconflict/snippets/html";
import { addField } from 'ra-core';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
class AceEditorField extends Component {
    static propTypes = {
        input: PropTypes.object,
        source: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    componentDidMount() {
        const {
            input: {value}
        } = this.props;

        this.setState({value});
    }

    handleValueChange(value) {
        this.setState({value});
        this.props.input.onChange(value);
    }

    render() {
        const {mode = "html", displayAceLabel = true, minLines = 1, maxLines = 50, values = this.state.value} = this.props;
        return (
            <FormControl fullWidth={true}>
                {displayAceLabel &&  <FormLabel style={{fontSize: "12px", marginBottom: "10px"}}>{this.props.label || this.props.source}</FormLabel>}
                <AceEditor
                    mode={mode}
                    theme="monokai"
                    name={this.props.source}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={values}
                    minLines={minLines}
                    maxLines={maxLines}
                    onChange={data=>this.handleValueChange(data)}
                    height={"500px"}
                    width={"100%"}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        useWorker: false,
                        tabSize: 2,
                    }} />
            </FormControl>
        );
    }
}

const AceEditorInput = addField(AceEditorField);

AceEditorInput.defaultProps = {
    addLabel: true,
    fullWidth: true,
};
export default AceEditorInput;