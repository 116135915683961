import React from 'react';
import {
    DateField, TextField, SelectField, BooleanField, TabbedForm, UrlField, ImageField, FormTab,Labeled,ArrayField,Datagrid
} from 'react-admin';
import { ColorField } from 'react-admin-color-input';

import Show from '../Show';
import LinkListField from '../../components/LinkListField';
import SelectListField from '../../components/SelectListField';
import ApiListField from '../../components/ApiListField';
import VanillaNavigationListField from "../../components/VanillaNavigationField";
import SearchListField from "../../components/SearchField";
import TrendingListField from "../../components/TrendingField";
import BrandsField from "../../components/BrandsField";
import Grid from "../../components/Grid";
import CMSConfigField from "../../components/CMSConfigs/Show"

const colorPickerField = (index, sourcePrefix, labelPrefix,record) => {
    return (
        <Labeled key={labelPrefix + "-" + (index + 1)} label={labelPrefix + "-" + (index + 1)}>
            <ColorField key={index} source={sourcePrefix + "_" + (index + 1)} picker="Photoshop" record={record} label={labelPrefix + "-" + (index + 1)}/>
        </Labeled>
    );
};
const LookFeelShow = ({record})=>(
    <Grid columns={4}>
        <Labeled style={{ marginTop: 10 }} label="Theme">
            <SelectField source="theme" choices={window.sitesTheme} record={record}/>
        </Labeled>
        <Grid>
            <Labeled label="Brand Colors"></Labeled>
            {window.brandLengthColors > 0 && Array.from(Array(window.brandLengthColors)).map((field, index) => (
                colorPickerField(index, 'brand_color', 'brand-color',record)
            ))}
        </Grid>
        <Grid>
            <Labeled style={{marginTop: 10}} label="Secondary colors"></Labeled>
            {window.secondaryLengthColors > 0 && Array.from(Array(window.secondaryLengthColors)).map((field, index) => (
                colorPickerField(index, 'secondary_color', 'secondary-color',record)
                ))}
        </Grid>
        <Grid>
            <Labeled style={{marginTop: 10}} label="Grayscales colors"></Labeled>
            {window.grayscalesLengthColors > 0 && Array.from(Array(window.grayscalesLengthColors)).map((field, index) => (
                colorPickerField(index, 'grayscales_color', 'grayscales-color',record)
            ))}
        </Grid>
    </Grid>
)

const SiteShow = props => (
    <Show title="Site show" {...props}>
        <TabbedForm>
            <FormTab label="Summary">
                <TextField source="slug" />
                <TextField source="name" />
                <TextField source="site_id" label="Site Id"/>
                <SelectField source="lang" label={"Language"} choices={window.sitesLangChoices} />
                <SelectField source="territory" label={"Territory"} choices={window.sitesTerritoryChoices} />
                <BooleanField source="full_flexi_site" />
                <BooleanField source="is_master" label={"Is master?"} />
                <ImageField source="logo[0].src" label="Icon" />
                <ImageField source="mobile_logo[0].src" label="Icon Mobile" />
                <ImageField source="brand_logo[0].src" label="Icon Brand" />
                <TextField source="manifest" label="Manifest (json)"/>
                <TextField source="head_markup" label="Head Markup"/>
                <TextField source="bottom_markup" label="Bottom Markup"/>
                <TextField source="strapline" label="Strapline"/>
                <UrlField source="url" label="Site URL"/>
                <TextField source="site_css" label="Site CSS"/>
                <DateField source="date" />
                <DateField source="published_date" />
                <TextField source="modified_by" />
            </FormTab>
            <FormTab label="CMS Config">
                <CMSConfigField source={'cms_config'} record={props.record} />
            </FormTab>
            <FormTab label="Look And Feel">
                <LookFeelShow {...props} />
            </FormTab>
            <FormTab label="Header">
                <VanillaNavigationListField source="header.vanilla_navigation" label={"Vanilla Navigation"}></VanillaNavigationListField>
                <LinkListField source="header.navigation" label={"Navigation"} >
                    <LinkListField source="sublinklist" component={LinkListField}/> 
                </LinkListField>
                <LinkListField source="header.top_navigation" label={"Top Navigation"} >
                    <LinkListField source="sublinklist" component={LinkListField}/> 
                </LinkListField>
                <TrendingListField source="header.trending" label={"Trending"}></TrendingListField>
                <BrandsField source="header.brands" label={"Brands"}></BrandsField>
                <SelectListField source="header.social" label={"Social"} component={LinkListField}></SelectListField>
            </FormTab>
            <FormTab label="Footer">
                <LinkListField source="footer.navigation" label={"Navigation"}>
                    <LinkListField source="sublinklist" />
                </LinkListField>
                <SelectListField source="footer.social" label={"Social"} />
                <SelectField source="footer.adress" label={"Future location"} choices={window.adressLocations} />
            </FormTab>
            <FormTab label="Social">
                <LinkListField source="social" label={"Social"} >
                    <LinkListField source="sublinklist" />
                </LinkListField>
            </FormTab>
            <FormTab label="CONFIG">
                <ApiListField source="api" label={"config"} />
            </FormTab> 
            <FormTab label="SEARCH">
                <SearchListField source="search" label={"Search"} />
            </FormTab> 
            <FormTab label="MISC">
                <TextField source="misc.site_css_class" label={"Site CSS Class"} />
                <TextField source="misc.site_css" label={"Site CSS"} />
                <TextField source="misc.amp_site_css" label={"Amp Site CSS"} />
                <TextField source="misc.manifest" label={"Manifest"} />
                <TextField source="misc.head_markup" label={"Header Markup"} />
                <ArrayField source="misc.custom" label={"Custom"}>
                    <Datagrid>
                        <TextField label="Key" source="key" />
                        <TextField label="Value" source="value" />
                        <TextField label="Param0" source="param0" />
                    </Datagrid>
                </ArrayField>
                <ImageField source="favicon_180x180[0].src" label="Favicon 180x180" />
                <ImageField source="favicon_32x32[0].src" label="Favicon 32x32" />
                <ImageField source="favicon_16x16[0].src" label="Favicon 16x16" />
            </FormTab>
        </TabbedForm>
    </Show>
);

export default SiteShow;
