import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { FixedSizeList } from 'react-window';
import CircularProgress from '@material-ui/core/CircularProgress';
import {  Field } from 'redux-form';
import { makeBranchedUrl } from '../restClient';
import SolrAceEditorInput from "./SolrAceEditorInput";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArticlesMetricsInput, { isMetricsFiltered } from './ArticlesMetricsInput';

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    buttons: {
        alignSelf: 'center',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: `160px 1fr 160px 1fr ${theme.spacing.unit * 6}px ${theme.spacing.unit * 6}px`,
        columnGap: theme.spacing.unit,
        width: '100%',
    },
    redField: {
        color: "red",
    },
    thirdx2:{
        width:"66%"
    },
    db:{
        display: 'block',
    },
    strong: {
        "font-weight": "bold",
    },
    urlSearchBox: {
        "margin-bottom": "15px",
        "margin-top": "5px",
        "margin-right": "10px",
    },
    urlCategory: {
        "margin-top": "20px",
        "margin-bottom": "0",
        "font-weight": "bold",
    },
    excludedUrlsList: {
        color: "red",
    }
});

class SolrConditionsInput extends Component {
    CancelToken = axios.CancelToken;
    cancel = null;
    currentURLs = [];
    searchResultURLs = [];
    excludedUrls = this.searchResultURLs.filter(x => this.currentURLs.includes(x));

    state = {
        isCheckingQuery: false,
        impactedDocuments: null,
        filteredSearchResultURLs: [],
        filteredCurrentURLs: [],
        excludedUrls: [],
    };

    componentDidMount() {
        const { conditions_query, site, url } = this.props.data;
        if(typeof url !== 'undefined') {
            if (typeof url === 'string') {
                this.currentURLs = url.split('\n');
            }
            else {
                this.currentURLs = url;
            }
            this.setState({
                filteredCurrentURLs: this.currentURLs,
                excludedUrls: this.excludedUrls,
                state: this.state,
            })
        }

        if (conditions_query && site) {
            this.solrQueryAPICall(conditions_query, site, isMetricsFiltered("abtests", this.props.data));
        }
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        const { conditions_query, site, url } = data;

        // Empty url field as we could have a lot of URLs and we don't need them
        if (typeof url !== 'undefined' && url.length > 100) {
            this.props.data.url = [];
        }

        if (conditions_query !== prevProps.data.conditions_query || site !== prevProps.data.site) {
            this.solrQueryAPICall(conditions_query, site);
        }
    }

    componentWillUnmount() {
        if (this.currentURLs > 100) {
            this.props.data.url = this.currentURLs;
        }
        if (this.cancel) {
            this.cancel('aborting current request');
        }
    }

    /**
     * 
     * @description given an existing string Solr query, makes an API call to the AB testing conditions endpoint;
     *              the query response is used to update the urls saved at component level;
     *              if the query contains metrics, overrides the original query with metrics clause appended
     * 
     * @param {string} conditionsQuery 
     * @param {string} inputSite 
     * @param {boolean} shouldBeMetricsFiltered set to false by default 
     * @returns {void}
     */
    solrQueryAPICall = (conditionsQuery, site, shouldBeMetricsFiltered = false) => {
        if (!conditionsQuery && !shouldBeMetricsFiltered) {
            return;
        }
        this.setState({ isCheckingQuery: true });
        if (this.cancel) {
            this.cancel('aborting for a new request');
        }

        // overriding the original input with one containing a metrics clause
        if (shouldBeMetricsFiltered) {
            this.props.data.conditions_query = conditionsQuery;
        }

        let siteQuerystring = site ? '?site='+site : '';
        axios.get(makeBranchedUrl(`/api/abtesting_condition/${btoa(conditionsQuery)}${siteQuerystring}`), {
            cancelToken: new this.CancelToken((c) => {
                this.cancel = c;
            }),
        })
        .then((response) => {
            this.searchResultURLs = response.data.url ? response.data.url : [];

            axios.post(makeBranchedUrl(`/api/abtesting_excluded_urls`), {
                urls: btoa(JSON.stringify(this.searchResultURLs.filter(x => !this.currentURLs.includes(x)))),
                cancelToken: new this.CancelToken((c) => {
                    this.cancel = c;
                }),
            }).then((excludedUrlsContent) => {
                if (excludedUrlsContent.data.length > 0) {
                    this.excludedUrls = excludedUrlsContent.data.map(e => {
                        const abtest = e.abtest ? e.abtest : 'no abtest found';
                        return e.url + ' (' + abtest +')';
                    });
                    this.setState({
                        excludedUrls: this.excludedUrls,
                    });
                }
            });

            this.setState({
                isCheckingQuery: false,
                impactedDocuments: response.data.documents,
                filteredSearchResultURLs: this.searchResultURLs,
                state: this.state,
            });
        });

    };

    renderURL = ({data, index, style}) => {
        return <div style={style}>{data[index]}</div>
    }

    filterSearchResultURLs = (e) => {
        const results = this.searchResultURLs.filter(value => value.includes(e.target.value));
        this.setState({
            filteredSearchResultURLs: results,
            state: this.state,
        });
    }

    filterCurrentURLs = (e) => {
        const results = this.currentURLs.filter(value => value.includes(e.target.value));
        this.setState({
            filteredCurrentURLs: results,
            state: this.state,
        });
    }

    renderURLs = () => {
        const { classes } = this.props;
        const { filteredSearchResultURLs, filteredCurrentURLs, excludedUrls } = this.state;

        return (
            <Typography component="div">
                <Typography variant="subheading" className={classes.urlCategory}>Query result (only eligible URLs)</Typography>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    className={classes.urlSearchBox}
                    onChange={this.filterSearchResultURLs}
                />
                {filteredSearchResultURLs.length + " URLs"}
                <FixedSizeList
                    height={Math.min(500, 30 * filteredSearchResultURLs.length)}
                    itemData={filteredSearchResultURLs}
                    itemKey={index => (index)}
                    itemSize={20}
                    itemCount={filteredSearchResultURLs.length}
                >
                        {this.renderURL}
                </FixedSizeList>
                {filteredSearchResultURLs.length === 0 && 'No URLs found'}
                <Typography variant="subheading" className={classes.urlCategory}>URLs currently used on this AB test campaign</Typography>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    className={classes.urlSearchBox}
                    onChange={this.filterCurrentURLs}
                />
                {filteredCurrentURLs.length + " URLs"}
                <FixedSizeList
                    height={Math.min(500, 30 * filteredCurrentURLs.length)}
                    itemData={filteredCurrentURLs}
                    itemKey={index => (index)}
                    itemSize={20}
                    itemCount={filteredCurrentURLs.length}
                >
                    {this.renderURL}
                </FixedSizeList>
                {filteredCurrentURLs.length === 0 && 'No URLs found'}

                <Typography variant="subheading" className={classes.urlCategory}>Excluded urls</Typography>
                {excludedUrls.length + " URLs"}
                <FixedSizeList
                    className={classes.excludedUrlsList}
                    height={Math.min(500, 30 * excludedUrls.length)}
                    itemData={excludedUrls}
                    itemKey={index => (index)}
                    itemSize={20}
                    itemCount={excludedUrls.length}
                >
                    {this.renderURL}
                </FixedSizeList>
                {excludedUrls.length === 0 && 'No excluded URLs'}
            </Typography>
        )
    }

    render() {
        const { classes, data, dispatch,  } = this.props;
        const { conditions_query } = data;
        const { isCheckingQuery } = this.state;

        return (
            <div>
                <ArticlesMetricsInput
                    classes={classes} 
                    dispatch={dispatch}
                    featureSrc="abtests"
                    formData={data} 
                    querySrc="conditions_query"
                    solrQueryAPICall={this.solrQueryAPICall}
                />
                <ExpansionPanel defaultExpanded={false}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Solr Tips</Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={classes.db}>
                        - Selecting the site above is only targeting the good Solr collection to use (proof_space, proof_fourfourtwo...)<br />
                        - In order to get only urls from a specific site, you should specify the site so that sites which would live in the same collection would be properly selected
                        (proof_default collection handle many small sites currently): <span className={classes.strong}>site: fourfourtwo</span>
                        <br /><br />
                        - You can also select many articles by id, by doing a query like this:<br />
                        <span className={classes.strong}>id : (tHFFSaHF2BN9NoLzctuH5c Hn9cRKNYZrxWQZjJatnGPm yxRjzw9rgyfuNWWWKub2ih)</span><br /><br />
                        - Sometimes you want to exclude documents such as for example all tag documents,
                        you need to prefix the field with a dash like that: <span className={classes.strong}>-articleType: tag</span>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Paper className={classes.paper}>
                    <Field labl="Solr Query Editor" name="conditions_query" source="conditions_query" value={conditions_query} component={SolrAceEditorInput} />
                    {isCheckingQuery && <CircularProgress size={16} />}
                    {!isCheckingQuery && this.renderURLs()}
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SolrConditionsInput);