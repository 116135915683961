import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';

const AbtestingEdit = props => (
    <Edit undoable={false} title="Abtesting config" {...props}>
        <EditForm permissions={props.permissions} />
    </Edit>
);

export default AbtestingEdit;
