// in src/App.js
import React from "react";
import { Admin, Resource, resolveBrowserLocale } from "react-admin";
import MainLayout from "./components/Layout";
import { createMuiTheme } from "@material-ui/core/styles";
import englishMessages from "ra-language-english";
import resources from "./resources";
import restClient from "./restClient";
import authProvider from "./authProvider";
import messages from "./messages";
import LoginPage from "./LoginPage";
import reducersSettingsUI, { settingsUISaga } from "./Entities/Settings/settingsUI";

// import SentryBoundary from './components/Boundaries/SentryBoundary';

const i18n = {
    en: { ...englishMessages, ...messages },
};

// Always return "en"
const i18nProvider = () => i18n["en"];

export const theme = createMuiTheme({
    palette: {
        type: "light",
        secondary: { main: window.environment !== "prod" ? "#851be6" : "#f50057" },
        warning: { main: "#F39C12" },
        error: { main: "#f50057" },
        text: {
            primary: "#333",
            secondary: "#d3d3d3",
        },
    },
    color: {
        grayScale: {
            superLight: "#EEEEEE",
            veryLight: "#CCCCCC",
            light: "#999999",
            dark: "#666666",
            veryDark: "#333333",
            superDark: "#000000",
        },
    },
    typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
        color: "#fff",
    },
    formLabel: {
        color: "#747474",
    },
    overrides: {
        MuiToolbar: {
            regular: {
                display: "flex",
                flexFlow: "row wrap",
            },
        },
        MuiFormHelperText: { root: { color: "#757575" } },
        MuiFormLabel: { root: { color: "#757575" } },
        MuiInput: {
            root: {
                minWidth: 150,
            },
        },
        MuiTypography: {
            subheading: {
                fontWeight: "bold",
            },
        },
        MuiTooltip: {
            tooltip: { fontSize: 14, whiteSpace: "pre-wrap" },
        },
    },
});

// separating the rendering resources logic in a separate function so it's easily testable (cf. src/Ui/Entities/Settings/List.test.js)
export const renderResources = (permissions) => {
    return resources.map((resource) => {
        switch (resource.name) {
            case "settings":
                return permissions.checkUserIsSuperAdmin(permissions.role) ? (
                    <Resource
                        key={resource.name}
                        name={resource.name}
                        list={resource.List}
                        edit={
                            permissions && permissions.checkUserCanCRUD(resource.name, "update") ? resource.Edit : null
                        }
                        create={
                            permissions && permissions.checkUserCanCRUD(resource.name, "create")
                                ? resource.Create
                                : null
                        }
                        show={permissions && permissions.checkUserCanCRUD(resource.name, "read") ? resource.Show : null}
                        icon={resource.Icon}
                        options={{ label: resource.label }}
                        {...resource}
                    />
                ) : (
                    []
                );
            default:
                return (
                    <Resource
                        key={resource.name}
                        name={resource.name}
                        list={resource.List}
                        edit={
                            permissions && permissions.checkUserCanCRUD(resource.name, "update") ? resource.Edit : null
                        }
                        create={
                            permissions && permissions.checkUserCanCRUD(resource.name, "create")
                                ? resource.Create
                                : null
                        }
                        show={permissions && permissions.checkUserCanCRUD(resource.name, "read") ? resource.Show : null}
                        icon={resource.Icon}
                        options={{ label: resource.label }}
                        {...resource}
                    />
                );
        }
    });
};

export default () => (
    <Admin
        locale={resolveBrowserLocale()}
        appLayout={MainLayout}
        i18nProvider={i18nProvider}
        dataProvider={restClient}
        authProvider={authProvider}
        loginPage={LoginPage}
        theme={theme}
        position="static"
        title={"Flexi UI"}
        customReducers={{ settingsui: reducersSettingsUI }}
        customSagas={[settingsUISaga]}
    >
        {(permissions) => renderResources(permissions)}
    </Admin>
);
