import React from 'react';
import {
    TabbedForm,
    FormTab,
    TextInput,
    DateTimeInput,
    BooleanInput,
    SelectInput,
    FormDataConsumer,
    LongTextInput, SelectArrayInput, BooleanField,
} from 'react-admin';
import { FieldArray } from 'redux-form';
import EditToolbar from '../EditToolbar';
import ChangelogField from "../../components/ChangelogField";
import Grid from "../../components/Grid";
import { required } from '../../tools';
import AbtestingInputs from '../../components/LinkListInput/AbtestingIndex';
import { withStyles } from '@material-ui/core/styles';
import SolrConditionsInput from "../../components/SolrConditionsInput";
import CircleIcon from '@material-ui/icons/FiberManualRecord';

const styles ={
    fr1001fr1fr:{
        gridTemplateColumns:"150px 1fr 1fr !important",
    },
    thirdx2:{width:"66%"},
    half:{width:"50%"},
    third:{width:"33%"},
    midHalf:{width:"25%"},
    ten:{width:"10%"},
    align:{
        marginTop:0,
    },
    grid:{
        display:"grid",
        width:150,
    },
    redField: {
        color: "red",
    }
}
const sortAscListId = (list) => list.sort((a,b)=> a.id > b.id ? 1 : -1)

const URLFormatter = (v) => {
    if (typeof v === 'undefined') {
        return '';
    }
    else if (typeof v === 'string') {
        return v;
    }
    return Array.from(v).join('\n');
}

const URLParser = (v) => {
    if (typeof v === 'string') {
        return v.split('\n');
    }
    return v;
}

const EditForm = ({classes, ...props}) => {

    const validateUrlOrGlobal = (values) => {
        const errors = {};

        if(values.type === 'normal' && (typeof values.url === 'undefined' || values.url.filter(Boolean).length === 0)) {
            errors.url = ['Url must be filled or type "Global" should set'];
        }

        return errors;
    };

    const convertToSelectedSites = (value) => {
        try {
            return value.split(',').sort();
        } catch (e) {
            return [];
        }
    }
    const convertToSiteValue = (values) => Array.isArray(values) ? values.sort().join(',') : values;

    const handleDateChange = (event, value, formData) => {
        if (event.currentTarget.name === 'start') {
            formData.invalidDate = (new Date(value)).getTime() > (new Date(formData.end)).getTime();
        } else {
            formData.invalidDate = (new Date(formData.start)).getTime() > (new Date(value)).getTime();
        }
    };

    return (
        <TabbedForm
        submitOnEnter={false}
        redirect="edit"
        toolbar={<EditToolbar record={props.record} permissions={props.permissions} />}
        {...props}
        validate={validateUrlOrGlobal}
        >
            <FormTab label="summary">
                <TextInput source="name" validate={required} />
                <SelectArrayInput label={'Site'} source="site" format={convertToSelectedSites} parse={convertToSiteValue} choices={window.sitesChoices} required validate={[required]} />
                <FormDataConsumer className={classes.thirdx2}>
                    {({ formData, ...rest }) => formData.type === 'normal' &&
                         <TextInput
                         helperText={formData && formData.url && formData.url.length ? `${formData.url.length} urls added` : ''}
                         source="url"
                         multiline
                         label="Relative Urls (one per line without trailing slash)"
                         format={URLFormatter}
                         parse={URLParser}
                         {...rest}
                     />
                    }
                </FormDataConsumer>
                <Grid columns="2" width={"60%"}>
                    <SelectInput source="type" choices={[
                        {'name' : 'Normal', "id": "normal"},
                        {'name' : 'Conditional', "id": "conditional"},
                    ]} defaultValue={"normal"} />
                </Grid>
                <Grid columns="2" width={"60%"}>
                    <FormDataConsumer className={classes.thirdx2}>
                        {({ formData, ...rest }) =>
                            <DateTimeInput source="start"
                                           validate={required}
                                           onChange={(event, value) => handleDateChange(event, value, formData)}
                            />
                        }
                    </FormDataConsumer>

                    <FormDataConsumer className={classes.thirdx2}>
                        {({ formData }) =>
                    <DateTimeInput source="end"
                                   validate={required}
                                   helperText={ formData.invalidDate ? 'Start date should be prior to the end date' : ''}
                                   onChange={(event, value) => handleDateChange(event, value, formData)}
                        />
                        }
                    </FormDataConsumer>
                </Grid>
                <SelectArrayInput
                    label="Allowed regions"
                    source="allowed_regions"
                    choices={sortAscListId(window.regionsCMS)}
                    allowEmpty
                />
                <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => formData.type === 'conditional' &&
                        <SolrConditionsInput name="conditions" data={formData} dispatch={dispatch} {...rest} />
                    }
                </FormDataConsumer>
                <FieldArray name="abtests"
                            label={"Ab Tests"}
                            fullWidth={true}
                            component={AbtestingInputs}
                            getTitle={(item) => (typeof props.record !== 'undefined' && typeof props.record.abtests !== 'undefined' && props.record.abtests.length ? Math.round(100/(props.record.abtests.length))+'% - ' : '')+(item.variant || 'control')}
                            extendedTitle={(item) => (typeof props.record.url !== "undefined" && props.record.url !== null ? 'Preview URI: '+ props.record.url[0] + (item.variant && item.flexi && props.record.id === true ? '?_flexi=1&_flexi_doc_slug='+item.variant+'&' : '?') + "_flexi_variantId=" + (item.variant || 'control') + '&_flexi_abtesting_preview=1'+ '&_flexi_abtest=' + props.record.id : '')}
                            gridColumnTitle={1}
                />
                <LongTextInput source="comment" />
                <BooleanField source="is_published" label="Is item published" defaultValue={false} />
            </FormTab>
            {props.permissions && props.permissions.checkUserCanCRUD('abtesting_log', "read") &&
            <FormTab label="Changelogs">
                <ChangelogField guid={props.record.guid} />
            </FormTab>
            }
        </TabbedForm>
    );
}

export default withStyles(styles)(EditForm);
