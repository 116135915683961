import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles/';
import SingleSelectWithCustom from "../../components/SingleSelectWithCustom";
import { Field } from "redux-form";

const styles = theme => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: `160px 1fr 160px 1fr ${theme.spacing.unit * 6}px ${theme.spacing.unit * 6}px`,
        columnGap: theme.spacing.unit,
        width: '100%',
    },
    autoTextInput: {
        fontSize: '20px'
    }
});

const customStyleSingleSelect = {
    control: () => ({ minWidth: 250 }),
    menu: () => ({ height: 300, width: 'inherit', maxWidth: 900, overflow: 'scroll' }),
}

const AutocompleteSiteConfigOverride = ({data,source, sourceData, label }) => {
    const [suggestList, setSuggestList] = useState([]);
    const [suggestListStr, setSuggestListStr] = useState([]);

    useEffect(() => {
        let completions = [];

        Object.entries(data).forEach(entry => {
            completions.push({
                id: entry[0] || '',
                name: entry[1] || '',
                value: entry[1] || ''
            });
        });
        setSuggestList(completions);
        setSuggestListStr(completions.map(e => e.name.id));
    }, [])

    return (
        <>
            {suggestList.length <= 0 ? <p>No configs found</p> :<Field
                label={label}
                name={source}
                component={SingleSelectWithCustom}
                resettable
                allowEmpty
                isClearable={true}
                options={suggestListStr}
                customStyle={customStyleSingleSelect}
                currentValue={sourceData && sourceData.widgetFeatureFlag ? sourceData.widgetFeatureFlag : ''}
                data={data}
            />}
        </>
    );
}

export default withStyles(styles)(AutocompleteSiteConfigOverride);
