import React from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { Labeled } from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { extractFromSettings } from "../Entities/Settings/settingsUI";

const styles = {
    deepRta : {
        "& ~ div.rta__autocomplete li:hover":{backgroundColor:"rgba(0, 0, 0, 0.08)"},
        fontFamily:"inherit"
    }
};

class TextareaAutoComplete extends React.Component {
    static propTypes = {
        prop: PropTypes
    };
    constructor(props){
        super(props);
        const {placeholdersListSettingsUI} = this.props;
        this.state = {
            value: props.input.value,
            rows: props.input.value.split("\n").length,
            caretPositionOnBlur:0,
            suggestsSourceList: (placeholdersListSettingsUI && placeholdersListSettingsUI.placeholders) || window.placeholdersSuggests,
        }
    }

    componentDidMount(){
        const { input ,field } = this.props;

        if(field && field.default && !input.value){
            input.onChange(field.default);

            this.setState({
                value: field.default,
            });
        }
    }

    shouldComponentUpdate(nextProps,nextState){
        const {placeholdersListSettingsUI,input} = this.props;
        return ((nextProps.placeholdersListSettingsUI && nextProps.placeholdersListSettingsUI.placeholders.length) !== (placeholdersListSettingsUI && placeholdersListSettingsUI.placeholders.length)) ||
        nextProps.input.value !== input.value
    }

    componentDidUpdate(prevProps){
        const {placeholdersListSettingsUI} = this.props;

        //prevent automatic caret position at the end of the field
        const {caretPositionOnBlur} = this.state;
        caretPositionOnBlur > 0 && this.rta.setCaretPosition(caretPositionOnBlur);

        if((prevProps.placeholdersListSettingsUI && prevProps.placeholdersListSettingsUI.placeholders.length) !== ( placeholdersListSettingsUI && placeholdersListSettingsUI.placeholders.length)){
            this.setState({
                suggestsSourceList:placeholdersListSettingsUI.placeholders
            });
        }
    }

    handleChange = (event) => {
        const {input} = this.props;
        input.onChange(event.target.value);

        this.setState({
            value: event.target.value,
            rows: event.target.value.split("\n").length,
        });
    };

    RenderItem = ({ entity: { key, value } }) => {
        return value !== '' ? <div>{`${key}`}<span style={{color: 'grey'}}> - {value}</span></div> : <div>{`${key}`}</div>;
    };

    render() {
        const Loading = ({ data }) => <div>Loading</div>;
        const {label, classes, style={}} = this.props;
        return (
            <Labeled label={label}>
                <ReactTextareaAutocomplete
                    className={classes.deepRta}
                    onChange={this.handleChange}
                    loadingComponent={Loading}
                    rows={this.state.rows}
                    value={this.state.value}
                    onCaretPositionChange={(pos)=>{this.setState({caretPositionOnBlur:pos})}}
                    style={{
                        fontSize: "16px",
                        padding: "2px 0 10px",
                        width: "100%",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                        resize: "none",
                        outline: "none",
                        ...style.reactTextAreaStyle
                    }}
                    ref={rta => {
                        this.rta = rta;
                    }}
                    innerRef={textarea => {
                        this.textarea = textarea;
                    }}
                    listStyle={{
                        width: "100%",
                        listStyleType: "none",
                        borderRadius: "4px",
                        boxShadow: "0 8px 10px 1px rgba(0, 0, 0, 0.14)",
                        fontFamily: 'Arial',
                        padding: "8px 0",
                        opacity: 1,
                        position: "absolute",
                        backgroundColor: "white",
                        zIndex: "2147483647"
                    }}
                    itemStyle={{
                        width: "auto",
                        cursor: "pointer",
                        padding: "12px 16px",
                    }}
                    minChar={0}
                    trigger={{
                        "%": {
                            dataProvider: value => {
                                const involvedList = [];
                                this.state.suggestsSourceList.forEach( item => {
                                    if( item.key.indexOf( value ) !== -1 ) {
                                        involvedList.push(item)
                                    }
                                });
                                return involvedList.slice(0, 10);
                            },
                            component: this.RenderItem,
                            output: (item, trigger) => item.key
                        }
                    }}
                />
            </Labeled>
        );
    }
}

TextareaAutoComplete.propTypes={
    field: PropTypes.object,
    input:PropTypes.object.isRequired
};

const mapStateToProps = state =>{
    return {placeholdersListSettingsUI: extractFromSettings(state.settingsui,"placeholders_list")}
}

const areStatesEqual = (prev,next) => {
    const prevPlaceholders = extractFromSettings(prev.settingsui,"placeholders_list");
    const nextPlaceholders = extractFromSettings(next.settingsui,"placeholders_list");
    return (prevPlaceholders && prevPlaceholders.placeholders) === (nextPlaceholders && nextPlaceholders.placeholders)}

export default connect(mapStateToProps,null,null,{areStatesEqual:areStatesEqual})(withStyles(styles)(TextareaAutoComplete));