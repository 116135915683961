import React from "react";
import { TextInput, SelectArrayInput, Filter, NullableBooleanInput, SelectInput } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../CustomRoutes/ListFilter";

const ListFilter = (props) => (
    <Filter className={props.classes.filter} {...props}>
        <TextInput alwaysOn source="name" resettable label="Search in name" />
        <TextInput source="guid" resettable label="Search in id (guid)" />
        <TextInput source="url" resettable label="Search by relative url" />
        <NullableBooleanInput alwaysOn source="is_published" label="is published" style={{ marginRight: 20 }} />
        <SelectArrayInput
            fullWidth={true}
            alwaysOn
            source="site"
            label="Search in site"
            choices={window.sitesChoices}
            parse={(val) => (val ? val.join("||") : "")}
            format={(val) => (val ? val.split("||") : [])}
        />
        <SelectInput
            source="type"
            choices={[
                { name: "Normal", id: "normal" },
                { name: "Conditional", id: "conditional" },
            ]}
            defaultValue={"normal"}
            resettable
        />
    </Filter>
);

export default withStyles(styles)(ListFilter);
