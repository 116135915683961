import * as React from "react";
import Chip from "@material-ui/core/Chip";

const Chips = ({
  onClickChip,
  chipsData = [],
  clickable = false,
  deletable = true,
  data,
  chipsKey,
  ...rest
}) => {
  const handleOnClickChip = React.useCallback(
    () => onClickChip(data, chipsKey),
    [data]
  );

  return (
    <Chip
      clickable={clickable}
      deletable={deletable}
      onClick={handleOnClickChip}
      label={data}
      {...rest}
    />
  );
};
export default Chips;
