/* eslint-disable no-eval */
import React, { Fragment } from "react";
import { Field, FieldArray } from "redux-form";
import * as _ from "lodash";
import { TextInput, SelectInput, BooleanInput, NumberInput, SelectArrayInput, FormDataConsumer } from "react-admin";
import { Paper, Typography, Divider } from "@material-ui/core";
import SolrFields from "./SolrFields";
import PublishersInput from "../PublishersInput";
import Grid from "../Grid";
import { LoopQuery, MoreQuery, getSolrQueryComponent } from "./CustomSolrQueryFields";


const publishersInput = (props) => {
    const _props = {
        ...props,
        options: { listStyle: { maxHeight: 400, overflow: "auto" } },
    };
    return <PublishersInput {..._props} />;
};

export const QueryFields = ({ origin, source, template = "", sourceApi = "", showAdvancedSolrFilters = true }) => {

    return (
        <Fragment>
            <Grid columns="3">
                {["cross_site", "more_like_this"].includes(template) === false &&
                    ["custom_query"].includes(sourceApi) && (
                        <SelectInput source={`${source}.site`} label="Site" resettable choices={window.sitesChoices} />
                    )}
                {(["cross_site", "more_like_this"].includes(template) === true ||
                    sourceApi === "syndication_api_endpoints") && (
                    <Fragment>
                        <Field label="Publishers" name={`${source}.publishers`} component={publishersInput} />
                        <SelectArrayInput source={`${source}.query`} label="Query" choices={window.queryChoices} />
                    </Fragment>
                )}
            </Grid>

            <FormDataConsumer>
                {({ formData }) => {
                    const currentWidgetData = _.get(formData, source);
                    const booleanStyle = { display: "inline", marginLeft: 10 };

                    const loopQueryParamsFilled = currentWidgetData.loop_query_params && currentWidgetData.loop_query_params.length > 0;
                    const moreQueryParamsFilled = currentWidgetData.more_query_params && currentWidgetData.more_query_params.length > 0;
                    // when we have more_query_params filled, loop query params shouldnt be available. same for reversed situation

                    return (
                        <div>
                            { !moreQueryParamsFilled &&
                                getSolrQueryComponent(LoopQuery, source)
                            }
                            <Paper style={{ padding: 20 }}>
                                <Typography variant="subheading">General Solr Filters</Typography>
                                <FieldArray
                                    name={`${source}.solr`}
                                    component={SolrFields}
                                    options={window.fcsisSearchFields}
                                />

                                <Divider style={{ margin: "40px 15px" }} />
                                <Typography variant="subheading">Sorting and Document Count</Typography>
                                <Grid columns={3} >
                                {currentWidgetData.sortBy && !currentWidgetData.sortBy.toLowerCase().includes('date') ?
                                    <Typography variant="body1" style={{ alignSelf: 'center'}}>
                                                Can't sort by schedule update date if sort by isn't a date
                                    </Typography>
                                    :
                                    <BooleanInput
                                        source={`${source}.sort_by_schedule_update_date`}
                                        style={{ display: "flex", justifyContent: "center", height: "100%"}}
                                        label="Sort by schedule update date"
                                        defaultValue={false}
                                    /> 
                                }
                                    
                                    <SelectInput
                                        source={`${source}.sortBy`}
                                        label="Sort By"
                                        choices={window.sortByChoices}
                                        onChange={() => currentWidgetData.sort_by_schedule_update_date = false}
                                    />

                                    <SelectInput
                                        source={`${source}.sortDir`}
                                        label="Sort Dir"
                                        choices={window.sortDirChoices}
                                    />
                                </Grid>

                                <Grid columns={2}>
                                    <NumberInput source={`${source}.items_per_page`} label="Items per page" />
                                    <TextInput source={`${source}.page`} label="Page" />
                                    <TextInput source={`${source}.rows`} label="Rows" />
                                    <TextInput source={`${source}.start`} label="Start" />
                                </Grid>

                                <Divider style={{ margin: "40px 15px" }} />
                                { showAdvancedSolrFilters && <>
                                    <Typography variant="subheading">
                                        Grouping
                                        <BooleanInput
                                            source={`${source}.group`}
                                            defaultValue={false}
                                            label={""}
                                            style={booleanStyle}
                                        />
                                    </Typography>
                                    {currentWidgetData.group && (
                                        <Grid columns={3}>
                                            <TextInput source={`${source}.group_field`} label="widget.group_field" />
                                            <TextInput source={`${source}.group_sort`} label="widget.group_sort" />
                                            <NumberInput source={`${source}.group_limit`} label="widget.group_limit" />
                                        </Grid>
                                    )}

                                    <Divider style={{ margin: "40px 15px" }} />
                                    <Typography variant="subheading">
                                        Facets
                                        <BooleanInput
                                            source={`${source}.facet`}
                                            label={""}
                                            style={booleanStyle}
                                            defaultValue={false}
                                        />
                                    </Typography>
                                    {currentWidgetData.facet && (
                                        <Grid columns={4}>
                                            <TextInput source={`${source}.facet_field`} label="widget.facet_field" />
                                            <TextInput source={`${source}.facet_sort`} label="widget.facet_sort" />
                                            <NumberInput source={`${source}.facet_limit`} label="widget.facet_limit" />

                                            <SelectInput
                                                source={`${source}.facet_date_range`}
                                                label="Facet date range field"
                                                resettable
                                                choices={window.sortByChoices}
                                                validate={function (values, props) {
                                                    if (!values) return;

                                                    var rangeStart = eval(`props.${source}.facet_date_range_start`);
                                                    var rangeEnd = eval(`props.${source}.facet_date_range_end`);
                                                    var rangeGap = eval(`props.${source}.facet_date_range_gap`);

                                                    if (!rangeStart) {
                                                        return "Facet start date is required !";
                                                    }

                                                    if (!rangeGap) {
                                                        return "Facet date gap is required !";
                                                    }

                                                    if (rangeStart && rangeEnd) {
                                                        var indexStart = window.dateFilterExtendedChoices.findIndex(
                                                            (p) => p.id === rangeStart
                                                        );
                                                        var indexEnd = window.dateFilterExtendedChoices.findIndex(
                                                            (p) => p.id === rangeEnd
                                                        );
                                                        if (indexEnd >= indexStart) {
                                                            return "Facet date start should be prior to facet date end !";
                                                        }
                                                    }

                                                    return;
                                                }}
                                            />

                                            <SelectInput
                                                source={`${source}.facet_date_range_start`}
                                                label="Facet date start*"
                                                resettable
                                                required
                                                choices={window.dateFilterExtendedChoices}
                                            />
                                            <SelectInput
                                                source={`${source}.facet_date_range_end`}
                                                label="Facet date end (default NOW)"
                                                resettable
                                                choices={window.dateFilterExtendedChoices}
                                            />
                                            <SelectInput
                                                source={`${source}.facet_date_range_gap`}
                                                label="Facet date gap"
                                                resettable
                                                choices={[
                                                    { id: "DAY", name: "Day" },
                                                    { id: "MONTH", name: "Month" },
                                                    { id: "YEAR", name: "Year" },
                                                ]}
                                            />
                                        </Grid>
                                    )}

                                    <Divider style={{ margin: "40px 15px" }} />
                                    <Typography variant="subheading">
                                        Custom Search String
                                        <BooleanInput
                                            source={`${source}.custom_search`}
                                            label={""}
                                            style={booleanStyle}
                                            defaultValue={false}
                                        />
                                    </Typography>
                                    {currentWidgetData.custom_search && (
                                        <>
                                            <Grid columns={2}>
                                                <TextInput
                                                    source={`${source}.custom_search_placeholder`}
                                                    label={"Custom search string placeholder"}
                                                />
                                                <TextInput
                                                    source={`${source}.custom_search_fields`}
                                                    label={"Custom search fields"}
                                                />
                                                <TextInput
                                                    source={`${source}.custom_filter_query`}
                                                    label="widget.custom_filter_query"
                                                />
                                                <TextInput source={`${source}.custom_sort`} label="widget.custom_sort" />
                                            </Grid>
                                            {origin && origin === "timeline" && (
                                                <Grid columns={4}>
                                                    <NumberInput
                                                        source={`${source}.custom_height`}
                                                        label="Custom widget height"
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                    { !loopQueryParamsFilled &&
                                        getSolrQueryComponent(MoreQuery, source)
                                    }
                                </>}

                            </Paper>
                        </div>
                    );
                }}
            </FormDataConsumer>
        </Fragment>
    );
};

export default QueryFields;
