import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {showNotification as showNotificationAction,} from 'react-admin';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '../components/IconButton';
import IconSend from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import "./CloneLocale.css";
import Cookies from "js-cookie";

const styles = theme => ({
    block: {
        display: "inline-block",
        verticalAlign: "middle",
        width: "100%",
        height:"100%",
        position: "absolute",
    },
    progress:{
        verticalAlign:"middle",
    }
});

const pStyle = {fontSize: 17, textAlign: "center"};

class CopyToProd extends Component {

    state = {
        message: null,
        error: null,
        showDialog: false,
        isLoading: false,
        override: false,
        showCopyButton: true,
        dataLink: null,
        editLink: null,
    };

    copyDocumentToProd() {
        const { resource, record, showNotification } = this.props;
        this.setState({ isLoading: true, error: null, message: null, dataLink: null, editLink: null });

        if (localStorage.getItem('user')) {
            record.modified_by = localStorage.getItem('user').toUpperCase();
        }

        let requestConfig = {
            headers: {
                Authorization: Cookies.get('token'),
                Accept: 'application/json',
                'X-Role': localStorage.getItem('role'),
                'X-ExpiresIn': localStorage.getItem('expiresIn'),
                'X-User-Name': localStorage.getItem('user'),
            },
            method: 'POST',
            body: JSON.stringify(record),
        };

        let url = `/api/copy-to-prod/${resource}/${record.guid}/${this.state.override}`;

        fetch(url, requestConfig)
            .then((response) => {
                return response.json().then((data) => {
                    return  data;
                });
            }).then((data) => {
                if (409 === data.status) {
                    this.setState({ isLoading: false, error: data.message, override: true, message: null, dataLink: null, editLink: null});
                    return true;
                }
                if (200 === data.status) {
                    this.setState({
                        isLoading: false, message: data.message, override: false,
                        dataLink: data.dataLink, editLink: data.editLink, showCopyButton: false
                    });
                    return true;
                }

                this.setState({ isLoading: false, error: data.message, override: false, message: null, dataLink: null, editLink: null});
                showNotification(`${data.message}`, "warning");
            }).catch((error) => {
                console.log('ERROR', error);
                showNotification(`${error}`, "warning");
            });
    }

    handleClick = () => {
        this.setState({ showDialog: true, error: null, message: null, override: false, dataLink: null, editLink: null, showCopyButton: true});
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false, error: null, message: null, override: false, dataLink: null, editLink: null});
    };

    render() {
        const { classes } = this.props;
        const { isLoading, override, showDialog, showCopyButton } = this.state;
        const title = "COPY DOCUMENT TO PROD";

        return (
            <Fragment>
                <IconButton
                    color="primary" icon={iconClasses => <IconSend className={iconClasses.leftIcon} />} onClick={() => this.handleClick()}>
                    Copy
                </IconButton>
                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label={title}>
                    <DialogTitle style={{textAlign: 'center'}}>{title}</DialogTitle>
                    <DialogContent>
                        {isLoading &&
                            <div style={{marginTop: 10, textAlign: "center"}}>
                                <CircularProgress className={classes.progress} size={20} thickness={2} />
                            </div>
                        }

                        {(null !== this.state.error) && <p style={{...pStyle, color: 'red'}}>{this.state.error}</p>}
                        {(null !== this.state.message) && <p style={{...pStyle, color: 'green'}}>{this.state.message}</p>}

                        {(null !== this.state.dataLink) &&
                            <div style={{...pStyle, color: 'blue'}}>Data Link: <a href={this.state.dataLink} target="_blank" rel="noopener noreferrer">{this.state.dataLink}</a></div>
                        }

                        {(null !== this.state.editLink) &&
                            <div style={{...pStyle, color: 'blue', marginTop: 12}}>Edit Link: <a href={this.state.editLink} target="_blank" rel="noopener noreferrer">{this.state.editLink}</a></div>
                        }

                        {false === override &&
                            <div style={{marginTop: 10, textAlign: "center"}}>
                                {(true === showCopyButton) &&
                                    <IconButton
                                        color="primary" icon={iconClasses => <IconSend className={iconClasses.leftIcon}/>}
                                        onClick={() => this.copyDocumentToProd()}
                                    >
                                        Copy To Prod
                                    </IconButton>
                                }

                                <IconButton
                                    color="secondary" icon={iconClasses => <IconCancel className={iconClasses.leftIcon} />}
                                    onClick={() => this.handleCloseClick()}
                                >
                                    Close
                                </IconButton>
                            </div>
                        }

                        {true === override &&
                            <div style={{marginTop: 10, textAlign: "center"}}>
                                <div>
                                    <IconButton
                                        color="primary" icon={iconClasses => <IconSend className={iconClasses.leftIcon}/>}
                                        onClick={() => this.copyDocumentToProd()}
                                    >
                                        YES Override The Prod document
                                    </IconButton>

                                    <IconButton
                                        color="secondary"
                                        icon={iconClasses => <IconCancel className={iconClasses.leftIcon}/>}
                                        onClick={() => this.handleCloseClick()}
                                    >
                                        Close
                                    </IconButton>
                                </div>
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    }
}

export default connect(null, { showNotification: showNotificationAction })(withStyles(styles)(CopyToProd));
