import { useIsSubbrand, getInheritedSites } from "./dataFetcher";
import { styles } from "../Entities/EditActions";

export const getSiteBadges = async (data, basePath) => {
  const subBrandsBadges = getSubbrandBadges(data, basePath);
  const internationalizationBadges = getInternationalizationBadges(
    data,
    basePath
  );
  const inheritanceBadges = await getinheritanceBadges(data, basePath);
  return [
    ...(subBrandsBadges || []),
    ...(internationalizationBadges || []),
    ...(inheritanceBadges || []),
  ];
};

const getSubbrandBadges = (data, basePath) => {
  let badges = [];

  if (data) {
    //get sub-brands for mastersite
    const subbrands = getSiteSubbrands(data, basePath);
    if (subbrands.length) {
      const links = [];
      subbrands.forEach((brand) => {
        if (brand !== "null") {
          links.push({ linkUrl: `#${basePath}/${brand}`, linkText: brand });
        }
      });
      if (links.length) {
        badges.push({
          styles: {
            badgeStyles: styles.subBrandBadge,
            linkStyles: styles.subBrandBadge,
          },
          description: "Existing Sub-brands: ",
          links,
        });
      }
    }

    //get master-site for sub-brands
    const parentBrandId = getParentBrandID(data, basePath);
    if (parentBrandId) {
      badges.push({
        styles: {
          badgeStyles: styles.subBrandBadge,
          linkStyles: styles.subBrandBadge,
        },
        description: "Is sub-brand of site: ",
        links: [
          {
            linkText: parentBrandId,
            linkUrl: `#${basePath}/${parentBrandId}`,
          },
        ],
      });
    }
  }
  return badges.slice();
};

const getInternationalizationBadges = (data, basePath) => {
  let badges = [];
  if (data) {
    //get internationalization badge for master site
    const intlSites = getIntlVersions(data, basePath);
    if (intlSites.length) {
      const links = [];
      intlSites.forEach((region) => {
        if (region.link) {
          const link = region.link === "uk" ? "gb" : region.link;
          const slug = `${data.site}_${region.language.split("-")[0]}_${link}`;
          links.push({
            linkText: slug,
            linkUrl: `#${basePath}/${slug}`,
          });
        }
      });
      if (links.length) {
        badges.push({
          styles: {
            badgeStyles: styles.internationalizedBadge,
            linkStyles: styles.internationalizedBadge,
          },
          description: "There is an internationalized version at: ",
          links,
        });
      }
    }
    //get master site internationalization for sub-sites
    const intlMasterSites = getParentIntlSiteID(data, basePath);

    if (intlMasterSites) {
      badges.push({
        styles: {
          badgeStyles: styles.internationalizedBadge,
          linkStyles: styles.internationalizedBadge,
        },
        description: "Internationalized version of: ",
        links: [
          {
            linkText: intlMasterSites,
            linkUrl: `#${basePath}/${intlMasterSites}`,
          },
        ],
      });
    }
  }
  return badges;
};

const getinheritanceBadges = async (data, basePath) => {
  let badges = [];

  if (data) {
    //get inherited site for mastersite
    const inheritedSites = await getInheritedSiteIDs(data, basePath);

    if (inheritedSites) {
      const links = [];

      if (inheritedSites && inheritedSites.length) {
        inheritedSites.forEach((site) => {
          links.push({
            linkText: site.slug,
            linkUrl: `#${basePath}/${site.slug}`,
          });
        });
        badges.push({
          styles: {
            badgeStyles: styles.clonedBadge,
            linkStyles: styles.clonedBadge,
          },
          description:
            "The following sites inherit from the current site config ",
          links,
        });
      }
    }

    //get master site of inherited sites
    const inheritedParentId = getParentOfInheritedSite(data, basePath);
    if (inheritedParentId) {
      badges.push({
        styles: {
          badgeStyles: styles.clonedBadge,
          linkStyles: styles.clonedBadge,
        },
        description: "This site config inherits from: ",
        links: [
          {
            linkText: inheritedParentId,
            linkUrl: `#${basePath}/${inheritedParentId}`,
          },
        ],
      });
    }
  }
  return badges;
};

////////////////////////BADGES DETAILS ////////////////////////////
export const getSiteSubbrands = (data) => {
  return (data.subbrands || []).slice();
};

export const getParentBrandID = (data, basePath) => {
  if (
    data.master_site &&
    !data.master_site.startsWith(data.site_id) &&
    useIsSubbrand(data.master_site, data.id)
  ) {
    return data.master_site;
  }
  return "";
};

export const getIntlVersions = (data, basePath) => {
  if (data.is_master && data.misc && data.misc.custom) {
    const internationaliationFlag = data.misc.custom.find(
      (item) => item.key === "enableSiteL10nsLite"
    );

    const isInternatinalized = internationaliationFlag
      ? !internationaliationFlag.value
      : true;
    if (isInternatinalized) {
      const locales = data.misc.custom.find((item) => item.key === "siteL10ns");
      if (locales) {
        const regions = JSON.parse(locales.value).filter(
          (locale) => !locale.link.includes("/")
        );
        const links = regions.filter((region) => !!region.link);
        return links.slice();
      }
    }
  }
  return [];
};

export const getParentIntlSiteID = (data, basePath) => {
  if (data.master_site && data.master_site.startsWith(data.site_id)) {
    return data.master_site;
  }
  return "";
};

export const getInheritedSiteIDs = async (data, basePath) => {
  if (!data.parent_site_guid) {
    const inheritedSites = await getInheritedSites(data.slug);
    return inheritedSites.slice();
  }

  return [];
};

export const getParentOfInheritedSite = (data, basePath) => {
  return data.parent_site_guid || "";
};
