import React, { /* PureComponent, */ useCallback, useEffect, useState } from "react";
import { Labeled } from "react-admin";
import { SingleSelect } from "react-select-material-ui";
import { withStyles } from "@material-ui/core/styles";
import { theme } from "../Admin";
import PropTypes from 'prop-types';
import CopyButton from "./CopyButton";
/* use case in parent
<Field 
    label={"Http Code"}
    name="redirect_http_code"
    component={SingleSelectWithCustom}
    options={["301","302","303","304","305","306","307","308","403","404","410"]}
    disabled={!formData.redirect}
    validate={value=>(isInvalidCodeHttp(value))} //validation from tools.js
    data={formData} //data from FormDataConsumer
/>
*/
const styles = (currenTheme) => ({
  root: {
    fontFamily: currenTheme.typography.fontFamily,
    color: currenTheme.palette.text.primary,
  },
  error: {
    fontFamily: currenTheme.typography.fontFamily,
    color: currenTheme.palette.error.main,
  },
  selectInputContainer: {
    display: "flex", gap: "10px", alignItems:"center"
  }
});

const disabledStyle = (state) => {
  const opacity = state ? 0.5 : 1;
  const transition = "opacity 300ms";
  return { opacity, transition };
};
//https://react-select.com/styles#style-object
const customStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: 16,
    ...disabledStyle(state.isDisabled),
  }),
  menuList: (provided) => ({
    ...provided,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: 16,
  }),
  // placeholder:()=>({color:"blue"}),
  menu: (provided) => ({ ...provided, height: 'fit-content', zIndex: 50 }),
  clearIndicator: (provided) => ({
    ...provided,
    color: theme.palette.secondary.main,
  }),
};

const renderHelper = (label, classes, invalid, disabled) => (
  <span
    style={disabledStyle(disabled)}
    className={!invalid ? classes.root : classes.error}
  >
    {!invalid ? `Select or write a new ${label}` : `It is an invalid ${label}`}
  </span>
);
const SingleSelectWithCustom = ({
    data,
    input: { value ,name, onChange},
    label = '',
    disabled,
    classes,
    meta: { invalid = false },
    helper,
    currentValue,
    customStyle = customStyles,
    isClearable = false,
    options : optionSingleSelect,
    onChangeSelect,
    canCopy = false
  }) => {

    //diffs options found with options already used

    const [options, setOptions] = useState(value ? [...new Set([...optionSingleSelect, value])] : optionSingleSelect)
    const [inputValue, setInputValue] = useState(value ? value : currentValue ? currentValue : data ? data[name] : undefined)
    const handleChange = useCallback(
      (selectedValue) => {
        onChangeSelect && onChangeSelect(selectedValue)
        onChange(selectedValue);
        setInputValue(selectedValue);
      },
      [inputValue],
    )

    //update options when option are async loaded.
    useEffect(() => {
        setOptions(optionSingleSelect)
    },[optionSingleSelect]);

    return (
      <>
      <Labeled label={label}>
        <div className={classes.selectInputContainer}>
      {canCopy && <CopyButton copyValue={inputValue}/>}

        <SingleSelect
            value={inputValue}
            options={options}
            helperText={
              helper
                ? helper
                : renderHelper(label.toLowerCase(), classes, invalid, disabled)
            }
            onChange={handleChange}
            SelectProps={{
              styles: customStyle,
              isCreatable: true,
              isClearable: isClearable, //TODO :bug with clearable, double clear necessary, ok when value = formData[input.name] but issue with getSource(formData,input.name)
              msgNoOptionsAvailable: `All ${label.toLowerCase()} are selected`,
              msgNoOptionsMatchFilter: `${label.toLowerCase()} already existing`,
              msgNoValidValue: `Not a valid ${label.toLowerCase()}`,
            }}
            disabled={disabled}
          />
        </div>
         
        </Labeled>
      </>
        
      );
}

SingleSelectWithCustom.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

export default withStyles(styles)(SingleSelectWithCustom);
