import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";

const styles = (theme) => ({
    boxWrapper: {
      position: "absolute",
      padding: 15,
      border: "1px solid #333",
      backgroundColor: "#FFF",
      zIndex: 1,
      marginLeft: -200,
      width: 350,
    },
    input: {
      marginTop: 5,
      marginBottom: 15,
    },
    box: {
      display: "block",
      '& > *': {
        width: "100%",
      },
    },
    button: {
      width: "50%",
      backgroundColor: "#4051B6",
      display: "block",
      margin: "auto",
      "&:hover": {
        backgroundColor: "#4051B6",
        opacity: 0.8,
      },
      "&:disabled": {
        backgroundColor: "grey",
        opacity: 0.8,
      },
      color: "#fff",
    },
  });

class CloneCustomRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedSiteValue: "",
          newGuidValue: "",
          showInput:this.props.showInput || false,
        };
    
        this.handleSelectedSiteChange = this.handleSelectedSiteChange.bind(this);
      }

      handleSelectedSiteChange = (e) => {
    
        this.setState({
          selectedSiteValue: e.target.value,
          showInput: true,
        });

      };

      render() {
        const {
            selectedSiteValue,
            showInput,
          } = this.state;

          const {record,classes,cbHandleClone} = this.props;

          return (
            <Fragment>
            {showInput && <div
                id={`${record.id}-clone-custom-route`}
                className={classes.boxWrapper}
              >
                <div className={classes.input}>
                  <InputLabel htmlFor={`${record.id}-clone-custom-route-site-param`}>
                    Target Site
                  </InputLabel>
                  <Select
                    disabled={this.state.withTimestamp}
                    className={classes.box}
                    id={`${record.id}-clone-custom-route-site-param`}
                    value={selectedSiteValue}
                    onChange={this.handleSelectedSiteChange}
                  >
                    {[{ id: "", name: "None" }, ...window.sitesChoices].map(
                      (site, index) => (
                        <MenuItem
                          key={`${record.id}-clone-custom-route-site-param-${index}`}
                          className={`clone-parameters`}
                          value={site.id}
                        >
                          {site.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
                <Button
                  onClick={()=>cbHandleClone(record.guid,selectedSiteValue)}
                  className={classes.button}
                  disabled={!selectedSiteValue}
                >
                  Let's clone
                </Button>
              </div>
            }
            </Fragment>
          );
  }
}

export default withStyles(styles)(CloneCustomRoute);

