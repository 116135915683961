import React, { Component } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import { FunctionField, Labeled } from 'react-admin';

import Grid from '../Grid';
import CustomRouteHeaderSummary from "./CustomRouteHeaderSummary";
import TabContainer from "../WidgetListInput/TabContainer";
import {SummaryFieldRender} from "../../Entities/CustomRoutes/Show";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appBar:{
        background:theme.palette.secondary.main
    }
});

class MyCard extends Component {
    constructor(props) {
        super(props);
        this.state = {value: 0};
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { value } = this.state;

        return value !== nextState.value;
    }

    handleTabChange = (e, value) => {
        this.setState({ value });
    };

    render() {
        const { classes, record } = this.props;
        const { value } = this.state;

        return (
            <ExpansionPanel defaultExpanded={initiallyExpanded(record)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <CustomRouteHeaderSummary sourceData={record}/>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className={classes.root}>
                        <AppBar position="static" className={classes.appBar}>
                            <Tabs value={value} onChange={this.handleTabChange}>
                                <Tab label="Summary" />
                                <Tab label="Routing Params" />
                                <Tab label="SSI Vars" />
                            </Tabs>
                        </AppBar>
                        {value === 0 && (
                            <TabContainer>
                                <SummaryFieldRender {...this.props} record={record}/>
                            </TabContainer>
                        )}
                        {value === 1 && (
                            <TabContainer>
                                <Labeled label="Route Params and Placeholders">
                                    <FunctionField label={" "} fullwidth="true" record={record} render={recordData => typeof recordData.other_routing_params === "undefined" ? '[]' :
                                        record.other_routing_params.map((item, index) => (
                                            <div key={index} style={{width: "100%", marginBottom: 10}}>
                                                <em>{item.key}</em>: <b>{item.value}</b>
                                            </div>
                                        ))
                                    }/>
                                </Labeled>
                                <br/>
                                <Labeled label="Fcsis Fields">
                                    <FunctionField label={" "} fullwidth="true" name="fcsis_routing_params" record={record} render={recordData => typeof recordData.fcsis_routing_params === "undefined" ? '[]' :
                                        record.fcsis_routing_params.map((item, index) => (
                                            <div key={index} style={{width: "100%", marginBottom: 10}}><em>{item.key}</em>: <b>{item.value}</b></div>
                                        ))
                                    }/>
                                </Labeled>
                                <FunctionField label={" "} fullwidth="true" name="fcsis_routing_params" record={record} render={recordData => <hr/>}/>
                            </TabContainer>
                        )}
                        {value === 2 && (
                            <TabContainer>
                                <Labeled label="SSI Vars Fields">
                                    <FunctionField label={" "} fullwidth="true" name="ssi_vars" record={record} render={recordData => typeof recordData.ssi_vars === "undefined" ? '[]' :
                                        record.ssi_vars.map((item, index) => (
                                            <div key={index} style={{width: "100%", marginBottom: 10}}><em>{item.key}</em>: <b>{item.value}</b></div>
                                        ))
                                    }/>
                                </Labeled>
                            </TabContainer>
                        )}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

const CustomRouteOverrideField = (props) => {
    const { record, permissions, classes } = props;

    return (
        <div>
            <Grid style={{ marginTop: '15px' }} columns="1" columnGap="20px" rowGap="20px">
                {record && record.overrides && record.overrides.length > 0 && record.overrides.map((item, index) =>
                    {
                        return (
                            <MyCard
                                key={`custom-route-${index}`}
                                index={index}
                                permissions={permissions}
                                record={item}
                                classes={classes}
                            />
                        );
                    }
                )}
            </Grid>
        </div>
    );
};

const initiallyExpanded = (record) => { return (!record || !record.lang || !record.territory); }

export default withStyles(styles)(CustomRouteOverrideField);
