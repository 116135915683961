import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';

const SettingsEdit = ({ classes,onCancel,...props }) => (
    <Edit undoable={false} {...props}>
        <EditForm permissions={props.permissions} onCancel={onCancel} />
    </Edit>
);

export default SettingsEdit;
