import React from 'react';
import { TextInput, NumberInput, BooleanInput } from 'react-admin';
import LinkListInput from './LinkListInput';

export default props => (
    <LinkListInput
        {...props}
        inputFields={[
            <TextInput source="title" label="Title" />,
            <NumberInput source="position" label="Position" />,
            <TextInput source="url" label="Url" />,
            <BooleanInput
                label="Open Link in New Window"
                source="target_blank"
            />,
        ]}
    />
);
