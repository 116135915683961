import React from 'react';
import Typography from '@material-ui/core/Typography';

import Grid from './Grid';

const ImageExample = (props) => {
    const { src, alt, className, classes, width } = props;
    return (
        <Grid classes={classes.groupSettings}>
            <Typography variant="p" gutterBottom>Preview</Typography>
            <div className={className}>
                <img style={{width: width}}          
                    src={src} 
                    alt={alt} 
                />
            </div>
        </Grid>
    );
};

export default ImageExample;
