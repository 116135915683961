import React from 'react';
import {
    DateField, TextField, SelectField, BooleanField, FunctionField, UrlField, SimpleShowLayout, TabbedShowLayout, Tab
} from 'react-admin';
import ChipsField from '../../components/ChipsField';
import Show from '../Show';
import CustomRouteOverrideField from "../../components/CustomRoute/CustomRouteOverrideField";

export const SummaryFieldRender = (props) => {
    return (
        <SimpleShowLayout record={props.record}>
            {!props.fromIndex && <SelectField source="lang" label={"Language"} choices={window.sitesLangChoices}/>}
            {!props.fromIndex && <SelectField source="territory" label={"Territory"} choices={window.sitesTerritoryChoices}/>}

            <TextField source="uri" label="URI" addLabel={true}/>
            <SelectField source="target" label="Target" choices={window.customRouteOrigin}/>
            <TextField source="flexi_layout_id" label="Flexi Layout Id"/>
            <BooleanField source="transmit_original_canonical" label="Transmit Original Canonical" defaultValue={false} />
            <BooleanField source="catch_all" label="Catch all" defaultValue={false} />
            <BooleanField source="redirect" label="Redirect" />
            <UrlField source="redirect_target_url" />
            <FunctionField label={"Redirect Http Code"} fullwidth="true" render={record => <ChipsField record={record} source={"redirect_http_code"} />} />
            <BooleanField source="reverse_proxy" label="Reverse Proxy" />
            <UrlField source="proxy_target_url" />
        </SimpleShowLayout>
    );
}

const CustomRouteShow = props => (
    <Show title="Custom Route show" {...props}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="slug" />
                <TextField source="name" />
                <SelectField source="site" choices={window.sitesChoices} />
                <DateField source="date" />
                <DateField source="published_date" />
                <TextField source="modified_by" />
                <SummaryFieldRender fromIndex={true}/>
            </Tab>
            <Tab label="Routing Params">
                <FunctionField label={"Route Params and Placeholders"} fullwidth="true" name="other_routing_params" render={record => typeof record.other_routing_params === "undefined" ? '[]' :
                    record.other_routing_params.map((item, index) => (
                        <div key={index} style={{width: "100%", marginBottom: 10}}>
                            <em>{item.key}</em>: <b>{item.value}</b>
                        </div>
                    ))
                }/>
                <FunctionField label={"Fcsis Fields"} fullwidth="true" name="fcsis_routing_params" render={record => typeof record.fcsis_routing_params === "undefined" ? '[]' :
                    record.fcsis_routing_params.map((item, index) => (
                        <div key={index} style={{width: "100%", marginBottom: 10}}>
                            <em>{item.key}</em>: <b>{item.value}</b>
                        </div>
                    ))
                }/>
                <FunctionField label={" "} fullwidth="true" name="fcsis_routing_params" render={record =>
                    <hr/>
                }/>
            </Tab>
            <Tab label="SSI Vars">
                <FunctionField label={"SSI Vars Fields"} fullwidth="true" name="ssi_vars" render={record => typeof record.ssi_vars === "undefined" ? '[]' :
                    record.ssi_vars.map((item, index) => (
                        <div key={index} style={{width: "100%", marginBottom: 10}}>
                            <em>{item.key}</em>: <b>{item.value}</b>
                        </div>
                    ))
                }/>
            </Tab>
            <Tab label="Overrides">
                <CustomRouteOverrideField source="overrides" permissions={props.permissions}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default CustomRouteShow;
