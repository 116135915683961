import React from "react";
import { FormDataConsumer } from "react-admin";

import SliceWidgetInput from "../SliceWidgetInput";

const FieldsScreen = ({ source }) => {
    // Getting value from squared brackets since it is a string,
    // and cant get the value otherwise
    // Todo: check this if it is right;
    const currentWidget = source.match(/\[(.*?)\]/)[1];

    return (
        <>
            <h2>Slice style setup</h2>
            <div style={{ marginBottom: 20 }}>
                This panel will allow you to style your slice.
                <strong> At the moment ONLY hex colours are allowed</strong>
            </div>

            <FormDataConsumer>
                {({ formData, record }) => {
                    const template = formData ? formData.widgets[currentWidget].slice_component : null;

                    return (
                        <SliceWidgetInput
                            template={template}
                            currentWidget={currentWidget}
                            source={source}
                            record={record}
                            jsonType="variables"
                            key={template}
                        />
                    );
                }}
            </FormDataConsumer>
        </>
    );
};

export default FieldsScreen;
