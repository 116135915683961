import React from 'react'
import { TextInput, SelectInput, BooleanInput, FormDataConsumer, REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form'
import CardExpanded from '../CardExpanded'

export const stores = [{ id: "riak", name: "Riak" }, { id: "arango", name: "Arango" }, { id: "mysql", name: "MySql" }];

const GlobalConfig = ({ source, ...props }) => {
    return (
        <CardExpanded columns={2} {...props}>
            <FormDataConsumer>
                {({ formData, dispatch,classes, ...rest }) => (
                    <>
                        <TextInput source={`${source}.name`} resettable label={"Name (site_id)"} defaultValue={formData && formData.site_id} {...rest}></TextInput>
                        <TextInput source={`${source}.label_name`} resettable label={"Label name"} defaultValue={formData && formData.name} {...rest}></TextInput>
                        <SelectInput choices={stores}
                            required
                            onChange={(e, value) => dispatch(change(REDUX_FORM_NAME, `${source}.features.copy_images_across_websites`, value === 'arango' ? false : true))}
                            defaultValue={"riak"}
                            source={`${source}.datastore`}
                            resettable
                            label={"Data Store"}
                        >
                        </SelectInput>
                        <BooleanInput label="Amp" source={`${source}.amp`} defaultValue={true} />
                        <BooleanInput label="Show in CMS UI" source={`${source}.show_in_cms`} defaultValue={false} />
                        <BooleanInput label="HTTPS Protocol" source={`${source}.https`} defaultValue={true} />
                        <TextInput style={{ gridColumn: "1 / 2 span" }} helperText={"ex: www.marieclaire.com"} source={`${source}.domain`} resettable label={"Domain"} {...rest}></TextInput>
                        <TextInput style={{ gridColumn: "1 / 2 span" }} helperText={"ex: www.marieclaire.com"} source={`${source}.redirect_domain`} resettable label={"Redirect Domain"} {...rest}></TextInput>
                        <TextInput style={{ gridColumn: "1 / 2 span" }} helperText={"ex: www.marieclaire.com"} source={`${source}.global_domain`} resettable label={"Global Domain"} {...rest}></TextInput>
                    </>
                )}
            </FormDataConsumer>
        </CardExpanded>
    )
}

GlobalConfig.defaultProps = {
    defaultexpanded: "true",
}

export default GlobalConfig;

