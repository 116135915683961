import React from "react";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

const styles = () => ({
  copyIcon: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6
    }
  },
});

function CopyButton({ copyValue, classes, customStyles }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      handleClose();
    }, 700);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography style={{ padding: "5px 10px" }}>
          Copied to clipboard
        </Typography>
      </Popover>
      <FilterNoneIcon
        aria-describedby={id}
        className={classes.copyIcon}
        style={customStyles}
        onClick={async (event) => {
          handleClick(event);
          await navigator.clipboard.writeText(copyValue);
        }}
      />
    </div>
  );
}

export default withStyles(styles)(CopyButton);
