import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextInput, required as req } from "react-admin";
import { ColorInput } from "react-admin-color-input";

const styles = () => ({
    colorInput: {
        width: "100%",
    },
});

export const StringFieldComponent = ({
    pattern,
    propertyName = "",
    description,
    required = false,
    source,
    style = {},
    classes,
    disableAllFields,
}) => {
    if (pattern === "#([a-fA-F0-9]{3}){1,2}") {
        return (
            <ColorInput
                style={{ width: "100%" }}
                className={classes.colorInput}
                source={`${source}.${propertyName}`}
                label={description ? description : ""}
                disabled={disableAllFields}
            />
        );
    }

    return (
        <TextInput
            label={description ? description : ""}
            source={`${source}.${propertyName}`}
            style={style}
            disabled={disableAllFields}
            validate={required ? req() : null}
        />
    );
};

export const StringField = withStyles(styles)(StringFieldComponent);
