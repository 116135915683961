import React, { Fragment, Component } from "react";
import { Datagrid, TextField, EditButton, CardActions, CreateButton } from "react-admin";
import IconButton from "../../components/IconButton";
import LowPriority from "@material-ui/icons/LowPriority";
import DeleteButtonWithConfirmation from "../../components/DeleteButtonWithConfirmation";
import ListFilter from "./ListFilter";
import List from "../List";
import { Route } from "react-router";
import SettingsCreate from "./Create";
import SettingsEdit from "./Edit";
import { Drawer, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import CopyToProd from "../../components/CopyToProd";
import ChangelogField from "../../components/ChangelogField";

const styles = {
    drawerContent: {
        width: 800,
    },
    paper: {
        textAlign: "center",
    },
};

const SettingListActions = ({ filters, resource, showFilter, displayedFilters, filterValues, basePath }) => (
    <CardActions>
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
            })}
        <CreateButton basePath={basePath} />
    </CardActions>
);

const ChangeLogButton = ({ record, onClick }) => (
    <IconButton
        icon={(iconClasses) => <LowPriority className={iconClasses.leftIcon} />}
        onClick={() => onClick(record)}
        label={"Changelogs"}
        color="primary"
    >
        ChangeLog
    </IconButton>
);
class SettingList extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleGetLog = this.handleGetLog.bind(this);
        this.setRecordRoute = this.setRecordRoute.bind(this);
        this.state = { currentRecord: null };
    }

    handleClose = () => {
        this.props.history.push("/settings");
    };

    setRecordRoute = (record) => {
        const {
            history: { replace },
        } = this.props;
        replace({ pathname: `/settings/log/${record.id}`, state: { record: record } });
        this.setState({ currentRecord: record });
    };

    handleGetLog = (record) => {
        this.setRecordRoute(record);
    };

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            settingsList = [],
        } = this.props;
        /* current record doesn't exist in route if reloading location pathname */
        if (this.props.settingsList.length > 0 && this.props.settingsList.length !== prevProps.settingsList.length) {
            const record = settingsList.filter((setting) => setting.id === pathname.split("/").splice(-1)[0])[0];
            record && this.setRecordRoute(record);
        }
    }

    render() {
        const { resource, permissions, classes, settingsList, dispatch, ...rest } = this.props;
        return (
            <Fragment>
                <List
                    perPage={100}
                    resource={resource}
                    permissions={permissions}
                    filters={<ListFilter />}
                    sort={{ field: "name", order: "ASC" }}
                    actions={<SettingListActions />}
                    {...rest}
                >
                    <Datagrid>
                        <TextField source="guid" />
                        <TextField source="setting" />
                        {permissions && permissions.checkUserIsSuperAdmin() ? <EditButton /> : null}
                        {permissions &&
                        permissions.checkUserIsSuperAdmin() &&
                        permissions.checkUserCanCopyToProd(resource) ? (
                            <CopyToProd />
                        ) : null}
                        {permissions && permissions.checkUserIsSuperAdmin() ? (
                            <DeleteButtonWithConfirmation /* undoable={false} */ />
                        ) : null}
                        {permissions && permissions.checkUserIsSuperAdmin() ? (
                            <ChangeLogButton source="guid" onClick={this.handleGetLog} />
                        ) : null}
                    </Datagrid>
                </List>
                <Route path="/settings/create">
                    {({ match }) => (
                        <Dialog
                            maxWidth="md"
                            fullWidth={true}
                            open={!!match}
                            onClose={this.handleClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <SettingsCreate
                                resource={resource}
                                permissions={permissions}
                                onCancel={this.handleClose}
                                className={classes.paper}
                                {...rest}
                            />
                        </Dialog>
                    )}
                </Route>
                <Route path="/settings/log/:id">
                    {({ match }) => {
                        const isMatch = (match && match.params && match.params.id !== "create") || false;
                        return (
                            <Dialog
                                maxWidth={false}
                                fullWidth={true}
                                open={isMatch}
                                onClose={this.handleClose}
                                aria-labelledby="form-dialog-title"
                            >
                                <ChangelogField
                                    guid={isMatch ? match.params.id : null}
                                    record={this.state.currentRecord}
                                    resource={resource}
                                    onCancel={this.handleClose}
                                    {...rest}
                                />
                            </Dialog>
                        );
                    }}
                </Route>
                <Route path="/settings/:id">
                    {({ match }) => {
                        const isMatch =
                            (match && match.params && match.params.id !== "create" && match.isExact) || false;

                        return (
                            <Drawer open={isMatch} onClose={this.handleClose}>
                                {isMatch ? (
                                    <SettingsEdit
                                        resource={resource}
                                        permissions={permissions}
                                        className={classes.drawerContent}
                                        id={isMatch ? match.params.id : null}
                                        onCancel={this.handleClose}
                                        {...rest}
                                    />
                                ) : (
                                    <div className={classes.drawerContent} />
                                )}
                            </Drawer>
                        );
                    }}
                </Route>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    settingsList: state.settingsui,
});

export default connect(mapStateToProps)(withStyles(styles)(SettingList));
