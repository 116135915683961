import React, {useState, useCallback} from 'react'
import { change, FieldArray } from "redux-form"
import {
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  FormDataConsumer,
  REDUX_FORM_NAME,
  showNotification as showNotificationAction,
} from 'react-admin'
import { connect } from 'react-redux'
import EditToolbar from '../EditToolbar'
import ChangelogField from '../../components/ChangelogField'
import AceEditorInput from '../../components/AceEditorInput'
import Preview from '../../components/CustomWidgets/Preview'
import { required } from '../../components/CommonFields/CommonFieldsComponents'
import CardExpanded from '../../components/CardExpanded'
import Grid from '../../components/Grid'
import RichTextInput from 'ra-input-rich-text'
import { withStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/CheckCircle'
import { useReducer } from 'react'
import { removeTwigCode } from "../../Helpers/strings"
import {isValidJson} from '../../tools'
import * as prettier from "prettier";
import parserHtml from 'prettier/parser-html'
import parserCss from 'prettier/parser-postcss'
import MultipleSelectWithCustom from "../../components/MultipleSelectWithCustom";
import ChatBot from "../../components/CustomWidgets/ChatBot";
import ModalButton from "../../components/ModalButton";
import ViewColumn from "@material-ui/icons/ViewColumn";
import RelatedCustomWidgetDocuments from "../../components/CustomWidgets/RelatedCustomWidgetDocuments";

export const styles = {
  button: {
    transition: 'all .4s ease-in-out',
    margin: '10px 0',
    width: '110px',
    height: '36px',
    padding: '10px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    border: '0',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer'
  },
  buttonSuccess: {
    backgroundColor: 'green',
  },
  svg: {
    transition: 'all .4s ease-in-out',
    padding: '0',
    marginRight: '0',
    height: '36px',
    position: 'absolute',
    backgroundColor: '#3f51b5',
    top: '0',
    borderRadius: '5px',
    opacity: 0
  },
  svgSuccess: {
    opacity: 100,
    backgroundColor: 'green',
    marginLeft: '10px'
  },
  hideHelp: {
    '& > div:first-child': {
      width: '100%',
      marginTop: '10px',
      '& > div:first-of-type': {
        paddingTop:  0
      }
    },
    '& p.MuiFormHelperText-root': {
      display: 'none'
    }
  },
}

const previewData = (editorType, record) => {
  if (editorType) {
    switch (editorType) {
      case 'twig':
        return record.custom_twig_markup || ''
      case 'html':
        return record.custom_html_markup || ''
      case 'standard':
        return record.custom_text_markup || ''
      default:
        return ''
    }
  }
}

export const EditForm = ({fields= {}, classes, showNotification, ...props }) => {
  const [editorType, setEditorType] = useState(Object.keys(props.record || {}).indexOf('editor_type') > -1 ? props.record.editor_type : 'twig')
  const [previewHtml, setPreviewHtml] = useState(previewData(editorType, props.record || {}))
  const [css, setCss] = useState(Object.keys(props.record || {}).indexOf('custom_css_markup') > -1 ? props.record.custom_css_markup : '')
  const [tailwindCss, setTailwindCss] = useState(Object.keys(props.record || {}).indexOf('custom_tailwind_css_markup') > -1 ? props.record.custom_tailwind_css_markup : '')
  const [formatSuccess, setFormatSuccess] = useState({twig: false, css: false})
  const [edited, setEdited] = useReducer(reducer, {edited: false})
  const [endOfLine, setEndOfLine] = useState('lf')

  function reducer(state, newState) {
    if (newState.hasOwnProperty('edited')) {
      state.edited = newState.edited
    }
    return state
  }

  const handleMarkupChange = (e, html) => {
    setEdited({edited: true})
    setPreviewHtml(html)
  }

  const handleCssChange = (_, value) => setCss(value)

  const handleTailwindCss = () => {
    try {
      let tailwindCode = document.getElementById('preview-iframe').contentWindow.document.head.querySelector('style:last-child').innerHTML
      setTailwindCss(tailwindCode)
    } catch (e) {}

    return true
  }

  const handleEditorSwitch = (_, editor, record) => {
    setEditorType(editor)
    setPreviewHtml(previewData(editor, record))
  }

  const createUniqueAlpineKeys = (html = '', formData = {}) => {
    const uniqueKey = formData.guid
    const regex = /x-data="([^"]*)"/g
    const match = html.match(regex)

    // Now change all references to the text or keys that has been found in javascript functions for the x-data attribute
    if(match && match[0]) {
      for (let i = 0; i < match.length; i++) {
        const uniqueKeyMatch = html.match(uniqueKey)

        if(!isValidJson(match[i], true) && !uniqueKeyMatch) {
          let replaceBrackets = match[i].replace('x-data=','').replace(/[!@#$%^&*(),.?":{}|<>\d]/g, '')

          html = html.replace(new RegExp(replaceBrackets, 'g'), function (found, index) {
            if (found && !isValidJson(found, true) && !uniqueKeyMatch) {
              return uniqueKey + '_' + replaceBrackets
            }
          })
        }
      }
    }

    return removeAtSign(html)
  }

  const removeAtSign = (html = '') => {
    // Replace all @ sign references with x-on:
    const regex = /@[a-zA-Z0-9_]+=/g
    const match = html.match(regex)

    if(match && match[0]) {
      for (let i = 0; i < match.length; i++) {
        if(!isValidJson(match[i], true) && match[i].indexOf('import') <= -1){
          let word = match[i].replace('@','')
          html = html.replace(match[i], 'x-on:' + word);
        }
      }
    }

    return html
  }

  const handleFormatCode = (e, dispatch, source, parser = 'html', code = '', formData = null) => {
    e.preventDefault()
    // This line forces the prettier.format() method to update the code, which fixes the validation issue
    endOfLine === 'lf' ? setEndOfLine('crlf') : setEndOfLine('lf')
    try {
      let formatted = format(code, parser, endOfLine).then(formattedCode => {
        formatted = formattedCode
        switch(source) {
          case 'custom_twig_markup':
            formatted = createUniqueAlpineKeys(formatted, formData)
            setPreviewHtml(formatted)
            setFormatSuccess({...formatSuccess, ...{twig: true}})
            break
          case 'custom_css_markup':
            setCss(formatted)
            setFormatSuccess({...formatSuccess, ...{css: true}})
            break
          default:
        }
      }).then(() => {
        setEdited({edited: false})
        dispatch(change(REDUX_FORM_NAME, source, formatted))
        setTimeout(() => setFormatSuccess({...formatSuccess, ...{twig: false, css: false}}), 2000)
      })
    } catch(err) {
      console.log('err',err)
      showNotification(`${err}`, "warning");
    }
  }

  const format = async (code = '', parser = '') => {
    return await prettier.format(code, {
        parser: parser,
        useTabs: false,
        tabWidth: 2,
        bracketSameLine: true,
        htmlWhitespaceSensitivity: "strict",
        endOfLine: endOfLine,
        plugins: [parserHtml, parserCss]
      })
  }

  const validateEditor = (html = '') => {
    let errors = ''

    if (edited.edited && html.length > 0) {
      errors = 'Please make sure you click the format code button and observe any changes to your x-data attributes if you have any'
    } else if (html.length <= 0) {
      errors = 'Twig/HTML markup is required'
    }

    return errors
  }

  const getModalTitle = useCallback(
      (record) => `Page layouts using this widget: ${record.guid || 'GUID not found'}`,
      []
  )

  return (
    <TabbedForm
      submitOnEnter={false}
      redirect='edit'
      toolbar={<EditToolbar record={props.record} permissions={props.permissions} />}
      {...props}
    >
      <FormTab guid='twigmarkup' label='Twig Markup'>
        <Grid columns={3} width={'100%'}>
          <TextInput label='Name' name={`name`} source={`name`} required validate={[required]} fullWidth={true} />
          <SelectInput
            source={`editor_type`}
            label='Editor Type'
            choices={[
              { id: 'twig', name: 'HTML / Alpine / Tailwind / Twig Markup' }
              // we will need these options later on
              // { id: "html", name: "HTML / Alpine / Tailwind Markup" },
              // { id: "text", name: "Rich Text Editor" },
            ]}
            defaultValue={'twig'}
            onChange={(_, value) => handleEditorSwitch(_, value, props.record)}
          />
          <div className={classes.hideHelp}>
            <FieldArray
                label="Category Tags"
                name={`customwidget_tags`}
                source={'customwidget_tags'}
                component={MultipleSelectWithCustom}
                choices={window.customwidget_tags ? window.customwidget_tags.map(value => value.id) : []}
                helper=''
            />
          </div>
        </Grid>
        {props.permissions && props.permissions.checkUserCanCRUD("customwidgets", "read") && (
          <ModalButton
              IconElement={<ViewColumn />}
              getModalTitle={getModalTitle}
              labelButton={`Related page layouts where this widget is being used`}
              maxWidth={false}
              styles={'related'}
          >
            <RelatedCustomWidgetDocuments route={`api/customwidgets_related`} />
          </ModalButton>
        )}
        {editorType === 'twig' && (
          <CardExpanded columns={1} fullWidth={true} label={'HTML / Alpine / Tailwind / Twig Markup'} {...props}>
            <ChatBot {...props}/>
            <FormDataConsumer>
              {({ formData, dispatch }) => {
                return (
                    <button
                        className={formatSuccess.twig ? classes.button + ' ' + classes.buttonSuccess : classes.button}
                        onClick={(e) => handleFormatCode(e, dispatch, 'custom_twig_markup', 'html', formData.custom_twig_markup, formData)}
                    >Format Code <Check className={formatSuccess.twig ? classes.svg + ' ' + classes.svgSuccess : classes.svg}/></button>
                )
              }}
            </FormDataConsumer>
            <AceEditorInput
                source={`custom_twig_markup`}
                label={'Twig / HTML / Alpine / Tailwind Markup'}
                mode={'twig'}
                displayAceLabel={false}
                placeholder='<!-- Put your HTML + Alpine + Twig code here -->'
                minLines={10}
                maxLines={30}
                onChange={(e, html) => handleMarkupChange(e, html)}
                values={previewHtml || ''}
                validate={validateEditor}
            />
          </CardExpanded>
        )}
        {editorType === 'html' && (
          <CardExpanded columns={1} fullWidth={true} label={'HTML / Alpine / Tailwind Markup'}>
            <AceEditorInput
              source={`custom_html_markup`}
              label={'HTML / Alpine / Tailwind Markup'}
              mode={'html'}
              displayAceLabel={false}
              placeholder='<!-- Put your HTML here -->'
              minLines={10}
              maxLines={30}
              onChange={(_, value) => handleMarkupChange(_, value)}
              validate={[required]}
            />
          </CardExpanded>
        )}
        {editorType === 'text' && (
          <RichTextInput
            label={`Enter text below`}
            name={'custom_text_markup'}
            source={'custom_text_markup'}
            onChange={(_, value) => setTimeout(() => handleMarkupChange(_, value), 10)}
            toolbar={[
              [
                { font: [] },
                'bold',
                'italic',
                'underline',
                'blockquote',
                { align: [] },
                { list: 'ordered' },
                { list: 'bullet' },
                { direction: 'rtl' },
                { size: ['small', false, 'large', 'huge'] },
                { header: [1, 2, 3, 4, 5, 6, false] },
                { color: [] },
                { background: [] },
                'link',
                'code-block',
                { script: 'sub' },
                { script: 'super' },
                'clean'
              ]
            ]}
          />
        )}
        <br/>
        <CardExpanded columns={1} fullWidth={true} label={'CSS'} {...props}>
          <FormDataConsumer>
            {({ formData, dispatch })=> {
              return (
                  <>
                    <button className={formatSuccess.css ? classes.button + ' ' + classes.buttonSuccess : classes.button} onClick={(e) => handleFormatCode(e, dispatch, 'custom_css_markup', 'css', css)}>Format Code <Check className={formatSuccess.css ? classes.svg + ' ' + classes.svgSuccess : classes.svg}/></button>
                    <AceEditorInput
                      source={`custom_css_markup`}
                      label={'CSS'}
                      mode={'css'}
                      displayAceLabel={false}
                      placeholder='<!-- Put your CSS code here -->'
                      minLines={10}
                      values={formData.custom_css_markup || ''}
                      onChange={(_, value) => handleCssChange(_, value)} />
                  </>
              )
            }}
          </FormDataConsumer>
        </CardExpanded>
        <Preview html={(removeTwigCode(previewHtml || '')).trim()} css={css.trim()} handleTailwindCss={handleTailwindCss} fullWidth={true} />
        <div hidden={true}>
          {/*hidden to collect the tailwind css from the iframe*/}
          <FormDataConsumer>
            {({ formData }) => {
              if (formData) formData.custom_tailwind_css_markup = tailwindCss
              return (
                <AceEditorInput
                  source={`custom_tailwind_css_markup`}
                  label={'Tailwind CSS'}
                  mode={'html'}
                  displayAceLabel={false}
                  values={tailwindCss}
                />
              )
            }}
          </FormDataConsumer>
        </div>
      </FormTab>
      {props.permissions && props.permissions.checkUserCanCRUD("customwidgets", "read") && (
        <FormTab label='Changelogs'>
          <ChangelogField guid={props.record.guid} />
        </FormTab>
      )}
    </TabbedForm>
  )
}

export default connect(null, { showNotification: showNotificationAction })(withStyles(styles)(EditForm))