import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "../Grid";

const styles = theme => ({
      area: {
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 0,
        alignSelf: 'center',
        fontStyle: 'italic',
      },
      position: {
        fontSize: theme.typography.pxToRem(12),
        flexShrink: 0,
        fontStyle: 'italic',
        alignSelf: 'center',
        lineHeight:2
      },
      onerror: {
        verticalAlign:'middle',
        marginTop: '-3px',
        color: '#f39c12'
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 0,
        alignSelf: 'center',
        marginLeft: theme.spacing.unit * 4,
        width: 'max-content'
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        alignSelf: 'center',
        marginLeft: theme.spacing.unit * 4,
        marginRight: theme.spacing.unit
      }
});

const CustomRouteHeaderSummary = ({ sourceData, classes }) => {
  return (
    <Fragment>
      <Grid width={"40%"} columns="3" direction="column">
        <Typography className={classes.heading}>
            Lang: {sourceData && sourceData.lang ? sourceData.lang.toUpperCase() : ''}
        </Typography>
        <Typography className={classes.heading}>
            Territory: {sourceData && sourceData.territory ? sourceData.territory.toUpperCase() : ''}
        </Typography>
        <Typography className={classes.heading}>
            Subscriber status: {sourceData && sourceData.subscriber_status ? sourceData.subscriber_status.toUpperCase() : ''}
        </Typography>
      </Grid>
  </Fragment>)
};

export default withStyles(styles)(CustomRouteHeaderSummary);
