import React, { Component, Fragment } from 'react';
import { List, Pagination } from 'react-admin';
import ListFilter from './ListFilter';
import { withStyles } from '@material-ui/core/styles';
import BulkActionButtons from "../components/BulkActionsButtons";
import Snackbar from '../components/SnackBar';
import { connect } from 'react-redux';
import { isCodeFreeze } from './Settings/settingsUI';

export const styles = {
    root: {
        "&.list-page div:first-child:not([role=progressbar])":{
            overflow:"inherit"
        },
    },
};

// TODO test this component
export class Listing extends Component{

    componentDidMount(){
        const { settingsui = [],requestSettingUI } = this.props;
        if(!settingsui.length > 0){
            requestSettingUI();
        }
    }

    render(){
        const { children, classes, permissions, codeFreeze = {}, requestSettingUI, dispatch, noBulkActionButtons, ...rest } = this.props;
        const { status, message, variant } = codeFreeze;

        const PostPagination = props => <Pagination rowsPerPageOptions={[100, 200, 300]} {...props} />;

        return (
            <Fragment>
                <List classes={{root: classes.root}} permissions={permissions} pagination={<PostPagination />} bulkActionButtons={noBulkActionButtons ? false : <BulkActionButtons permissions={permissions}/>} {...rest}>
                    {children}
                </List>
                { status && <Snackbar open={true} variant={variant} message={message} />}
            </Fragment>
        )
    }
}

Listing.defaultProps = {
    filters: <ListFilter />,
    noBulkActionButtons: false
};

const mapStateToProps = (state) => ({
    settingsui:state.settingsui,
    codeFreeze:isCodeFreeze(state.settingsui)
})

const mapPropsToDispatch = dispatch => ({
    requestSettingUI: () => dispatch({type:"SETTINGS_FETCH_REQUESTED"})
})

export default connect(mapStateToProps,mapPropsToDispatch)(withStyles(styles)(Listing));
