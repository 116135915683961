import React from "react";
import PropTypes from "prop-types";
import { getSource } from "../tools";
import { SelectInput, TextInput } from "react-admin";
class CustomTextOnSelect extends React.Component {

    constructor(props) {
        super(props);
        const { record, source } = this.props;

        this.handleSelectInput = this.handleSelectInput.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.state = {
            showCustom:this.IsCustomField(
                record,
                source,
                window.fileType
            ),
            choices: window.fileType ? window.fileType : []
        };
    }

    IsCustomField(record, source, choices) {
        let listChoicesId = choices.map(choice => { return choice.id });
        if (getSource(record, source) && typeof getSource(record, source) === "string" ) {
            let showCustom = listChoicesId.includes(getSource(record, source)) ? false : true;
            return showCustom;
        }
        return false;
    }
    handleSelectInput(e, val) {
        val === "custom"
            ? this.setState({ showCustom: true })
            : this.setState({ showCustom: false });

    }
    handleOnBlur(e, val) {
        val === ""
            ? this.setState({ showCustom: false })
            : this.setState({ showCustom: true });
    }
    render() {
        const { index, source, label, key, choices } = this.props;
        const { showCustom } = this.state;
        return (
            <div key={index}>
                {
                    showCustom
                        ? (
                            <TextInput
                                key={key}
                                label={label}
                                resettable
                                source={source}
                                onBlur={this.handleOnBlur}
                                helperText={"Enter your type"}
                            />
                        ) : (
                            <SelectInput
                                key={key}
                                source={source}
                                label={label}
                                choices={choices}
                                onChange={this.handleSelectInput}
                                resettable
                                helperText={"Select your type"}
                            />
                        )
                }
            </div>
        );
    }
}
CustomTextOnSelect.propTypes = {
    record: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
}
export default CustomTextOnSelect;
