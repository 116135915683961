import React, { Fragment, PureComponent, useEffect } from "react";
import { SelectInput, FormDataConsumer, REDUX_FORM_NAME } from "react-admin";
import { Field, change } from "redux-form";
import DynamicWidgetInput from "./DynamicWidgetInput";
import SingleSelectWithCustom from "./SingleSelectWithCustom";
import ArticlesMetricsInput, { getWidgetCustomQuery } from "./ArticlesMetricsInput";
import Grid from "./Grid";
import * as _ from "lodash";

export const styles = {
    hideTemplate: {
        display: 'block'
    }
}
class DynamicWidgetInputComponent extends PureComponent {
    constructor(props) {
        super(props);
        const { record } = this.props;

        this.state = {
            sourceQuery: (record && record.source_query) || "fields_query",
            template: (record && record.template) || null,
            sourceApi: (record && record.source_api) || null,
        };
    }

    handleSourceQueryChange = (e, val) => {
        this.setState({ sourceQuery: val });
    };

    handleTemplateChange = (e, val) => {
        this.setState({ template: val });
    };

    handleSourceApiChange = (e, val) => {
        this.setState({ sourceApi: val });
    };

    render() {
        const { source, choices, record, style = {} } = this.props;
        const { sourceQuery, template, sourceApi } = this.state;

        return (
            <FormDataConsumer>
                {({ dispatch, formData }) => {
                    const widgetIdx = source.match(/\d+/)[0];
                    const currentWidgetData = _.get(formData, source);

                    useEffect(() => {
                        if (!record.source_api) {
                            dispatch(change(REDUX_FORM_NAME, `${source}.source_api`, "vanilla"));
                        }
                    }, []);

                    return (
                        <Fragment>
                            <div style={style}>
                                <SelectInput
                                    source={`${source}.template`}
                                    component={SelectInput}
                                    label="Template"
                                    resettable
                                    choices={window.widgetTemplates.dynamic}
                                    onChange={this.handleTemplateChange}
                                />
                                <ArticlesMetricsInput
                                    dispatch={dispatch}
                                    featureSrc={source}
                                    formData={formData}
                                    querySrc={`${source}.custom_query`}
                                    widgetCustomQuery={getWidgetCustomQuery(formData, widgetIdx)}
                                    widgetIdx={widgetIdx}
                                />
                            </div>

                            {undefined !== currentWidgetData && !currentWidgetData._metric_type_field && (
                                <>
                                    <Grid columns={3}>
                                        <SelectInput
                                            source={`${source}.source_query`}
                                            label="widget.source_query"
                                            resettable
                                            choices={choices}
                                            onChange={this.handleSourceQueryChange}
                                        />

                                        {sourceQuery === "fields_query" && (
                                            <>
                                                <SelectInput
                                                    source={`${source}.source_api`}
                                                    label="widget.source_api"
                                                    resettable
                                                    choices={window.dynamicSourceApiChoices}
                                                    onChange={this.handleSourceApiChange}
                                                />

                                                <Field
                                                    label={"SOLR Collection"}
                                                    name={`${source}.search_index`}
                                                    component={SingleSelectWithCustom}
                                                    isClearable={true}
                                                    options={["proof", "proof_default"]}
                                                    helper={"eg: proof_cyclingnews"}
                                                    data={formData}
                                                />
                                            </>
                                        )}
                                    </Grid>

                                    <DynamicWidgetInput
                                        sourceApi={sourceApi}
                                        source={source}
                                        template={template}
                                        type={sourceQuery}
                                    />
                                </>
                            )}
                        </Fragment>
                    );
                }}
            </FormDataConsumer>
        );
    }
}
export default DynamicWidgetInputComponent;
