import React, { Component, createRef } from "react";
import { findDOMNode } from "react-dom";
import ReactFlagsSelect from "react-flags-select";
import { withStyles } from "@material-ui/core/styles";
import { showNotification as showNotificationAction } from "react-admin";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "../components/IconButton";
import ContentCopy from "@material-ui/icons/ContentCopy";
import fetchRA from "../hooks/fetchRA";
// import "./CloneLocale.css";
import {setAttributes} from "../tools";
const styles = theme => ({
  block: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
    position: "absolute"
  },
  progress: {
    verticalAlign: "middle"
  }
});

/** Merge existing locale param with diff territories */
const territories = window.sitesTerritoryChoices.map(territry => territry.name);
var countryLabels = window.baseLocale.reduce(
  (acc, value) => (acc = { ...acc, [value["id"]]: value.name }),
  {}
);
countryLabels = territories
  .filter(terri => {
    let res = !Object.keys(countryLabels).includes(terri) ? terri : null;
    return res;
  })
  .reduce(
    (acc, terri) => (acc = { ...acc, [terri]: `${terri} - ${terri}` }),
    countryLabels
  );

class CloneLocale extends Component {
  constructor(props) {
    super(props);
    this.state = { site_clone: props.record.site || "" };
    this.onSelectFlag = this.onSelectFlag.bind(this);
    //Override ReactFlagsSelect FLEX661
    this.listenMutation = this.listenMutation.bind(this);
    this.createInputElementOn = this.createInputElementOn.bind(this);
    this.cloneLocaleRef = createRef();
    this.observer = new MutationObserver(this.listenMutation);
  }
  onSelectFlag(val) {

    const { resource, record, showNotification } = this.props;
    const { site_clone } = this.state;
    this.setState({ isLoading: true });

    const selectedLangTerritory = countryLabels[val].replace(new RegExp(" ", "g"), "")
                                                    .toLowerCase().trim()
                                                    .replace("-","_");
    let slugFrom = record.guid;
    let slugTo = ((site_clone||record.site_id)+"_"+selectedLangTerritory);
    const resourceApi = `${resource}_clone`;

    fetchRA(
      `/api/${resourceApi}/${slugFrom}/${slugTo}`,
      {
        method: "post",
        body: JSON.stringify(record),
        resourceApi
      }
    ).then(res => {
      this.setState({ isLoading: false });
      if (res && res.status === 200) {
        return setTimeout(window.location.reload(), 500);
      }
    });
  }
  createInputElementOn(addedNode) {
    const {site_clone} = this.state;
    
    //param new Input
    let newInput = document.createElement("input");
    setAttributes(newInput,{
        "type":"text",
        "placeholder":"Custom Site ID",
        "ref":"inputSite",
        "style":"margin-bottom:5px;",
    })
    var that = this;
    newInput.addEventListener("change", function(e) {
      if (e.target.value) {
        that.setState({ site_clone: e.target.value });
      }else{
        that.setState({ site_clone: "" });
      }
    });
    newInput.value = site_clone;
    newInput.focus();

    //position in DOM
    var parentNode = addedNode.firstChild;
    parentNode.insertBefore(newInput, parentNode.firstChild);
    
  }
  // Callback function to execute when mutations are observed
  listenMutation = function(mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (mutation.type === "childList" && mutation.addedNodes) {
        mutation.addedNodes.forEach(addedNode => {
          if (addedNode.firstChild.className === "filterBox") {
            this.createInputElementOn(addedNode);
          }
        });
      }
    }
  };
  componentDidMount() {
    let cloneElem = this.cloneLocaleRef.current
      ? this.cloneLocaleRef.current
      : null;
      let nodeElt = findDOMNode(cloneElem);
    if (nodeElt) {
      // Options for the observer (which mutations to observe)
      const config = { attributes: false, childList: true, subtree: false };
      //listen for dom display of flag options
      this.observer.observe(nodeElt, config);
    }
  }
  componentWillUnmount() {
    this.observer.disconnect();
  }
  render() {
    const { classes, record } = this.props;
    const { isLoading } = this.state;
    return record.is_master ? (
      <div id={`reactflagsselect-${record.guid}`}>
        <IconButton
          color="primary"
          icon={iconClasses => <ContentCopy className={iconClasses.leftIcon} />}
        >
          Clone
          {
            <ReactFlagsSelect
              ref={this.cloneLocaleRef}
              searchable={true}
              className={`${classes.block}`}
              countries={territories}
              customLabels={countryLabels}
              placeholder=""
              onSelect={this.onSelectFlag}
            />
          }
        </IconButton>
        {isLoading && (
          <CircularProgress
            className={classes.progress}
            size={20}
            thickness={2}
          />
        )}
      </div>
    ) : (
      <div />
    );
  }
}
//override method to close flagsoptions
ReactFlagsSelect.prototype.closeOptions = function closeOptions(event) {
    if (event.target !== this.refs.selectedFlag && event.target !== this.refs.flagOptions && event.target !== this.refs.filterText && event.target.getAttribute("ref") !== "inputSite") {
        this.setState({
            openOptions: false
        });
    }
};

export default connect(null, { showNotification: showNotificationAction })(
  withStyles(styles)(CloneLocale)
);
