import React, { Fragment, Component } from 'react';
import {
    SelectInput, RadioButtonGroupInput,
} from 'react-admin';
import DynamicWidgetInput from './DynamicWidgetInput';

export default class TimelineWidgetInputComponent extends Component {
    constructor(props) {
        super(props);
        const { record } = this.props;
        this.state = {
            sourceQuery: (record && record.source_query) || 'fields_query',
            template: (record && record.template) || null,
            sourceApi: (record && record.source_api) || null,
        };
    }

    handleSourceQueryChange = (e, val) => {
        this.setState({ sourceQuery: val });
    };

    handleTemplateChange = (e, val) => {
        this.setState({ template: val });
    };

    handleSourceApiChange = (e, val) => {
        this.setState({ sourceApi: val });
    };

    render() {
        const { source ,choices} = this.props;
        const { sourceQuery, template, sourceApi } = this.state;
        return (
            <Fragment>
                <div>
                    <RadioButtonGroupInput
                        source={`${source}.source_query`}
                        label="widget.source_query"
                        choices={
                            choices
                        }
                        optionText="name"
                        optionValue="id"
                        defaultValue="fields_query"
                        onChange={this.handleSourceQueryChange}
                    />
                </div>
                <div>
                <SelectInput
                    source={`${source}.template`}
                    component={SelectInput}
                    label="Template"
                    resettable
                    choices={window.widgetTemplates.timeline}
                    onChange={this.handleTemplateChange}
                    />
                </div>
                <div>
                    {sourceQuery === 'fields_query' && (
                        <SelectInput
                            source={`${source}.source_api`}
                            label="widget.source_api"
                            choices={window.dynamicSourceApiChoices}
                            onChange={this.handleSourceApiChange}
                            resettable
                        />
                    )}
                </div>
                <DynamicWidgetInput
                    type={sourceQuery}
                    template={template}
                    sourceApi={sourceApi}
                    source={source}
                    origin="timeline"
                />
            </Fragment>
        );
    }
}