import * as PageLayout from './Entities/PageLayouts';
import * as Site from './Entities/Sites';
import * as CustomWidgets from './Entities/CustomWidgets';
import * as CustomRoute from './Entities/CustomRoutes';
import * as Abtesting from './Entities/Abtesting';
import * as AbtestingUrls from './Entities/AbtestingUrls';
import * as Settings from './Entities/Settings';

const resources = [
    Site,
    PageLayout,
    CustomWidgets,
    CustomRoute,
    Abtesting,
    AbtestingUrls,
    Settings
];

export default resources;
