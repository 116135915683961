import React, { Fragment } from 'react';
import {
    DateField, SelectField, TextField, BooleanField, RichTextField, TabbedForm, FormTab
} from 'react-admin';
import WidgetListField from '../../components/WidgetListField';
import Show from '../Show';

const PageLayoutShow = props => {
    return (
        <Show title="Pagelayout show" {...props}>
            <TabbedForm>
                <FormTab label="Summary">
                    <TextField source="slug" />
                    <SelectField source="site" choices={window.sitesChoices} />
                    <SelectField source="baselayout" choices={window.baseLayoutChoices} />
                    <SelectField source="templatelayout" choices={window.templateLayoutChoices} />
                    <TextField source="name" />
                    <TextField source="placement_id" />
                    <TextField source="status" />
                    <TextField source="publication_status" />
                    {props.permissions
                        && props.permissions.checkUserIsSuperAdmin()
                        &&  <Fragment>
                        <TextField source="layout_css_class" />
                        <TextField source="page_layout_css" />
                        <TextField source="page_layout_markup" />
                        <TextField source="bottom_layout_markup" />
                    </Fragment>
                    }
                    <TextField source="doc_override" />
                    <DateField source="date" />
                    <BooleanField source="infinite" />
                    <DateField source="published_date" />
                    <TextField source="modified_by" />
                </FormTab>
                <FormTab label="Widgets">
                    <WidgetListField source="widgets" permissions={props.permissions}/>
                </FormTab>

                {props.permissions && props.permissions.userIsAdminOrSuperAdmin() &&
                    <FormTab label="Conditions">
                        <SelectField
                            source="conditions_status"
                            label={'Status'}
                            choices={[
                                { id: 'active', name: 'Active' },
                                { id: 'not_active', name: 'Not active' },
                            ]}
                        />

                        <RichTextField source="conditions_query" />
                        <DateField source="conditions_start_date" label={'Start date'}/>
                        <DateField source="conditions_end_date" label={'End date'}/>
                    </FormTab>
                }
            </TabbedForm>
        </Show>
     );
};

export default PageLayoutShow;
