import React, {  Fragment, Component } from 'react';
import {
    BooleanInput, NumberInput, SelectInput, TextInput,
} from 'react-admin';
import { Field, FieldArray } from 'redux-form';
import Grid from './Grid';
import ContentParsedWidgetInputComponent from './ContentParsedWidgetInputComponent';
import CoverListInput from './CoverListInput';
import DynamicWidgetInputComponent from './DynamicWidgetInputComponent';
import SliceWidgetInputComponent from './SliceWidgetInputComponent';
import TimelineWidgetInputComponent from './TimelineWidgetInputComponent';
import LinkListInput from './LinkListInput';
import TocLinkListInput from './TocLinkListInput';
import WidgetProductBlockInput from './WidgetProductBlockInput';
import WidgetVideoSettings from'./WidgetVideoSettings';
import { ColorInput } from 'react-admin-color-input';
import CustomWidgetFieldComponent from "./CustomWidgets/CustomWidgetFieldComponent";

class WidgetTypeInput extends Component {

    shouldComponentUpdate(nextProps) {
        const { type, sourceData } = this.props;
        return type !== nextProps.type || sourceData.player_id !== nextProps.sourceData.player_id;
    }
    
    render() {
        const { type = '', source, sourceData, record, permissions,...rest } = this.props;
        switch (type.toLowerCase()) {
            case 'ads':
                return (
                    <Fragment>
                        <TextInput
                            source={`${source}.divid`}
                            label="widget.divid"
                        />
                        <BooleanInput
                            source={`${source}.iterable`}
                            label="Iterable"
                        />
                    </Fragment>
                );
            case 'community':
                return (
                    <Fragment>
                        <NumberInput
                            source={`${source}.items_per_page`}
                            label="widget.items_per_page"
                        />
                        <SelectInput
                            source={`${source}.site_from`}
                            label="widget.site_from"
                            choices={window.communitySitesFromChoices}
                        />
                        <TextInput
                            source={`${source}.category`}
                            label="widget.category"
                        />
                    </Fragment>
                );
            case 'contentparsed':
                return (
                    <ContentParsedWidgetInputComponent source={source} record={sourceData} {...rest} />
                );
            case 'coverlist':
                return (
                    <Fragment>
                        <FieldArray name={`${source}.linklist`} component={CoverListInput}>
                            <FieldArray
                                name="sublinklist"
                                addLabel="Add Sub link"
                                removeLabel="Remove Sub link"
                                component={CoverListInput}
                            />
                        </FieldArray>
                    </Fragment>
                );
            case 'customwidgets':
            return (
                <CustomWidgetFieldComponent
                    source={source}
                    record={sourceData}
                    {...rest}
                />
            );
            case 'deals':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            defaultValue="latestdeals"
                            label="Template"
                            choices={window.widgetTemplates.deals}
                            allowEmpty
                        />
                        <TextInput
                            source={`${source}.feed_url`}
                            label="widget.feed_url"
                        />
                        <NumberInput
                            source={`${source}.items_per_page`}
                            label="widget.items_per_page"
                        />
                        <NumberInput
                            source={`${source}.max_items`}
                            label="widget.max_items"
                        />
                        <TextInput
                            source={`${source}.title_category`}
                            label="widget.title_category"
                        />
                        <BooleanInput
                            source={`${source}.no_fallback_feed`}
                            label="widget.no_fallback_feed"
                        />
                        <BooleanInput
                            source={`${source}.allow_aggregated`}
                            label="widget.allow_aggregated"
                        />
                        <BooleanInput
                            source={`${source}.lazy_data`}
                            label="widget.lazy_data"
                        />
                    </Fragment>
                );
            case 'dynamic':
                return (
                    <DynamicWidgetInputComponent
                        choices={[
                            { id: 'other', name: 'Feeds' },
                            { id: 'fields_query', name: 'Fields Query' },
                            { id: 'page_context', name: 'Page Context' }
                        ]}
                        record={sourceData}
                        source={source}
                        {...rest}
                    />
                );
            case 'edtlisting':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            allowEmpty
                            label="Template"
                            choices={window.widgetTemplates.edtlisting}
                        />
                        <div>
                            <NumberInput
                                source={`${source}.items_per_page`}
                                label="widget.items_per_page"
                            />
                        </div>
                    </Fragment>
                );
            case 'empty':
                return (
                    <SelectInput
                    source={`${source}.template`}
                    label="Template"
                    choices={window.widgetTemplates.empty}
                    allowEmpty
                    />
                );
            case 'forumcomments':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            defaultValue="forumcomments"
                            label="Template"
                            choices={window.widgetTemplates.forumcomments}
                            allowEmpty
                        />
                        <BooleanInput
                            source={`${source}.toggle_comments`}
                            label="widget.toggle_comments"
                        />
                    </Fragment>
                );
            case 'forumtopics':
                return (
                    <DynamicWidgetInputComponent
                    source={source}
                    record={sourceData}
                    choices={[
                        { id: 'other', name: 'Feeds' },
                        { id: 'fields_query', name: 'Fields Query' },
                        { id: 'page_context', name: 'Page Context' }
                    ]}
                    {...rest}
                    />
                );
            case 'hero':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            label="Template"
                            choices={window.widgetTemplates.hero}
                            allowEmpty
                        />
                        <BooleanInput
                            source={`${source}.hide_title`}
                            label="widget.hide_title"
                        />
                        <BooleanInput
                            source={`${source}.hide_byline`}
                            label="widget.hide_byline"
                        />
                        <BooleanInput
                            source={`${source}.hide_share_bar`}
                            label="widget.hide_share_bar"
                        />
                        <BooleanInput
                            source={`${source}.hide_breadcrumbs`}
                            label="widget.hide_breadcrumbs"
                        />
                    </Fragment>
                );
            case 'iframe':
                return (
                    <Grid columns="2">
                        <TextInput
                            source={`${source}.iframe_url`}
                            label="Url"
                        />
                        <TextInput
                            source={`${source}.iframe_height`}
                            label="Height"
                        />
                        <TextInput
                            source={`${source}.iframe_width`}
                            label="Width"
                        />
                        <ColorInput
                            source={`${source}.iframe_borders_color`}
                            label="Borders Color"
                        />
                        <TextInput
                            source={`${source}.iframe_borders_width`}
                            label="Borders Width"
                        />
                        <SelectInput
                            source={`${source}.iframe_borders_style`}
                            label="Borders Style"
                            choices={window.widgetTemplates.iframeborderstyle}
                            AllowEmpty
                        />
                        <SelectInput
                            source={`${source}.iframe_allow_scroll`}
                            label="Allow Scroll"
                            choices={window.widgetTemplates.iframeaxis}
                            allowEmpty
                        />
                    </Grid>
                );
            case 'linklist':
                return (
                    <Fragment>
                        <Grid columns="3">
                            <SelectInput
                                source={`${source}.template`}
                                label="Template"
                                allowEmpty
                                choices={window.widgetTemplates.linklist}
                            />
                            <NumberInput
                                source={`${source}.items_per_page`}
                                label="widget.items_per_page"
                            />
                            <TextInput
                                source={`${source}.filter_placeholder`}
                                label="widget.filter_placeholder"
                            />
                        </Grid>
                        <div>
                            <BooleanInput
                                source={`${source}.show_filter`}
                                label="widget.show_filter"
                            />
                        </div>
                        <FieldArray name={`${source}.linklist`} component={LinkListInput}>
                            <FieldArray
                                name="sublinklist"
                                addLabel="Add Sub link"
                                removeLabel="Remove Sub link"
                                component={LinkListInput}
                            />
                        </FieldArray>
                    </Fragment>
                );
            case 'messagebox':
                return (
                    <Fragment>
                        <NumberInput
                            source={`${source}.min_height`}
                            label="widget.min_height"
                        />
                        <SelectInput
                            source={`${source}.messagebox_position`}
                            allowEmpty
                            label="Position"
                            choices={[
                                { id: 'fixed', name: 'fixed' },
                                { id: 'absolute', name: 'absolute' },
                                { id: 'static', name: 'static' },
                            ]}
                        />
                        <NumberInput
                            source={`${source}.cookie_delay`}
                            label="widget.cookie_delay"
                        />
                        <BooleanInput
                            source={`${source}.hide_widget`}
                            label="widget.hide_widget"
                        />
                        <FieldArray
                            name={`${source}.linklist`}
                            addLabel="Add link"
                            removeLabel="Remove link"
                            component={LinkListInput}
                        />
                    </Fragment>
                );
            case 'newsletter':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            defaultValue="regular"
                            label="Template"
                            choices={window.widgetTemplates.newsletter}
                            allowEmpty
                        />
                    </Fragment>
                );
            case 'picstory':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            defaultValue="regular"
                            label="Template"
                            choices={window.widgetTemplates.picStory}
                            allowEmpty
                        />
                        <BooleanInput
                            source={`${source}.lazy_data`}
                            label="widget.lazy_data"
                        />
                    </Fragment>
                );
            case 'productblock':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            defaultValue="latestdeals"
                            label="Template"
                            choices={window.widgetTemplates.productblock}
                            allowEmpty
                        />
                        <BooleanInput
                            source={`${source}.iterable`}
                            label="Iterable"
                        />
                        <Field
                            label="widget.product_blocks"
                            name={`${source}.product_blocks`}
                            component={WidgetProductBlockInput}
                        />
                    </Fragment>
                );
            case 'productlist':
                return (
                    <Fragment>
                        <SelectInput
                            source={`${source}.template`}
                            label="Template"
                            choices={window.widgetTemplates.productlist}
                            allowEmpty
                        />
                        <NumberInput
                            source={`${source}.nb_items`}
                            label="widget.nb_items"
                        />
                    </Fragment>
                );
            case 'productmetrics':
                return (
                    <SelectInput
                        source={`${source}.template`}
                        label="Template"
                        choices={window.widgetTemplates.productmetrics}
                        allowEmpty
                    />
                );
            case 'slice':
                return (
                    <SliceWidgetInputComponent
                        source={source}
                        record={sourceData}
                        permissions={permissions}
                        {...rest}
                    />
                );
            case 'timeline':
                return (
                    <TimelineWidgetInputComponent choices={[
                        { id: 'other', name: 'Other' },
                        { id: 'fields_query', name: 'Fields Query' },
                        { id: 'timeline_fallback', name: 'Fallback' }
                    ]} source={source} record={sourceData}
                    {...rest}
                    />
                );
            case 'toc':
                return (
                    <FieldArray
                        addLabel="Add item"
                        removeLabel="Remove item"
                        name={`${source}.linklist`}
                        component={TocLinkListInput}
                    />
                );
            case 'videoplaylist':
                return (
                    <Grid columns={3}>
                        <WidgetVideoSettings source={source} sourceData={sourceData} />
                    </Grid>
                );
            default:
                return <div />;
        }
    }
}

export default WidgetTypeInput;
