import React, { Component } from 'react';

import MinusIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import AddIcon from "@material-ui/icons/Add";
import IconButton from "../IconButton";
import { withStyles } from '@material-ui/core/styles';
import { FieldArray } from 'redux-form';
import {FormDataConsumer} from 'react-admin';

import Grid from '../Grid';
import CustomRouteHeaderSummary from "./CustomRouteHeaderSummary";
import TabContainer from "../WidgetListInput/TabContainer";
import {SummaryRender} from "../../Entities/CustomRoutes/EditForm";
import CommonDynamicFieldsComponents from "../CommonDynamicFieldsComponents";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appBar:{
        background:theme.palette.secondary.main
    }
});

class MyCard extends Component {
    constructor(props) {
        super(props);
        this.state = {value: 0};
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { value } = this.state;
        const { fields } = this.props;

        return (
            value !== nextState.value || fields.length !== nextProps.fields.length //avoid uncorrect render of delete widgets (FLEX-275)
        );
    }

    handleTabChange = (e, value) => {
        this.setState({ value });
    };

    handleDelete = () => {
        const { fields, index } = this.props;
        fields.remove(index);
    };

    render() {
        const {permissions, classes, source, record } = this.props;
        const { value } = this.state;

        return (
            <div>
                <FormDataConsumer>
                {({formData,...rest})=>{
                    let currentOverrideData = formData && formData.overrides && formData.overrides[this.props.index] ? formData.overrides[this.props.index] : null;

                    return (
                        <ExpansionPanel defaultExpanded={initiallyExpanded(currentOverrideData)}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                <CustomRouteHeaderSummary
                                    sourceData={currentOverrideData}
                                    {...rest}
                                />
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={classes.root}>
                                    <AppBar position="static" className={classes.appBar}>
                                        <Tabs value={value} onChange={this.handleTabChange}>
                                            <Tab label="Summary" />
                                            <Tab label="Routing Params" />
                                            <Tab label="SSI Vars" />
                                        </Tabs>
                                    </AppBar>
                                    {value === 0 && (
                                        <TabContainer>
                                            <FieldArray
                                                component={SummaryRender}
                                                formData={{site:formData.site,...currentOverrideData}}
                                                classes={classes}
                                                name={''}
                                                source={source}
                                                rest={this.props.rest}
                                                change={this.props.change}
                                                parentFormData={formData}
                                                {...rest}
                                            />
                                        </TabContainer>
                                    )}
                                    {value === 1 && (
                                        <TabContainer>
                                            <FieldArray
                                                label={"Route Params and Placeholders"}
                                                component={CommonDynamicFieldsComponents}
                                                source={`${source}.other_routing_params`}
                                                name={`${source}.other_routing_params`}
                                            />
                                            <FieldArray
                                                label={"Fcsis Fields"}
                                                source={`${source}.fcsis_routing_params`}
                                                name={`${source}.fcsis_routing_params`}
                                                fscis_fields={true}
                                                component={CommonDynamicFieldsComponents}
                                            />
                                        </TabContainer>
                                    )}
                                    {value === 2 && (
                                        <TabContainer>
                                            <FieldArray
                                                label={"SSI Vars"}
                                                source={`${source}.ssi_vars`}
                                                name={`${source}.ssi_vars`}
                                                suggest={true}
                                                component={CommonDynamicFieldsComponents}
                                            />
                                        </TabContainer>
                                    )}
                                </div>
                            </ExpansionPanelDetails>
                            <ExpansionPanelActions>
                                {(true || permissions.checkUserCanDeleteItem(record)) ? (
                                    <IconButton
                                        variant="contained"
                                        color="secondary"
                                        icon={iconClasses => <MinusIcon className={iconClasses.leftIcon} />}
                                        onClick={this.handleDelete}
                                    >
                                        Delete this override
                                    </IconButton>
                                ) : null}
                            </ExpansionPanelActions>
                        </ExpansionPanel>
                    )
                }}
            </FormDataConsumer>
            </div>
        );
    }
}

const CustomRouteOverrideInput = (props) => {
    const { fields, permissions } = props;

    return (
        <div>
            <Grid style={{ marginTop: '15px' }} columns="1" columnGap="20px" rowGap="20px">
                {fields.length > 0 && fields.map((item, index) =>
                    {
                        return (
                            <MyCard
                                key={`custom-route-${index}`}
                                index={index}
                                fields={fields}
                                source={item}
                                record={fields.get(index)}
                                permissions={permissions}
                                item={item}
                                {...props}
                            />
                        );
                    }
                )}
            </Grid>

            <div style={{ marginTop: 16 }}>
                <IconButton color="primary" icon={classes => <AddIcon className={classes.leftIcon} />} onClick={() => fields.push({})}>
                    Add Override
                </IconButton>
            </div>
        </div>
    );
};

const initiallyExpanded = (record) => { return (!record || !record.lang || !record.territory); }

export default withStyles(styles)(CustomRouteOverrideInput);
