export const SLICES_DEFAULTS = {
    newsletterForm: {
        components: {
            header: "%siteConfig.misc.selligent.title%",
            tagline: "%siteConfig.misc.selligent.strapline%",
            method: "POST",
            endpoint: "https://newsletter-subscribe.futureplc.com/v2/submission/submit",
            formFooterText: "%PHPTranslatorTerms.SUBMITTING_INFORMATION%",
            failureMessage: "There was a problem. Please refresh the page and try again.",
            successMessage: {
                body: "Thank you for signing up. You will receive a confirmation email shortly.",
            },
            inputs: [
                {
                    name: "NAME",
                    type: "hidden",
                },
                {
                    name: "MAIL",
                    type: "email",
                    required: true,
                },
                {
                    name: "NEWSLETTER_CODE",
                    type: "hidden",
                    value: "%siteConfig.misc.selligent.newsletter-code%",
                },
                {
                    name: "LANG",
                    type: "hidden",
                    value: "%routingData.__language%",
                },
                {
                    name: "SOURCE",
                    type: "hidden",
                    value: "101",
                },
                {
                    name: "CONTACT_OTHER_BRANDS",
                    label: {
                        text: "%PHPTranslatorTerms.CONSENT_OFFERS%",
                    },
                    type: "checkbox",
                },
                {
                    name: "CONTACT_PARTNERS",
                    label: {
                        text: "%PHPTranslatorTerms.CONSENT_SPONSORS%",
                    },
                    type: "checkbox",
                },
                {
                    type: "submit",
                    value: "%PHPTranslatorTerms.TRD_NEWSLETTER_SIGN_ME_UP%",
                },
            ],
        },
        variables: {
            "--newsletter-form-primary-color": "%siteConfig.flexiConfigDocument.brand_color_1%",
            "--newsletter-form-secondary-color": "%siteConfig.flexiConfigDocument.brand_color_2%",
            "--newsletter-form-background-color": "#f6f6f6",
        },
    },
    rating: {
        components: {
            summary: "%rating_api.totalVotes% ratings with the average rating of %rating_api.average% out of 5 stars.",
            interjection: {
                successMessage: "Thanks for your vote",
                alreadyVotedMessage: "You already voted",
                errorMessage: "Please refresh your page and try again",
            },
            total: "%rating_api.totalVotes%",
            score: "%rating_api.average%",
            layout: "vertical",
            site: "%siteConfig.buildName%",
            articleId: "%article.id%",
            endpoint: "%rating_api.postEndpoint%",
        },
        variables: {
            "--star-rating-color--active": "%siteConfig.flexiConfigDocument.brand_color_1%",
            "--star-rating-color": "#ddd",
        },
    },
};
