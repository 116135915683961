import React from 'react'
import { SelectArrayInput, FormDataConsumer, REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form'
import CardExpanded from '../CardExpanded'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core';
import { isObject } from '../../tools';

const styles = theme => ({
    formGroup: {
        flexDirection: "row"
    },
    formLabel: {
        color: theme.color.grayScale.dark
    }
})


const filterChoicesFor = (filter, choices) => {
    return window[choices]
        .filter(item => { return item.distribution && item.distribution.includes(filter) })
        .sort((a, b) => (a.name > b.name) ? 1 : -1);
}

const parseTags = (datas) => {
    var finalParse = datas.reduce((acc, item) => {
        var found = window.tagsCMS.find(tag => tag.id === item)
        if (found) {
            var formatFound = { label: found.name, ...found };
            delete formatFound.distribution;
            delete formatFound.id;
            delete formatFound.name;
            acc = { [item]: { ...formatFound }, ...acc };
        }
        return acc;
    }, {});
    return finalParse;
}

const parseTypes = (datas) => {
    var finalParse = datas.reduce((acc, item) => {
        var found = window.typesCMS.find(tag => tag.id === item)
        if (found) {
            var formatFound = { label: found.name, ...found };
            delete formatFound.distribution;
            delete formatFound.id;
            delete formatFound.name;
            acc = { [item]: { ...formatFound }, ...acc };
        }
        return acc;
    }, {});
    return finalParse;
}

const parseChannels = (datas) => {
    var finalParse = datas.reduce((acc, item) => {
        var found = window.channelsCMS.find(tag => tag.id === item)
        if (found) {
            var formatFound = { label: found.name, ...found };
            delete formatFound.id;
            delete formatFound.name;
            acc = { [item]: { ...formatFound }, ...acc };
        }
        return acc;
    }, {});
    return finalParse;
}

export const formatData = datas => {
    var formattedDatas = datas && isObject(datas) ? Object.keys(datas) : [];
    return formattedDatas;

}
export class DocumentsConfig extends React.Component {
    constructor(props) {
        super(props);
        const { record: { cms_config } } = props
        this.state = {
            displayEventGroup: (cms_config && cms_config.documents && cms_config.documents.event_group) || false,
            displayPersonGroup: (cms_config && cms_config.documents && cms_config.documents.person_group) || false,
            displayEvent: (cms_config && cms_config.documents && cms_config.documents.event) || false,
            displayPerson: (cms_config && cms_config.documents && cms_config.documents.person) || false,
        };
        this.handleCheck = this.handleCheck.bind(this);

    }

    handleCheck = (target, { dispatch, source }) => {
        // remove store by templates when uncheck
        if (this.state[target]) {
            dispatch(change(REDUX_FORM_NAME, source, ""))
        }
        //display/undisplay specific template
        this.setState(prevState => ({ [target]: !prevState[target] }));
    }

    render() {
        const { source, label, classes, ...props } = this.props;
        const { displayEventGroup, displayPersonGroup, displayEvent, displayPerson } = this.state;
        return (
            <CardExpanded columns={1} label={label} {...props}>
                <FormDataConsumer>
                    {({ formData, dispatch }) => {
                        return (
                            <>
                                <FormControl component="fieldset">
                                    <FormLabel classes={{ root: classes.formLabel }} component="legend">Enable templates</FormLabel>
                                    <FormGroup className={classes.formGroup}>
                                        <FormControlLabel label={"Event Group"} control={<Checkbox checked={displayEventGroup} onClick={() => this.handleCheck("displayEventGroup", { formData, dispatch, source: `${source}.event_group` })}></Checkbox>} />
                                        <FormControlLabel label={"Person Group"} control={<Checkbox checked={displayPersonGroup} onClick={() => this.handleCheck("displayPersonGroup", { formData, dispatch, source: `${source}.person_group` })}></Checkbox>} />
                                        <FormControlLabel label={"Event"} control={<Checkbox checked={displayEvent} onClick={() => this.handleCheck("displayEvent", { formData, dispatch, source: `${source}.event` })}></Checkbox>} />
                                        <FormControlLabel label={"Person"} control={<Checkbox checked={displayPerson} onClick={() => this.handleCheck("displayPerson", { formData, dispatch, source: `${source}.person` })}></Checkbox>} />
                                    </FormGroup>
                                </FormControl>
                                <CardExpanded columns={1} label={`Article`}>
                                    <CardExpanded columns={1} label={`Templates`}>
                                        <SelectArrayInput label={`Standard`} source={`${source}.article.templates.standard`} choices={filterChoicesFor("standard", "typesCMS")} parse={parseTypes} format={formatData} />
                                        <SelectArrayInput label={`Review`} source={`${source}.article.templates.review`} choices={filterChoicesFor("review", "typesCMS")} parse={parseTypes} format={formatData} />
                                        <SelectArrayInput label={`Gallery`} source={`${source}.article.templates.gallery`} choices={filterChoicesFor("gallery", "typesCMS")} parse={parseTypes} format={formatData} />
                                        <SelectArrayInput label={`Supported`} source={`${source}.article.templates.supported`} choices={filterChoicesFor("supported", "typesCMS")} parse={parseTypes} format={formatData} />
                                        <SelectArrayInput label={`Advertisement Feature`} source={`${source}.article.templates.advertisement_feature`} choices={filterChoicesFor("advertisement-feature", "typesCMS")} parse={parseTypes} format={formatData} />
                                        <SelectArrayInput label={`Live Report`} source={`${source}.article.templates.live_report`} choices={filterChoicesFor("live-report", "typesCMS")} parse={parseTypes} format={formatData} />
                                        <SelectArrayInput label={`Recipe`} source={`${source}.article.templates.recipe`} choices={filterChoicesFor("recipe", "typesCMS")} parse={parseTypes} format={formatData} />
                                    </CardExpanded>
                                    <CardExpanded columns={1} label={`Tags`}>
                                        <SelectArrayInput label={`Tags`} source={`${source}.article.tags`} choices={filterChoicesFor("standard", "tagsCMS")} parse={parseTags} format={formatData} />
                                    </CardExpanded>
                                    <CardExpanded columns={1} label={`Channels`}>
                                        <SelectArrayInput label={`Tags`} source={`${source}.article.channels`} choices={window.channelsCMS} parse={parseChannels} format={formatData} />
                                    </CardExpanded>
                                </CardExpanded>
                                {displayEventGroup &&
                                    <CardExpanded columns={1} label={`Event Group`}>
                                        <CardExpanded columns={1} label={`Tags`}>
                                            <SelectArrayInput
                                                label={`Tags`}
                                                source={`${source}.event_group.tags`}
                                                choices={filterChoicesFor("event-group", "tagsCMS")}
                                                parse={parseTags}
                                                format={formatData}
                                            />
                                        </CardExpanded>
                                    </CardExpanded>
                                }
                                {displayPersonGroup &&
                                    <CardExpanded columns={1} label={`Person Group`}>
                                        <CardExpanded columns={1} label={`Tags`}>
                                            <SelectArrayInput label={`Tags`}
                                                source={`${source}.person_group.tags`}
                                                choices={filterChoicesFor("person-group", "tagsCMS")}
                                                parse={parseTags}
                                                format={formatData}
                                            />
                                        </CardExpanded>
                                    </CardExpanded>
                                }
                                {displayEvent &&
                                    <CardExpanded columns={1} label={`Event`}>
                                        <CardExpanded columns={1} label={`Tags`}>
                                            <SelectArrayInput label={`Tags`}
                                                source={`${source}.event.tags`}
                                                choices={filterChoicesFor("event", "tagsCMS")}
                                                parse={parseTags}
                                                format={formatData}
                                            />
                                        </CardExpanded>
                                    </CardExpanded>
                                }
                                {displayPerson &&
                                    <CardExpanded columns={1} label={`Person`}>
                                        <CardExpanded columns={1} label={`Tags`}>
                                            <SelectArrayInput label={`Tags`}
                                                source={`${source}.person.tags`}
                                                choices={filterChoicesFor("person", "tagsCMS")}
                                                parse={parseTags}
                                                format={formatData}
                                            />
                                        </CardExpanded>
                                    </CardExpanded>
                                }
                            </>
                        )
                    }}
                </FormDataConsumer>

            </CardExpanded>
        )
    }
}

export default withStyles(styles)(DocumentsConfig)
