import React, { Fragment } from "react";
import { BooleanField, Labeled, SelectField, TextField } from "react-admin";
// import Grid from './Grid';
import ContentParsedWidgetFieldComponent from "./ContentParsedWidgetFieldComponent";
import CoverListField from "./CoverListField";
import SliceWidgetFieldComponent from "./SliceWidgetFieldComponent";
import DynamicWidgetFieldComponent from "./DynamicWidgetFieldComponent";
import TimelineWidgetFieldComponent from "./TimelineWidgetFieldComponent";
import LinkListField from "./LinkListField";
import TocLinkListField from "./TocLinkListField";
import WidgetProductBlockField from "./WidgetProductBlockField";
import WidgetVideoSettingsField from "./WidgetVideoSettingsField";
import CustomWidgetFieldComponent from "./CustomWidgets/CustomWidgetFieldComponent";

const WidgetTypeField = ({ type = "", record, data }) => {
  switch (type.toLowerCase()) {
    case "ads":
      return (
        <Fragment>
          <Labeled label="widget.divid">
            <TextField source="divid" record={record} />
          </Labeled>
          <Labeled label="Iterable">
            <BooleanField source="iterable" record={record} />
          </Labeled>
        </Fragment>
      );
    case "linklist":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.linklist}
            />
          </Labeled>
          <Labeled label="widget.items_per_page">
            <TextField record={record} source="items_per_page" />
          </Labeled>
          <Labeled label="widget.show_filter">
            <BooleanField record={record} source="show_filter" />
          </Labeled>
          <Labeled label="widget.filter_placeholder">
            <TextField record={record} source="filter_placeholder" />
          </Labeled>
          <Labeled label="Link List">
            <LinkListField data={data} record={record} source="linklist">
              <LinkListField data={data} source="sublinklist" />
            </LinkListField>
          </Labeled>
        </Fragment>
      );
    case "coverlist":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.coverlist}
            />
          </Labeled>
          <Labeled label="Link List">
            <CoverListField record={record} source="linklist">
              <CoverListField source="sublinklist" />
            </CoverListField>
          </Labeled>
        </Fragment>
      );
      case "customwidgets":
        return (<CustomWidgetFieldComponent
            record={record}
            source={'customwidgets'}
        />)
    case "videoplaylist":
      return <WidgetVideoSettingsField record={record} />;
    case "messagebox":
      return (
        <Fragment>
          <Labeled label="widget.min_height">
            <TextField record={record} source="min_height" />
          </Labeled>
          <Labeled label="widget.cookie_delay">
            <TextField record={record} source="cookie_delay" />
          </Labeled>
          <Labeled label="widget.hide_widget">
            <BooleanField record={record} source="hide_widget" />
          </Labeled>
          <Labeled label="Position">
            <SelectField
              record={record}
              source="messagebox_position"
              choices={[
                { id: "fixed", name: "fixed" },
                { id: "absolute", name: "absolute" },
                { id: "static", name: "static" },
              ]}
            />
          </Labeled>
          <Labeled label="Linklist">
            <LinkListField record={record} source="linklist" />
          </Labeled>
        </Fragment>
      );
    case "edtlisting":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.edtlisting}
            />
          </Labeled>
          <Labeled label="widget.items_per_page">
            <TextField source="items_per_page" record={record} />
          </Labeled>
        </Fragment>
      );
    case "hero":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.hero}
            />
          </Labeled>
          <Labeled label="widget.hide_title">
            <BooleanField record={record} source="hide_title" />
          </Labeled>
          <Labeled label="widget.hide_byline">
            <BooleanField record={record} source="hide_byline" />
          </Labeled>
          <Labeled label="widget.hide_share_bar">
            <BooleanField record={record} source="hide_share_bar" />
          </Labeled>
          <Labeled label="widget.hide_breadcrumbs">
            <BooleanField record={record} source="hide_breadcrumbs" />
          </Labeled>
        </Fragment>
      );
    case "contentparsed":
      return <ContentParsedWidgetFieldComponent record={record} />;
    case "productlist":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.productlist}
            />
          </Labeled>
          <Labeled label="widget.nb_items">
            <TextField record={record} source="nb_items" />
          </Labeled>
        </Fragment>
      );
    case "productmetrics":
      return (
        <Labeled label="Template">
          <SelectField
            record={record}
            source="template"
            allowEmpty
            choices={window.widgetTemplates.productmetrics}
          />
        </Labeled>
      );
    case "empty":
      return (
        <Labeled label="Template">
          <SelectField
            record={record}
            source="template"
            allowEmpty
            choices={window.widgetTemplates.empty}
          />
        </Labeled>
      );
    case "slice":
      return <SliceWidgetFieldComponent record={record} />;
    case "dynamic":
    case "forumtopics":
      return <DynamicWidgetFieldComponent record={record} />;
    case "timeline":
      return <TimelineWidgetFieldComponent record={record} />;
    case "forumcomments":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.forumcomments}
            />
          </Labeled>
          <Labeled label="widget.toggle_comments">
            <BooleanField record={record} source="toggle_comments" />
          </Labeled>
        </Fragment>
      );
    case "community":
      return (
        <Fragment>
          <Labeled label="widget.items_per_page">
            <TextField record={record} source="items_per_page" />
          </Labeled>
          <Labeled label="widget.site_from">
            <TextField record={record} source="site_from" />
          </Labeled>
          <Labeled label="Category">
            <TextField record={record} source="category" />
          </Labeled>
        </Fragment>
      );
    case "deals":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.deals}
            />
          </Labeled>
          <Labeled label="widget.feed_url">
            <TextField record={record} source="feed_url" />
          </Labeled>
          <Labeled label="widget.items_per_page">
            <TextField record={record} source="items_per_page" />
          </Labeled>
          <Labeled label="widget.max_items">
            <TextField record={record} source="max_items" />
          </Labeled>
          <Labeled label="widget.title_category">
            <TextField record={record} source="title_category" />
          </Labeled>
          <Labeled label="widget.no_fallback_feed">
            <BooleanField record={record} source="no_fallback_feed" />
          </Labeled>
          <Labeled label="widget.allow_aggregated">
            <BooleanField record={record} source="allow_aggregated" />
          </Labeled>
          <Labeled label="widget.lazy_data">
            <BooleanField record={record} source="lazy_data" />
          </Labeled>
        </Fragment>
      );
    case "productblock":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.productblock}
            />
          </Labeled>
          <Labeled label="Iterable">
            <BooleanField record={record} source="iterable" />
          </Labeled>
          <WidgetProductBlockField record={record} />
        </Fragment>
      );
    case "toc":
      return <TocLinkListField data={data} record={record} />;
    case "picstory":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              allowEmpty
              choices={window.widgetTemplates.picStory}
            />
          </Labeled>
          <Labeled label="widget.lazy_data">
            <BooleanField record={record} source="lazy_data" />
          </Labeled>
        </Fragment>
      );
    case "newsletter":
      return (
        <Fragment>
          <Labeled label="Template">
            <SelectField
              record={record}
              source="template"
              choices={window.widgetTemplates.newsletter}
              allowEmpty
            />
          </Labeled>
        </Fragment>
      );
    /* case 'hawk':
            return (
                <Fragment>
                    <Grid columns="3">
                        <Labeled label="widget.divid">
                            <TextField source="divid" record={record} />
                        </Labeled>
                        <Labeled label="Iterable">
                            <BooleanField source="iterable" record={record} />
                        </Labeled>
                    </Grid>
                </Fragment>
            ); */
    default:
      return <div />;
  }
};

export default WidgetTypeField;
