import React, { useEffect, useState, useCallback } from "react";
import { IconButton as MUIconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import fetchRA from "../hooks/fetchRA";
import CloseIcon from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import OpenInNew from "@material-ui/icons/OpenInNew";
import { ReactMUIDatatable } from "react-material-ui-datatable";
import { showNotification as showNotificationAction } from "react-admin";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import ListChips from "./ListChips";
//https://master.flexi-ui-app.van-qa.futureplc.engineering/api/customroutes?_end=100&_start=0&flexi_layout_id=default_page_layout_landing

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
const ToolBarSelectActions = ({
  allData,
  selectedData,
  updateSelectedData,
  handleDelete,
}) => {
  const handleSelectAction = useCallback(() => {
    selectedData.forEach((item) => {
      const entity = getApiEndpoint(item.doc_type);

      window.open(`/#/${entity}/${item.guid}`, "_blank");
      window.blur();
      window.focus();
    });
  }, [selectedData]);

  const handleDeleteAction = useCallback(() => {
    handleDelete(selectedData);
  }, []);

  return (
    <React.Fragment>
      <MUIconButton onClick={handleSelectAction}>
        <OpenInNew />
      </MUIconButton>
      <MUIconButton onClick={handleDeleteAction}>
        <CloseIcon />
      </MUIconButton>
    </React.Fragment>
  );
};
const getApiEndpoint = (key) => {
  let apiEndpoint = "";
  switch (key) {
    case "custom_route":
      apiEndpoint = "customroutes";
      break;
    case "page_layout":
      apiEndpoint = "pagelayouts";
      break;
    case "site":
      apiEndpoint = "sites";
      break;

    default:
      apiEndpoint = key;
      break;
  }
  return apiEndpoint;
};

const defaultColumns = [
  { label: "Guid", name: "guid" },
  { label: "Name", name: "name" },
  { label: "is Publish?", name: "is_published" },
  { label: "Last modified", name: "last_modified.$date.$numberLong" },
];

const RelatedDocuments = ({ record, showNotification, route, ...rest }) => {
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(0);
  const [columns, setColumns] = useState(defaultColumns);
  const [apiEndpoint, setApiEndPoint] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleClickChipCustomroutesOutside = useCallback((val) => {
    window.open(`/#/customroutes/${val}`, "_blank");
  }, []);

  const handleClickChipPagelayoutsInside = useCallback((val) => {
    window.open(`/#/pagelayouts/${val}`, "_blank");
  }, []);

  const handleCustomCell = useCallback(
    ({ value, column, row }) => {
      switch (column.name) {
        case "guid":
          return (
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href={`/#/${apiEndpoint}/` + value}
            >
              {value}
            </a>
          );
        case "name":
          return <span>{value}</span>;
        case "catch_all":
          return !value ? null : <Check />;
        case "is_published":
          return !value ? <CloseIcon style={{ color: "red" }} /> : <Check />;
        case "pagelayouts_inside":
          return !value ? null : (
            <ListChips
              deletable="false"
              onClickChip={handleClickChipPagelayoutsInside}
              chipsData={value}
            />
          );
        case "customroutes_outside":
          return !value ? null : (
            <ListChips
              deletable="false"
              onClickChip={handleClickChipCustomroutesOutside}
              chipsData={value}
            />
          );
        case "master_site_link":
          return (
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href={`/#/sites/` + value}
            >
              {value}
            </a>
          );
        case "last_modified.$date.$numberLong":
          return new Date(value * 1).toUTCString();
        default:
          return value;
      }
    },
    [data, tab]
  );

  const setColumnsForDataTable = (index = 0) => {
    if (data && data[index] && data[index][0]) {
      const additionnalColumns = [
        ...(data[index][0] === "custom_route" && record.doc_type === "site"
          ? [
              { label: "is Catch all?", name: "catch_all" },
              {
                label: "Pagelayouts inside the route",
                name: "pagelayouts_inside",
              },
            ]
          : []),
        ...(data[index][0] === "page_layout" && record.doc_type === "site"
          ? [
              {
                label: `Custom routes of ${record.name} using it`,
                name: "customroutes_outside",
              },
            ]
          : []),
        ...((data[index][0] === "page_layout" ||
          data[index][0] === "custom_route") &&
        record.doc_type !== "site"
          ? [
              {
                label: "master's site link",
                name: "master_site_link",
              },
            ]
          : []),
      ];

      setColumns([...defaultColumns, ...additionnalColumns]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;

    const fetchApi = async () => {
      const documents = await fetchRA(
        `${route}/${record.guid}?_end=5000&_start=0`,
        { method: "get", resourceApi: "fetch_related_documents" }
      );

      if (
        documents &&
        documents.status === 200 &&
        Object.keys(documents.json).length > 0
      ) {
        //format data for data of ReactMUIDatatable
        const formattedData = Object.keys(documents.json).map((key, index) => [
          key,
          Object.values(documents.json[key]),
          getApiEndpoint(key),
        ]);

        if (isMounted) {
          setApiEndPoint(formattedData[0]? formattedData[0][2]: ""); //set the initial api endpoint for the first tab
          setData(formattedData);
        }
      } else {
        console.error(documents && documents.reason);
      }
      setIsLoading(false);
    };
    fetchApi();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    setColumnsForDataTable();
  }, [data]);

  const handleChangeTab = useCallback(
    (e, v) => {
      setColumnsForDataTable(v);
      setTab(v);
      setApiEndPoint(data[v][2]);
    },
    [data, tab]
  );

  return (
    <>
      <AppBar color="inherit" position="sticky">
        <Tabs value={tab} onChange={handleChangeTab}>
          {data.map((listEntity) => (
            <Tab
              label={`${listEntity[2]}`}
              key={`related-tab-${listEntity[2]}`}
            />
          ))}
        </Tabs>
      </AppBar>
      {isLoading ? (
        <CircularProgress size={50} thickness={2} />
      ) : (
        data.map(
          (listEntity, i) =>
           tab === i && (
              <TabContainer key={`related-container-${listEntity[2]}`}>
                {
                  <ReactMUIDatatable
                    data={listEntity[1]}
                    columns={columns}
                    customCell={handleCustomCell}
                    perPage={50}
                    perPageOption={[10, 20, 50]}
                    selectable={true}
                    filterable={true}
                    sort={[
                      { columnName: "is_published", direction: "ASC" },
                      {
                        columnName: "last_modified.$date.$numberLong",
                        direction: "DESC",
                      },
                    ]}
                    toolbarSelectActions={ToolBarSelectActions}
                  />
                }
              </TabContainer>
            )
        )
      )}
    </>
  );
};

export default connect(null, { showNotification: showNotificationAction })(
  RelatedDocuments
);
