import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';

const PageLayoutEdit = props => (
    <Edit undoable={false} title="Page Layout" {...props}>
        <EditForm permissions={props.permissions} disabledOverrideField />
    </Edit>
);

export default PageLayoutEdit;
