import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MinusIcon from '@material-ui/icons/Remove';
import IconButton from '../IconButton';
import { Input } from '@material-ui/core';
import { getSource } from "../../tools";
const styles = () => ({
    title: {
        fontSize: 14,
    },
    icon: {
        width: 100,
        height: 50,
        backgroundSize: "contain",
    },
    formControl: {
        minWidth: 120,
    },
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    detailsRoot: {
        display: 'inline-block',
    }
});

class SelectListCard extends Component {

    shouldComponentUpdate() {
        return true;
    }
    findTitleInCategory(record, category, e) {
        return getSource(record, category).find(elt => {
            return elt.title === e.target.value
        })
    }
    render() {
        const {
            index,
            fields,
            category,
            record,
            input,
            classes,
            removeLabel,
            getTitle,
            getIcon,
            getUrl,
        } = this.props;
        const item = fields.get(index);
        return (

            <Card key={item} className={classes.root}>
                <CardMedia
                    className={[classes.detailsRoot, classes.icon].join(" ")}
                    image={getIcon(item)}
                />
                <CardContent className={classes.detailsRoot}>
                    <FormControl className={classes.formControl}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {getUrl(item)}
                        </Typography>
                        <InputLabel htmlFor={`social-helper-${index}`}></InputLabel>
                        <Select
                            key={index}
                            value={getTitle(item)}
                            onChange={(event) => {
                                // object found in category copied in source
                                getSource(record, category)
                                    && getSource(record, category).length > 0
                                    && input.onChange(this.findTitleInCategory(record, category, event))
                            }}
                            input={<Input value={getTitle(item)} name="title" id={`social-helper-${index}`} />}
                        >
                            <MenuItem value="">
                                <em>Choose</em>
                            </MenuItem>
                            {getSource(record, category)
                                && getSource(record, category).length > 0
                                && getSource(record, category).map((elt, i) => (
                                    // {record[category].map((elt,i)=>(
                                    <MenuItem key={i} value={elt.title}>{elt.title}</MenuItem>
                                ))}
                        </Select>
                        {<FormHelperText>Position {index + 1}</FormHelperText>}
                    </FormControl>
                </CardContent>
                <IconButton
                    color="secondary"
                    icon={iconClasses => <MinusIcon className={iconClasses.leftIcon} />}
                    onClick={() => fields.remove(index)}
                >
                    {`${removeLabel} #${index + 1}`}
                </IconButton>
            </Card>
        )
    }
}
export default withStyles(styles)(SelectListCard);
