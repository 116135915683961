import fetchRA from '../../hooks/fetchRA';
import { call, takeEvery,put } from 'redux-saga/effects';

const isInRange = (date,from,to) => date > from && date < to ? true: false;

export const isCodeFreeze = (settings) => {
    const now = new Date().getTime();
    
        const codeFreezeSettings = settings && settings.find(o=> o.setting === "code_freeze");
        if(codeFreezeSettings && (codeFreezeSettings.dateFrom && codeFreezeSettings.dateTo)){
            var from = new Date(codeFreezeSettings.dateFrom);
            from.setDate(from.getDate()-5);
            var startWarningDate = from.getTime();
            if(now < new Date(codeFreezeSettings.dateFrom).getTime() && now > startWarningDate ){
                return {
                    status:false,
                    message:`Next Code Freeze from ${codeFreezeSettings.dateFrom} to ${codeFreezeSettings.dateTo} `,
                    variant:"info"
                }
            }
            if(isInRange(now,new Date(codeFreezeSettings.dateFrom).getTime(),new Date(codeFreezeSettings.dateTo).getTime())){
                return {
                    status:true,
                    message:`Changes are limited to SuperAdmins due to Code Freeze until ${codeFreezeSettings.dateTo}`,
                    variant:"warning"
                }
            }
        }
        return {status:false}
}

export const getSettingsUI = async() => {
    const { json } = await fetchRA(`/api/settings`,{method: "get",resourceApi:"settings"});
    return json || [];
}

export const extractFromSettings = (settings,id) => settings && settings.find(o=> o.setting === id);

/* Reducers Settings */
const reducersSettingsUI = (previousState = [], { type, payload }) => {
    if (type === "SETTINGS_FETCH_SUCCEEDED") {
        return [...payload];
    }
    return previousState;
}

/* Sagas Settings */
//https://redux-saga.js.org/docs/api/
// worker Saga: will be fired on SETTINGS_FETCH_REQUESTED actions
function* fetchSettings() {
    try {
        const settings = yield call(getSettingsUI);
        yield put({type: "SETTINGS_FETCH_SUCCEEDED",payload:settings});
    } catch (e) {
       yield put({type: "SETTINGS_FETCH_FAILED", message: e.message});
    }
 }
export function* settingsUISaga() {
    yield takeEvery("SETTINGS_FETCH_REQUESTED", fetchSettings);
}

export default reducersSettingsUI