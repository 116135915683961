import { isSubStrIgnoreCase } from "../../tools";

const useFilterConfigHanlder = (configItem = {}, filterStr) => {
  let isFiltered = false;
  let isExpanded = false;
  if (!filterStr) {
    return { isFiltered: true, isExpanded };
  }

  if (isSubStrIgnoreCase(configItem.id, filterStr)) {
    isFiltered = true;
  } else if (isSubStrIgnoreCase(configItem.name, filterStr)) {
    isFiltered = true;
    isExpanded = true;
  } else if (
    configItem.items &&
    configItem.items.find(
      (item) =>
        isSubStrIgnoreCase(item.key, filterStr) ||
        isSubStrIgnoreCase(item.value, filterStr)
    )
  ) {
    isExpanded = true;
    isFiltered = true;
  }
  return { isFiltered, isExpanded };
};

const useFilterfteConfig = (filterObj = {}, filterStr) => {
  const filteredConfig = {};

  const checkConfValue = (key, value) => {
    let foundKey = false;
    switch (typeof value) {
      case "boolean":
      case "number":
      case "string": {
        if (isSubStrIgnoreCase(value, filterStr)) {
          filteredConfig[key] = value;
          foundKey = true;
        }
        break;
      }
      case "object": {
        if (value && !Array.isArray(value)) {
          const recObj = useFilterfteConfig(value, filterStr);
          if (Object.keys(recObj).length) {
            filteredConfig[key] = recObj;
            foundKey = true;
          }
        } else if (value) {
          value.forEach((val) => {
            const recObj = checkConfValue(key, val);
            if (recObj) {
              filteredConfig[key] = value;
              foundKey = true;
            }
          });
        }
        break;
      }
    }

    return foundKey;
  };
  if (!filterStr) {
    return filterObj;
  }

  Object.entries(filterObj).forEach(([key, value]) => {
    if (isSubStrIgnoreCase(key, filterStr)) {
      filteredConfig[key] = value;
    } else {
      checkConfValue(key, value);
    }
  });
  return filteredConfig;
};

export { useFilterConfigHanlder, useFilterfteConfig };
