import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autorenew from '@material-ui/icons/Autorenew';
import LogoutButton from './components/LogoutButton';
import Auth from './Auth.js';

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
});

const styles = them => ({
    progress: {
        margin: them.spacing.unit * 2,
    },
    loader: {
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    }
});

class LoginPage extends Component {

    state = {
        loading: true,
        message: `Login, please wait.. \nPlease allow third party cookies in your browser settings.`,
        retry: false,
    };

    auth = new Auth();

    componentDidMount() {
        this.auth.checkSession(
            ( payload ) => {
                if (
                    !payload.role
                    || payload.role.length <= 0
                ) {
                    this.setState({
                        loading: false,
                        message: 'Your account is pending validation as you need a Flexi role granted to access the app.',
                        retry: true,
                    });
                    return;
                }
                this.props.userLogin(payload);
            }
        );
    }

    render() {
        const { loading, message, retry } = this.state;
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <div className={classes.loader}>
                    <Typography variant="headline" gutterBottom>
                        {message}
                    </Typography>
                    {loading && <CircularProgress className={classes.progress} color="secondary" />}
                    {retry && <LogoutButton Icon={Autorenew} label="Login" />}
                </div>
            </MuiThemeProvider>
        )
    }
}

export default connect(undefined, { userLogin })(withStyles(styles)(LoginPage));