import React, { useState } from "react";
import { BooleanInput, required as req } from "react-admin";
import { StringField } from "./string";
import SyncIcon from "@material-ui/icons/Sync";

export const BooleanField = ({ description = "", required = false, source, propertyName = "", disableAllFields }) => {
    /***
     * If boolean the component will accept true or false, otherwise will accept any value.
     * This is for mapping purposes
     * */

    const [isBoolean, setIsBoolean] = useState(true);

    return (
        <div style={{ marginTop: 15, display: "flex", alignItems: "center", flexFlow: "nowrap row" }}>
            {isBoolean ? (
                <BooleanInput
                    style={disableAllFields ? { pointerEvents: "none" } : {}}
                    label={description}
                    source={`${source}.${propertyName}`}
                    validate={required ? req() : null}
                    disabled={disableAllFields}
                />
            ) : (
                <StringField
                    style={{ width: "100%", margin: 0 }}
                    propertyName={propertyName}
                    description={description}
                    validate={required ? req() : null}
                    source={`${source}.${propertyName}`}
                    disableAllFields={disableAllFields}
                />
            )}
            {!disableAllFields && (
                <SyncIcon
                    onClick={() => setIsBoolean((value) => !value)}
                    style={{
                        width: "0.9em",
                        color: "gray",
                        cursor: "pointer",
                        alignSelf: !isBoolean ? "flex-end" : null,
                    }}
                    titleAccess={isBoolean ? "Input value based on a variable" : "Static value"}
                />
            )}
        </div>
    );
};
