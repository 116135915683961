import React, { Component, Fragment } from 'react';
import {
    TextInput, Labeled,NumberInput,BooleanInput, FormDataConsumer
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '../IconButton';
import LinkListCard from './Card';
import { getSource, orderByKey, number, negativeNumber, minValue0 } from '../../tools';

class BrandsInputs extends Component {
    shouldComponentUpdate(nextProps,nextState) {
        const { fields } = this.props;
        return fields.length !== nextProps.fields.length 
    }

    render() {
        const { record, source, label, ...rest } = this.props;
        const { fields, addLabel } = rest;
        return (
            <FormDataConsumer>
            {({formData})=>(
                <Fragment>
                <Labeled label={label} />
                    <div style={{ marginBottom: '8px' }}>
                        {fields.length > 0
                        && fields.map((itemSource, index) =>{
                            return (
                            <div key={`brands-list-${itemSource}`}>
                                <LinkListCard
                                    key={`brands-list-${itemSource}`}
                                    index={index}
                                    source={itemSource}
                                    {...rest}
                                />
                            </div>
                            )
                        }).sort((a, b) => {
                            const sourceA = getSource(formData, a.props.source);
                            const sourceB = getSource(formData, b.props.source);
                            return orderByKey(sourceA,sourceB,"position")
                        })
                    }
                    </div>
                    <IconButton
                        color="primary"
                        icon={classes => <AddIcon className={classes.leftIcon} />}
                        onClick={() => fields.push({})}
                    >
                        {addLabel}
                    </IconButton>
                </Fragment>
            )}
            </FormDataConsumer>
        );
    }
}

BrandsInputs.defaultProps = {
    label: 'Brands list',
    getTitle: item => item.name,
    getUrl: item => (item.url ? `Position: ${item.url}` : ''),
    showEmptyKeys : ["name","position","url"],
    addLabel: 'Add Brand',
    removeLabel: 'Remove Brand',
    initiallyExpanded:(item)=> !item.name && !item.url,
    inputFields: [
            <TextInput source="name" label={"Title"} style={{display:"block"}} resettable />,
            <NumberInput
            source="position"
            label="Position"
            validate={[number, minValue0]}
            warn={negativeNumber}
            />,
            <TextInput source="url" label="URL" resettable />,
            <BooleanInput
                label="Open Link in New Window"
                source="target_blank"
            />,
    ],
};

export default BrandsInputs;
