import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import CssBaseline from '@material-ui/core/CssBaseline';
import Admin from './Ui/Admin';
import './globalAdminUi.css';

ReactDOM.render(
    <React.Fragment>
        <CssBaseline />
        <Admin />
    </React.Fragment>,
    document.getElementById('root')
);
