import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import Cookies from 'js-cookie';

const currentBranch = window.currentBranch ? window.currentBranch.toLowerCase() : '/api/sites';

const makeBranchedUrl = url => `${url + (url.indexOf('?') >= 0 ? '&' : '?')}branch=${currentBranch}&clean_data_for_front=true&check_token=true${url.indexOf('status=') === -1 ? '&status=InProgress' : ''}`;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const newUrl = makeBranchedUrl(url);
    // add your own headers here
    options.headers.set('X-User-Name', localStorage.getItem('user'));

    if (localStorage.getItem('role')) {
        options.headers.set('X-Role', localStorage.getItem('role'));
    }

    if (localStorage.getItem('expiresIn')) {
        options.headers.set('X-ExpiresIn', localStorage.getItem('expiresIn'));
    }

    return fetchUtils.fetchJson((newUrl || url), options)
        .catch(err => {
            if (401 === err.status) {
                window.location.href = '#/login'
            }

            const message = `${err.body.status}
            ${(err.body && err.body.detail)
                || (err.body && err.body.title)
                || (err.body && err.body.error)
            }`;

            throw new Error(message);
        });
};

const restClient = jsonServerProvider('/api', httpClient);

export default restClient;

export {
    currentBranch,
    makeBranchedUrl,
    httpClient as fetch
};