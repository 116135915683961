import React, { useEffect, useState } from "react";
import { CardActions, ListButton, ShowButton } from "react-admin";
import DeleteButtonWithConfirmation from "../components/DeleteButtonWithConfirmation";
import { withStyles } from "@material-ui/core/styles";
import Badge from "../components/Badge";
import { getSiteBadges } from "../hooks/fetchSiteInfo";

export const styles = {
  clonedBadge: {
    background: "#fff4e5",
    color: "rgb(102, 60, 0)",
  },
  internationalizedBadge: {
    background: "#d8e4ff",
    color: "#222272",
  },
  subBrandBadge: {
    background: "#ddf7c9",
    color: "#07240f",
  },
  badgesContainer: {
    marginLeft: "10px",
    display: "flex",
    flexWrap: "wrap",
    flex: "auto",
  },
};

const EditActions =  ({ basePath, data, resource, permissions }) => {

  const [badgesArray, setBadgesArray] = useState([])


  useEffect(() => {
    const setSiteBadges = async () =>{
      const siteBadges = await getSiteBadges(data, basePath);
      setBadgesArray(siteBadges.slice())
    }

    setSiteBadges();
  }, [data]);



  return (
    <CardActions style={{ flexWrap: "nowrap" }}>
      <div style={styles.badgesContainer}>
        { badgesArray.map((item) => (
          <Badge {...item} />
        ))}
      </div>

      <ShowButton basePath={basePath} record={data} />
      <ListButton basePath={basePath} />
      {permissions && permissions.checkUserCanCRUD(resource, "delete") ? (
        <DeleteButtonWithConfirmation
          resource={resource}
          basePath={basePath}
          record={data}
        />
      ) : null}
    </CardActions>
  );
};

export default withStyles(styles)(EditActions);
