import React from 'react';
import { SelectArrayInput,TextInput, Filter } from 'react-admin';
import { withStyles } from "@material-ui/core/styles";

export const styles = theme => ({
    form: {
    //   width: 150,
      "& > div.filter-field[data-source=site]":{width:"300px"},
    },
  });

const ListFilter = props => (
    <Filter className={props.classes.form} {...props}>
        <TextInput source="name" resettable label="Search by name" alwaysOn />
        <TextInput source="guid" /* alwaysOn */ resettable label="Search by id (guid)"/>
        <TextInput source="uri" alwaysOn resettable label="Search by URI"/>
        <SelectArrayInput
            fullWidth={true}
            alwaysOn 
            source="site"
            label="Search in site"
            choices={window.sitesChoices}
            parse={(val)=> val ? val.join("||") : ""}
            format={(val)=> val ? val.split("||") : []}
        />
    </Filter>
);

export default withStyles(styles)(ListFilter);
