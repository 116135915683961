import React, { useEffect, useState } from "react";
import { TextInput, FormDataConsumer, Button } from "react-admin";

import SliceModal from "./SliceModal";

import IconContentAdd from "@material-ui/icons/Add";
import IconContentEdit from "@material-ui/icons/Edit";

const SliceWidgetInputComponent = ({ source, record, permissions }) => {
    // Getting value from squared brackets since it is a string,
    // and cant get the value otherwise
    const currentWidget = source.match(/\[(.*?)\]/)[1];
    const [showDialog, setShowDialog] = useState(false);
    const [isLoadingVersion, setIsLoadingVersion] = useState(true);

    useEffect(() => {
        window.slice = { version: "master" };
        const fetchSliceVersion = async () => {
            try {
                // We use this URL so it is not cached
                const response = await fetch(
                    "https://www.techradar.com.master.van-prod.futureplc.engineering/slice/version",
                    { headers: { Accept: "application/json" } }
                );

                if (response.ok) {
                    const versionData = await response.json();
                    window.slice.version = versionData.version;
                    setIsLoadingVersion(false);
                } else {
                    console.error("Error trying to fetch version", response.status);
                }
            } catch (error) {
                console.error("Error trying to fetch version", error);
            }
        };

        window.environment === "prod" ? fetchSliceVersion() : setIsLoadingVersion(false);
    }, []);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const sliceProps = { handleClick, handleCloseClick, showDialog, source, record, permissions };

    if (isLoadingVersion) {
        return <span style={{ color: "#757575" }}>Loading...</span>;
    }

    return (
        <FormDataConsumer>
            {({ formData }) => {
                const selectedSliceName = formData.widgets[currentWidget].slice_component;

                return (
                    <>
                        <div style={{ minHeight: 50 }}>
                            <span
                                style={{
                                    display: "block",
                                    marginTop: 20,
                                    fontSize: "1.1em",
                                    fontWeight: "bold",
                                    color: "#8e8e8e",
                                }}
                            >
                                Template
                            </span>
                            {selectedSliceName && (
                                <TextInput style={{ marginTop: 0 }} source={selectedSliceName} disabled />
                            )}
                            <Button
                                onClick={handleClick}
                                label={selectedSliceName ? `Edit settings` : "Choose and set up your slice (Required)"}
                            >
                                {selectedSliceName ? <IconContentEdit /> : <IconContentAdd />}
                            </Button>
                        </div>

                        {showDialog && <SliceModal formData={formData} {...sliceProps} />}
                    </>
                );
            }}
        </FormDataConsumer>
    );
};

export default React.memo(SliceWidgetInputComponent);
