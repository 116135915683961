import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Chips from "./Chip";

const styles = (theme) => ({
  margin: theme.spacing.unit * 0.5,
  list: { "list-style-type": "none" },
});

const ChipsArray = ({
  chipsData = [],
  classes,
  ...rest
}) => {

  return (
    <ul className={classes.list}>
      {chipsData.map((data, key) => {
        return (
          <li key={`chipslist-${data}`}>
            <Chips
              clickable={true}
              data={data}
              chipsKey={`chips-${data}`}
              {...rest}
            />
          </li>
        );
      })}
    </ul>
  );
};
export default withStyles(styles)(ChipsArray);
