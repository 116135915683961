import React, { Component, Fragment } from "react";
import { SelectInput, BooleanInput, TextInput } from "react-admin";
import { Field } from "redux-form";
import Grid from "./Grid";
import SingleSelectWithCustom from "./SingleSelectWithCustom";
import RichTextInput from "ra-input-rich-text";
import AceEditorInput from "./AceEditorInput";
import { QueryFields } from "./SolrQueryFields/QueryFields";

const RenderEditor = ({ contentType, ...rest }) => {
  switch (contentType) {
    case "html":
      return (
        <AceEditorInput
          {...rest}
          label={"HTML Markup"}
          mode={contentType}
          placeholder="<!-- Put your HTML code here -->"
        />
      );
    case "twig":
      return (
        <AceEditorInput
          {...rest}
          label={"HTML / Twig Markup"}
          mode={contentType}
          placeholder="<!-- Put your HTML / Twig code here -->"
        />
      );
    default:
      return (
        <RichTextInput
          {...rest}
          label={`Paragraph`}
          toolbar={[
            [
              { font: [] },
              "bold",
              "italic",
              "underline",
              "blockquote",
              { align: [] },
              { list: "ordered" },
              { list: "bullet" },
              { direction: "rtl" },
              { size: ["small", false, "large", "huge"] },
              { header: [1, 2, 3, 4, 5, 6, false] },
              { color: [] },
              { background: [] },
              "link",
              "code-block",
              { script: "sub" },
              { script: "super" },
              "clean",
            ],
          ]}
        />
      );
  }
};
class ContentParsedWidgetInputComponent extends Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    this.state = {
      sourceQuery: (record && record.source_query) || null,
      contentType: (record && record.content_type) || null,
      sourceApi: (record && record.source_api) || null,
    };
  }

  handleSourceQueryChange = (e, sourceQuery) => {
    this.setState({ sourceQuery });
  };

  handleSwitchEditor = (e, contentType) => {
    this.setState({ contentType });
  };

  handleSourceApiChange = (e, val) => {
    this.setState({ sourceApi: val });
  };

  render() {
    const { source } = this.props;
    const { sourceQuery, contentType, formData } = this.state;
    return (
      <Fragment>
        <SelectInput
          source={`${source}.template`}
          label="Template"
          choices={window.widgetTemplates.contentparsed}
        />
        <BooleanInput
          source={`${source}.iterable`}
          label="Iterable"
          allowEmpty
        />
        <BooleanInput
          source={`${source}.multipage`}
          label="Multipage"
          allowEmpty
        />
        <BooleanInput
          label="Active SSE"
          source={`${source}.active_sse`}
          allowEmpty
        />
        <SelectInput
          source={`${source}.source_query`}
          label="Source Query"
          choices={[
            { id: "content_document", name: "CMS Document" },
            { id: "curated", name: "Curated" },
            { id: "external", name: "External" },
            { id: "fields_query", name: "Fields Query" },
          ]}
          optionText="name"
          optionValue="id"
          defaultValue="content_document"
          onChange={this.handleSourceQueryChange}
        />
        {sourceQuery === "curated" && (
          <>
            <SelectInput
              source={`${source}.content_type`}
              label="Editor Type"
              choices={[
                { id: "standard", name: "Rich Editor" },
                { id: "html", name: "HTML" },
                { id: "twig", name: "HTML + Twig" },
              ]}
              defaultValue={{ id: "standard", name: "Rich Editor" }}
              onChange={this.handleSwitchEditor}
            />
            <RenderEditor
              source={`${source}.curated_content`}
              contentType={contentType}
            />
          </>
        )}
        {sourceQuery === "external" && (
          <>
            <TextInput source={`${source}.external_url`} label="External Url" style={{marginLeft: "20px" }} />
            <TextInput
              source={`${source}.external_target_selector`}
              label="CSS Selector"
              style={{minWidth: "20%", marginLeft: "20px" }}
            />
            <TextInput
                  source={`${source}.external_json_content_key`}
                  label="External Feed JSON Content key"
                  style={{minWidth: "30%", marginLeft: "20px" }}
                  helper={"eg: content"}
            />
          </>
        )}
        {sourceQuery === "fields_query" && (
          <>
            <Grid columns="3">
              <SelectInput
                source={`${source}.source_api`}
                label="widget.source_api"
                resettable
                choices={window.dynamicSourceApiChoices}
                onChange={this.handleSourceApiChange}
              />
              <Field
                label={"SOLR Collection"}
                name={`${source}.search_index`}
                component={SingleSelectWithCustom}
                isClearable={true}
                options={["proof", "proof_default"]}
                helper={"eg: proof_cyclingnews"}
                data={formData}
              />
            </Grid>
            <QueryFields
              source={source}
              showAdvancedSolrFilters={false}
            ></QueryFields>
          </>
        )}
      </Fragment>
    );
  }
}

export default ContentParsedWidgetInputComponent;
