import React from 'react';
import { Field } from 'redux-form';
import { NumberInput, TextInput, SelectInput, BooleanInput } from 'react-admin';
import { negativeNumber, number, minValue0 } from './LinkListInput';
import LinkListInput from './LinkListInput';
import ReferenceWidgets from './ReferenceWidgets';

const typesById = window.toc_types.reduce((acc, type) => ({ ...acc, [type.id]: type }), {});

export default props => (
    <LinkListInput
        initiallyExpanded={item => !item.type}
        getTitle={item => (item.type ? `Type: ${item.type && typesById[item.type] && typesById[item.type].name}` : '')}
        inputFields={[
            <SelectInput source="type" label="Type" choices={window.toc_types} resettable />,
            <BooleanInput
                label="widget.lightbox_toggler"
                source="lightbox_toggler"
            />,
            <Field
                name="slug"
                label="widget.lightbox_target_slug"
                component={ReferenceWidgets}
            />,
            <NumberInput
                source="position"
                label="Position"
                validate={[number, minValue0]}
                warn={negativeNumber}
            />,
            <TextInput source="title" label="Title" />,
            <TextInput source="link" label="Link" />,
        ]}
        {...props}
    />
);
