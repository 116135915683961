import React, { useCallback } from "react";
import {
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
  BooleanInput,
  LongTextInput,
  ReferenceArrayInput,
  Labeled,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Field, FieldArray, reduxForm, getFormSyncErrors } from "redux-form";
import { ColorInput } from "react-admin-color-input";
import EditToolbar from "../EditToolbar";
import InputFile from "../../components/InputFile";
import LinkListInput from "../../components/LinkListInput";
import SelectListInput from "../../components/SelectListInput";
import ApiInputs from "../../components/LinkListInput/ApiIndex";
import SearchInputs from "../../components/LinkListInput/SearchIndex";
import VanillaNavigationIndex from "../../components/LinkListInput/VanillaNavigationIndex";
import DocumentsConfig from "../../components/CMSConfigs/DocumentsConfig";
import FeaturesConfig from "../../components/CMSConfigs/FeaturesConfig";
import GlobalConfig from "../../components/CMSConfigs/GlobalConfig";
import Outbox from "../../components/CMSConfigs/Outbox";
import RegionsConfig from "../../components/CMSConfigs/RegionsConfig";
import TrendingInputs from "../../components/LinkListInput/TrendingIndex";
import BrandsInputs from "../../components/LinkListInput/BrandsIndex";
import ColoredLine from "../../components/ColoredLine";
import CommonDynamicFieldsComponents from "../../components/CommonDynamicFieldsComponents";
import MultipleSelectWithCustom from "../../components/MultipleSelectWithCustom";
import CustomTextOnSelect from "../../components/CustomTextOnSelect";
import ChangelogField from "../../components/ChangelogField";
import Grid from "../../components/Grid";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import AceEditorInput from "../../components/AceEditorInput";
import RelatedDocuments from "../../components/RelatedDocuments";
import ModalButton from "../../components/ModalButton";
import ViewColumn from "@material-ui/icons/ViewColumn";
import InfoIcon from '@material-ui/icons/Info';


import {
  required,
  RACheckIfJsonIsValid,
  findDuplicateKeyInArrayObj,
  checkIfSvgHasTitle,
} from "../../tools";

const colorPickerInPutField = (index, sourcePrefix, labelPrefix) => {
  return (
    <ColorInput
      key={index}
      source={sourcePrefix + "_" + (index + 1)}
      picker="Photoshop"
      label={labelPrefix + "-" + (index + 1)}
      style={{ display: "inline-flex" }}
    />
  );
};
export const styles = {
  grid: {
    height: "fit-content",
  },
  alignTextInput: { margin: 0 },
  quarterWidthCss: { width: "25%" },
  helperTextList: { paddingLeft: 15, margin: 0 },
  miscWarning: {
    width: "100%", 
    padding: "15px", 
    borderRadius: "5px", 
    display:"flex", 
    flexDirection: "column", 
    gap: "25px",
    marginTop: "20px"
  },
  miscWarningLabel: {
    display: "flex",
    alignItems: "center",
    color: "#653b03",
  },
};
const helperTextList = (classes) => (
  <ul className={classes.helperTextList}>
    <li>Parent config overwritten by its child</li>
    <li>Use "null" value for any config to reset parent config</li>
  </ul>
);
const limitRenderSuggestions = (val) => {
  return val.trim().length > 2;
};
const optionRenderer = (choice) => `${choice.guid}`;
export const EditForm = ({ classes, permissions, ...props }) => {
  const getModalTitle = useCallback(
    (record) => `Documents related to this site : ${record.guid}`,
    []
  );
  const hasParentSite = props.record && props.record.parent_site_guid;
  const filterToQuery = useCallback((text) => ({ guid: text }), []);

  return (
      <TabbedForm
        submitOnEnter={false}
        redirect="edit"
        toolbar={<EditToolbar record={props.record} permissions={permissions} />}
        {...props}
      >
        <FormTab guid="summary" label="summary">
          <TextInput source="name" />
          <TextInput
            source="site_id"
            label="Site ID"
            required
            validate={[required]}
          />
          <SelectInput
            source="lang"
            label={"Language"}
            choices={window.sitesLangChoices}
            required
            validate={[required]}
          />
          <SelectInput
            source="territory"
            label={"Territory"}
            choices={window.sitesTerritoryChoices}
            required
            validate={[required]}
          />
          <BooleanInput source="full_flexi_site" defaultValue={false} />
          <ModalButton
            IconElement={<ViewColumn />}
            getModalTitle={getModalTitle}
            labelButton={`Documents related`}
            maxWidth={false}
          >
            <RelatedDocuments route={`api/sites_related`} />
          </ModalButton>
          <Grid columns={3} classes={classes.grid}>
            <SelectInput
              source={`is_master`}
              label="Is master?"
              choices={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
                { id: "null", name: "Null (not inherit parent)" },
              ]}
              resettable
            />
            {permissions && permissions.checkUserIsSuperAdmin() && (
              <>
                <TextInput
                  className={classes.alignTextInput}
                  source={"master_site"}
                  label={"Master Site"}
                  resettable
                />
                <TextInput
                  className={classes.alignTextInput}
                  source={"master_site_id"}
                  label={"Master Site Id"}
                  resettable
                />
              </>
            )}
          </Grid>
          <ReferenceInput
            allowEmpty
            className={classes.quarterWidthCss}
            label="Parent Site"
            source={`parent_site_guid`}
            // perPage={100}
            reference="sites"
            fullWidth={true}
            filterToQuery={filterToQuery}
            optionText={optionRenderer}
            sort={{ field: "guid", order: "ASC" }}
          >
            <AutocompleteInput
              helperText={helperTextList(classes)}
              shouldRenderSuggestions={limitRenderSuggestions}
              optionText={optionRenderer}
              optionValue="guid"
            ></AutocompleteInput>
          </ReferenceInput>
          <ReferenceArrayInput
            {...props.rest}
            source="subbrands"
            label="Subbrands"
            reference="sites"
            fullWidth={true}
            sort={{ field: "guid", order: "ASC" }}
            allowEmpty
            shouldRenderSuggestions={limitRenderSuggestions}
            optionText={optionRenderer}
            optionValue="guid"
          >
            <FormDataConsumer>
              {({ choices }) => (
                <FieldArray
                  label="Subbrands"
                  name="subbrands"
                  source="subbrands"
                  component={MultipleSelectWithCustom}
                  choices={choices.map((value) => value.guid)}
                  helper={`You can enter input null to disable inheritance from parent`}
                />
              )}
            </FormDataConsumer>
          </ReferenceArrayInput>
          <Field
            multiple={false}
            name="logo"
            label="Logo"
            component={InputFile}
            warn={[checkIfSvgHasTitle]}
            validate={[checkIfSvgHasTitle]}
          />
          <Field
            multiple={false}
            name="mobile_logo"
            label="Mobile Logo"
            component={InputFile}
            warn={[checkIfSvgHasTitle]}
            validate={[checkIfSvgHasTitle]}
          />
          <Field
            multiple={false}
            name="brand_logo"
            label="Brand Logo"
            component={InputFile}
            warn={[checkIfSvgHasTitle]}
            validate={[checkIfSvgHasTitle]}
          />
          <LongTextInput source="strapline" label="Strapline" />
          <TextInput
            source="url"
            label="Site URL"
            helperText="This url is used to purge cache of site entity"
          />
        </FormTab>
        <FormTab guid="cms_config" label="CMS config">
          {/* https://purch1.atlassian.net/wiki/spaces/TECH/pages/2515664984/TDP+-+Proof+Site+Configuration+through+Flexi */}
          <Grid gridTemplateColumns={`1fr 2fr`} classes={classes.grid}>
            <GlobalConfig
              label={"Main"}
              /* style={{gridRow:"1 / 2 span"}} */ source={"cms_config"}
            ></GlobalConfig>
            <Outbox
              label={"FCSIS / Varnish / Redirect"}
              source={"cms_config"}
              permissions={permissions}
              {...props}
            ></Outbox>
            <RegionsConfig
              label={"Regions"}
              source={"cms_config.regions"}
            ></RegionsConfig>
            <FeaturesConfig
              label={"Features"}
              source={"cms_config.features"}
            ></FeaturesConfig>
            <DocumentsConfig
              label={"Documents"}
              style={{ gridColumn: "1 / 2 span" }}
              source={"cms_config.documents"}
              record={props.record}
            ></DocumentsConfig>
          </Grid>
        </FormTab>
        <FormTab guid="look_and_feel" label="Look And Feel">
          <Grid columns={5} classes={classes.grid}>
            <FormDataConsumer>
              {(formData) => (
                <Labeled style={{ marginTop: 10 }} label="Theme">
                  <SelectInput
                    defaultValue={"responsive"}
                    source="theme"
                    label={"Theme"}
                    choices={window.sitesTheme}
                    formData={formData}
                  />
                </Labeled>
              )}
            </FormDataConsumer>
            <Grid classes={classes.grid}>
              <Labeled
                style={{ marginTop: 10 }}
                guid="brand_colors"
                label="Brand Colors"
              />
              {window.brandLengthColors > 0 &&
                Array.from(Array(window.brandLengthColors)).map((field, index) =>
                    colorPickerInPutField(index, "brand_color", "brand-color")
                )}
            </Grid>
            <Grid classes={classes.grid}>
              <Labeled
                style={{ marginTop: 10 }}
                guid="secondary_colors"
                label="Secondary colors"
              />
              {window.secondaryLengthColors > 0 &&
                Array.from(Array(window.secondaryLengthColors)).map(
                  (field, index) =>
                    colorPickerInPutField(
                      index,
                      "secondary_color",
                      "secondary-color"
                    )
                )}
            </Grid>
            <Grid classes={classes.grid}>
              <Labeled
                style={{ marginTop: 10 }}
                guid="grayscales_colors"
                label="Grayscales colors"
              />
              {window.grayscalesLengthColors > 0 &&
                Array.from(Array(window.grayscalesLengthColors)).map(
                  (field, index) =>
                    colorPickerInPutField(
                      index,
                      "grayscales_color",
                      "grayscales-color"
                    )
                )}
            </Grid>
            <Grid classes={classes.grid}>
              <Labeled
                style={{ marginTop: 10 }}
                guid="font_family"
                label="Font Family"
              />
              {window.fontFamilyLength > 0 &&
                Array.from(Array(window.fontFamilyLength)).map((field, index) => (
                    <TextInput
                      key={`font_family_${index + 1}`}
                      source={`font_family_${index + 1}`}
                      label={`font-family-${index + 1}`}
                      style={{ display: "inline-flex" }}
                    />
                  ))}
            </Grid>
          </Grid>
        </FormTab>
        <FormTab guid="header" label="Header">
          <FieldArray
            name="header.vanilla_navigation"
            label={"Vanilla Navigation"}
            component={VanillaNavigationIndex}
          />
          <FieldArray
            name="header.vanilla_trending"
            label={"Vanilla Trending"}
            component={VanillaNavigationIndex}
          />
          <FieldArray
            name="header.vanilla_brands"
            label={"Vanilla Brands"}
            component={VanillaNavigationIndex}
          />
          <ColoredLine />
          <FieldArray
            name="header.navigation"
            label={"Navigation"}
            component={LinkListInput}
          >
            <FieldArray
              name="sublinklist"
              addLabel="Add Sub link"
              removeLabel="Remove Sub link"
              component={LinkListInput}
            />
          </FieldArray>
          <FieldArray
            name="header.top_navigation"
            label={"Top Navigation"}
            component={LinkListInput}
          >
            <FieldArray
              name="sublinklist"
              addLabel="Add Sub link"
              removeLabel="Remove Sub link"
              component={LinkListInput}
            />
          </FieldArray>
          <FieldArray
            name="header.trending"
            label={"Trending"}
            component={TrendingInputs}
          ></FieldArray>
          <FieldArray
            name="header.brands"
            label={"Brands"}
            component={BrandsInputs}
          ></FieldArray>
          <ColoredLine />
          <FieldArray
            name="header.social"
            label={"Active Social"}
            component={SelectListInput}
            category="social"
          />
        </FormTab>
        <FormTab guid="footer" label="Footer">
          <FieldArray
            name="footer.navigation"
            label={"Navigation"}
            component={LinkListInput}
          >
            <FieldArray
              name="sublinklist"
              addLabel="Add Sub link"
              removeLabel="Remove Sub link"
              component={LinkListInput}
            />
          </FieldArray>
          <FieldArray
            name="footer.social"
            label={"Active Social"}
            component={SelectListInput}
            category="social"
          ></FieldArray>
          <SelectInput
            source="footer.adress"
            label={"Future location"}
            choices={window.adressLocations}
          />
        </FormTab>
        <FormTab guid="social" label="Social">
          <FieldArray
            name="social"
            label={"Social"}
            additionnalFields={[
              <TextInput source="icon_class" label="Class icon" key={""} />,
            ]}
            component={LinkListInput}
          ></FieldArray>
        </FormTab>
        <FormTab guid="config" label="Config">
          <FieldArray
            name="api"
            label={"Config"}
            component={ApiInputs}
          ></FieldArray>
        </FormTab>
        <FormTab guid="search" label="Search">
          <FieldArray
            name="search"
            label={"Search"}
            component={SearchInputs}
          ></FieldArray>
        </FormTab>
        {permissions && permissions.userIsAdminOrSuperAdmin() && (
          <FormTab guid="misc" label="Misc">
            <LongTextInput
              source="misc.site_css_class"
              label={"Site CSS Class"}
            />
                   
          <div style={{...styles.miscWarning, ...{background: hasParentSite ? "#face92" : "", padding: hasParentSite ? "15px" : "0px" }}}>
          {hasParentSite && (
              <div style={styles.miscWarningLabel}> <InfoIcon style={{ marginRight: "5px" }}/> Changes to this tab
              will DELETE the original config,
              NOT extend them </div>
            )}
          <AceEditorInput
              source={`misc.site_css`}
              displayAceLabel={true}
              mode={"css"}
              label={"Site CSS"}
              placeholder="<!-- Put your CSS code here -->"
            />
            <AceEditorInput
              source={`misc.amp_site_css`}
              displayAceLabel={true}
              mode={"css"}
              label={"AMP site CSS"}
              placeholder="<!-- Put your CSS code here -->"
            />
            <AceEditorInput
              source={`misc.head_markup`}
              displayAceLabel={true}
              label={"Head markup"}
              placeholder="<!-- Put your HTML code here -->"
            />
            <AceEditorInput
              source={`misc.bottom_markup`}
              displayAceLabel={true}
              label={"Bottom markup"}
              placeholder="<!-- Put your HTML code here -->"
            />
          </div>
            <LongTextInput source="misc.manifest" label={"Manifest Json"} />
            <FormDataConsumer>
              {({ formData }) => (
                <FieldArray
                  defaultValues={window.defaultSiteMisc.custom}
                  name="misc.custom"
                  label={"Misc"}
                  longTextValue={true}
                  component={CommonDynamicFieldsComponents}
                  errorValidator={[RACheckIfJsonIsValid]}
                  warnValidator={[RACheckIfJsonIsValid]}
                  permissions={permissions}
                  additionnalFields={[
                    <CustomTextOnSelect
                      choices={window.fileType}
                      source={"format"}
                      label={"Content-Type"}
                      record={props.record}
                      key={0}
                    />, //source & label need to be initialed because they are overrided by React.clone in CommonDynamicFieldsComponents
                  ]}
                  formData={formData}
                  {...props}
                ></FieldArray>
              )}
            </FormDataConsumer>
            <Field
              multiple={false}
              name="misc.favicon_180x180"
              label="Favicon 180x180"
              component={InputFile}
            />
            <Field
              multiple={false}
              name="misc.favicon_32x32"
              label="Favicon 32x32"
              component={InputFile}
            />
            <Field
              multiple={false}
              name="misc.favicon_16x16"
              label="Favicon 16x16"
              component={InputFile}
            />
          </FormTab>
        )}
        {permissions && permissions.checkUserCanCRUD("sites_log", "read") && (
          <FormTab guid="search" label="Changelogs">
            <ChangelogField guid={props.record.guid} />
          </FormTab>
        )}
      </TabbedForm>
  );
};

const mapStateToProps = (state, props) => ({
  syncErrors: getFormSyncErrors("record-form")(state),
});

const validate = (values) => {
  const errors = {};

  if (
    values &&
    values["api"] &&
    findDuplicateKeyInArrayObj(values["api"]).length > 0
  ) {
    errors["api"] = findDuplicateKeyInArrayObj(values["api"]);
  }

  if (
    values &&
    values["misc"] &&
    values["misc"]["custom"] &&
    findDuplicateKeyInArrayObj(values["misc"]["custom"], "key").length > 0
  ) {
    errors["misc"] = {};
    errors["misc"]["custom"] = findDuplicateKeyInArrayObj(
      values["misc"]["custom"],
      "key"
    );
  }

  if (Object.keys(errors).length === 0) {
    return;
  }

  return errors;
};

export default reduxForm({
  form: `record-form`,
  touchOnChange: true,
  asyncBlurFields: [],
  destroyOnUnmount: false,
  validate: validate,
  shouldError: () => false,
})(connect(mapStateToProps)(withStyles(styles)(EditForm)));
