import React, {useCallback, useState} from "react";
import { withStyles } from '@material-ui/core/styles';
import Grid from "../Grid";
import IconButton from "../IconButton";
import MobileFriendly from "@material-ui/icons/Smartphone";
import Computer from "@material-ui/icons/Computer";
import { InputLabel } from "@material-ui/core";
import Tablet from "@material-ui/icons/Tablet";

const styles = {
    frame: {
        height: "fit-content",
        minHeight: "520px",
        overflowY: "scroll",
        resize: "both",
        overflow: "auto",
        border: '1px solid grey',
        margin: '0 auto'
    }
}

const Preview = ({ html = 'No HTML Found', css = '', classes, handleTailwindCss, style = {} }) => {
    const alpineJs = '<script defer src="https://unpkg.com/alpinejs@3.2.2/dist/cdn.min.js"></script>';
    const tailwind = '<script src="https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp"></script>';
    const srcDoc = '<html lang="en"><head><title>Preview</title>'+ alpineJs + tailwind + '<style class="custom-style">'+ css +'</style></head><body>'+ html +'</body></html>';
    const sandbox = 'allow-same-origin allow-popups-to-escape-sandbox allow-scripts allow-popups allow-forms allow-pointer-lock allow-top-navigation';
    const [sizing, setSizing] = useState({ width: "100%" });
    const handleMediaQueryChange = useCallback((width) => {
        setSizing({ width: width });
    }, [])

    return (
        <>
            <div>
                <Grid columns={3}>
                    <IconButton
                        color="primary"
                        icon={(className) => (
                            <MobileFriendly className={className.leftIcon}/>
                        )}
                        onClick={() => handleMediaQueryChange(499)}
                    >
                        Mobile
                    </IconButton>
                    <IconButton
                        color="primary"
                        icon={(className) => <Tablet className={className.leftIcon}/>}
                        onClick={() => handleMediaQueryChange(768)}
                    >
                        Tablet
                    </IconButton>
                    <IconButton
                        color="primary"
                        icon={(className) => <Computer className={className.leftIcon}/>}
                        onClick={() => handleMediaQueryChange("100%")}
                    >
                        Desktop
                    </IconButton>
                </Grid>
            </div>
            <InputLabel htmlFor={`preview-iframe`}>
                <div>Preview</div><br/>
                <iframe id={'preview-iframe'} onLoad={() => handleTailwindCss ? handleTailwindCss() : ''} srcDoc={srcDoc} sandbox={sandbox} className={classes.frame} style={{display: 'block', ...sizing}} title={'Preview'} width="100%"/>
            </InputLabel>
        </>
    );
};

export default withStyles(styles)(Preview);
