import Icon from '@material-ui/icons/ViewHeadline';
import List from './List';
import Edit from './Edit';
import Show from './Show';
import Create from './Create';

const name = 'sites';

export {
    List,
    Edit,
    Show,
    Create,
    Icon,
    name,
};
