import auth0 from 'auth0-js';
import {fetch as fetchApi} from "./restClient";

export default class Auth {

    auth0 = new auth0.WebAuth({
        domain: window.auth_config.domain,
        clientID: window.auth_config.clientID,
        redirectUri: window.location.origin,
        responseType: 'token id_token',
        scope: 'openid profile email groups roles offline_access',
        leeway: 60,
    });


    login() {
        this.auth0.authorize({ connection: window.auth_config.database });
    }

    logout() {
        this.auth0.logout({
            returnTo: window.location.origin + '/#/login',
            clientID: window.auth_config.clientID
        });
    }

    checkSession(callback) {
        // auth for the app
        this.auth0.checkSession({}, async (err, authResult) => {
            if (err !== null) {
                this.login();
                return;
            }

            let role = [];
            let connections = [];
            const index = authResult.idTokenPayload.sub.lastIndexOf('|');
            // User id based on current identity provider (database)
            let user_id = window.auth_config.database + authResult.idTokenPayload.sub.slice(index)
            // Legacy User Id
            let legacy_user_id = 'ad|FutureAD' + authResult.idTokenPayload.sub.slice(index)
            let current_user_id = 'ad|' + window.auth_config.database + authResult.idTokenPayload.sub.slice(index);
            await this.getAccessToken()
            let tktemp = localStorage.getItem('role_access_token')

            await fetchApi( `/api/roles/${authResult.idTokenPayload.sub}` )
                .then( res => res.json )
                .then( rolesResponse => {
                    try {
                        const rolesArr = JSON.parse( rolesResponse );
                        if ( rolesArr.length > 0 ) {
                            // overriding auth0 autorization extension role with role obtained via Flexi API RolesController
                            rolesArr.forEach( roleItem => {
                                // checking that we are indeed in presence of a Flexi app' role
                                if ( roleItem.name.includes( "FLEXI_" ) )
                                    // get everything after FLEXI_
                                    role.push( roleItem.name.split('_').pop() );
                            });
                        }

                    } catch ( rolesRespErr ) {
                        console.error( rolesRespErr );
                    }
                });

            // Update main user if null on legacy user
            await this.getUserIdentities(tktemp, legacy_user_id)
                .then(function(result) {
                    if (result.statusCode === 404 ) {
                        legacy_user_id = current_user_id
                    }
                });

            // Start the link account process if main user is not user id based on the current database
            if (legacy_user_id !== current_user_id) {
                // Get main user's identities
                await this.getUserIdentities(tktemp, legacy_user_id).then(function(result) {
                    result = Object.entries(result)[0];
                    result[1].forEach(function(item) {
                        connections.push(item.connection)
                    });
                });

                // Do the merge of user_id account into main user account if the related database is not in main user identities
                if (connections.indexOf(window.auth_config.database) === -1) {
                    await this.linkAccount(tktemp, legacy_user_id, user_id)
                    window.location.reload()
                }
            }

            window.auth_config.client_secret = '';
            role = this.hasInvalidRoles( role ) ? [] : role;
            if ( !role.length ) {
                // account is pending validation
                callback({ role });
                return;
            }
            // get the right accessToken
            this.auth0.checkSession({},
                (error, apiResult) => {
                    if (error) {
                        this.logout();
                        return;
                    }
                    const { email, companies } = apiResult.idTokenPayload;
                    callback({
                        role,
                        companies: companies ? companies : [],
                        token: apiResult.accessToken,
                        expiresIn: authResult.expiresIn,
                        user: email
                    });
            }); // EO recursive call to auth0.checkSession
        }); // EO initial call to auth0.checkSession
    } // EO checkSession method

    hasInvalidRoles = ( roles ) => {
        let containsInvalidRoles = false;
        roles.forEach( role => {
            if(
                !["SuperAdmin","Admin","EditorInChief", "Central", "AbTester", "Editor", "ReadOnly","Rejected", "AdOps", "DummyRole"]
                    .includes( role )
            )
            containsInvalidRoles = true;
        });
        return containsInvalidRoles;
    };

    getAccessToken() {
        let role_access_token = null;
        const bodyParams = {
            grant_type: "client_credentials",
            client_id: window.auth_config.clientID,
            client_secret: window.auth_config.client_secret,
            audience: window.auth_config.audience
        };

        return fetch(window.auth_config.auth0TokenDomain, { method : 'POST', headers : {"content-type": "application/json"}, body: JSON.stringify(bodyParams) })
            .then((response) => response.json())
            .then((result) => {
                role_access_token = result.access_token;
                localStorage.setItem("role_access_token", role_access_token);
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
    }

    linkAccount(token, userId, subId) {
        let headers = { "Authorization": `Bearer ${token}`, "Accept": "application/json", "Content-Type": "application/json"};
        let raw = JSON.stringify({
            "provider": "ad",
            "user_id": `${subId}`
        });

        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        fetch(`https://futureplc.eu.auth0.com/api/v2/users/${userId}/identities`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    getUserIdentities(token, userId) {
        let headers = {"Accept": "application/json", "Authorization": `Bearer ${token}`};
        let requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        return fetch(`https://futureplc.eu.auth0.com/api/v2/users/${userId}?fields=identities&include_fields=true`, requestOptions)
            .then(function(response) {
                return response.json();
            }).then(function(json) {
                return json;
            })
            .catch(error => console.log('error', error));
    }
}
