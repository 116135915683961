import React from "react";
import { SelectInput, required as req } from "react-admin";
import { capName } from "../../../Helpers/strings";

export const DropdownField = ({
    description = "",
    required = false,
    source,
    propertyName = "",
    disableAllFields,
    ...props
}) => {
    const choices = props.enum.map((option) => {
        return {
            id: option,
            name: capName(option),
        };
    });

    return (
        <SelectInput
            component={SelectInput}
            source={`${source}.${propertyName}`}
            label={description}
            validate={required ? req() : null}
            choices={choices}
            disabled={disableAllFields}
            resettable
        />
    );
};
