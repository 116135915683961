import React from "react";
import {Datagrid, TextField, EditButton, ShowButton, RichTextField} from "react-admin";
import DeleteButtonWithConfirmation from "../../components/DeleteButtonWithConfirmation";
import CloneButton from "../../components/Clone";
import ListFilter from "./ListFilter";
import List from "../List";
import CopyToProd from "../../components/CopyToProd";
import { connect } from "react-redux";
import { isCodeFreeze } from "../Settings/settingsUI";
import CloneCustomWidget from "../../components/Clone/CloneCustomWidget";

const rowStyle = (record) => {
  return {
    background: !record.is_published && "#f5f5f5",
  };
};

const CustomWidgetList = (props) => {
  const {
    resource,
    permissions,
    codeFreeze: { status },
    ...rest
  } = props;

  return (
    <List
      perPage={100}
      resource={resource}
      permissions={permissions}
      sort={{ field: "last_modified", order: "DESC" }}
      filters={<ListFilter />}
      {...rest}
    >
      <Datagrid rowStyle={rowStyle}>
        <TextField source="guid" label="ID" />
        <TextField source="name" />
        <RichTextField source="customwidget_tags" label="Category Tags" />
        {permissions && permissions.checkUserCanCRUD(resource, "update") ? (
          <EditButton
            disabled={status && !permissions.checkUserIsSuperAdmin(resource)}
          />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "read") ? (
          <ShowButton />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "create") ? (
          <CloneButton>
            <CloneCustomWidget />
          </CloneButton>
        ) : null}
        {permissions && permissions.checkUserCanCopyToProd(resource) ? (
          <CopyToProd />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "delete") ? (
          <DeleteButtonWithConfirmation
            disabled={
              status && !permissions.checkUserIsSuperAdmin(resource)
            }
          />
        ) : null}
      </Datagrid>
    </List>
  );
};

const mapStateToProps = (state) => ({
  codeFreeze: isCodeFreeze(state.settingsui),
});

export default connect(mapStateToProps)(CustomWidgetList);
