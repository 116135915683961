import axios from 'axios';
import fetchRA from "./fetchRA";


export const useIsSubbrand = async (masterSite, site_id) =>{
    const subBrands = await axios.get(`${window.location.origin}/api/sites/${masterSite}?fl=subbrands`);
    return subBrands && subBrands.data && subBrands.data.subbrands && subBrands.data.subbrands.includes(site_id);
  }

export const getFTEConfig = async (record) => {
  if(!record.parent_site_guid && !record.master_site_id){
    let url = record.url;
    const sitePattern = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    if(!url || !url.match(sitePattern)){
      url = `www.${record.site}.com`;
    }
    if(url.charAt(url.length -1) === "/"){
      url = url.slice(0, url.length -1);
    }
    url = url.replace("https://", '');
    const response = {json: {}, isError: false}
     const {json} = await fetchRA(`/data/config/flexi/${url}`).catch(e => {response.isError = true; console.log("e",e)});
     response.json = json
    return response
  }
}
  export const getInheritedSites = async (parent_site_guid) =>{
    const inheritedSites = await axios.get(`${window.location.origin}/api/sites?parent_site_guid=${parent_site_guid}`);
    return inheritedSites.data.content;
  }
