import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import MinusIcon from '@material-ui/icons/Remove';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '../IconButton';
import Grid from '../Grid';
import { isSubStrIgnoreCase } from '../../tools';


const styles = () => ({
    root: {
        width: '100%',
    },
    detailsRoot: {
        display: 'block',
    },
    icon:{
        verticalAlign:'middle',
        marginRight:5,
        marginTop:"-3px",
    },
    missing:{
        fontStyle:'italic',
        color:'#f39c12',
        fontSize:12,
    },
    highlighted:{
        background: 'yellow',
        width: "fit-content",
        padding: "2px 5px",
        borderRadius: "5px"
    }
});


class LinkListCard extends Component {
    constructor(props){
        super(props)
        const {initiallyExpanded, fields, index} = this.props;
        const item = fields.get(index);
        this.state = {isExpanded: initiallyExpanded(item) || false}
        this.showEmptyKeyOn = this.showEmptyKeyOn.bind(this);
        this.handleExpand = this.handleExpand.bind(this);
    }

    showEmptyKeyOn = (item, myKeys = []) => {
        const { classes } = this.props;
        let emptyKeys = myKeys.filter(key => {
            return !item[key] ? key : null;
        });
        return emptyKeys.length > 0 ?
            <Typography className={classes.missing}>
                <ErrorIcon className={classes.icon} />{emptyKeys.join(' , ').toUpperCase()}
            </Typography>
            : null
    }

    handleExpand(){
        this.setState(prevState => ({isExpanded:!prevState.isExpanded}))
    }

    render() {
        const {
            children,
            index,
            fields,
            inputFields,
            source,
            classes,
            initiallyExpanded,
            removeLabel,
            getTitle,
            showEmptyKeys,
            expanded,
            gridColumnTitle = 3,
            gridTemplateColumns,
            extendedTitle,
            resource,
            displayIndex,
            hideRemoveButton,
            filterStr,
        } = this.props;
        const { isExpanded } = this.state;
        const item = fields.get(index);
        return (
            <div className={classes.root}>
                <ExpansionPanel
                    defaultExpanded={initiallyExpanded(item)}
                    expanded={expanded ? expanded : isExpanded}
                >
                    <ExpansionPanelSummary onClick={this.handleExpand} expandIcon={<ExpandMoreIcon />}>
                        <Grid columns={gridColumnTitle} gridTemplateColumns={gridTemplateColumns} >
                            <Typography className={isSubStrIgnoreCase( getTitle(item), filterStr) ? classes.highlighted : null}>
                                {getTitle(item)}
                            </Typography>
                            {extendedTitle && extendedTitle(item) ? <Typography variant="caption" className={isSubStrIgnoreCase(extendedTitle(item), filterStr) ? classes.highlighted : null}>{extendedTitle(item)}</Typography> : ''}
                            {displayIndex && <Typography>{index}</Typography>}
                            {this.showEmptyKeyOn(item,showEmptyKeys)}
                        </Grid>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
                        {resource === "abtesting" && extendedTitle && extendedTitle(item) ? <Typography>{extendedTitle(item)}</Typography> : ''}
                        <div>
                            {inputFields.map((InputField, i) => (
                                <div key={`${source}.${InputField.props.source}.${i}`}>
                                    {renderInput(InputField, source,i, filterStr)}
                                </div>
                            ))}
                        </div>
                        {children
                            && children.length > 0
                            && children.map(component => (renderInput(component,source, undefined, filterStr)) )
                        }
                    </ExpansionPanelDetails>
                    {hideRemoveButton === false && <ExpansionPanelActions>
                        <IconButton
                            color="secondary"
                            icon={iconClasses => <MinusIcon className={iconClasses.leftIcon} />}
                            onClick={() => fields.remove(index)}
                        >
                            {`${removeLabel} #${index + 1}`}
                        </IconButton>
                    </ExpansionPanelActions>

                    }

                </ExpansionPanel>
            </div>
        );
    }
}

function renderInput(InputField, source,index, filterStr) {
    return React.cloneElement(InputField, {
        key: source,
        source: `${source}.${InputField.props.source}`,
        id:`${source}.${index}.${InputField.props.id}`,
        name: `${source}.${InputField.props.name}`,
        displayindex: `${source}.${InputField.props.displayIndex}`,
        filterStr,
        ...(InputField.props.choices && {choices:InputField.props.choices})
    });
}

LinkListCard.defaultProps = {
    initiallyExpanded: record => !record.title && !record.url,
    hideRemoveButton: false,
    displayIndex:false,
};

export default withStyles(styles)(LinkListCard);
