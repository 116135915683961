import React, { useRef, useEffect } from "react";

export const useDraggableRef = (
  id,
  elementDragStart = (e) => null,
  elementDragOver = (e) => null,
  elementDragEnter = (e) => null,
  elementDrop = (e) => null,
  elementDragEnd = (e) => null,
  replaceParentNode = false, //switch parent container position
  isDropZone = true, //to define drop zone
  isDraggable = true //to define draggable component (could only be drop zone)
) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("dragover", handleDragOver);

      ref.current.addEventListener("dragenter", handleDragEnter);

      if (isDraggable) {
        ref.current.draggable = true;
        ref.current.addEventListener("dragstart", handleDragStart);
        ref.current.addEventListener("dragend", handleDragEnd);
      }

      if (isDropZone) {
        ref.current.addEventListener("drop", handleDrop);
      }

      return () => {
        ref.current.removeEventListener("dragover", handleDragOver);
        ref.current.removeEventListener("dragenter", handleDragEnter);
        if (isDraggable) {
          ref.current.removeEventListener("dragstart", handleDragStart);
          ref.current.removeEventListener("dragend", handleDragEnd);
        }
        if (isDropZone) {
          ref.current.removeEventListener("drop", handleDrop);
        }
      };
    }
  }, []);

  const handleDragStart = (e) => {
    e.dataTransfer.effectAllowed = "move";
    const dataList = e.dataTransfer.items;
    if (!dataList.length) {
      dataList.add(e.target.id, "text/plain");
      elementDragStart(e);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); //to define element as drop zone
    elementDragOver(e);
  };

  const handleDragEnter = (e) => {
    e.preventDefault(); //to define element as drop zone
    elementDragEnter(e);
  };

  const handleDrop = (e) => {
    const item = e.dataTransfer.items[0];
    if (item && item.kind === "string" && item.type.match(/^text\/plain/)) {
      // This item is the target node

      item.getAsString((s) => {
        const element = replaceParentNode
          ? document.getElementById(id).parentNode
          : document.getElementById(id);
        const replaceNode = replaceParentNode
          ? document.getElementById(s).parentNode
          : document.getElementById(s);
        if (s) {
          element.parentNode.insertBefore(replaceNode, element);
          setTimeout(() => {
            //give the DOM some time to update the nodes before performing any other actions
            elementDrop(e);
          }, 200);
        }
      });
    }
  };

  const handleDragEnd = (e) => {
    const dataList = e.dataTransfer.items;
    // Clear any remaining drag data
    dataList.clear();
    elementDragEnd(e);
  };
  return ref;
};
