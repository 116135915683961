import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TextInput, SelectInput } from "react-admin";
import Grid from "../Grid";
import { initialize, reduxForm } from "redux-form";
import { intersectObjects } from "../../tools";

const initialValues = {
  sm_gapY: "10",
  sm_gapX: "10",
  sm_scroll_gridX: "2",
  layout_mobile: "scroll",
  sm_scroll_offset: "0.5",
  sm_scroll_multi_height: "500",
  sm_scroll_multi_gridY: "2",
  sm_grid_gridX: "2",
  layout_desktop: "scroll",
  md_gapX: "20",
  md_gapY: "20",
  md_scroll_gridX: "4",
  md_scroll_offset: "0.5",
  md_scroll_multi_height: "750",
  md_scroll_multi_gridY: "2",
  md_grid_gridX: "4",
};
const WidgetListingV2Options = ({
  classes,
  source,
  sourceData,
  record,
  dispatch,
  index,
}) => {

  useEffect(() => {
    if (record.widgets.length > 0) {
      const initialFormValues = {
        ...record,
        widgets: record.widgets.map((widget, i) =>
          index === i ? { ...sourceData, ...initialValues } : widget
        ),
      };
      /* if no value match between initial values et the current widget data then we fully load initialValues as defaultValues */
      /* we keep all user values if at least one of them existing */
      if(intersectObjects(initialValues, sourceData).length === 0){
          dispatch(
            initialize(
              `record-form`,
              initialFormValues
            )
          );
      }
    }
  }, [record.widgets.length]);

  return (
    <Grid columns={3}>
      <Grid columns={1} classes={classes.groupSettings}>
        <SelectInput
          className={classes.lineSettings}
          source={`${source}.layout_mobile`}
          label="Mobile Layout"
          choices={[
            { id: "layout_sm_scroll", name: "Mobile Scroll Layout" },
            { id: "layout_sm_scroll_multi", name: "Mobile Scroll Multi Layout" },
            { id: "layout_sm_grid", name: "Mobile Grid Layout" },
          ]}
        />
        <TextInput
          className={classes.lineSettings}
          source={`${source}.sm_gapX`}
          label="sm-gapX (px)"
        />
        <TextInput
          className={classes.lineSettings}
          source={`${source}.sm_gapY`}
          label="sm-gapY (px)"
        />
        {sourceData &&
          (sourceData.layout_mobile === "layout_sm_scroll" ||
            sourceData.layout_mobile === "layout_sm_scroll_multi") && (
            <>
              <TextInput
                className={classes.lineSettings}
                source={`${source}.sm_scroll_gridX`}
                label="sm-scroll-gridX (px)"
              />
              <TextInput
                className={classes.lineSettings}
                source={`${source}.sm_scroll_offset`}
                label="sm-scroll-offset"
                helperText={"between 0 and 1"}
              />
            </>
          )}
        {sourceData &&
          sourceData.layout_mobile === "layout_sm_scroll_multi" && (
            <>
              <TextInput
                className={classes.lineSettings}
                source={`${source}.sm_scroll_multi_height`}
                label="sm-scroll-multi-height (px)"
              />
              <TextInput
                className={classes.lineSettings}
                source={`${source}.sm_scroll_multi_gridY`}
                label="sm-scroll-multi-gridY"
              />
            </>
          )}
        {sourceData && sourceData.layout_mobile === "layout_sm_grid" && (
          <TextInput
            className={classes.lineSettings}
            source={`${source}.sm_grid_gridX`}
            label="sm-grid-gridX"
          />
        )}
      </Grid>
      <Grid columns={1} classes={classes.groupSettings}>
        <SelectInput
          className={classes.lineSettings}
          source={`${source}.layout_desktop`}
          label="Desktop Layout"
          choices={[
            { id: "layout_md_scroll", name: "Desktop Scroll Layout" },
            { id: "layout_md_scroll_multi", name: "Desktop Scroll Multi Layout" },
            { id: "layout_md_grid", name: "Desktop Grid Layout" },
          ]}
        />
          <SelectInput
              className={classes.lineSettings}
              source={`${source}.layout_desktop_display`}
              label="Desktop Layout Custom Display"
              resettable
              choices={[
                  { id: "landscape-hero", name: "Landscape Hero" },
                  { id: "row-feature", name: "Row Feature display" },
                  { id: "row-feature row-reverse", name: "Reverse Row Feature display" },
              ]}
          />
        <TextInput
          className={classes.lineSettings}
          source={`${source}.md_gapX`}
          label="md-gapX (px)"
        />
        <TextInput
          className={classes.lineSettings}
          source={`${source}.md_gapY`}
          label="md-gapY (px)"
        />
        {sourceData &&
          (sourceData.layout_desktop === "layout_md_scroll" ||
            sourceData.layout_desktop === "layout_md_scroll_multi") && (
            <>
              <TextInput
                className={classes.lineSettings}
                source={`${source}.md_scroll_gridX`}
                label="md-scroll-gridX (px)"
              />
              <TextInput
                className={classes.lineSettings}
                source={`${source}.md_scroll_offset`}
                label="md-scroll-offset"
                helperText={"between 0 and 1"}
              />
            </>
          )}
        {sourceData &&
          sourceData.layout_desktop === "layout_md_scroll_multi" && (
            <>
              <TextInput
                className={classes.lineSettings}
                source={`${source}.md_scroll_multi_height`}
                label="md-scroll-multi-height (px)"
              />
              <TextInput
                className={classes.lineSettings}
                source={`${source}.md_scroll_multi_gridY`}
                label="md-scroll-multi-gridY"
              />
            </>
          )}
        {sourceData && sourceData.layout_desktop === "layout_md_grid" && (
          <TextInput
            className={classes.lineSettings}
            source={`${source}.md_grid_gridX`}
            label="md-grid-gridX"
          />
        )}
      </Grid>
    </Grid>
  );
};

WidgetListingV2Options.propTypes = {
    classes: PropTypes.object,
    source:PropTypes.string,
    sourceData:PropTypes.object.isRequired,
    record:PropTypes.object.isRequired,
    dispatch:PropTypes.func.isRequired,
    index:PropTypes.number.isRequired,
};

export default reduxForm({
  form: "record-form",
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(WidgetListingV2Options);
