export const capName = (name) => {
    if (typeof name === "number") {
        return name;
    }
    if (!name || !name[0]) {
        return name;
    }
    return `${name[0].toUpperCase()}${name.slice(1)}`;
}

export const removeTwigCode = (html) => {
    const regex = /{%([^"]*)%}/g
    const regex2 = /{{([^"]*)}}/g
    const match = html.match(regex)
    const match2 = html.match(regex2)

    //hide the twig markup value from the preview
    if (match) {
        for (let i = 0; i < match.length; i++) {
            html = html.replace(match[i], '')
        }
    }
    if (match2) {
        for (let i = 0; i < match2.length; i++) {
            html = html.replace(match2[i], '')
        }
    }

    return html
}