import React, { useCallback } from "react";
import { Datagrid, TextField, EditButton, ShowButton, FunctionField } from "react-admin";
import DeleteButtonWithConfirmation from "../../components/DeleteButtonWithConfirmation";
import ListFilter from "./ListFilter";
import List from "../List";
import CloneLocale from "../../components/CloneLocale";
import CopyToProd from "../../components/CopyToProd";
import RelatedDocuments from "../../components/RelatedDocuments";
import ModalButton from "../../components/ModalButton";
import { connect } from "react-redux";
import { isCodeFreeze } from "../Settings/settingsUI";
import ViewColumn from "@material-ui/icons/ViewColumn";
import StarsIcon from '@material-ui/icons/Stars';

const rowStyle = (record) => {
  return {
    background: !record.is_published && "#f5f5f5",
  };
};

const SiteList = (props) => {
  const {
    resource,
    permissions,
    codeFreeze: { status },
    classes,
    ...rest
  } = props;

  const getModalTitle = useCallback(
    (record) => `Documents related to this site : ${record.guid}`,
    []
  );

  return (
    <List
      perPage={100}
      resource={resource}
      permissions={permissions}
      sort={{ field: "guid", order: "ASC" }}
      filters={<ListFilter />}
      {...rest}
    >
      <Datagrid rowStyle={rowStyle}>
        <TextField source="guid" label="ID" />
        <TextField source="name" />
        <FunctionField
                source="is_master"
                render={record => { return record.is_master ? <StarsIcon style={{color: '#e3e305'}}/> : null}}
            />
        

        {permissions && permissions.checkUserCanCRUD(resource, "update") ? (
          <EditButton
            disabled={status && !permissions.checkUserIsSuperAdmin(resource)}
          />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "read") ? (
          <ShowButton />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "read") ? (
          <ModalButton
            IconElement={<ViewColumn />}
            getModalTitle={getModalTitle}
            labelButton={`Documents related`}
            maxWidth={false}
          >
            <RelatedDocuments route={`api/sites_related`} />
          </ModalButton>
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "create") ? (
          <CloneLocale />
        ) : null}
        {permissions && permissions.checkUserCanCopyToProd(resource) ? (
          <CopyToProd />
        ) : null}
        {permissions && permissions.checkUserCanCRUD(resource, "delete") ? (
          <DeleteButtonWithConfirmation
            disabled={
              status && !permissions.checkUserIsSuperAdmin(resource)
            } /* undoable={false} */
          />
        ) : null}
      </Datagrid>
    </List>
  );
};

const mapStateToProps = (state) => ({
  codeFreeze: isCodeFreeze(state.settingsui),
  settingsui: state.settingsui,
});

export default connect(mapStateToProps)(SiteList);
