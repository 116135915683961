import React, { Component } from 'react';
import { Labeled } from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { getSource } from '../tools';

const styles = () => ({
    title: {
        fontSize: 14,
    },
    icon: {
        width: 100,
        height: 50,
        backgroundSize: "contain",
    },
    root: {
        width: '100%',
        display: "flex",
        alignItems: "center",
    },
    detailsRoot: {
        display: 'inline-block',
    }
});

class SelectListField extends Component {

    shouldComponentUpdate() {
        return true;
    }
    render() {
        const {
            classes,
            label,
            record,
            source,
            getIcon,
            getTitle,
            getUrl,
        } = this.props;
        return (
            <div >
                <Labeled label={label} />
                {getSource(record, source)
                    && getSource(record, source).length > 0
                    && getSource(record, source).map((field, index) => {
                        return (
                            <Card key={index} className={classes.root}>
                                <CardMedia
                                    className={[classes.detailsRoot, classes.icon].join(" ")}
                                    image={getIcon(field)}
                                />
                                <CardContent className={classes.detailsRoot}>
                                    <Typography className={classes.title} color="textPrimary" gutterBottom>
                                        {getTitle(field)}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {getUrl(field)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )
                    })
                }
            </div>
        );
    }
}
SelectListField.defaultProps = {
    addRootLabel: true,
    label: 'social',
    getTitle: (item) => (item.title ? item.title : ""),
    getSubTitle: (item) => (item.position ? `Position: ${item.position}` : ''),
    getUrl: (item) => (item.url ? item.url : ""),
    getIcon: (item) => (item.icon && item.icon.length > 0 ? item.icon[0].src : "https://via.placeholder.com/150"),
};
export default withStyles(styles)(SelectListField);
