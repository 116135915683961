import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import { Button } from 'react-admin';
import PageviewIcon from '@material-ui/icons/Pageview';
import { CircularProgress, Select, MenuItem, FormHelperText, FormControl, InputLabel, Typography } from "@material-ui/core";

import Grid from "./../components/Grid";


const flexiDemo = {id: 'flexidemo', url: 'https://www.flexidemo.com.master.van-qa.futureplc.engineering', isDemo: true};

const templateLayouts = [
    { ids: ['home', 'videohub'], urlPiece: '/', enabled: true },
    { ids: ['landing', 'videolist'], urlPiece: '/flexi/preview/landing', enabled: true },
    { ids: ['news', 'reviews', 'recipes', 'video', 'live'], urlPiece: '/flexi/preview/article', enabled: true },
    { ids: ['paidtraffic', 'bestpicks', 'slideshow', 'sponsored'], urlPiece: null, enabled: false }
];

const useGetUrlAndThemeOfSite = async (site) => {
    let result = null;
    const http = window.environment === 'dev' ? 'http' : 'https';
    try {
        const { data: response } = await axios.get(`${http}://${window.location.host}/api/sites?guid=${site}&is_master=true&fl=url,theme`);
        result =  response.content[0];
      } catch (error) {
        // do nothing
      }
      return result;
}

const LayoutPreviewButton = ({ templateLayout, layoutSlug, site }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isInError, setIsInError] = useState(false);
    const [selectedSite, setSelectedSite] = useState(flexiDemo);
    const [customSite, setCustomSite] = useState(null);
    const [theme, setTheme] = useState('responsive');

    const selectedTemplateLayout = templateLayouts.find((template) => template.ids.includes(templateLayout)) || templateLayouts[3];

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await useGetUrlAndThemeOfSite(site);
          setIsLoading(false);
          if (data) 
          {
            if (data.url) 
            {
                let url = data.url;
                if (url.endsWith('/')) {
                    // Remove the possible trailing "/"
                    url = url.slice(0, -1);
                }
                const currentSite = { id: site, url: `${url}.master.van-qa.futureplc.engineering`, isDemo: false };
                setCustomSite(currentSite);
                setSelectedSite(currentSite);
            }
            if (data.theme)
            {
                setTheme(data.theme);
            }
          }
        } catch (error) {
            setIsInError(true);
            setIsLoading(false);
        }
      };
      if(isLoading) {
        fetchData();
      }
    }, []);

    const handlePreviewClick = () => {
        const selectedSiteUrl = selectedSite.url;
        if (selectedTemplateLayout.enabled) {
            let completeUrl = `${selectedSiteUrl}${selectedTemplateLayout.urlPiece}?_flexi_doc_slug=${layoutSlug}&_flexi_widget_data=mock`;
            if(selectedSite.isDemo)
            {
                completeUrl += `&_flexi_theme=${theme}`
            }
            window.open(completeUrl, '_blank');
        }
    };

    return (
        isLoading || isInError ? 
        <Grid columns={2} style={{maxWidth:'550px', marginTop: '16px', marginBottom: '16px'}}>
            <Typography variant="h1">{isInError ? 'Error while retrieving data from server' : 'Loading data to generate a preview link'}</Typography>
            <CircularProgress
                size={20}
                thickness={2}
            />
        </Grid> :
        <Grid columns={2} style={{ marginBottom: '16px', width: 'fit-content' }}>
            <FormControl>
                <InputLabel id="layout-preview-label-id">Layout preview</InputLabel>
                <Select
                    labelId="layout-preview-label-id"
                    value={selectedSite.id}
                    style={{ width: '250px' }}
                    disabled={!selectedTemplateLayout.enabled}
                    onChange={(event) => {
                        setSelectedSite(event.target.value === flexiDemo.id ? flexiDemo : customSite);
                    }}
                >
                    {   customSite && 
                        <MenuItem key={customSite.id} value={customSite.id}>
                            {customSite.id}
                        </MenuItem>
                    }
                    <MenuItem key={flexiDemo.id} value={flexiDemo.id}>
                        {flexiDemo.id}
                    </MenuItem>

                </Select>
                {!selectedTemplateLayout.enabled && (
                    <FormHelperText>This template layout is not compatible</FormHelperText>
                )}
            </FormControl>
            <Button
                onClick={() => handlePreviewClick()}
                label="Preview layout"
                key="button"
                style={{ padding: '8px 16px', fontSize: '14px' }}
                disabled={!selectedTemplateLayout.enabled}
            >
                <PageviewIcon style={{ height: '24px', width: '24px', marginRight: '1px' }} />
            </Button>
        </Grid>
    );
};

LayoutPreviewButton.propTypes = {
    layoutSlug: PropTypes.string,
    templateLayout: PropTypes.string,
    site: PropTypes.string
  };

export default LayoutPreviewButton;