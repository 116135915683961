import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SelectInput, REDUX_FORM_NAME, FormDataConsumer } from "react-admin";
import { reset, change, getFormSyncErrors } from "redux-form";
import * as _ from "lodash";

import { SLICE_ROUTE, CURRENT_PROTOCOL } from "../../Constants/slice";
import { SLICES_DEFAULTS } from "../../Constants/slice/defaults";

import { Modal } from "../Modal";
import { theme } from "../../Admin";
import DataScreen from "./DataScreen";
import FieldsScreen from "./FieldsScreen";
import StyleScreen from "./StyleScreen";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = () => ({
    modalContentSpace: {
        height: "90vh",
        maxWidth: "80vw",
    },
    modalRoot: {
        maxWidth: 1900,
    },
    relativePaper: { width: 185, position: "relative" },
    modalWrapper: { display: "flex", height: "100%" },
    listNumber: {
        flex: "0 0 auto",
        background: "lightgray",
        borderRadius: "100%",
        width: 20,
        height: 20,
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1.3,
        color: "darkgray",
        textAlign: "center",
        fontSize: "0.9em",
    },
    listNumberActive: {
        background: theme.palette.secondary.main,
        color: "white",
        fontWeight: "bold",
    },
});

export const getScreen = (screen, source, permissions) => {
    switch (screen) {
        case "data":
            return <DataScreen source={source} />;
        case "fields":
            return <FieldsScreen source={source} permissions={permissions} />;
        case "style":
            return <StyleScreen source={source} />;
        default:
            return <div />;
    }
};

const SliceModal = ({ handleCloseClick, showDialog, classes, source, record, permissions, syncErrors }) => {
    const [activeTab, setActiveTab] = useState("data");

    const tooltipHelp = (
        <a
            href="http://master.vanilla-slice.van-qa.futureplc.engineering/storybook"
            target="_blank"
            rel="noopener noreferrer"
            title="Click to open Slice UI"
            style={{
                display: "block",
                fontSize: "0.6em",
                textDecoration: "none",
                background: `white`,
                fontFamily: theme.typography.fontFamily,
                borderRadius: 5,
                padding: "8px 16px",
            }}
        >
            Check all the slices
        </a>
    );

    return (
        <FormDataConsumer>
            {({ formData, dispatch }) => {
                const dropdownChoices = [
                    { name: "Data", id: "data" },
                    { name: "Fields", id: "fields" },
                    { name: "Styles", id: "style" },
                ].filter(Boolean);

                return (
                    <Modal
                        title={
                            <span style={{ display: "inline-flex", justifyContent: "space-between", width: "100%" }}>
                                Vanilla Slice setup {tooltipHelp}
                            </span>
                        }
                        showDialog={showDialog}
                        handleCloseClick={() => handleCloseClick()}
                        handleCloseClickCancel={() => {
                            handleCloseClick();
                            dispatch(reset(REDUX_FORM_NAME, "slice_component", record.slice_component));
                            dispatch(reset(REDUX_FORM_NAME, "slice", record.slice));
                        }}
                        handleDisableApply={(setDisableApply) => {
                            const currentWidgetData = _.get(formData, source);
                            if (
                                !currentWidgetData &&
                                (!currentWidgetData.slice || !currentWidgetData.slice_component)
                            ) {
                                setDisableApply(`Please select a slice and fill all the required parameters`);
                            }

                            if (currentWidgetData.slice && currentWidgetData.slice_component) {
                                if (currentWidgetData.slice[currentWidgetData.slice_component]) {
                                    setDisableApply(`Please fill all the required parameters`);
                                }

                                const syncErrorsForCurrentSlice = _.get(syncErrors, source);

                                if (
                                    syncErrorsForCurrentSlice &&
                                    syncErrorsForCurrentSlice.slice &&
                                    syncErrorsForCurrentSlice.slice[currentWidgetData.slice_component] &&
                                    syncErrorsForCurrentSlice.slice[currentWidgetData.slice_component].components
                                ) {
                                    setDisableApply(`Please fill all the required parameters`);
                                } else {
                                    setDisableApply(null);
                                }
                            }
                        }}
                        classes={{ paper: classes.modalContentSpace, root: classes.modalRoot }}
                        generalTooltip={tooltipHelp}
                        record={record}
                        formData={formData}
                    >
                        <div className={classes.modalWrapper}>
                            <Sidebar options={dropdownChoices} {...{ source, setActiveTab, activeTab, classes }} />
                            <Content {...{ classes, activeTab, source, permissions, record, dispatch }} />
                        </div>
                    </Modal>
                );
            }}
        </FormDataConsumer>
    );
};

const Sidebar = ({ options, classes, activeTab, setActiveTab, source }) => {
    return (
        <Drawer
            anchor={"left"}
            variant="permanent"
            open={true}
            style={{ width: 185, flexShrink: 0, whiteSpace: "nowrap", overflowX: "hidden" }}
            classes={{
                paper: classes.relativePaper,
            }}
        >
            <SliceSelect {...{ source }} />
            <List>
                {options.map((option, index) => (
                    <ListItem button key={option.id} onClick={() => setActiveTab(option.id)}>
                        <div
                            className={`${classes.listNumber} ${
                                activeTab === option.id ? classes.listNumberActive : ""
                            }`}
                        >
                            {index + 1}
                        </div>
                        <ListItemText primary={option.name} />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

const SliceSelect = ({ source }) => {
    const [vanillaSliceChoices, setVanillaSliceChoices] = useState(null);

    useEffect(() => {
        if (!vanillaSliceChoices) {
            fetch(`${CURRENT_PROTOCOL}${window.slice.version.replaceAll(".", "-")}.${SLICE_ROUTE}/slices`)
                .then((response) => response.json())
                .then((data) => setVanillaSliceChoices(data))
                .catch((e) => console.error("Error trying to fetch slice list", e));
        }
    }, []);

    if (!vanillaSliceChoices) {
        return <CircularProgress color="secondary" />;
    }

    const normalisedSlices = (activeSlices) => {
        return activeSlices.map((item) => {
            return { ...item, id: item.id.charAt(0).toLowerCase() + item.id.slice(1) };
        });
    };

    return (
        <SelectInput
            style={{ margin: "20px 10px 8px" }}
            source={`${source}.slice_component`}
            label="Select a slice"
            choices={normalisedSlices(vanillaSliceChoices.activeSlices)}
        />
    );
};

const Content = ({ activeTab, source, record, dispatch, permissions }) => {
    useEffect(() => {
        if (!record.slice_component) {
            dispatch(change(REDUX_FORM_NAME, `${source}.slice`, SLICES_DEFAULTS));
        }
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "15px 40px 20px",
                maxHeight: "100%",
                width: "100%",
                overflowY: "auto",
                fontFamily: theme.typography.fontFamily,
            }}
        >
            {getScreen(activeTab, source, permissions)}
        </div>
    );
};

export const mapStateToProps = (state) => ({
    syncErrors: getFormSyncErrors("record-form")(state),
});

export default connect(mapStateToProps)(withStyles(styles)(SliceModal));
