import React, { useState, useEffect } from 'react'
import AceEditorInput from "../AceEditorInput"

const Typewriter = ({ text, delay }) => {
    const [reset, setReset] = useState(false)
    const [currentText, setCurrentText] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)

    if (reset && text.length !== currentText.length) {
        setReset(false)
        setCurrentIndex(0)
        setCurrentText('')
    }

    useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + text[currentIndex])
                setCurrentIndex(prevIndex => prevIndex + 1)
            }, delay)
            return () => clearTimeout(timeout)

        } else {
            setReset(true)
        }
    }, [currentIndex, delay, text])

    return <AceEditorInput
        source={``}
        label={''}
        mode={'twig'}
        displayAceLabel={false}
        placeholder='ChatGPT response'
        minLines={15}
        maxLines={45}
        values={currentText}
        disabled={true}
    />
}

export default Typewriter