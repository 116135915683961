import React, { Component, Fragment } from 'react';
import {
    TextInput, Labeled, BooleanInput, FormDataConsumer, ReferenceInput, AutocompleteInput
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '../IconButton';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MinusIcon from '@material-ui/icons/Remove';

import { getSource, orderByKey } from '../../tools';
import { withStyles } from '@material-ui/core/styles';
import ABTestParamsOverride from "./ABTestParamsOverride";
import AceEditorInput from "../AceEditorInput";
import Grid from "../Grid";
import AutocompleteSiteConfigOverride from "./AutocompleteSiteConfigOverride";


const styles = theme => ({
    fr1001fr1fr:{
        gridTemplateColumns:"150px 1fr 1fr !important",
    },
    thirdx2:{width:"66%"},
    half:{width:"50%"},
    third:{width:"33%"},
    midHalf:{width:"25%"},
    ten:{width:"10%"},
    align:{
        marginTop:0
    },
    grid:{
        display:"grid",
        width:150
    },
    root: {
        width: '100%'
    },
    detailsRoot: {
        display: 'block'
    },
    icon:{
        verticalAlign:'middle',
        marginRight:5,
        marginTop:"-3px"
    },
    missing:{
        fontStyle:'italic',
        color:'#f39c12',
        fontSize:12
    },
    editorWidth: {
        width: '100%'
    }
});

const limitRenderSuggestions = (val) => { return val.trim().length > 2 };
const optionRenderer = choice => `${choice.guid}`;

class AbtestingInputs extends Component {
    shouldComponentUpdate(nextProps,nextState) {
        const { fields } = this.props;
        return fields.length !== nextProps.fields.length
    }
    render() {
        const { record, source, label, classes, ...rest } = this.props;
        const { fields, addLabel } = rest;

        return (
            <FormDataConsumer>
                {({formData})=>(
                    <Fragment>
                        <Labeled label={label} />
                        <div style={{ marginBottom: '8px' }}>
                            {fields.length > 0
                            && fields.map((itemSource, index) =>{
                                const item = fields.get(index);
                                if(typeof formData.abtests[index].no_flexi_variant_name !== 'undefined'){
                                    formData.abtests[index].variant = formData.abtests[index].no_flexi_variant_name;
                                    if(typeof formData.abtests[index].flexi === 'undefined') {
                                        formData.abtests[index].flexi = false;
                                    }
                                    delete formData.abtests[index].no_flexi_variant_name;
                                }
                                return (
                                    <div key={`abtesting-list-${itemSource}`} className={classes.root}>
                                        <ExpansionPanel defaultExpanded={false}>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Grid columns={1}>
                                                    <Typography>{this.props.getTitle(item)}</Typography>
                                                    {this.props.extendedTitle && this.props.extendedTitle(item) ? <Typography variant="caption">{this.props.extendedTitle(item)}</Typography> : ''}
                                                </Grid>
                                            </ExpansionPanelSummary>

                                            <ExpansionPanelDetails>
                                                <div className={classes.root}>
                                                    {this.props.extendedTitle && this.props.extendedTitle(item) ? <Typography>{this.props.extendedTitle(item)}</Typography> : ''}
                                                    <BooleanInput label="use Flexi" source={`${itemSource}.flexi`} defaultValue={false} />
                                                    {formData.abtests[index].flexi === true && <ReferenceInput
                                                        source={`${itemSource}.variant`}
                                                        label="Variant name (Flexi pagelayout)"
                                                        perPage={100}
                                                        reference="pagelayouts"
                                                        className={classes.thirdx2}
                                                        fullWidth={true}
                                                        sort={{ field: 'guid', order: 'ASC' }}
                                                        filterToQuery={text=>({guid:text})}
                                                        allowEmpty
                                                    >
                                                        <AutocompleteInput allowEmpty
                                                                           helperText='Select a pagelayout or leave empty for variant "control"'
                                                                           shouldRenderSuggestions={limitRenderSuggestions}
                                                                           optionText={optionRenderer} optionValue="guid"
                                                                           setFilter={text=>({guid:text})}
                                                        >
                                                        </AutocompleteInput>
                                                    </ReferenceInput>}
                                                    {(typeof formData.abtests[index].flexi === 'undefined' || formData.abtests[index].flexi === false) && <TextInput
                                                        className={classes.thirdx2}
                                                        fullWidth={true}
                                                        source={`${itemSource}.variant`}
                                                        label="Variant name (Flexi disabled)"
                                                        parse={text => text.replace(/[^_-a-zA-Z0-9\s]+/ig, '').replace(/\s+/gi, "-").toLowerCase()}
                                                        helperText="Do not use when a flexi layout variant is selected because it overwrites the variant's behavior" />}
                                                    {typeof formData.url !== 'undefined' && formData.url !== null && formData.url.length <= 1 && <TextInput
                                                        className={classes.thirdx2}
                                                        source={`${itemSource}.articleId`}
                                                        fullWidth={true}
                                                        label="A/B testing articleId"
                                                        helperText="Fill an EXISTING A/B testing CMS articleId to replace the original article" />}

                                                    <AutocompleteSiteConfigOverride
                                                        name="flexi_overrides"
                                                        label="Add Flexi config overrides"
                                                        source={`${itemSource}.flexi_overrides`}
                                                        data={formData} {...rest}
                                                    />
                                                    <ABTestParamsOverride
                                                        source={`${itemSource}`}
                                                        label={"Override FTE Parameters"}
                                                    />
                                                    <AceEditorInput
                                                        source={`${itemSource}.headMarkup`}
                                                        label={"Head markup"}
                                                        placeholder="<!-- Put your HTML code here -->" />
                                                    <AceEditorInput
                                                        source={`${itemSource}.bottomMarkup`}
                                                        label={"Bottom Markup"}
                                                        placeholder="<!-- Put your HTML code here -->" />
                                                    <BooleanInput
                                                        source={`${itemSource}.enhancedElk`}
                                                        label="Add extra tag to ELK blocks (in order to better move/change/update the markup in front End)"
                                                        defaultValue={false} />
                                                    <TextInput
                                                        source={`${itemSource}.battleId`}
                                                        label={"Battle ID (Hawk)"}
                                                        helperText="ID to build bridge between Flexi - Battle"
                                                    />
                                                </div>
                                            </ExpansionPanelDetails>
                                            <ExpansionPanelActions>
                                                <IconButton
                                                    color="secondary"
                                                    icon={iconClasses => <MinusIcon className={iconClasses.leftIcon} />}
                                                    onClick={() => fields.remove(index)}
                                                >
                                                    {`${AbtestingInputs.defaultProps.removeLabel} #${index + 1}`}
                                                </IconButton>
                                            </ExpansionPanelActions>

                                        </ExpansionPanel>
                                    </div>
                                )
                            }).sort((a, b) => {
                                const sourceA = getSource(formData, a.props.source);
                                const sourceB = getSource(formData, b.props.source);
                                return orderByKey(sourceA,sourceB,"position")
                            })
                            }
                        </div>
                        <IconButton
                            color="primary"
                            icon={itemClasses => <AddIcon className={classes.leftIcon} />}
                            onClick={() => fields.push({})}
                        >
                            {addLabel}
                        </IconButton>
                    </Fragment>
                )}
            </FormDataConsumer>
        );
    }
}

AbtestingInputs.defaultProps = {
    label: 'Abtesting list',
    getTitle: item => false,
    getUrl: item => (item.url ? `Position: ${item.url}` : ''),
    showEmptyKeys: [],
    addLabel: 'Add variation',
    removeLabel: 'Remove variation',
};

export default withStyles(styles)(AbtestingInputs);
