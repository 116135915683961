import React, { Fragment, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import classnames from 'classnames';
import IconCancel from '@material-ui/icons/Cancel';
import { Button } from 'react-admin';

const DialogConfirmationWrapper = ({ children,title,content,onConfirm }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleConfirm = () => {
        onConfirm();
        setShowDialog(false);
    }
    return (
        <Fragment>
            {cloneElement(children,
                {
                    ...children.props,
                    onClick: () => setShowDialog(true),
                })}
            <Dialog fullWidth open={showDialog} onClose={() => setShowDialog(false)} aria-label="Are you sure?">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    {cloneElement(children,{
                        ...children.props,
                        onClick: handleConfirm,
                    })}
                    <Button label="ra.action.cancel" onClick={() => setShowDialog(false)}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

DialogConfirmationWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    onActionConfirm: PropTypes.func,
    action: PropTypes.string,
}

export default DialogConfirmationWrapper
