import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import AddIcon from '@material-ui/icons/Add';
import { Labeled } from 'react-admin';
import SelectListCard from './Card';
// import { getSource } from "../../tools";
import IconButton from '../IconButton';
export const negativeNumber = value => (value && value < 0 ? 'This number must be >= 0' : undefined);
export const number = value => (value && !Number.isInteger(value) ? 'Must be a number' : undefined);
export const minValue = min => value => (value && value < min ? `Must be at least ${min}` : undefined);
export const minValue0 = minValue(0);

class SelectListInput extends Component {
    shouldComponentUpdate(nextProps) {
        const { fields } = this.props;
        return fields.length !== nextProps.fields.length;
    }

    render() {
        const { addLabel,label, ...rest } = this.props;
        const { fields } = rest;
        return (
            <Fragment>
                <Labeled label={label} />
                <div style={{ marginBottom: '8px' }}>
                    {fields.length > 0
                    && fields.map((field, index) => 
                            (
                                <Field
                                key={`select-list-${field}-${index}`}
                                name={field} //target iteration of previous fieldarray target ex:itemSource=> header.social[0]
                                component={SelectListCard}
                                index={index}
                                itemLinked={field}
                                {...rest}
                                />
                            )
                        )
                    }
                </div>
                <IconButton
                    color="primary"
                    icon={classes => <AddIcon className={classes.leftIcon} />}
                    onClick={() => fields.push({})}
                >
                    {addLabel}
                </IconButton>
            </Fragment>
        )
    }
}
SelectListInput.defaultProps={
    addLabel: 'Add link',
}
SelectListCard.defaultProps = {
    getTitle: (item) => {
        let titleCheck=item.title?item.title:""
        return titleCheck
    },
    getSubTitle: item => (item.position ? `Position: ${item.position}` : ''),
    getUrl:(item)=>{
        let urlCheck=item.url?item.url:"" //check there is an image
        return urlCheck
    },
    getIcon:(item)=>{
        let iconCheck=item.icon?item.icon[0].src:"https://via.placeholder.com/150" //check there is an image
        return iconCheck
    },
    removeLabel: 'Remove link',
};

export default SelectListInput;
