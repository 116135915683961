import React from "react";
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    line:{
        color:theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        height:4,
    }
})

const ColoredLine = ({ classes }) => (
    <hr
        className={classes.line}
    />
);
export default withStyles(styles)(ColoredLine);