import React from 'react';
import { TextInput, SelectArrayInput, Filter } from 'react-admin';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../CustomRoutes/ListFilter";

const ListFilter = props => (
    <Filter className={props.classes.filter} {...props}>
        <TextInput source="name" label="Search in name" alwaysOn resettable/>
        <TextInput source="guid" resettable label="Search in id (guid)"/>
        <SelectArrayInput
            fullWidth={true}
            alwaysOn
            source="site"
            label="Search in site"
            choices={window.sitesChoices}
            parse={(val)=> val ? val.join("||") : ""}
            format={(val)=> val ? val.split("||") : []}
        />
        {/* <SelectInput alwaysOn source="site" label="Search in site" choices={window.sitesChoices}/> */}
    </Filter>
);

export default withStyles(styles)(ListFilter);
