import React, { Component, Fragment } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import { Field } from "redux-form";
import {
    Labeled, SelectField, TextField, ImageField, BooleanField,FormDataConsumer,ArrayField,SingleFieldList
} from 'react-admin';
import WidgetTypeField from '../WidgetTypeField';
import ReferenceWidgetsField from '../ReferenceWidgetsField';
import WidgetMoreLinksField from '../WidgetMoreLinksField';
import TabContainer from '../WidgetListInput/TabContainer';
import Grid from '../Grid';
import WidgetCardSummary from '../WidgetListInput/CardSummary';
import { getSource } from "../../tools";
import WidgetVideoSettingsField from '../WidgetVideoSettingsField';
import AutocompleteSiteConfigOverride from "../LinkListInput/AutocompleteSiteConfigOverride";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    lineSettings:{
        margin:"auto 0",
    },
    groupSettings: {
        width: '100%',
        backgroundColor:"#8080801f",
        borderColor:"#8080801f",
        borderStyle:'solid',
        borderWidth:1,
        padding:20,
        borderRadius:"3px",
        marginTop:10,

    },
    appBar:{
        background:theme.palette.secondary.main
    }
});

class WidgetCard extends Component {
    state = {
        value: 0,
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { value } = this.state;
        return value !== nextState.value;
    }

    handleTabChange = (e, value) => {
        this.setState({ value });
    };

    render() {
        const { record, classes, permissions, data, source } = this.props;
        const { value } = this.state;
        return permissions && permissions.checkUserCanReadWidget(record) ?
        <FormDataConsumer>
            {({formData,...rest})=>{
                const sourceData = formData ? getSource(formData,source) :record;
                return(
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <WidgetCardSummary record={record} sourceData={sourceData} />
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className={classes.root}>
                                <AppBar position="static" className={classes.appBar}>
                                    <Tabs value={value} onChange={this.handleTabChange}>
                                        <Tab label="General settings" />
                                        <Tab label="See all link" />
                                        <Tab label="Advanced settings" />
                                        <Tab label="Commercial settings" />
                                    </Tabs>
                                </AppBar>
                                {value === 0 && (
                                    <TabContainer>
                                        <Grid columns="3">
                                            <Labeled label="Name">
                                                <TextField source="name" record={record} />
                                            </Labeled>
                                            <Labeled label="Title">
                                                <TextField source="title" record={record} />
                                            </Labeled>
                                            <Labeled label="Title Markup Options">
                                                <SelectField  record={record} source="title_markup" choices={window.widgetTitlesMarkups} />
                                            </Labeled>
                                        </Grid>
                                        <Grid columns="4">
                                            <Labeled label="Area">
                                                <TextField source="area" record={record} />
                                            </Labeled>
                                            <Labeled label="Position">
                                                <TextField source="position" record={record} />
                                            </Labeled>
                                        </Grid>
                                        <div>
                                            <Labeled label="Description">
                                                <TextField source="description" record={record} />
                                            </Labeled>
                                        </div>
                                       
                                        <Labeled label="Type">
                                            <SelectField  record={record} source="type" choices={window.widgetTypes} />
                                        </Labeled>
                                        <div>
                                            <WidgetTypeField type={record.type} data={data} record={record} />
                                        </div>
                                    </TabContainer>
                                )}
                                {value === 1 && (
                                    <TabContainer>
                                        <Grid columns="2">
                                            <Labeled label="widget.more_links_label">
                                                <TextField source="more_links_label" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.see_all_url">
                                                <TextField source="url" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.target_blank">
                                                <BooleanField source="target_blank" />
                                            </Labeled>
                                        </Grid>
                                        <div>
                                            <Labeled label="widget.more_links" />
                                            <WidgetMoreLinksField data={data} record={record} source="more_links" />
                                        </div>
                                    </TabContainer>
                                )}
                                {value === 2 && (
                                    <TabContainer>
                                        <Grid columns="4">
                                            <Labeled label="widget.target_blank_default">
                                                <BooleanField source="target_blank_default" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.lightbox">
                                                <BooleanField source="lightbox" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.lightbox_toggler">
                                                <BooleanField source="lightbox_toggler" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.full_width">
                                                <BooleanField source="full_width" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.lightbox_target_slug">
                                                <ReferenceWidgetsField
                                                    data={record}
                                                    record={record}
                                                    source="lightbox_target_slug"
                                                />
                                            </Labeled>
                                            <Labeled label="widget.pinned_item">
                                                <BooleanField source="pinned_item" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.css_class">
                                                <TextField source="css_class" record={record} />
                                            </Labeled>
                                            <Labeled label="Icon">
                                                <ImageField record={record} source="icon[0].src" />
                                            </Labeled>
                                            <Labeled label="Active SSE">
                                                <BooleanField source="active_sse" record={record} />
                                            </Labeled>
                                            <Labeled label="Essential">
                                                <BooleanField source="essential" record={record} />
                                            </Labeled>
                                            <Labeled label="Add Flexi config overrides">
                                                <Field
                                                    label={"Add Flexi config overrides"}
                                                    name={`${source}flexi_overrides`}
                                                    source={`${source}flexi_overrides`}
                                                    component={AutocompleteSiteConfigOverride}
                                                    allowEmpty
                                                    data={formData}
                                                    record={record}
                                                    {...this.props}
                                                />
                                            </Labeled>
                                            <Labeled label="Toggle More/Less At">
                                                <TextField source="toggle_more_at" record={record} />
                                            </Labeled>
                                            <Labeled label="No Render">
                                                <BooleanField source="no_render" record={record} />
                                            </Labeled>
                                            {['dynamic'].includes(record.type) && ['video'].includes(record.template) && (
                                                <WidgetVideoSettingsField record={record} />
                                                )
                                            }
                                            {['carousel'].includes(record.template) === true && (
                                                <Grid columns={4}>
                                                    <Labeled label="widget.change_media_config">
                                                        <TextField
                                                            record={record}
                                                            source={`change_media_config`}
                                                        />
                                                    </Labeled>
                                                    <Labeled label="Is scrollable?">
                                                        <BooleanField record={record} source="is_scrollable" />
                                                    </Labeled>
                                                    <Labeled label="From item (index)">
                                                        <TextField record={record} source="scroll_from"></TextField>
                                                    </Labeled>
                                                    <Labeled label="With gap (%)">
                                                        <TextField record={record} source="scroll_gap"></TextField>
                                                    </Labeled>
                                                </Grid>
                                                )}
                                        </Grid>
                                        <Grid classes={classes.groupSettings}>
                    <Labeled style={{fontSize:20}} className={classes.relatedTemplateLabeled} label={`Related to type ${sourceData.type ? sourceData.type : ""}`} />
                    {
                        ['dynamic','forumtopics'].includes(sourceData.type)
                        &&
                        (
                            <Fragment>
                                <Grid columns={4}>
                                    <Grid columns={2} classes={classes.groupSettings}>
                                    <Labeled label="Pagination">
                                        <BooleanField
                                                className={classes.lineSettings}
                                                source={`pagination`}
                                                record={record}
                                            />
                                    </Labeled>
                                        {sourceData && sourceData.pagination
                                            &&
                                            <Grid columns={2}>
                                                <Labeled label="Pagination Type">
                                                    <SelectField
                                                        className={classes.lineSettings}
                                                        source={`pagination_type`}
                                                        record={record}
                                                        choices={window.widget_pagination_type}
                                                    />
                                                </Labeled>
                                                <Labeled label="Pagination Anchor">
                                                    <SelectField
                                                        className={classes.lineSettings}
                                                        source={`pagination_anchor`}
                                                        record={record}
                                                    />
                                                </Labeled>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Labeled label="Standalone">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`standalone`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="Collapse">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`collapse`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="Sort options">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`sort_options`}
                                            record={record}
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid columns={4}>
                                    <Labeled label="Show filter">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`show_filter`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="Hide Title">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`hide_title`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="Hide Subtitle">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`hide_subtitle`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="Hide Image">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`hide_image`}
                                            record={record}
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid columns={2}>
                                    <Grid columns={2} style={{gridTemplateColumns:"30% 1fr"}} classes={classes.groupSettings}>
                                        <Labeled label="Display endorsement image">
                                            <BooleanField
                                                className={classes.lineSettings}
                                                source={`${source}.endorsement_image`}
                                                label={"Display endorsement image"}
                                                defaultValue={false}
                                            />
                                        </Labeled>

                                        {sourceData && sourceData.endorsement_image &&
                                            <SelectField
                                                className={classes.lineSettings}
                                                source={`${source}.endorsement_placement`}
                                                choices={[
                                                    { id: "main_image", name: "Replace main image" },
                                                    { id: "overlay", name: "Overlay over main image" },
                                                ]}
                                                defaultValue="main_image"
                                                label={"Endorsement Placement"}
                                            />
                                        }
                                    </Grid>
                              
                                    <Grid columns={2} style={{gridTemplateColumns:"30% 1fr"}} classes={classes.groupSettings}>
                                        <Labeled label="Lazy">
                                            <BooleanField
                                                className={classes.lineSettings}
                                                source={`lazy`}
                                                record={record}
                                            />
                                        </Labeled>
                                        {sourceData && sourceData.lazy &&
                                            <Labeled label="TTL (in second and only for lazy content)">
                                                <TextField
                                                    className={classes.lineSettings}
                                                    source={`ttl`}
                                                    record={record}
                                                />
                                            </Labeled>
                                        }
                                    </Grid>
                                    <Grid columns={3}>
                                        <Labeled label="Article Label">
                                            <TextField
                                                className={classes.lineSettings}
                                                source={`article_label_with`}
                                                record={record}
                                            />
                                        </Labeled>
                                        <Labeled label="Number of results (limit)">
                                            <TextField source="limit" record={record} />
                                        </Labeled>
                                    </Grid>
                                    {sourceData && sourceData.facet &&
                                        <Labeled label="Anchor on Drilldown">
                                            <BooleanField
                                                className={classes.lineSettings}
                                                source={`drilldown_anchor`}
                                                record={record}
                                            />
                                        </Labeled>
                                    }
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
                <Grid classes={classes.groupSettings}>
                    <Labeled style={{fontSize:20}} className={classes.relatedTemplateLabeled} label={`Related to template ${sourceData.template ? sourceData.template : ""}`} />
                        <Fragment>
                        {
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            ['fter_lists_list'].includes(sourceData.template)
                            &&
                            (
                                <Grid columns={4}>
                                    <Labeled label="Grid Default">
                                        <BooleanField
                                            source={`default_grid`}
                                            record={record}
                                        />
                                    </Labeled>
                                </Grid>
                            )
                        }
                        {
                            (['dynamic','forumtopics','homeCarousel'].includes(sourceData.type)
                            &&
                            ['fter_lists_list','fter_lists_searchlist','fter_lists','feature','fter_feature_block'].includes(sourceData.template))
                            &&
                            (
                                <Grid columns={4}>
                                    <Labeled label="Hide Hero">
                                        <BooleanField
                                            source={`hide_hero`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="Hide date">
                                        <BooleanField
                                            source={`hide_date`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="widget.hide_author">
                                        <BooleanField
                                            source={`hide_author`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="widget.hide_publisher">
                                        <BooleanField
                                            source={`hide_publisher`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="widget.show_category_link">
                                        <BooleanField
                                            source={`show_category_link`}
                                            record={record}
                                        />
                                    </Labeled>
                                </Grid>
                            )
                        }
                        {
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            ['group_hub'].includes(sourceData.template)
                            &&
                            (
                                <Grid columns={4}>
                                    <Labeled label={"Hide \"More Link\""}>
                                        <BooleanField
                                            source={`hide_more_link`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label={"Disable Content Links"}>
                                        <BooleanField
                                            source={`disable_content_links`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Labeled label="Reverse Sort Priority">
                                        <BooleanField
                                            className={classes.lineSettings}
                                            source={`reverse_sort_priority`}
                                            record={record}
                                        />
                                    </Labeled>
                                </Grid>
                            )
                        }
                        {
                            ['table_list'].includes(sourceData.template) === true
                            &&
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            (
                                <Labeled label={"Headers Table"}>
                                    <ArrayField label="Headers Table" source={`header_table`} record={record} >
                                        <SingleFieldList>
                                            <TextField label="Header Label" source="header" />
                                        </SingleFieldList>
                                    </ArrayField>
                                </Labeled>
                            )
                        }
                        {
                            ['list_video'].includes(sourceData.template) === true
                            &&
                            ['dynamic'].includes(sourceData.type)
                            &&
                            (
                                <Grid columns={3}>
                                    <Labeled label={"Height Player"}>
                                        <TextField
                                            source={`player_height`}
                                            record={record}
                                        />
                                    </Labeled>
                                    <Grid columns={2} style={{gridTemplateColumns:"30% 1fr"}} classes={classes.groupSettings}>
                                        <Labeled label={"Player ID"}>
                                            <TextField
                                                source={`player_id`}
                                                record={record}
                                            />
                                        </Labeled>
                                        {
                                            sourceData && sourceData.player_id
                                            &&
                                            <Labeled label={"Jwplayer Provider"}>
                                                <SelectField
                                                    className={classes.lineSettings}
                                                    source={`jwplayer_provider`}
                                                    record={record}
                                                    choices={[{id:"future",name:"Future"}]}
                                                />
                                            </Labeled>
                                        }
                                    </Grid>
                                    <Labeled label={"Use as content?"}>
                                        <BooleanField
                                            source={`is_content`}
                                            record={record}
                                        />
                                    </Labeled>
                                </Grid>
                            )
                        }
                        {
                            ['carousel'].includes(sourceData.template) === true
                            &&
                            ['dynamic','forumtopics'].includes(sourceData.type)
                            &&
                            (
                            <Grid columns={3}>
                                <Labeled label={"widget.change_media_config"}>
                                    <TextField
                                        className={classes.lineSettings}
                                        source={`change_media_config`}
                                        record={record}
                                    />
                                </Labeled>
                                <Grid columns={3} style={{gridTemplateColumns:"33% 1fr 1fr"}} classes={classes.groupSettings}>
                                    <Labeled label={"Is scrollable?"}>
                                        <BooleanField
                                                defaultValue={true}
                                                source={`is_scrollable`}
                                                record={record}
                                            />
                                    </Labeled>
                                    {sourceData && sourceData.is_scrollable &&
                                    <Fragment>
                                        <Labeled label={"From item (index)"}>
                                            <TextField
                                                    className={classes.lineSettings}
                                                    source={`scroll_from`}
                                                    record={record}
                                                    defaultValue={3}
                                                />
                                        </Labeled>
                                        <Labeled label={"With gap (%)"}>
                                            <TextField
                                                className={classes.lineSettings}
                                                source={`scroll_gap`}
                                                record={record}
                                                defaultValue={20}
                                            />
                                        </Labeled>
                                    </Fragment>
                                    }
                                </Grid>
                            </Grid>
                            )
                        }
                    </Fragment>
                </Grid>
                                    </TabContainer>
                                )}
                                {value === 3 && (
                                    <TabContainer>
                                        <Grid classes={classes.groupSettings}>
                                            <Labeled style={{fontSize:30,fontWeight:"bold"}} className={classes.relatedTemplateLabeled} label={`Commercial Header`} />
                                            <Grid columns="4">
                                                <Labeled label="widget.commercial_label">
                                                    <TextField source="commercial_label" record={record} />
                                                </Labeled>
                                                <Labeled label="widget.commercial_class_logo">
                                                    <TextField source="commercial_class_logo" record={record} />
                                                </Labeled>
                                                <Labeled label="widget.commercial_url_logo">
                                                    <TextField source="commercial_url_logo" record={record} />
                                                </Labeled>
                                                <Labeled label="widget.commercial_link_logo">
                                                    <TextField source="commercial_link_logo" record={record} />
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Labeled label="widget.sponsored_item">
                                                <BooleanField source="sponsored_item" record={record} />
                                            </Labeled>
                                            <Labeled label="widget.sponsored_item_position">
                                            <TextField source="sponsored_item_position" record={record} />
                                            </Labeled>
                                    </TabContainer>
                                )}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
            )
        }}
        </FormDataConsumer>
        : <div/>;
    }
}

export default withStyles(styles)(WidgetCard);
