import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SelectInput, FormDataConsumer } from 'react-admin';
import { change } from 'redux-form';
import { makeBranchedUrl } from '../restClient';
import ConditionsValueInput from './ConditionsValueInput';

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    buttons: {
        alignSelf: 'center',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: `160px 1fr 160px 1fr ${theme.spacing.unit * 6}px ${theme.spacing.unit * 6}px`,
        columnGap: theme.spacing.unit,
        width: '100%',
    }
});

export class ConditionsInput extends Component {
    CancelToken = axios.CancelToken;
    cancel = null;

    state = {
        isCheckingQuery: false,
        impactedDocuments: null,
    };

    componentDidMount() {
        const { site, fields, conditionsQuery } = this.props;
        const conditions = fields.getAll();
        const siteCondition = conditions ? conditions.filter(condition => condition.field === 'site') : [];
        // we have a site but it's not in the conditions
        if (site && !siteCondition.length) {
            fields.splice(0, 0, { field: 'site', operator: '=', value: site });
        }
        if (conditionsQuery) {
            this.checkConditionQuery(conditionsQuery);
        }
    }

    componentDidUpdate(prevProps) {
        const { fields, dispatch, meta: { form }, conditionsQuery } = this.props;
        const conditions = fields.getAll();
        const prevConditions = prevProps.fields.getAll();
        if (conditions !== prevConditions) {
            const query = preview(conditions);
            dispatch(change(form, 'conditions_query', query));
        }
        if (conditionsQuery !== prevProps.conditionsQuery) {
            this.checkConditionQuery(conditionsQuery);
        }
    }

    componentWillUnmount() {
        if (this.cancel) {
            this.cancel('aborting current request');
        }
    }

    checkConditionQuery = (conditionsQuery) => {
        if (!conditionsQuery) {
            return;
        }
        this.setState({ isCheckingQuery: true });
        if (this.cancel) {
            this.cancel('aborting for a new request');
        }
        axios.get(makeBranchedUrl(`/api/pagelayouts_condition/${btoa(conditionsQuery)}`), {
            cancelToken: new this.CancelToken((c) => {
                this.cancel = c;
            }),
        })
            .then((response) => {
                this.setState({
                    isCheckingQuery: false,
                    impactedDocuments: response.data.documents,
                });
            });

    };

    render() {
        const { fields, classes, conditionsQuery } = this.props;
        const { impactedDocuments, isCheckingQuery } = this.state;
        return (
            <Fragment>
                <Paper className={classes.paper}>
                    {fields.length > 0 &&
                        fields.map((itemSource, index) => (
                            <div key={`condition-${index}`}>
                                <Typography component="div">
                                    <div className={classes.grid}>
                                        <SelectInput
                                            style={{ opacity: index === 0 ? 0 : 1 }}
                                            label="flexi.condition_join"
                                            source={`${itemSource}.join`}
                                            choices={[
                                                { id: 'and', name: 'and' },
                                                { id: 'or', name: 'or' },
                                            ]}
                                        />
                                        <SelectInput
                                            label="flexi.condition_field"
                                            source={`${itemSource}.field`}
                                            allowEmpty
                                            choices={window.fcsisSearchFields}
                                        />
                                        <SelectInput
                                            label="flexi.condition_operator"
                                            source={`${itemSource}.operator`}
                                            choices={window.operators.map(id => ({ id, name: id }))}
                                        />
                                        <FormDataConsumer>
                                            {({ formData }) =>
                                                <ConditionsValueInput
                                                    source={`${itemSource}.value`}
                                                    fieldValue={formData.conditions[index].field}
                                                />
                                            }
                                        </FormDataConsumer>
                                        <Tooltip
                                            title="Remove this condition"
                                        >
                                            <IconButton
                                                className={classes.buttons}
                                                onClick={() => fields.remove(index)}
                                            >
                                                <MinusIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {index === (fields.length - 1) &&
                                            <Tooltip
                                                title="Add a new condition"
                                            >
                                                <IconButton
                                                    className={classes.buttons}
                                                    onClick={() => fields.push({ join: 'and', operator: '=' })}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </Typography>
                            </div>
                        ))
                    }
                    {fields.length === 0 &&
                        <Button
                            size="small"
                            onClick={() => fields.push({ join: 'and', operator: '=' })}
                        >
                            <AddIcon /> Add a condition
                        </Button>
                    }
                </Paper>
                <Paper className={classes.paper}>
                    <Typography component="div" gutterBottom>
                        <Typography variant="caption" gutterBottom>
                            Query preview
                        </Typography>
                        {conditionsQuery}
                    </Typography>
                    <Typography component="div">
                        <Typography variant="caption" gutterBottom>
                            Impacted documents by this query
                        </Typography>
                        {isCheckingQuery && <CircularProgress size={16} />}
                        {!isCheckingQuery && impactedDocuments}
                    </Typography>
                </Paper>
            </Fragment>
        );
    }
}

function preview(conditions) {
    return conditions ?
        conditions
            .filter(condition => condition.field && condition.value)
            .map((condition, i) => [
                    i === 0 ? '' : condition.join,
                    condition.field,
                    condition.operator,
                    formatValue(condition.value)
                ].join(' ').trim()
            )
            .join(' ')
            .trim()
        : null;
}

function formatValue(value) {
    if (Array.isArray(value)) {
        return `(${value.map(formatValue).join(', ')})`;
    } else if (Number.isInteger(value)) {
        return value;
    }
    return `'${value}'`;
}

export default withStyles(styles)(ConditionsInput);