import React, { useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { isSubStrIgnoreCase } from "../tools";

const styles = {
  expandIconStyles: {
    color: "slategrey",
    cursor: "pointer",
    marginBottom: "-7px",
  },
  highlighted: {
    background: 'yellow',
    width: "fit-content",
    padding: "2px",
    borderRadius: "5px"
  },
  objectRendererContainer: { marginLeft: "10px", marginBottom: "3px" },
  objectRendererKey: { display: "flex", alignItems: "center" },
  greyColor: { color: "grey" },
  marginLeft: { marginLeft: "30px" },
  viewMoreBtn: { cursor: "pointer", color: "#0b0b9d" },
  valueRendererContainer: { marginLeft: "34px", marginBottom: "3px" }
};
const renderNodes = (node, filterStr) => {
  if (Array.isArray(node)) {
    return node.map((item) => <TreeItem value={item} filterStr={filterStr}/>);
  }
  return Object.keys(node).map((key) => {
    return <TreeItem objKey={key} value={node[key]} filterStr={filterStr} />;
  });
};

//also renders arrays
const ObjectRenderer = ({ objKey, value, filterStr }) => {
  const [isExpanded, setIsExpanded] = useState(!!filterStr);
  const startingChar = Array.isArray(value) ? "[" : "{";
  const endingChar = Array.isArray(value) ? "]" : "}";

  return (
    <div style={styles.objectRendererContainer}>
      <div style={styles.objectRendererKey}>
        <span
          style={styles.expandIconStyles}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
        </span>
        <div>{objKey ? <div><span style={isSubStrIgnoreCase(objKey, filterStr) ? styles.highlighted : {}}>&quot;{objKey}&quot;</span>:</div> : ""}</div>
        <span>
          {startingChar}{" "}
          {!isExpanded && (
            <span>
              {Object.keys(value).length ? (
                <span style={styles.greyColor}>...</span>
              ) : (
                ""
              )}{" "}
              {endingChar}{" "}
            </span>
          )}
        </span>
      </div>

      <div>{isExpanded && renderNodes(value, filterStr)}</div>
      {isExpanded && <span style={styles.marginLeft}>  {endingChar}</span>}
    </div>
  );
};

const ValueRenderer = ({ objKey, value, filterStr }) => {
  const longStringRenderer = (longStr) => {
    const [displayVal, setDisplayVal] = useState(
      longStr.substring(0, 130) + "..."
    );

    const ViewBtn = () => {
      return (
        <span
          style={styles.viewMoreBtn}
          onClick={() =>
            setDisplayVal(
              displayVal.length > 133
                ? longStr.substring(0, 130) + "..."
                : value
            )
          }
        >
          View {displayVal.length > 133 ? "Less" : "More"}
        </span>
      );
    };

    return (
      <>
        &quot;{displayVal}&quot; <ViewBtn />
      </>
    );
  };

  return (
    <div style={styles.valueRendererContainer}>
      {objKey && <span style={isSubStrIgnoreCase(objKey, filterStr) ? styles.highlighted : {}}>&quot;{objKey}&quot;: </span>}
      {value && typeof value !== "string" ? (
        <span style={isSubStrIgnoreCase(value.toString(), filterStr) ? {...styles.highlighted, ...{ color: "blue" }} : { color: "blue" }}>{value.toString()}</span>
      ) : value && typeof value === "string" ? (
        <span style={isSubStrIgnoreCase(value, filterStr) ? {...styles.highlighted, ...{ color: "green" }} : { color: "green" }}>
          
          {value && value.length > 170 ? longStringRenderer(value) : <span>&quot;{value}&quot;</span>}
         
        </span>
      ) : (
        <span>&quot;&quot;</span>
      )}
      ,
    </div>
  );
};

const TreeItem = ({ objKey, value, filterStr }) => {
  return (
    <div>
      {value && typeof value === "object" ? (
        <ObjectRenderer objKey={objKey} value={value} filterStr={filterStr}/>
      ) : (
        <ValueRenderer objKey={objKey} value={value} filterStr={filterStr}/>
      )}
    </div>
  );
};

const TreeView = ({ data , filterStr}) => {
  return (
    <div>
      {"{"} {renderNodes(data, filterStr)} {"}"}
    </div>
  );
};

export default TreeView;
