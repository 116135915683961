import React, {Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { getSource } from '../tools';
import {
    Labeled, TextField
} from 'react-admin';

const styles = () => ({
    root: {
        width: '100%',
    },
    label: {
        display: 'block',
    },
    detailsRoot: {
        display: 'block',
    }
});

class VanillaNavigationListField extends Component {

    shouldComponentUpdate() {
        return true;
    }
    render() {
        const {
            classes, addRootLabel, label, getTitle, Fields, source, record
        } = this.props;
        return (
            <div className={classes.root}>
                {addRootLabel && <Labeled label={label} classes={{ label: classes.label }} />}
                {
                getSource(record,source)
                    && getSource(record,source).length > 0
                    && getSource(record,source).map((item, index) => {
                                return (
                                <ExpansionPanel key={`api-list-field-${source}-${index}`}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {getTitle(item)}
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
                                        {Fields.map((Field, i) => {
                                            return(
                                            <div key={`api-list-field-${source}-${index}-${i}`}>
                                                <Labeled
                                                    source={source}
                                                    label={Field.props.label}
                                                    classes={{ label: classes.label }}
                                                >
                                                {React.cloneElement(Field, {
                                                    record: item,
                                                })}
                                                </Labeled>
                                            </div>
                                        )})}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        }
                    )
                }
            </div>
        );
    }
}
VanillaNavigationListField.defaultProps = {
    addRootLabel: true,
    label: 'Field list',
    getTitle: item => item.key ? item.key: "",
    Fields: [
        <TextField source="key" label={"Key"} style={{display:"block"}} />,
        <TextField source="value" label={"Value"} style={{display:"block"}} />,
    ],
};

export default withStyles(styles)(VanillaNavigationListField);
