import React, {Component} from 'react';
import { SelectInput } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class AreasField extends Component {
    constructor(props) {
        super(props);
        const {record:{widgets}} = props;
        this.state = {
            widgets: widgets,
            existingAreas: widgets ? [...new Set(widgets.map(item => item.area))]:[]
        };
    }

    render() {
        const { existingAreas } = this.state;

        return (
            <Paper>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Existing Areas</TableCell>
                            <TableCell align="right">Areas Disposition</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {existingAreas && existingAreas.map(area => (
                            <TableRow key={area}>
                                <TableCell component="th" scope="row">
                                    Widget Area {area}
                                </TableCell>
                                <TableCell align="right">
                                    <SelectInput
                                        source={`areasType.area${area}`}
                                        label="Area Model"
                                        choices={window.areasTypes}
                                        resettable
                                    />
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export default AreasField;
